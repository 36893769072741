export function background (currentPage, pageSize) {
    const borderWidth = 1; // Adjust the border width
    const spaceBetweenBorders = 1; // Adjust the space between the borders

    const outerBorder = {
        type: "rect",
        x: 27,
        y: 27,
        w: pageSize.width - 55,
        h: pageSize.height - 55,
        lineWidth: borderWidth,
    };

    const innerBorder = {
        type: "rect",
        x: outerBorder.x + borderWidth + spaceBetweenBorders,
        y: outerBorder.y + borderWidth + spaceBetweenBorders,
        w: outerBorder.w - (2 * (borderWidth + spaceBetweenBorders)),
        h: outerBorder.h - (2 * (borderWidth + spaceBetweenBorders)),
        lineWidth: borderWidth,
    };

    const contentTable = {
        table: {
            widths: [pageSize.width - (20 + spaceBetweenBorders * 2)],
            heights: [pageSize.height - (20 + spaceBetweenBorders * 2)],
            body: [[""]],
        },
        layout: "noBorders",
        margin: 5,
    };

    return {
        canvas: [outerBorder, innerBorder, contentTable],
    };
}