import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
// import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import { setting_svg } from "../../Utils/SVGListing";
import CompanyNameTable from "../../Components/DataTables/CompanyNameTable";

const CompanyName = () => {
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  // const [searchNameValue, setSearchNameValue] = useState("")

  // To redirect to add page
  const redirectToAdd = () => {
    history.push({
      pathname: "/company/add-edit-company",
      state: { label: "Add" },
    });
  };
  return (
    <DashBoardLayout pageTitle="Company">
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Pending Booking" /> */}
        {/* Search and setting */}
        <div className="flex md:flex-row flex-col md:justify-between  gap-2">
          <div>
            <SearchField
              placeholder="Search By Company Name"
              handleChange={setSearchValue}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          {/* <div>
            <SearchField 
                placeholder="Searching By Person" 
                handleChange={setSearchNameValue} 
                value={searchNameValue}/>
          </div> */}
          <div className="flex flex-row space-x-2 items-start  md:order-last	order-first">
            <Button
              svg={setting_svg}
              onClick={() => history.push("/profile")}
            ></Button>
            <ButtonWithBG
              handleClick={redirectToAdd}
              theme="primary"
              label="Add Company"
            />
          </div>
        </div>
      </div>
      <CompanyNameTable
        searchValue={searchValue}
        // searchNameValue={searchNameValue}
      />
    </DashBoardLayout>
  );
};

export default CompanyName;
