import React from "react";

const index = ({isPaid,value}) => {
  const styles = {
    common: ` px-2 py-1 inline-flex rounded-md justify-between space-x-2 cursor-pointer`,
    // Pending: `bg-primary-kirtiroadways font-bold text-white`,
    Paid: `bg-green-100  font-bold text-green-700`,
  };
  return (
    <div
      className={`${styles.common} ${isPaid ? styles["Paid"] : ''}`}
    >
      <div>{value}</div>
    </div>
  );
};

export default index;
