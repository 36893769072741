import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { ADD_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
import { UPDATE_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import Select from "react-select";
import Loader from "../../Components/UI/Loader";
import { CommissionOptions } from "../../Utils/Constants";
import Notification from "../../Components/UI/Notification";
import APILoader from "../../Components/UI/APILoader";
import ImageUploading from "react-images-uploading";
import { triggerBase64Download } from "react-base64-downloader";
import ReactiveButton from "../../Components/UI/Button/ReactiveButton";
import axiosInstance from "../../Utils/API";

const Input = (props) => (
  <input
    className="peer uppercase w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function AddEditVehicle({ props }) {
  const location = useLocation();
  const history = useHistory();
  const [heading, setHeading] = useState("");
  const [commission, setCommission] = useState(false);
  const [commissionType, setCommissionType] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [notif, setNotif] = useState(false);
  const [notifOpts, setNotifOpts] = useState({});
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  const [images, setImages] = useState([]);
  const [delteImages, setDeleteImages] = useState([]);

  const SelectHandler = (e) => {
    setCommissionType(e.value);
    if (e.value === "Manual") {
      setCommission(true);
    } else {
      setCommission(false);
    }
  };

  // To fetch Data from previous route
  useEffect(() => {
    if (location?.state?.label === "Add") {
      setHeading("Add Vehicle Owner");
    } else {
      setHeading("Edit Vehicle Owner");
    }
    setCommissionType(location?.state?.data?.data?.commissionType);
    if (location?.state?.data?.data?.commissionType === "Manual") {
      setCommission(true);
    }
    // console.log(location?.state?.data?.data);
    let result = location?.state?.data?.data?.images.map((option) => {
      return {
        id: option.imageId,
        data_url: option.imageUrl,
        name: option.imageName,
      };
    });
    setImages(result);
    // console.log(result, "results");
    // const newFile = result.map((file) => {
    //   return { data_url: file };
    // });
    // setImages(result)
  }, [location]);

  // For Loading
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setIsLoading(false);
    };
    loadData();
  }, []);

  // Image On changes
  const onChangeImages = (imageList, addUpdateIndex, name) => {
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);

    if (addUpdateIndex) {
      let modify = imageList;
      var newdata = modify.pop();
      Object.assign(newdata, { name: "" });
      imageList.push(newdata);
    }
    // console.log(imageList);
  };

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };
  //   Form Submit click event
  const submitForms = async (ev) => {
    ev.preventDefault();
    setLoader({ ...loader, loading: true });
    var Image_files = [];
    if(images !== undefined){
      for (var i = 0; i < images.length; i++) {
        var imageArray = {};
        // imageArray = images[i].data_url;
        if (!images[i].id) {
          Object.assign(imageArray, {
            ImageName: images[i].name,
            ImageUrl: images[i].data_url,
          });
          // console.log(imageArray, "obj");
          // imageArray = images[i].name;
          Image_files.push(imageArray);
        }
      }
    }
    // console.log(Image_files, "delteImages");
    var obj = formValueConvertor(getFormJSON(ev.target));
    localStorage.setItem("objData", JSON.stringify({ ...obj }));
    if (location?.state?.label === "Add") {
      let VEHICLE_OWNER_DATA = {
        Name: obj?.Owner_Name?.toUpperCase(),
        PanNo: obj.Pan_No === " " ? "NA" : obj?.Pan_No?.toUpperCase(),
        ContactNo: obj.Contact_No,
        CreatedBy: localStorage.getItem("userId"),
        CommissionType: commissionType,
        Amount:
          commissionType === "Manual" ? parseInt(obj.manualCommission) : 0,
        Images: Image_files,
      };
      // await axios({
      //   method: "post",
      //   url: `${APIURL}${ADD_VEHICLE_OWNER}`,
      //   data: VEHICLE_OWNER_DATA,
      //   headers: {
      //     "Content-Type":
      //       "multipart/form-data; boundary=<calculated when request is sent>",
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Access-Control-Allow-Origin": "http://localhost:3000",
      //     "Access-Control-Allow-Credentials": "true",
      //     "Access-Control-Allow-Headers": "content-type",
      //     "Access-Control-Allow-Methods":
      //       "PUT, POST, GET, DELETE, PATCH, OPTIONS",
      //   },
      // })
      await axiosInstance
        .post(ADD_VEHICLE_OWNER, VEHICLE_OWNER_DATA)
        .then((response) => {
          //handle success
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/vehicle",
          });
        })
        .catch((response) => {
          //handle error
          ConfirormationModal(response.data.data.message, "error", 2000);
        });
      // const formData = new FormData();
      // formData.append("Name", obj.Owner_Name.toUpperCase());
      // formData.append(
      //   "PanNo",
      //   obj.Pan_No === " " ? "NA" : obj.Pan_No.toUpperCase()
      // );
      // formData.append("ContactNo", obj.Contact_No);
      // formData.append("CreatedBy", localStorage.getItem("userId"));
      // formData.append("CommissionType", commissionType);
      // formData.append(
      //   "Amount",
      //   commissionType === "Manual" ? parseInt(obj.manualCommission) : 0
      // );
      //   for (let i = 0; i < images.length; i++) {
      //     formData.append('Images', images[i].files)
      // }
      // for (const item of Image_files) {
      //   formData.append("Images", new Blob([item]));
      // }
      //  console.log(file)
      // await axiosInstance
      //   .post(ADD_VEHICLE_OWNER, formData)
      //   .then((response) => {
      //     ConfirormationModal(response.data.data.message, "success", 2000);
      //     history.push({
      //       pathname: "/vehicle",
      //     });
      //   })
      //   .catch((error) => {
      //     ConfirormationModal(error.message, "error", 2000);
      //   });
    } else {
      let EDIT_VEHICLE_OWNER_DATA = {
        OwnerId: location?.state?.data?.data?.ownerId,
        Name: obj.Owner_Name.toUpperCase(),
        PanNo: obj.Pan_No === " " ? "NA" : obj.Pan_No,
        ContactNo: obj.Contact_No,
        CreatedBy: localStorage.getItem("userId"),
        CommissionType: commissionType,
        Amount:
          commissionType === "Manual" ? parseInt(obj.manualCommission) : 0,
        DeletedImage: delteImages,
        Images: Image_files,
      };
      await axiosInstance
        .post(UPDATE_VEHICLE_OWNER, EDIT_VEHICLE_OWNER_DATA)
        .then((response) => {
          setNotif(true);
          setNotifOpts({
            mode: "success",
            timeout: 10000,
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/vehicle",
          });
        })
        .catch((error) => {
          ConfirormationModal(error.message, "error", 2000);
        });
      // let EditformData = new FormData();
      // EditformData.append("OwnerId", location?.state?.data?.data?.ownerId);
      // EditformData.append("Name", obj.Owner_Name.toUpperCase());
      // EditformData.append(
      //   "PanNo",
      //   obj.Pan_No === " " ? "NA" : obj.Pan_No.toUpperCase()
      // );
      // EditformData.append("ContactNo", obj.Contact_No);
      // EditformData.append("CreatedBy", localStorage.getItem("userId"));
      // EditformData.append("CommissionType", commissionType);
      // EditformData.append(
      //   "Amount",
      //   commissionType === "Manual" ? parseInt(obj.manualCommission) : 0
      // );
      // EditformData.append(
      //   "DeletedImage",
      //   location?.state?.data?.data?.imageUrl.length > 0
      //     ? location?.state?.data?.data?.imageUrl[0].imageId
      //     : " "
      // );
      // EditformData.append("DeletedImage", delteImages);
      // for (const item of Image_files) {
      //   if (item !== undefined) {
      //     EditformData.append("Images", new Blob([item]));
      //   }
      // }

      // await axios({
      //   method: "post",
      //   url: `${APIURL}${UPDATE_VEHICLE_OWNER}`,
      //   data: EditformData,
      //   headers: {
      //     "Content-Type":
      //       "multipart/form-data; boundary=<calculated when request is sent>",
      //     Authorization: "Bearer " + localStorage.getItem("token"),
      //     "Access-Control-Allow-Origin": "http://localhost:3000",
      //     "Access-Control-Allow-Credentials": "true",
      //     "Access-Control-Allow-Headers": "content-type",
      //     "Access-Control-Allow-Methods":
      //       "PUT, POST, GET, DELETE, PATCH, OPTIONS",
      //   },
      // })
      //   .then((response) => {
      //     //handle success
      //     setNotif(true);
      //     setNotifOpts({
      //       mode: "success",
      //       timeout: 10000,
      //       top: 10,
      //       title: "Success",
      //       content: response.data.data.message,
      //     });
      //     ConfirormationModal(response.data.data.message, "success", 2000);
      //     history.push({
      //       pathname: "/vehicle",
      //     });
      //   })
      //   .catch((response) => {
      //     //handle error
      //     ConfirormationModal(response.message, "error", 2000);
      //   });
    }
  };
  return (
    <DashBoardLayout pageTitle={heading}>
      <APILoader loader={loader} />
      <Notification
        show={notif}
        setShow={setNotif}
        {...notifOpts}
        supportContent={true}
      ></Notification>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md h-full pb-16">
          {/* <button onClick={()=>{console.log(location.state.data.data)}}>Click</button> */}
          <form onSubmit={submitForms}>
            <div className="h-full">
              <div className=" text-left space-y-8 h-full">
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  {/* Owner Name */}
                  <div className=" flex flex-col  text-left md:w-1/2 h-full">
                    <Label label="Owner Name" isRequired="true" />
                    <Input
                      defaultValue={location?.state?.data?.data?.name}
                      name="Owner_Name"
                      required
                    />
                  </div>
                  <div className="w-1/2">
                    <Label label="Commission Type" />
                    <Select
                      defaultValue={{
                        label: location?.state?.data?.data?.commissionType,
                        value: location?.state?.data?.data?.commissionType,
                      }}
                      onChange={SelectHandler}
                      options={CommissionOptions}
                      placeholder="Select Commission Type"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 5,
                        colors: {
                          ...theme.colors,
                          primary25: "#f68e92",
                          primary: "#ed1c24",
                        },
                      })}
                    />
                  </div>

                </div>

                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 ">
                {commission && (
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Manual Commission Amount" />
                      <Input
                        type="text"
                        name="manualCommission"
                        // required
                        autoComplete="off"
                        defaultValue={location?.state?.data?.data?.amount}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="flex flex-row mt-10">
              <input type="file" name="Image" onChange={handleChange} />
              <div className="flex flex-col">
                <div className="h-52 w-52 border-2 border-slate-200">
                  <img
                    className="h-52 w-52"
                    src={
                      displayImage ||
                      (location?.state?.data?.data?.imageUrl.length > 0
                        ? location?.state?.data?.data?.imageUrl[0].imageUrl
                        : " ")
                    }
                    alt=""
                  />
                </div>
                {displayImage && <Input name="Owner_Image" />}
              </div>
            </div> */}
            <div className="flex  mt-10">
              <ImageUploading
                multiple
                value={images}
                onChange={onChangeImages}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper space-x-2    ">
                    <ButtonWithBG
                      className="px-4 py-2  border border-transparent text-sm font-medium  rounded-md focus:outline-none focus:ring-2 focus:outline-kirtiroadways-300 focus:ring-offset-2 text-center text-white bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover "
                      style={isDragging ? { color: "red" } : undefined}
                      handleClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop Image
                    </ButtonWithBG>
                    &nbsp;
                    {imageList.length > 0 && (
                      <ButtonWithBG
                        theme="secondary"
                        handleClick={() => {
                          let imageDeleteAll = [];
                          // if(isKeyPresent){
                          imageList.map((image, index) => {
                            if (image.id) {
                              return imageDeleteAll.push(image.id);
                            }
                            return null;
                          });
                          // console.log(imageDeleteAll);
                          setDeleteImages(imageDeleteAll);
                          // }
                          onImageRemoveAll();
                        }}
                      >
                        Remove all images
                      </ButtonWithBG>
                    )}
                    <div className="flex flex-row  justify-center w-full space-x-7 flex-wrap m-5">
                      {imageList.map((image, index, name) => (
                        <div
                          key={index}
                          className="image-item my-5 flex flex-row "
                        >
                          <div className=" flex flex-col  items-center  space-y-2">
                            <div className="border-2 border-primary-kirtiroadways-hover p-1">
                              <img
                                src={image["data_url"]}
                                alt=""
                                style={{
                                  height: "200px",
                                  maxHeight: "200px",
                                  width: "250px",
                                  maxWidth: "250px",
                                }}
                              />
                              {/* <p className=" mt-2  border-t border-primary-kirtiroadways">{ image["file"].name}</p> */}
                            </div>
                            <div>
                              <Input
                                defaultValue={image.name}
                                name={name}
                                onChange={(e) => {
                                  // images.map((l) => {
                                  Object.assign(imageList[index], {
                                    name: e.target.value,
                                  });
                                  // console.log(imageList);
                                  // });
                                }}
                              />
                            </div>
                            <div className="image-item__btn-wrapper space-x-2">
                              {/* {location?.state?.label === "Add" && (
                                <ButtonWithBG
                                  handleClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </ButtonWithBG>
                              )} */}
                              <ReactiveButton
                                type="button"
                                id="Download"
                                text="Download"
                                onClick={() => {
                                  // console.log(image);
                                  triggerBase64Download(
                                    image.data_url,
                                    image.name
                                  );
                                }}
                              >
                                download
                              </ReactiveButton>
                              <ButtonWithBG
                                theme="danger"
                                handleClick={() => {
                                  onImageRemove(index);
                                  setDeleteImages((oldArray) => [
                                    ...oldArray,
                                    image.id,
                                  ]);
                                  // setDeleteImages(image.id)
                                }}
                              >
                                Remove
                              </ButtonWithBG>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>
            <div className="items-ceter mt-10">
              <ButtonWithBG theme="primary" label="SAVE" type="submit" />
            </div>
          </form>
        </div>
      )}
    </DashBoardLayout>
  );
}
