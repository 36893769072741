import React, { useState, useEffect, Fragment, useRef } from "react";
import logo from "../../../Images/logo_transparent.png";
import black_logo from "../../../Images/black_logo.png";
import { GET_BANK_LIST } from "../../../Utils/ApiGlobal";
import axiosInstance from "../../../Utils/API";
import Select from "react-select";
import qrcode from "../../../Images/qrcode.jpg";
import {
  InvoicSubject,
  KIRTIROADLINES,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  OURBANKDETAILS,
  SCANQRCONTENT,
  PaymentbypayeeCchequeDrafs,
  WE_USING_PAYPHONE,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import moment from "moment";
// import CommissionInvoicePrint from "./CommissionInvoicePrint";
import Checkbox from "../../../Components/UI/Checkbox";
import {
  Tablebg,
  TabletheadRedStyle,
  TabletheadStyle,
  address,
  borderToggle,
  heading,
} from "../../../Utils/TableStyling";
import sign from "../../../Images/sign.png";
import CommissionInvoiceViewDownload from "./CommissionInvoiceViewDownload";
import { Dialog, Transition } from "@headlessui/react";
import ButtonWithBG from "../../../Components/UI/ButtonMain";

const CommissionInvoiceView = (props) => {
  const ref = useRef(null);

  const Label = (props) => (
    <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
      {props.label}
      {props.isRequired === "true" && (
        <>
          {" "}
          <span className="ml-1 text-red-500 block text-sm font-medium ">
            *
          </span>
        </>
      )}
    </p>
  );
  // const [bankDetails, setBankDetails] = useState(true);
  const [bankBranch, setBankBranch] = useState({
    bank: "",
    branch: "",
    accNo: "",
    ifsc: "",
  });
  const [Dropdown, setDropdown] = useState(false);
  const [optionsBank, setOptions] = useState({});
  const [showBorder, setShowBorder] = useState(true);
  const [blackWhite] = useState(true);
  const [headerShow, setHeaderShow] = useState(true); // eslint-disable-next-line
  const [mobilePrint, setMobilePrint] = useState(true);
  const [open, setOpen] = useState(true);
  const [note, setNote] = useState("");
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
  };

  // Access Bank Details
  useEffect(() => {
    getBankDetails();
  }, []);

  const getBankDetails = async () => {
    // setIsLoading(true);
    await axiosInstance.get(`${GET_BANK_LIST}`).then((response) => {
      // console.log(response?.data?.data);
      if (response?.data?.data.length > 0) {
        const options = response?.data?.data.map(
          ({ accountId, bankName, branch, accountNo, ifscCode }, index) => ({
            accountId,
            bankName,
            branch,
            accountNo,
            ifscCode,
          })
        );
        setBankBranch({
          bank: response?.data?.data[0].bankName,
          branch: response?.data?.data[0].branch,
          accNo: response?.data?.data[0].accountNo,
          ifsc: response?.data?.data[0].ifscCode,
        });
        setDropdown(true);
        setOptions(options);
        // console.log(options);
      }
    });
  };
  console.log(props?.data, "props?.data");
  const hasHamali =
    props?.data?.transporterHamali > 0 &&
    props?.data?.transporterHamali !== null;
  const hasExtraCharge =
    props?.data?.transporterExtraCharge > 0 &&
    props?.data?.transporterExtraCharge !== null;
  const hasDetention =
    props?.data?.transporterDetention > 0 &&
    props?.data?.transporterDetention !== null;

  return (
    <>
      {open && (
        <Transition show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={ref}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-300 bg-opacity-95 transition-opacity" />
            </Transition.Child>

            <div className=" fixed inset-0 z-10 overflow-y-auto">
              <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="border-2 border-primary-kirtiroadways  rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-1/2">
                    <div className="bg-white px-8 pt-4 pb-4">
                      <div className="">
                        <div className=" text-center ">
                          <div className="mt-2">
                            <div className=" flex md:w-full  flex-col  text-left ">
                              <Label label="Note" />
                              <textarea
                                className="peer  w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                onChange={(val) =>
                                  setNote(val.target.value.toUpperCase())
                                }
                                maxLength={90}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" px-4 py-3 flex justify-center  sm:px-6">
                        <ButtonWithBG onClick={() => setOpen(false)}>
                          OK
                        </ButtonWithBG>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {props?.data?.transporterName && (
        <div className="m-5">
          <div className="flex flex-row gap-4 justify-center mt-5">
            <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">With Border</p>
              <Checkbox
                defaultChecked={showBorder}
                handleClick={(e) => setShowBorder(e.target.checked)}
              />
            </div>
            {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">Black and white</p>
              <Checkbox
                defaultChecked={blackWhite}
                handleClick={(e) => setBlackWhite(e.target.checked)}
              />
            </div> */}
            <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">Header</p>
              <Checkbox
                defaultChecked={headerShow}
                handleClick={(e) => setHeaderShow(e.target.checked)}
              />
            </div>
            {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
                  <p className="text-xs">Mobile Print</p>
                  <Checkbox
                    defaultChecked={blackWhite}
                    handleClick={(e) => setMobilePrint(e.target.checked)}
                  />
                </div> */}
          </div>
          <div
            className={`${
              showBorder &&
              ` border-double  border-4 ${
                blackWhite ? "border-black" : "border-red-200"
              } `
            } ${
              !headerShow && `mt-52`
            }  pageStyle  block  min-h-screen m-5   align-top text-center bg-center bg-no-repeat  bg-[length:65%]`}
            // style={{
            //   backgroundImage: "url(" + logo_oposity + ")",
            // }}
          >
            <div className="mt-2">
              <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-sm">
                <h5>Tax Invoice</h5>
              </div>
              {/* Logo and Title Start */}
              {headerShow && (
                <div className="m-2 items-center space-x-3 flex flex-row">
                  <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                    <div className="flex flex-row justify-between">
                      <div className="w-80 ml-2">
                        {blackWhite ? (
                          <img src={black_logo} alt="Kirti Roadlines" />
                        ) : (
                          <img src={logo} alt="Kirti Roadlines" />
                        )}{" "}
                      </div>
                      <div className=" flex flex-col  w-full">
                        <span
                          style={{ fontSize: "35px", lineHeight: "1" }}
                          className={`kirtiroadline-headline ${
                            !blackWhite && "text-primary-kirtiroadways"
                          } font-serif font-extrabold `}
                        >
                          {props.profileData.tagLine}
                        </span>
                        <span className="2xl:text-4xl text-3xl font-serif text-black">
                          {props.profileData.subTagLine}
                        </span>
                      </div>
                    </div>
                    {/* Address Start */}
                    <div
                      className={`rounded-b-3xl rounded-t-lg font-bold   p-2 w-full  ${address(
                        blackWhite
                      )} flex flex-col space-y-1 text-center text-sm`}
                    >
                      {" "}
                      <p className="flex  text-md justify-center md:flex-row items-start">
                        <span className="text-md w-5">{address_svg}</span>{" "}
                        <span className="text-md ml-1">
                          {props.profileData.address}
                        </span>
                      </p>
                      <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                        <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                          <span>{call_svg} </span>{" "}
                          <span className="flex flex-row">
                            {props.secondaryPhoneNo.map((number, i) => (
                              <span
                                key={number}
                                className="text-sm font-semibold tracking-wider flex flex-row"
                              >
                                {(i ? ", " : "") + number}
                              </span>
                            ))}
                          </span>
                        </div>
                        <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                          <span>{email_svg} </span>
                          <span>{props.profileData.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Invoice  No and details*/}
            <div
              // className={`${!headerShow && "mt-64"} p-2 flex font-sans flex-row justify-between w-full`}
              className={`p-2 flex font-sans flex-row justify-between w-full`}
            >
              <div className="flex flex-col text-left w-1/2">
                <div
                  className={`font-bold uppercase text-md	py-1 mb-0 px-5 w-36  ${heading(
                    blackWhite
                  )}`}
                >
                  {" "}
                  Invoice to
                </div>
                <div>
                  <span className="text-left font-bold uppercase font-sans text-2xl	subpixel-antialiased tracking-wide mt-0	">
                    {props?.data?.transporterName.toUpperCase()}
                  </span>
                </div>
                {props?.data?.address && (
                  <div className="text-sm text-left">
                    <div className="flex  flex-row space-x-1 mt-1">
                      <p>Address :</p>
                      <p className="w-1/2 ">
                        {props?.data?.address.toUpperCase()}
                      </p>
                    </div>
                  </div>
                )}
                {/* <div className="text-md mt-1">
                  <div className="flex flex-row space-x-1 text-left">
                    <p className="w-14">PAN :</p>
                    <p className="w-1/2">{props?.commissionData?.panNo}</p>
                  </div>
                </div> */}
              </div>

              {/* Right side */}
              <div className="flex  flex-col text-right justify-end items-end">
                <div
                  className={`font-bold uppercase text-md	py-1 mb-0 px-5  ${
                    blackWhite
                      ? "text-black border border-black rounded-lg"
                      : "text-white rounded-lg bg-primary-kirtiroadways"
                  } `}
                >
                  {" "}
                  OUR Details
                </div>
                <div className="flex text-base  flex-row justify-end uppercase font-semibold pt-2">
                  <p className="flex flex-col ">
                    <span>Service : {props.profileData.service}</span>
                    <span>Pan No. : {props.profileData.panNo}</span>
                    <span>State Code : {props.profileData.stateCode}</span>
                  </p>
                  {/* <p className="flex flex-col">
                    <span>Transportation</span>
                    <span>AJGPD9182R</span>
                    <span>24</span>
                  </p> */}
                </div>

                <div
                  className={`uppercase font-semibold	pt-1 mt-2 pl-16 border-t-2 ${borderToggle(
                    blackWhite
                  )} `}
                >
                  <div className="flex flex-row text-sm text-right space-x-2">
                    <span className="flex flex-col ">
                      <p className="text-right ">Invoice No :</p>
                      {/* <p className="text-right min-w-1/2">
                          Bill OF Loading :
                        </p> */}
                      <p className="text-right ">Date :</p>
                      {/* <p className="text-right w-1/2">Transportation</p> */}
                    </span>
                    <span className="flex flex-col ">
                      <p className="text-left min-w-1/2">
                        {props?.data?.invoiceNo ? props?.data?.invoiceNo : "--"}
                      </p>
                      {/* <p className="text-left min-w-1/2">--</p> */}
                      <p className="text-left min-w-1/2">{Today_Date}</p>
                      {/* <p className="text-right w-1/2">Transportation</p> */}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* Table start*/}
            <div style={{ height: "390px" }} className="px-2 w-full">
              <table className=" border-spacing-y-2 border-separate w-full border-neutral-900 text-base font-sans">
                <thead style={{ maxHeight: "5px" }} className="mb-2">
                  <tr className=" border-neutral-900 text-white ">
                    <th
                      style={{
                        maxHeight: "5px",
                      }}
                      className={TabletheadStyle(blackWhite)}
                    >
                      Loading
                      <br />
                      Date
                    </th>
                    <th
                      style={{
                        maxHeight: "5px",
                      }}
                      className={TabletheadStyle(blackWhite)}
                    >
                      Truck
                      <br />
                      No.
                    </th>
                    <th
                      style={{
                        maxHeight: "5px",
                      }}
                      className={TabletheadStyle(blackWhite)}
                    >
                      From
                    </th>
                    <th
                      style={{
                        maxHeight: "5px",
                      }}
                      className={TabletheadStyle(blackWhite)}
                    >
                      To
                    </th>
                    <th
                      style={{
                        maxHeight: "5px",
                      }}
                      className={TabletheadRedStyle(blackWhite)}
                    >
                      Freight
                    </th>
                    <th
                      style={{
                        maxHeight: "5px",
                      }}
                      className={TabletheadRedStyle(blackWhite)}
                    >
                      Advance
                    </th>

                    <th
                      style={{
                        maxHeight: "5px",
                      }}
                      className={` border-black text-black border-b border-t pb-2 ${
                          hasHamali ||  hasDetention || hasExtraCharge ? "border-r" : ""
                      }`}
                    >
                      Balance
                    </th>
                    {hasHamali && (
                      <th
                        style={{
                          maxHeight: "5px",
                        }}
                        className={TabletheadRedStyle(blackWhite)}
                      >
                        Hamali
                      </th>
                    )}
                    {hasDetention && (
                      <th
                        style={{
                          maxHeight: "5px",
                        }}
                        className={` border-black text-black border-b border-t pb-2 ${
                          !hasHamali && hasDetention ? "" : "border-r"
                        }`}
                      >
                        Detention
                      </th>
                    )}
                    {hasExtraCharge && (
                      <th
                        style={{
                          maxHeight: "5px",
                        }}
                        className={` border-black text-black border-b border-t pb-2 `}
                      >
                        Extra
                        <br />
                        Charge
                      </th>
                    )}
                  </tr>
                </thead>
                {/* Tr data */}
                <tbody className="font-bold text-base	align-top text-center w-full">
                  <tr height="20px">
                    <td
                      height="5px"
                      className={` ${
                        blackWhite
                          ? "border-black border-r"
                          : "border-r-2 border-black-300"
                      }   items-center  w-28 `}
                    >
                      <span className="mt-2">
                        {moment(props?.data?.loadingDate).format("DD-MM-YYYY")}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className={` ${
                        blackWhite
                          ? "border-black border-r"
                          : "border-r-2 border-black-300"
                      }   items-center  w-40 `}
                    >
                      <span className="mt-2">
                        {props?.data?.vehicleNo.toUpperCase()}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className={` ${
                        blackWhite
                          ? "border-black border-r"
                          : "border-r-2 border-black-300"
                      }   items-center  w-40 `}
                    >
                      <span className="mt-2">
                        {props?.data?.from.toUpperCase()}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className={` ${
                        blackWhite
                          ? "border-black border-r"
                          : "border-r-2 border-black-300"
                      }   items-center  w-40 `}
                    >
                      <span className="mt-2">
                        {props?.data?.to.toUpperCase()}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className={` ${
                        blackWhite
                          ? "border-black border-r"
                          : "border-r-2 border-black-300"
                      }   items-center  w-24 `}
                    >
                      <span className="ml-2 mt-2 text-center">
                        {props?.data?.transporterFreigth}
                      </span>
                    </td>
                    <td
                      height="20px"
                      className={` ${
                        blackWhite
                          ? "border-black border-r"
                          : "border-r-2 border-black-300"
                      }   items-center  w-24 `}
                    >
                      <span className="mt-2 text-center">
                        {props?.data?.transporterAdvance}
                      </span>
                    </td>
                    <td height="20px"   className={`w-24 border-black text-black mt-2 text-center ${
                          hasHamali ||  hasDetention || hasExtraCharge ? "border-r":""}`}>
                      <span className={`mt-2 text-center`}>
                        {props?.data?.transporterBalance}
                      </span>
                    </td>
                    {hasHamali && (
                      <td
                        height="20px"
                        className={` ${
                          blackWhite
                            ? "border-black border-x"
                            : "border-x-2 border-black-300"
                        }   items-center  w-24 `}
                      >
                        <span className="mt-2 text-center">
                          {props?.data?.transporterHamali}
                        </span>
                      </td>
                    )}
                    {hasDetention && (
                      <td
                        height="20px"
                        className={` ${
                          !hasHamali
                            ? "border-l border-black"
                            : "border-r border-black"
                        } items-center  w-24 `}
                      >
                        <span className="mt-2 text-center">
                          {props?.data?.transporterDetention}
                        </span>
                      </td>
                    )}
                    {hasExtraCharge && (
                      <td height="20px" className="w-24">
                        <span className="mt-2 text-center">
                          {props?.data?.transporterExtraCharge}
                        </span>
                      </td>
                    )}
                  </tr>
                  <tr style={{ height: "250px" }}></tr>
                </tbody>
              </table>
            </div>
            <table className="w-full ">
              {/* Total data */}
              <thead className="py-2">
                <tr className="  border-b-[8px] border-white ">
                  <th className="pl-2">
                    {note ? (
                      <div
                        className={`px-1 leading-snug h-14 py-1 w-[450px] text-md tracking-wide rounded-lg uppercase ${Tablebg(
                          blackWhite
                        )} flex items-start`}
                      >
                        {" "}
                        <p className="font-bold w-14">NOTE:</p>
                        <p
                          style={{ fontSize: "12px" }}
                          className="font-semibold break-all"
                        >
                          {note}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </th>
                  <th
                    style={{ minHeight: "10px" }}
                    colSpan="6"
                    className="w-20"
                  ></th>
                  <th
                    style={{
                      minHeight: "10px",
                    }}
                    className="w-28  align-middle p-0"
                  >
                    <div
                      className={`w-full text-center h-10 py-2 tracking-wide self-center font-bold uppercase ${
                        !blackWhite
                          ? "text-white bg-primary-kirtiroadways border-r-2"
                          : "border border-black border-r"
                      } `}
                    >
                      Total
                    </div>
                  </th>
                  <th
                    style={{
                      maxHeight: "5px",
                    }}
                    className={`w-28  align-middle p-0 ${
                      hasExtraCharge || hasDetention || hasHamali ? "" : "pr-2"
                    }`}
                  >
                    <div
                      className={`w-full text-center h-10 py-2 tracking-wide self-center font-bold uppercase ${
                        !blackWhite
                          ? "text-white bg-primary-kirtiroadways border-r-2"
                          : "border border-black border-r"
                      } `}
                    >
                      {props?.data?.transporterBalance}
                    </div>
                  </th>
                  {hasHamali && (
                    <th
                      style={{ maxHeight: "5px" }}
                      className={`w-28  align-middle p-0 ${
                        hasExtraCharge || hasDetention ? "" : "pr-2"
                      }`}
                    >
                      <div
                        className={`w-full text-center h-10 py-2 tracking-wide self-center font-bold uppercase ${
                          !blackWhite
                            ? "text-white bg-primary-kirtiroadways border-r-2"
                            : "border border-black border-r"
                        } `}
                      >
                        {props?.data?.transporterHamali}
                      </div>
                    </th>
                  )}
                  {hasDetention && (
                    <th
                      style={{ maxHeight: "5px" }}
                      className={`w-28  align-middle p-0 ${
                        hasExtraCharge ? "" : "pr-2"
                      }`}
                    >
                      <div
                        className={`w-full text-center h-10 py-2 tracking-wide self-center font-bold uppercase ${
                          !blackWhite
                            ? "text-white bg-primary-kirtiroadways border-r-2"
                            : "border border-black border-r"
                        } `}
                      >
                        {props?.data?.transporterDetention}
                      </div>
                    </th>
                  )}
                  {hasExtraCharge && (
                    <th
                      style={{ maxHeight: "5px" }}
                      className={`w-28  align-middle p-0 pr-2`}
                    >
                      <div
                        className={`w-full text-center h-10 py-2 tracking-wide self-center font-bold uppercase ${
                          !blackWhite
                            ? "text-white bg-primary-kirtiroadways border-r-2"
                            : "border border-black border-r"
                        } `}
                      >
                        {props?.data?.transporterExtraCharge}
                      </div>
                    </th>
                  )}
                </tr>
              </thead>
              {/* Horizotal Line */}
              <tbody className="py-2">
                <tr
                  className={`border-b-2 ${
                    !blackWhite
                      ? "border-primary-kirtiroadways"
                      : "border-black"
                  }  min-w-full`}
                >
                  <td
                    className={`border-b-2 ${
                      !blackWhite
                        ? "border-primary-kirtiroadways"
                        : "border-black"
                    } `}
                    colSpan="10"
                  ></td>
                </tr>
              </tbody>
            </table>

            <div
              className={`flex flex-row mt-0  item-start justify-between p-2	px-2 font-sans border-b-2  ${
                !blackWhite ? "border-primary-kirtiroadways" : "border-black"
              }  font-semibold text-left text-sm`}
            >
              <div className="item-start  w-full">
                <div className="w-full">
                  <div className="flex flex-row justify-between w-full">
                    <div
                      className={`px-1 leading-snug	text-center py-2 w-96 mb-2 text-md tracking-wide  rounded-lg uppercase ${Tablebg(
                        blackWhite
                      )}`}
                    >
                      {" "}
                      <p>{OURBANKDETAILS}</p>
                      <p style={{ fontSize: "10px" }}>( {SCANQRCONTENT} )</p>
                    </div>
                    {/* grand Total */}
                    <div className="flex flex-row  justify-end   h-fit ">
                      <span
                        className={`text-center  text-md whitespace-nowrap	 px-4 py-2 tracking-wide   rounded-lg uppercase ${
                          !blackWhite
                            ? "text-white bg-primary-kirtiroadways "
                            : "border border-black"
                        }  `}
                      >
                        Grand Total : &#8377;{" "}
                        {props?.data?.transporterTotal === null
                          ? "0"
                          : props?.data?.transporterTotal}
                      </span>
                    </div>
                  </div>
                  {/* Bannk Detail Start */}

                  <div className="flex flex-col space-x-1 mt-2">
                    {optionsBank.length > 0 && (
                      <div
                        style={{ minWidth: "350px", maxWidth: "350px" }}
                        className="flex flex-col  text-left w-1/2 z-80"
                      >
                        <Select
                          options={optionsBank.map((el) => ({
                            label: el.bankName,
                            value: el.accountNo,
                            branch: el.branch,
                            billAdd: el.billToAddress,
                            ifscCode: el.ifscCode,
                          }))}
                          onChange={(e) => {
                            setBankBranch({
                              ...bankBranch,
                              bank: e.label,
                              branch: e.branch,
                              accNo: e.value,
                              ifsc: e.ifscCode,
                            });
                            // console.log(e);
                            setDropdown(true);
                          }}
                          placeholder="Bank Branch"
                          className="z-80 "
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                              ...theme.colors,
                              primary25: "#f68e92",
                              primary: "#ed1c24",
                            },
                          })}
                        />
                      </div>
                    )}

                    <div>
                      <div className="flex space-x-1">
                        {/* QR code */}
                        <div className="flex flex-col ">
                          <img width="100" src={qrcode} alt="Kirti Roadlines" />
                        </div>
                        <div className=" space-y-1  mt-2">
                          <h5>Bank Name</h5>
                          <h5>Branch</h5>
                          <h5>A/c. No</h5>
                          <h5>IFSC Code</h5>
                        </div>
                        {Dropdown === false ? (
                          <div className="space-y-1   mt-2">
                            <h5>: State Bank of India</h5>
                            <h5>: Prahlad Nagar Ahmedabad</h5>
                            <h5>: 005167983000351</h5>
                            <h5>: SBIT0CKMNSB (FIFTH CHARACTER IS ZERO)</h5>
                          </div>
                        ) : (
                          <div className="space-y-1  mt-2">
                            <h5>: {bankBranch.bank}</h5>
                            <h5>: {bankBranch.branch}</h5>
                            <h5>: {bankBranch.accNo}</h5>
                            <h5>: {bankBranch.ifsc}</h5>
                          </div>
                        )}
                      </div>
                      <p className="text-xs">{WE_USING_PAYPHONE}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Bannk Detail End */}

            {/* In word */}
            <div className="flex flex-col justify-start items-start mx-2 font-sans">
              <p>Amount Chargeable (in words)</p>
              <p className="font-mono text-xl  w-full text-left">
                {/* Four Thousand Four Hundred Fifty */}
                {props?.data?.totalInWords} ONLY
              </p>
              <p className="text-xs font-bold text-left w-full border-double border-black tracking-widest border-b-4">
                as per MSME law you kindly release payment in 45 days
              </p>
            </div>
            {/* terms and condition */}
            <div className="flex  flex-row text-sm	 justify-between mt-2 mx-2 font-sans">
              <div className="text-left flex flex-col space-y-1">
                <p className="border-black border-b-4 w-40 pb-1">
                  Terms & Conditions
                </p>
                <p>{InvoicSubject}</p>
              </div>
              <div className="text-left text-sm">E. & O.E.</div>
              <div className="text-left text-sm ">FOR,</div>
              {/* Extra informations */}
              <div className="text-lg font-bold text-right ">
                {KIRTIROADLINES}
                <img src={sign} alt="signature" width={100} />
              </div>
            </div>

            <div className="flex border-black border-b-2 flex-row justify-between items-start mx-2 mb-2 font-sans text-sm	">
              <div className="text-left ">
                <p>
                  {PaymentbypayeeCchequeDrafs} {KIRTIROADLINES}
                </p>
              </div>
              <div>
                <p className="font-bold">{AuthorizedSignatory}</p>
              </div>
            </div>

            {/* printing */}
            <h2
              className={`text-sm mt-0 mb-0 text-center font-bold ${Tablebg(
                blackWhite
              )} rounded-md p-2 font-sans`}
            >
              {computerGeneratedInvoice}
            </h2>
          </div>
          <CommissionInvoiceViewDownload
            bankBranch={bankBranch}
            Dropdown={Dropdown}
            data={props?.data}
            profileData={props.profileData}
            secondaryPhoneNo={props.secondaryPhoneNo}
            showBorder={showBorder}
            blackWhite={blackWhite}
            noHeader={!headerShow}
            mobilePrint={mobilePrint}
            setIsLoading={props.setIsLoading}
            note={note}
          />
        </div>
      )}
    </>
  );
};

export default CommissionInvoiceView;
