import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { GET_OTHERPARTY_TABLE } from "../../Utils/ApiGlobal";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../Utils/API";
import Loader from "../UI/Loader";

const animatedComponents = makeAnimated();

export default function OtherPartyName({ handleOnChange, name, defaultValue }) {
  const [optionsParty, setOptions] = useState({});
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    axiosInstance
      .get(`${GET_OTHERPARTY_TABLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        if (response?.data?.data?.otherParties.length > 0) {
          const options = response?.data?.data?.otherParties.map(
            (
              { otherPartyName, otherPartyGst, address, otherPartyId },
              index
            ) => ({
              otherPartyName,
              otherPartyGst,
              address,
              otherPartyId,
            })
          );
          setOptions(options);
          setLoader(false);
        }
      }).finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    setOptions([]);
  }, []);
  return (
    <>
      {!loader ? (
        // <div style={{ width: "310px" }}>
        <Select
          options={optionsParty.map((el) => ({
            label: el.otherPartyName,
            value: el.otherPartyGst,
            add: el.address,
            type: { name },
            partyId: el.otherPartyId,
          }))}
          // name={name}
          components={animatedComponents}
          defaultValue={{ label: defaultValue, value: defaultValue }}
          onChange={handleOnChange}
          placeholder="Select other party name"
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: "#f68e92",
              primary: "#ed1c24",
            },
          })}
        />
      ) : (
        <Loader />
      )}
    </>
  );
}
