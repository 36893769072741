import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import "react-datepicker/dist/react-datepicker.css";
import { ADD_LEDGER } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";
import Loader from "../../Components/UI/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import Select from "react-select";
// import { LedgerTypesOptions } from "../../Utils/Constants";
// import SearchVelicleOwner from "../../Components/Functionality/SearchVelicleOwner";
import CompanyInformation from "../../Components/Functionality/CompanyInformation";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import moment from "moment";
import BankDetails from "../../Components/Functionality/BankDetails";

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row w-32 text-sm font-medium text-slate-700 mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function AddEditLedger(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [amountType, setAmountType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [vehicleOwnerId, setVehicleOwnerId] = useState();
  const [comapnyId, setComapanyId] = useState();
  const [ledgerType, setLedgerType] = useState();
  const [bankBranch, setBankBranch] = useState();
  const [bankAcc, setBankAcc] = useState();
  const [file, setFile] = useState(" ");
  const openModal = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    // console.log(props, "data for company name");
    // StatusValue();
    setAmountType()
    setVehicleOwnerId()
    setLedgerType()
  }, [file]);
  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });

  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };
  //Image Handler
  function handleChange(e) {
    // console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  }
  //   Form submit click event
  const ledgerForm = (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    var obj = formValueConvertor(getFormJSON(ev.target));
    let ADD_LEDGER_DATA = {
      Date: moment(startDate).toDate(),
      PaymentType: amountType,
      Amount: obj.amount,
      LedgerType: ledgerType,
      VehicleOwnerId: vehicleOwnerId,
      CompanyId: comapnyId,
      Note: obj.note.toUpperCase(),
      BankBranchName: bankBranch,
      BankAccountNo: bankAcc,
      CreatedBy: localStorage.getItem("userId"),
    };
    axiosInstance
      .post(ADD_LEDGER, ADD_LEDGER_DATA)
      .then((response) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        if (response.data.data.message) {
          ConfirormationModal(response.data.data.message, "success", 2000);
        }
        setIsLoading(false);
        // props.getAPI("");
        props.getAPI(props.dependency.ledgerType, props.dependency.bankBranch)
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };
  const Payment = (event) => {
    setAmountType(event.target.value);
  };
  const handleButtonClick = (action) => {
    // handle all the action in the main view
    setIsOpen(false);
    // [] should be passing info
    // handleClose({ action });
  };
  return (
    <>
      <div>
        <div className="flex justify-end">
          <ButtonWithBG
            handleClick={openModal}
            theme="primary"
            label="Add Ledger"
          />
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed w-full inset-0 z-30  flex justify-center  items-center bg-white bg-opacity-75 rounded-lg shadow-md"
            open={isOpen}
            onClose={() => setIsOpen(false)}
          >
            <div className="w-full ml-4 px-4 text-center">
              <Dialog.Overlay className="	 fixed  inset-0" />
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <div className="border-2 w-7/12	 border-gray-500 inline-block  pt-2 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {isLoading === true ? (
                  <div className=" px-6 pt-6 w-full pb-6 h-64">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <Dialog.Title
                      as="h3"
                      className="text-lg  font-medium leading-6 text-center text-gray-900 "
                    >
                      <div className="flex flex-row justify-between items-center">
                        {/* <p className="ml-8">Payment-In</p> */}
                        <p className="ml-80">Add Ledger</p>
                        <ButtonWithBG className="bg-primary-kirtiroadways font-bold p-1 rounded-xl text-white mr-2"
                          handleClick={handleButtonClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </ButtonWithBG>
                      </div>

                    </Dialog.Title>
                    <form
                      onSubmit={ledgerForm}
                      className=" pt-6 w-full pb-6"
                    >
                      {/* <div className="space-y-4 ">
                        <div className="flex flex-row space-x-4">
                          <div className="flex flex-col w-1/2 text-left ">
                            <Label label="Date" isRequired="true" />
                            <DatePicker
                              dateFormat="dd-MM-yyy"
                              className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              name="ledgerDate"
                            />
                          </div>
                          <div className="flex flex-col w-1/2	text-left ">
                            <Label label="Type of Amount" isRequired="true" />
                            <div
                              className="space-x-8 w-full"
                              onChange={onAmountType}
                            >
                              <input
                                type="radio"
                                className="form-radio text-primary-kirtiroadways focus:ring-primary-kirtiroadways dark:focus:ring-primary-kirtiroadways dark:ring-offset-gray-800 focus:ring-2 dark:bg-primary-kirtiroadways dark:border-gray-600"
                                value="Credit"
                                name="TypeOfAmount"
                              />
                              {"   "}
                              Credit
                              <input
                                type="radio"
                                className="form-radio text-primary-kirtiroadways focus:ring-primary-kirtiroadways dark:focus:ring-primary-kirtiroadways dark:ring-offset-gray-800 focus:ring-2 dark:bg-primary-kirtiroadways dark:border-gray-600"
                                value="Debit"
                                name="TypeOfAmount"
                              />
                              {"   "}
                              Debit
                            </div>
                          </div>
                          <div className="flex flex-col w-1/2 text-left">
                            <Label label="Bank Branch" isRequired="true" />
                            <BankDetails
                              defaultValue=""
                              handleOnChange={(e) => {
                                setBankBranch(e.label)
                                setBankAcc(e.value)
                              }}
                            />
                          </div>
                        </div>

                        <div className="flex flex-row space-x-2 w-full text-left">
                          <div className="flex flex-col w-1/2 text-left">
                            <Label label="Amount" isRequired="true" />
                            <Input name="amount" placeholder="Amount" />
                          </div>
                          <div className="flex flex-col w-1/2 text-left">
                            <Label label="Ledger Type" isRequired="true" />
                            <Select
                              options={LedgerTypesOptions}
                              name="LedgerType"
                              onChange={(e) => {
                                setLedgerType(e.value);
                              }}
                              placeholder="Select Ledger Type"
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#f68e92",
                                  primary: "#ed1c24",
                                },
                              })}
                            />
                          </div>
                          <div className="flex flex-col w-1/2 text-left">
                            <Label label="Vehicle Owner" isRequired="true" />
                            <SearchVelicleOwner
                              defaultValue=""
                              handleOnChange={(e) => {
                                setVehicleOwnerId(e.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex s h-24 space-x-2">
                          {ledgerType === "Booking" && (
                            <div className="flex flex-col w-1/2 text-left">
                              <Label label="Company Owner" isRequired="true" />
                              <CompanyInformation
                                defaultValue=""
                                handleOnChange={(e) => {
                                  setComapanyId(e.CompanyId);
                                }}
                              />
                            </div>
                          )}
                          <div className="flex flex-col md:w-full text-left">
                            <Label label="Note" />
                            <textarea
                              className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                              name="note"
                              id="note"
                              rows="2"
                              type="text"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    
                      <div className=" mt-4 space-x-2 flex justify-center">
                        <ButtonWithBG
                          type="submit"
                          theme="primary"
                          label="Save"
                        />
                        <ButtonWithBG
                          handleClick={handleButtonClick}
                          theme="secondary"
                          label="Cancel"
                        />
                      </div>  */}


                      <div className="space-x-8 w-full px-6 mb-8"
                      onChange={Payment}
                      >
                        <input
                          type="radio"
                          className="form-radio text-primary-kirtiroadways focus:ring-primary-kirtiroadways dark:focus:ring-primary-kirtiroadways dark:ring-offset-gray-800 focus:ring-2 dark:bg-primary-kirtiroadways dark:border-gray-600"
                          value="PaymentIn"
                          name="TypeOfAmount"
                          defaultChecked="true"
                        />
                        {"   "}
                        Payment-In
                        <input
                          type="radio"
                          className="form-radio text-primary-kirtiroadways focus:ring-primary-kirtiroadways dark:focus:ring-primary-kirtiroadways dark:ring-offset-gray-800 focus:ring-2 dark:bg-primary-kirtiroadways dark:border-gray-600"
                          value="PaymentOut"
                          name="TypeOfAmount"
                        />
                        {"   "}
                        Payment-Out
                      </div>

                      <div className=" flex flex-row justify-between px-6">
                        <div className="w-72 flex flex-col space-y-2">
                          <div className="flex flex-col w-72 text-left">
                            <Label label="Company Owner" isRequired="true" />
                            <CompanyInformation
                              defaultValue=""
                              handleOnChange={(e) => {
                                setComapanyId(e.CompanyId);
                              }}
                            />
                          </div>
                          <div className="flex flex-col w-72 text-left">
                            <Label label="Payment Type" isRequired="true" />
                            <BankDetails
                              defaultValue=""
                              handleOnChange={(e) => {
                                setBankBranch(e.label)
                                setBankAcc(e.value)
                              }}
                            />
                          </div>
                          <div className="flex flex-col w-72 text-left">
                            <Label label="Add Description" />
                            <textarea
                              className="peer w-full px-3 py-2 overflow-y-scroll  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                              name="note"
                              id="note"
                              rows="2"
                              type="text"
                            ></textarea>
                          </div>
                          <div className="mt-5">
                            <input type="file" name="Image" onChange={handleChange} />
                          </div>
                        </div>

                        <div className="flex flex-col space-y-28 mt-5 w-72">
                          <div className=" flex flex-col space-y-5">
                            <div className="flex flex-row justify-end items-center space-x-2">
                              <Label label="Receipt No." isRequired="true" />
                              <Input type="text" name="Receipt No" />
                            </div>
                            <div className="flex flex-row justify-between items-center w-full">
                              <Label label="Date" isRequired="true" />
                              <DatePicker
                                dateFormat="dd-MM-yyy"
                                className="peer px-3 py-2 w-full border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                name="ledgerDate"
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-between items-center space-x-2">
                          {amountType === "PaymentOut" ? 
                             (<Label label="Paid" isRequired="true" />)
                              : 
                              (<Label label="Received" isRequired="true" />)
                              }
                            
                            <Input type="text" name="Received" />
                          </div>
                        </div>

                      </div>
                      <div className=" mt-16 space-x-2 flex justify-end border-t-2 pt-5 px-6">
                        <ButtonWithBG
                          // handleClick={handleButtonClick}
                          theme="secondary"
                          label="Share"
                        />
                        <ButtonWithBG
                          type="submit"
                          theme="primary"
                          label="Save"
                        />
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
}
