import React, { useEffect, useState } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";

function AddEditDriver({ props }) {
  const location = useLocation();
  const [heading, setHeading] = useState("");
  // To fetch Data from previous route
  useEffect(() => {
    if (location?.state?.label === "Add") {
      setHeading("Add Driver");
    } else {
      setHeading("Edit Driver");
    }
  }, [location]);

  return (
    <DashBoardLayout pageTitle={heading}>
      <div className="bg-indigo-100 p-5">
        <div className="bg-white p-5">
          <form>
              <div className="bg-indigo-100 p-5 text-left">
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">

                  <div className="flex flex-col md:w-1/3 text-left">
                    <label htmlFor="freight">Driver Name</label>
                    <input
                      className="border-2"
                      id="freight"
                      type="text"
                      defaultValue={location?.state?.data?.data?.Driver_Name}
                      required
                    />
                  </div>
                  <div className=" flex md:w-1/3  flex-col  text-left">
                    <label htmlFor="advance">Driver Mobile</label>
                    <input
                      className="border-2 w-full"
                      id="advance"
                      type="text"
                      defaultValue={location?.state?.data?.data?.Driver_Mobile}
                      required
                    />
                  </div>
                  <div className=" flex md:w-1/3  flex-col  text-left">
                    <label htmlFor="advance">Driver Paid Balance</label>
                    <input
                      className="border-2 w-full"
                      id="advance"
                      type="text"
                      defaultValue={location?.state?.data?.data?.Driver_Paid_Balance}
                      required
                    />
                  </div>
                </div>
              </div>

            <div className="items-ceter mt-6">
              <ButtonWithBG theme="primary" label="SAVE" />
            </div>
          </form>
        </div>
      </div>
    </DashBoardLayout>
  );
}

export default AddEditDriver;