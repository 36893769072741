import React, { Fragment, useRef } from "react";
import { useLocation } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import Logo from "../../Images/logo_transparent.png";
import black_logo from "../../Images/black_logo.png";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import LCVPrint from "./LCVPrint";
import email_logo from "../../../src/Images/images/email.png";
import location_logo from "../../../src/Images/images/location.png";
import phone_logo from "../../../src/Images/images/phone.png";
import border from "../../Images/images/border.png";
import { useEffect, useState } from "react";
import Loader from "../../Components/UI/Loader";
import axiosInstance from "../../Utils/API";
import { GET_COMPANY_INFORMATION_DATA } from "../../Utils/ApiGlobal";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import moment from "moment";
import { Dialog, Transition } from "@headlessui/react";
import Checkbox from "../../Components/UI/Checkbox";
import { address } from "../../Utils/TableStyling";
import sign from "../../Images/sign.png";
import { background } from "../../Layouts/pdfLayout/BackgroundLayout";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "../../Utils/PTSerif-Regular";
import { robotoBlack } from "../../Utils/Roboto-Black";
import { PTSerifBold } from "../../Utils/PTSerif-Bold";
import { NotoSansDevanagariBold } from "../../Utils/NotoSansDevanagari-Bold";
import { NotoSansDevanagariRegular } from "../../Utils/NotoSansDevanagari-Regular";
const pdfMakeX = require("pdfmake/build/pdfmake.js");
const pdfFontsX = require("pdfmake-unicode/dist/pdfmake-unicode.js");
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
//importing the fonts whichever present inside vfs_fonts file
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["PTSerif-Regular.ttf"] = font;
window.pdfMake.vfs["PTSerif-Bold.ttf"] = PTSerifBold;
window.pdfMake.vfs["Roboto-Black.ttf"] = robotoBlack;
window.pdfMake.vfs["NotoSansDevanagari-Bold.ttf"] = NotoSansDevanagariBold;
window.pdfMake.vfs["NotoSansDevanagari-Regular.ttf"] =
  NotoSansDevanagariRegular;
const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

const TransporterLoadingSlip = () => {
  let location = useLocation();
  let componentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [open, setOpen] = useState(true);
  const [goods, setGoods] = useState("");
  const [showBorder, setShowBorder] = useState(true); // eslint-disable-next-line
  const [blackWhite, setBlackWhite] = useState(true);
  const [guarantee, setGuarantee] = useState(""); // eslint-disable-next-line
  const [mobilePrint, setMobilePrint] = useState(true);
  const pageStyle = `@page {
    size: A5;
    margin: 10pt;
  }
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
  }`;

  useEffect(() => {
    _FetchProfileAPI();
  }, []);

  const _FetchProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(data);
        setProfileData(data);
        setsecondaryPhoneNo(response?.data?.data?.secondaryPhoneNo);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  const generatePDFAndDisplay = async (action) => {
    try {
      const transporterName = localStorage.getItem("transporterName");
      // let token = localStorage.getItem("token");
      // // Get the entire content from the useReactToPrint reference
      // const content = componentRef.current;
      // console.log("content", content);
      setIsLoading(true);
      // console.log("transporterName", transporterName);
      // await handleDownloadPdf(consigneeName);
      handleDownloadPdf(transporterName, location?.data?.data, action)
        .then(() => {
          // Handle success if needed
          // console.log("PDF generated successfully");
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error generating PDF:", error);
        });
    } catch (error) {
      console.error("Error:", error);
      // Handle errors appropriately
    }
  };

  async function generateContent(data) {
    const headerImages = {
      backgroundImage: await getBase64ImageFromURL(black_logo),
      Sign: await getBase64ImageFromURL(sign),
      Locate: await getBase64ImageFromURL(location_logo),
      phone: await getBase64ImageFromURL(phone_logo),
      email: await getBase64ImageFromURL(email_logo),
      border: await getBase64ImageFromURL(border),
    };
    return [
      {
        text: "No. :",
        fontSize: 11,
        bold: true,
        alignment: "left",
        absolutePosition: { x: 35, y: 163 },
      }, // Left align 'NO.'
      {
        text: `${data.lcvNo}`,
        fontSize: 11,
        bold: true,
        absolutePosition: { x: 70, y: 163 },
      }, // Left align 'NO.'
      {
        text: "DATE:",
        fontSize: 11,
        bold: true,
        absolutePosition: { x: 275, y: 163 },
      }, // Right align 'DATE'
      {
        text: `${moment(data.loadingDate).format("DD-MM-YYYY")}`,
        bold: true,
        fontSize: 11,
        absolutePosition: { x: 315, y: 163 },
      }, // Left align 'NO.'

      {
        text: "M/S.",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 185 },
      },
      {
        canvas: [
          { type: "line", x1: 40, y1: 45, x2: 350, y2: 45, lineWidth: 1 },
        ],
      },
      {
        text: `${data.transporterName}`,
        fontSize: 12,
        bold: true,
        alignment: "center",
        absolutePosition: { x: 35, y: 185 },
      },

      {
        text: "WE ARE SENDING TRUCK NO.",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 210 },
      },
      {
        canvas: [
          { type: "line", x1: 170, y1: 25, x2: 350, y2: 25, lineWidth: 1 },
        ],
      },
      {
        text: `${data.vehicleNo}`,
        fontSize: 12,
        bold: true,
        alignment: "center",
        absolutePosition: { x: 200, y: 210 },
      },

      {
        text: "PLEASE LOAD FROM",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 235 },
      },
      {
        canvas: [
          { type: "line", x1: 120, y1: 24, x2: 220, y2: 24, lineWidth: 1 },
        ],
      },
      {
        text: `${data.from}`,
        fontSize: 12,
        bold: true,
        alignment: "center",
        absolutePosition: { x: 10, y: 235 },
      },

      {
        text: "TO,",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 259, y: 235 },
      },
      {
        canvas: [
          { type: "line", x1: 250, y1: 0, x2: 350, y2: 0, lineWidth: 1 },
        ],
      },
      {
        text: `${data.to}`,
        fontSize: 12,
        bold: true,
        alignment: "center",
        absolutePosition: { x: 290, y: 235 },
      },

      {
        text: "OF GOODS",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 260 },
      },
      {
        canvas: [
          { type: "line", x1: 65, y1: 25, x2: 170, y2: 25, lineWidth: 1 },
        ],
      },
      {
        text: `${goods}`,
        fontSize: 12,
        bold: true,
        absolutePosition: { x: 130, y: 260 },
      },

      {
        text: "GUARANTEE",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 212, y: 260 },
      },
      {
        canvas: [
          { type: "line", x1: 250, y1: 2, x2: 350, y2: 2, lineWidth: 1 },
        ],
      },
      {
        text: `${guarantee}`,
        fontSize: 12,
        bold: true,
        alignment: "center",
        absolutePosition: { x: 280, y: 260 },
      },

      {
        text: "FULL LOAD HAS BEEN SETTLED WITH",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 285 },
      },
      {
        canvas: [
          { type: "line", x1: 210, y1: 25, x2: 350, y2: 25, lineWidth: 1 },
        ],
      },
      {
        text: `${data.loadSettledWith == null ? "-" : data.loadSettledWith}`,
        fontSize: 12,
        bold: true,
        absolutePosition: { x: 300, y: 285 },
      },

      // { text: 'RATE RS.',fontSize: 16, alignment: 'left', absolutePosition: { x: 10, y: 360 } },
      // {canvas: [ { type: 'line', x1: 380, y1: 0, x2: 565, y2: 0, lineWidth: 1 } ]},
      // { text: `${data.rates}`,fontSize: 15,bold:true, alignment: 'center', absolutePosition: { x: 370, y: 267 } },

      {
        text: "RATE RS.",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 310 },
      },
      {
        canvas: [
          { type: "line", x1: 52, y1: 25, x2: 160, y2: 25, lineWidth: 1 },
        ],
      },
      {
        text: `${data.rates == null ? "-" : data.rates}`,
        fontSize: 12,
        bold: true,
        absolutePosition: { x: 135, y: 310 },
      },

      {
        text: "TOTAL FREIGHT RS.",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 200, y: 310 },
      },
      {
        canvas: [
          { type: "line", x1: 275, y1: 0, x2: 350, y2: 0, lineWidth: 1 },
        ],
      },
      {
        text: `${
          data.transporterFreigth == null ? "-" : data.transporterFreigth
        }`,
        fontSize: 12,
        bold: true,
        absolutePosition: { x: 330, y: 310 },
      },

      {
        text: "ADVANCE RS.",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 335 },
      },
      {
        canvas: [
          { type: "line", x1: 80, y1: 25, x2: 200, y2: 25, lineWidth: 1 },
        ],
      },
      {
        text: `${data.transporterAdvance}`,
        fontSize: 12,
        bold: true,
        absolutePosition: { x: 170, y: 335 },
      },

      {
        text: "BALANCE:",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 246, y: 335 },
      },
      {
        canvas: [
          { type: "line", x1: 280, y1: 0, x2: 350, y2: 0, lineWidth: 1 },
        ],
      },
      {
        text: `${data.transporterBalance}`,
        fontSize: 12,
        bold: true,
        alignment: "center",
        absolutePosition: { x: 310, y: 335 },
      },

      {
        text: "THANKS",
        fontSize: 12,
        alignment: "left",
        absolutePosition: { x: 35, y: 360 },
      },
      { text: "", margin: [0, 6, 0, 0] },
      {
        canvas: [
          { type: "line", x1: 0, y1: 35, x2: 350, y2: 35, lineWidth: 1 },
        ],
      },
      {
        text: `NOTE :`,
        fontSize: 13,
        bold: true,
        absolutePosition: { x: 35, y: 395 },
      },
      {
        text: `गाड़ी भरने के वक्त गाड़ी की`,
        fontSize: 12,
        absolutePosition: { x: 80, y: 398 },
        font: "NotoSansDevanagari",
      },
      { text: `R.C. `, fontSize: 12, absolutePosition: { x: 200, y: 398 } },
      {
        text: ` और `,
        fontSize: 12,
        absolutePosition: { x: 225, y: 398 },
        font: "NotoSansDevanagari",
      },
      { text: `PAN CARD`, fontSize: 12, absolutePosition: { x: 250, y: 398 } },
      {
        text: `की ज़ेरॉक्स की `,
        fontSize: 12,
        absolutePosition: { x: 310, y: 398 },
        font: "NotoSansDevanagari",
      },
      {
        text: ` कॉपी लेना ट्रांसपोर्ट की जिमेदारी रहेगी`,
        fontSize: 12,
        absolutePosition: { x: 80, y: 417 },
        font: "NotoSansDevanagari",
      },
      {
        canvas: [
          { type: "line", x1: 0, y1: 45, x2: 350, y2: 45, lineWidth: 1 },
        ],
      },
      // {canvas: [ { type: 'line', x1: -8, y1: 55, x2: 573, y2: 55, lineWidth: 1 } ]},
      {
        columns: [
          {
            text: `NO VARAI, NO HAMALI`,
            absolutePosition: { x: 35, y: 460 },
            color: "#000",
            fontSize: 14,
            bold: true,
            // margin: [0, 10, 15, 0],
            // alignment: "left",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: `YOUR FAITHFULLY`,
            absolutePosition: { x: 265, y: 440 },
            color: "#000",
            fontSize: 12,
          },
          {
            absolutePosition: { x: 285, y: 457 },
            image: headerImages.Sign,
            width: 70,
          },
          {
            text: `FOR, KIRTI ROADLINES`,
            absolutePosition: { x: 255, y: 480 },
            color: "#000",
            fontSize: 12,
            bold: true,
          },
        ],
      },

      {
        canvas: [
          { type: "line", x1: 0, y1: 60, x2: 350, y2: 60, lineWidth: 1 },
        ],
      },

      //  {canvas: [ { type: 'line', x1: -8, y1: 210, x2: 573, y2: 210, lineWidth: 1 } ]},
      {
        text: `NOTE :`,
        fontSize: 14,
        bold: true,
        absolutePosition: { x: 35, y: 500 },
      },
      {
        text: `1. PLEASE CHECK UP ENGINE & CHASSIS NO. R.T.O. BOOK C.S.T. NO. ETC FOR  `,
        fontSize: 10,
        absolutePosition: { x: 35, y: 520 },
      },
      {
        text: `YOUR SATISFACTION`,
        fontSize: 10,
        absolutePosition: { x: 45, y: 530 },
      },
      {
        text: `2. WE ARE NOT RESPONSIBLE OF ACCIDENT, LEAKAGE & BREAKAGE`,
        fontSize: 10,
        absolutePosition: { x: 35, y: 540 },
      },
    ];
  }
  async function getBase64ImageFromURL(url) {
    // console.log(url);
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", url, error);
        reject(error);
      };
      img.src = url;
    });
  }
  const sanitizeFilename = (filename) => {
    // Replace special characters with underscores
      // Replace special characters with underscores
      let checkNull = filename.includes("null");
      if (checkNull) {
        return filename.replace(/[^\w\s]/gi, "_")?.replace("null", "");
      } else if (!checkNull) {
        return filename.replace(/[^\w\s]/gi, "_");
      }
  };
  async function handleDownloadPdf(consigneeName, data, action) {
    const headerImages = {
      backgroundImage: await getBase64ImageFromURL(black_logo),
      Sign: await getBase64ImageFromURL(sign),
      Locate: await getBase64ImageFromURL(location_logo),
      phone: await getBase64ImageFromURL(phone_logo),
      email: await getBase64ImageFromURL(email_logo),
      border: await getBase64ImageFromURL(border),
    };
    //Specifying the fonts which we are going to use in our PDF
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Black.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      PTSerifRegular: {
        normal: "PTSerif-Regular.ttf",
        bold: "PTSerif-Bold.ttf",
        italics: "PTSerif-Regular.ttf",
        bolditalics: "PTSerif-Regular.ttf",
      },
      NotoSansDevanagari: {
        normal: "NotoSansDevanagari-Regular.ttf",
        bold: "NotoSansDevanagari-Bold.ttf",
        italics: "NotoSansDevanagari-Regular.ttf",
        bolditalics: "NotoSansDevanagari-Regular.ttf",
      },
    };

    const pdfDocDefinition = {
      pageMargins: [35, 155, 35, 0],
      pageSize: "A5",
      header: [
        {
          alignment: "justify",
          columns: [
            {
              image: headerImages.backgroundImage,
              width: 100,
              height: 45,
              margin: [40, 35, 35, 0],
            },
            {
              margin: [20, 35, 35, 0],
              alignment: "right",
              stack: [
                {
                  margin: [0, 0, 10, 0],
                  text: `VEHICLE LOADING SLIP`,
                  color: "#000",
                  fontSize: 13,
                  bold: true,
                  // margin: [20, 10, 30, 0],
                  font: "PTSerifRegular",
                  // absolutePosition: { x: 0, y: 16 }, // Adjust the values accordingly
                },
                {
                  margin: [0, -2, 10, 0],
                  text: `${profileData?.tagLine}`,
                  color: "#000",
                  fontSize: 23,
                  bold: true,
                  lineHeight: 1,
                  font: "PTSerifRegular",
                  // absolutePosition: { x: 0, y: 25 }, // Adjust the values accordingly
                },
              ],
            },
          ],
          columnGap: 1,
        },
        {
          margin: [35, 2, 35, 0], // Adjust margins accordingly
          table: {
            widths: ["100%"], // Adjust the width of the table as needed
            body: [
              [
                {
                  text: "L. C. V",
                  bold: true,
                  fontSize: 18,
                  alignment: "center",
                  margin: [0, 0], // Adjust the top margin of the header
                  border: [false, true, false, true],
                },
              ],
            ],
          },
          columnGap: 0,
        },
        {
          columns: [
            {
              stack: [
                {
                  // Add a rounded border using headerImages.border
                  image: headerImages.border,
                  width: 350,
                  height: 44,
                  alignment: "center",
                },
              ],
              margin: [25, 0, 10, 0],
            },
            {
              margin: [35, 0, 35, 0],
              absolutePosition: { x: 40, y: 118 },
              table: {
                widths: ["auto", 320],
                body: [
                  [
                    {
                      image: headerImages.Locate,
                      width: 7,
                      height: 7,
                      alignment: "right",
                    },
                    {
                      text: `${profileData.address
                        .replace(/\s+/g, "  ")
                        .trim()
                        .toUpperCase()}`,
                      color: "#000",
                      fontSize: 7,
                      bold: true,
                      alignment: "center",
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              margin: [35, 0, 35, 0],
              absolutePosition: { x: 42, y: 136 },
              table: {
                widths: ["auto", "auto", "auto", "auto"],
                body: [
                  [
                    {
                      image: headerImages.phone,
                      width: 7,
                      height: 7,
                      alignment: "right",
                    },
                    {
                      text: `${secondaryPhoneNo}`,
                      color: "#000",
                      fontSize: 7,
                      alignment: "left",
                      bold: true,
                    },
                    {
                      image: headerImages.email,
                      width: 7,
                      height: 7,
                      alignment: "right",
                    },
                    {
                      text: `${profileData.email.toUpperCase()}`,
                      color: "#000",
                      fontSize: 7,
                      alignment: "left",
                      bold: true,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            // {
            //   margin: [35, 0, 35, 0],
            //   absolutePosition: { x: 40, y: 140 },
            //   table: {
            //     widths: ["auto", "auto", "auto", "auto"],
            //     body: [
            //       [
            //         {
            //           image: headerImages.phone,
            //           width: 7,
            //           height: 7,
            //           alignment: "right",
            //         },
            //         {
            //           text: `${secondaryPhoneNo}`,
            //           color: "#000",
            //           fontSize: 7,
            //           alignment: "left",
            //           bold: true,
            //         },
            //         {
            //           image: headerImages.email,
            //           width: 7,
            //           height: 7,
            //           alignment: "right",
            //         },
            //         {
            //           text: `${profileData.email.toUpperCase()}`,
            //           color: "#000",
            //           fontSize: 7,
            //           alignment: "left",
            //           bold: true,
            //         },
            //       ],
            //     ],
            //   },
            //   layout: "noBorders",
            // },
          ],
        },
      ],
      content: await generateContent(location?.data?.data),
      footer: [],
      styles: {
        table: {
          margin: [0, 0, 0, 0], // Adjust the top margin of the entire table
        },
        tableHeader: {
          fontSize: 9,
          alignment: "start", // 'start' aligns text to the start of the cell
          bold: true,
          fillColor: "#fff",
          color: "#000",
          margin: [0, 2, 0, 2], // Adjust the margin for each header cell
          borderBottom: "1px solid #28328c",
        },
        cellStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
        },
      },
      background: background,
    };
    // Download pdf
    const pdfDocGenerator = pdfMake.createPdf(pdfDocDefinition);
    // history.push("/commission-entry");
    // setIsLoading(false);
    if (action === "download") {
      // console.log("download",action)
      const sanitizedFilename = sanitizeFilename(
        "LCV of " + location?.data?.data?.transporterName
      );
      const fileName = `${sanitizedFilename}.pdf`;

      const newLocal = pdfMake.createPdf(pdfDocDefinition);
      const pdfDocGenerator = newLocal;

      pdfDocGenerator.getBlob((dataBlob) => {
        const url = URL.createObjectURL(dataBlob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);

        // history.push("/bilty/billty-invoice-view");
        setIsLoading(false);
      });
      // pdfDocGenerator.getBlob((dataBlob) => {
      //   const url = URL.createObjectURL(dataBlob);

      //   // Open the PDF in a new tab
      //   window.open(url, "_blank");

      //   URL.revokeObjectURL(url);
      // history.push("/bilty/billty-invoice-view");
      // });
    } else if (action === "print") {
      // console.log("print", action);
      pdfDocGenerator.print();
    }
  }
  return (
    <>
      <DashBoardLayout pageTitle="Transporter Loading Slip">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="min-w-[600px]">
            <div className="w-full flex justify-end">
              <ButtonWithBG
                tooltipContent="Edit"
                onClick={() => setOpen(true)}
                theme="secondary"
                label="Update Goods and Guarantee"
              ></ButtonWithBG>
            </div>
            {open && (
              <Transition show={open} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-100"
                  initialFocus={componentRef}
                  onClose={setOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-300 bg-opacity-95 transition-opacity" />
                  </Transition.Child>

                  <div className=" fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      >
                        <Dialog.Panel className="border-2 border-primary-kirtiroadways  rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-1/2">
                          <div className="bg-white px-8 pt-4 pb-4">
                            <div className="">
                              <div className=" text-center ">
                                <div className="mt-2">
                                  <div className="flex flex-row justify-between space-x-4 mt-4">
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      <Label label="Goods" />
                                      <Input
                                        defaultValue={goods}
                                        onChange={(e) =>
                                          setGoods(e.target.value)
                                        }
                                      />
                                    </div>
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      <Label label="Garantee" />
                                      <Input
                                        defaultValue={guarantee}
                                        onChange={(e) =>
                                          setGuarantee(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" px-4 mt-5 py-3 flex justify-center  sm:px-6">
                              <ButtonWithBG
                                onClick={() => setOpen(false)}
                                ref={componentRef}
                                // disabled={!Rate || !Weight}
                                theme="primary"
                              >
                                OK
                              </ButtonWithBG>
                            </div>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            )}
            <div className="flex flex-row gap-4 justify-center ">
              <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
                <p className="text-xs">With Border</p>
                <Checkbox
                  defaultChecked={showBorder}
                  handleClick={(e) => setShowBorder(e.target.checked)}
                />
              </div>
              {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
                <p className="text-xs">Black and white</p>
                <Checkbox
                  defaultChecked={blackWhite}
                  handleClick={(e) => setBlackWhite(e.target.checked)}
                />
              </div> */}
              {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
                  <p className="text-xs">Mobile Print</p>
                  <Checkbox
                    defaultChecked={blackWhite}
                    handleClick={(e) => setMobilePrint(e.target.checked)}
                  />
                </div> */}
            </div>
            <div
              className={`${
                showBorder &&
                ` border-2 ${
                  blackWhite ? "border-black" : "border-primary-kirtiroadways "
                } `
              } md:mx-10 mt-3 bg-center bg-no-repeat  bg-[length:65%]`}
              // style={{
              //   backgroundImage: "url(" + logo_green_oposity + ")",
              // }}
            >
              <div className="">
                {/* logo and Kiti Roadlines Start */}
                <div className="flex flex-row space-x-0 mt-2 mx-2">
                  <div>
                    {blackWhite ? (
                      <img
                        src={black_logo}
                        alt="Kirti Roadlines"
                        className="lg:w-96 md:w-56 sm:w-56 w-36"
                      />
                    ) : (
                      <img
                        src={Logo}
                        alt="Kirti Roadlines"
                        className="lg:w-96 md:w-56 sm:w-56 w-36"
                      />
                    )}{" "}
                  </div>
                  <div className="flex flex-col  w-full">
                    <div className="flex flex-row justify-end lg:text-xl md:text-md sm:text-sm text-xs font-bold ">
                      {/* <h1 className="xl:ml-52 lg:ml-24 md:ml-12  sm:ml-24 ml-8">|| श्री हरी ||</h1> */}
                      {/* <h1 className="xl:ml-40 lg:ml-16 md:ml-20 sm:ml-20 ml-10">|| श्री हरी ||</h1> */}
                      {/* <h1>|| श्री मुंडेश्वरी माँ ||</h1> */}
                      <h1>Vehicle Loading Slip</h1>
                    </div>
                    <div
                      className={`xl:text-7xl text-right lg:text-6xl text-5xl font-bold  w-full ${
                        !blackWhite && "text-primary-kirtiroadways"
                      }`}
                    >
                      {profileData.tagLine}
                    </div>
                  </div>
                </div>
                {/* logo and Kiti Roadlines End */}

                {/* LCV Start */}
                <div
                  className={`border-y-2 ${
                    blackWhite
                      ? "border-black"
                      : "border-primary-kirtiroadways "
                  } lg:text-4xl md:text-3xl sm:text-2xl text-xl font-bold mt-2 text-center py-1`}
                >
                  L. C. V
                </div>
                {/* LCV End */}

                {/* Address Start */}
                <div
                  className={`rounded-b-3xl  text-sm rounded-t-lg font-bold   p-2 w-full  ${address(
                    blackWhite
                  )}`}
                >
                  <p className="flex  text-md justify-center md:flex-row items-start">
                    <span className="text-md w-5">{address_svg}</span>{" "}
                    <span className="text-md ml-1">{profileData.address}</span>
                  </p>
                  <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                    <div className="flex space-x-1 text-xs justify-center md:flex-row items-center ">
                      <span>{call_svg} </span>{" "}
                      <span className="flex flex-row">
                        {secondaryPhoneNo.map((number, i) => (
                          <span
                            key={number}
                            className="text-sm font-semibold tracking-wider flex flex-row"
                          >
                            {(i ? ", " : "") + number}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className="flex text-xs space-x-1 justify-center flex-row items-center ">
                      <span>{email_svg} </span>
                      <span>{profileData.email}</span>
                    </div>
                  </div>
                </div>
                {/* Address End */}

                {/* Main Form Start */}
                <div className="m-2 space-y-2">
                  {/* No and date */}
                  <div className="flex flex-row justify-between font-bold">
                    <div className="flex text-2xl flex-row ">
                      No. :
                      <h1 className="ml-2">{location?.data?.data?.lcvNo}</h1>
                    </div>
                    <div className="flex flex-row justify-center items-center space-x-2">
                      <h1 className="text-2xl">Date :</h1>
                      {/* <h1>20-09-2022</h1> */}
                      <h1 className="mt-1">
                        {moment(location?.data?.data?.loadingDate).format(
                          "DD-MM-YYYY"
                        )}
                      </h1>
                    </div>
                  </div>
                  {/* Name */}
                  <div className="flex flex-row">
                    <p className="text-lg">M/S.</p>
                    <div
                      className={`w-full ml-1 border-b-2 ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      }  text-center font-bold`}
                    >
                      {location?.data?.data?.transporterName}
                    </div>
                  </div>
                  {/* Vehicle No */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <div className="min-w-fit	text-left">
                      <h1>We Are Sending Truck No.</h1>
                    </div>
                    <div
                      className={`w-full border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.vehicleNo}
                    </div>
                  </div>
                  {/* From to  */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">Please Load From</h1>
                    <div
                      className={`w-full border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.from}
                    </div>
                    <h1 className="mx-2 text-left">To,</h1>
                    <div
                      className={`w-full border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.to}
                    </div>
                  </div>
                  {/* goods and garantee */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">of Goods</h1>
                    <div
                      className={`w-full ml-1 border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {goods}
                    </div>
                    <h1 className="min-w-fit text-center">Guarantee</h1>
                    <div
                      className={`w-full ml-1 border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {guarantee}
                    </div>
                  </div>
                  {/* full loads */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <div className="min-w-fit text-left">
                      Full Load has been Settled with
                    </div>
                    <div
                      className={`w-full ml-1 border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.loadSettledWith}
                    </div>
                  </div>
                  {/* rate rs and total fright */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <div className="min-w-fit text-left">Rate Rs.</div>
                    <div
                      className={`w-full ml-1 border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.rates}
                    </div>
                    <div className="min-w-fit text-center">
                      Total Freight Rs.
                    </div>
                    <div
                      className={`w-full ml-1 border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.transporterFreigth}
                    </div>
                  </div>
                  {/* Adance and balance */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">Advance Rs.</h1>
                    <div
                      className={`w-full ml-1 border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.transporterAdvance}
                    </div>
                    <div className="text-left min-w-fit">Balance:</div>
                    <div
                      className={`w-full ml-1 border-b-2 text-center ${
                        blackWhite
                          ? "border-b-black"
                          : "border-b-primary-kirtiroadways "
                      } font-bold`}
                    >
                      {location?.data?.data?.transporterBalance}
                    </div>
                  </div>
                  {/* extra note */}
                  <div className="flex flex-row space-x-0 text-lg">
                    <h1 className="min-w-fit text-left">Thanks</h1>
                  </div>
                </div>
                {/* Main Form End */}

                {/* Note Start */}
                <div
                  className={`text-xl p-1 border-y-2 ${
                    blackWhite
                      ? "border-black"
                      : "border-primary-kirtiroadways "
                  } align-center  text-center`}
                >
                  <span className="text-2xl font-bold">Note :</span> गाड़ी भरने
                  के वक्त गाड़ी की R.C. औरा PAN CARD की ज़ेरॉक्स की कॉपी लेना
                  ट्रांसपोर्ट की जिमेदारी रहेगी
                </div>
                {/* Note End */}

                {/* Signature Box Start */}
                <div className="flex flex-row justify-between m-4">
                  <div
                    className={`lg:text-5xl md:text-3xl sm:text-2xl text-md font-bold mt-4 ${
                      !blackWhite && "text-primary-kirtiroadways"
                    }`}
                  >
                    No Varai, No Hamali
                  </div>
                  <div className=" lg:text-2xl md:text-xl sm:text-lg text-md flex flex-col items-center">
                    <p>Your Faithfully</p>
                    <img src={sign} alt="signature" width={100} />
                    <h1 className="font-bold">For, Kirti Roadlines</h1>
                  </div>
                </div>
                {/* Signature Box End */}

                {/* Last content Start */}
                <div
                  className={`border-t-2 ${
                    blackWhite
                      ? "border-black"
                      : "border-primary-kirtiroadways "
                  }`}
                >
                  <div className="m-4">
                    <p className="text-3xl text-left font-bold">NOTE :</p>
                    <ol className="list-decimal text-xl list-outside ml-6 text-left">
                      <li>
                        Please Check up Engine & Chassis No. R.T.O. Book C.S.T.
                        No. Etc for your Satisfaction
                      </li>
                      <li>
                        We are not Responsible of Accident, Leakage & Breakage
                      </li>
                    </ol>
                  </div>
                </div>
                {/* Last content End */}
              </div>
            </div>
            {/* Printing Data Start*/}
            <div className="hidden">
              <div ref={componentRef}>
                <style>{pageStyle}</style>
                <LCVPrint
                  profileData={profileData}
                  goods={goods}
                  guarantee={guarantee}
                  secondaryPhoneNo={secondaryPhoneNo}
                  showBorder={showBorder} // eslint-disable-next-line
                  blackWhite={blackWhite}
                  mobilePrint={mobilePrint}
                />
              </div>
            </div>
            {/* Print Buttom */}
            <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
              {/* <ReactToPrint
                documentTitle={
                  `LCV of ` +location?.data?.data?.transporterName+"-"+location?.data?.data?.lcvNo
                }
                trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
                content={() => componentRef.current}
              /> */}

              <ButtonWithBG
                onClick={() => generatePDFAndDisplay("print")}
                label="Print"
              />
              <ButtonWithBG
                onClick={() => generatePDFAndDisplay("download")}
                label="Download Pdf"
              />
            </div>

            {/* <button onClick={()=> console.log(location?.data?.data)}>Click</button> */}
            {/* Printing Data End*/}
          </div>
        )}
      </DashBoardLayout>
    </>
  );
};

export default TransporterLoadingSlip;
