import React, { useState, useEffect } from "react";
// import { Vehicle } from "../../Utils/Constants";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
// import { useHistory } from "react-router-dom";
// import axiosInstance from "../../Utils/API";
// import { GET_TRANSPORTER_DATA, GET_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";
import { GET_TRANSPORTERS_LIST } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";

export default function TransporterTable(props) {
  //   let history = useHistory();
  const [transporterData, SetTransporterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // for Searching By Owner Name
  //   const filterOwnerNames = ({ name }) => {
  //     return name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  //   };

  const columns = [
    {
      Header: "Action",
      id: "Action",
      maxWidth: 10,
      minWidth: 10,
      width: 10,
      Cell: (row) => (
        <div>
          <TableAction
            isLedger
            isBilling
            data={row.row.original}
            redirectPath="transporter/add-edit-transporter"
            redirectLedger="Ledger-Details"
            component="Transporter"
            getAPI={getAPI}
            redirectBilling="transporter/transporter-invoice"
          />
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "transporterName",
      Cell: (row) => <div className="text-left">{row.value}</div>,
    },
    {
      Header: "Address	",
      accessor: "address",
      Cell: (row) => <div className="text-left truncate">{row.value}</div>,
      maxWidth: 25,
      minWidth: 10,
      // width: 200,
    },
    // {
    //   Header: "Contact NUMBER",
    //   accessor: "mobileNumber",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
  ];

  useEffect(() => {
    getAPI();
    // eslint-disable-next-line
  }, [props.searchValue]);
  // GET API
  const getAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_TRANSPORTERS_LIST}?search=${props.searchValue}&pageNo=1&pageSize=`
      )
      .then((response) => {
        // console.log(response);
        const data = response?.data?.data?.transporterDetails;
        SetTransporterData(data);
        // console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <section>
      <div className="mt-4">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={transporterData} columns={columns} />
        )}
      </div>
    </section>
  );
}
