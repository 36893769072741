import React from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import AddEditBooking from "../Booking-Pending/AddEditBooking";
import AddEditBillty from "../Billty/AddEditBillty";
import { useState } from "react";
import TabInterface from "../../Components/UI/TabInterface";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import UpdateBooking from "../Booking-Pending/UpdateBooking";

const tabs = [
  { id: 1, label: "Booking", component: UpdateBooking },
  { id: 2, label: "Buillty", component: AddEditBillty },
];
const tab = [{ id: 1, label: "Booking", component: AddEditBooking }];

export default function UpdateBookingBuillty() {
  const [activeTab, setActiveTab] = useState(0);
  const [builltyAvail, setBuilltyAvail] = useState();
  const location = useLocation();
  useEffect(() => {
    setBuilltyAvail(location?.state?.data?.data?.isBiltyReceive);
  }, [location]);
  var WhichComponent = tabs[activeTab].component;

  const handleSwitchTab = (val) => {
    setActiveTab(val);
  };

  return (
    <>
    <DashBoardLayout pageTitle="Update Booking and Billty">
      <div className="flex flex-col">
        {builltyAvail === true ? (
          <>
            <TabInterface
              tabs={tabs}
              activeTabIndex={activeTab}
              handleSwitchTab={handleSwitchTab}
            />
            <WhichComponent
              update
              setActiveTab={setActiveTab}
              handleSwitchTab={handleSwitchTab}
            />
          </>
        ) : (
          <>
            <TabInterface
              tabs={tab}
              activeTabIndex={activeTab}
              handleSwitchTab={handleSwitchTab}
            />
            <WhichComponent
              update
              setActiveTab={setActiveTab}
              handleSwitchTab={handleSwitchTab}
            />
          </>
        )}
      </div>
    </DashBoardLayout>
    </>
  );
}
