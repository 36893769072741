import React, { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import axiosInstance from "../../Utils/API";
import { GET_COMPANY_TABLE } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";
import CompanyCreatePopup from "./CompanyCreatePopup";

const animatedComponents = makeAnimated();

export default function CreatableSearchCompanyInfo({
  handleOnChange,
  name,
  defaultValue,
}) {
  const [optionsComapny, setOptions] = useState({});
  const [open, setOpen] = useState(false);
  const [defaultCompany, setDefaultCompany] = useState("");
  useEffect(() => {
    axiosInstance
      .get(`${GET_COMPANY_TABLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        // console.log(response?.data?.data?.company);
        if (response?.data?.data?.company.length > 0) {
          const options = response?.data?.data?.company.map(
            (
              { name, gst, companyId, shipToAddress, billToAddress },
              index
            ) => ({
              name,
              gst,
              shipToAddress,
              billToAddress,
              companyId,
            })
          );
          setOptions(options);
        }
      });
  }, []);

  useEffect(() => {
    setOptions({});
  }, []);

  const handleCreateNew = (inputValue) => {
    setDefaultCompany(inputValue);
    setOpen(true);
  };

  const handleSubmit = (data) => {
    // Create new option to add to the select options
    const newOption = {
      type: { name },
      CompanyId: data.companyId,
      label: data?.name,
      add: data?.shipToAddress, // Assuming you can use the same value as the label for this example
      value: data?.gst,
      billAdd: data?.billToAddress,
    };

    // Update the options and set the selected vehicle
    setOptions((prevOptions) => [...prevOptions, newOption]);

    // Update the parent component with the new option
    handleOnChange(newOption);
  };

  return (
    <>
      <CompanyCreatePopup
        open={open}
        setOpen={setOpen}
        handleSubmit={handleSubmit}
        defaultCompany={defaultCompany}
        setDefaultCompany={setDefaultCompany}
      />
      {optionsComapny.length > 0 ? (
        <CreatableSelect
          options={optionsComapny.map((el) => ({
            label: el.name,
            value: el.gst,
            add: el.shipToAddress,
            billAdd: el.billToAddress,
            type: { name },
            CompanyId: el.companyId,
          }))}
          // name={name}
          components={animatedComponents}
          defaultValue={{ label: defaultValue, value: defaultValue }}
          onChange={(newValue, actionMeta) => {
            if (actionMeta.action === "create-option") {
              handleCreateNew(newValue.label); // Pass the input value to the modal
            } else {
              handleOnChange(newValue); // Update the parent component
            }
          }}
          placeholder="Search Company"
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              primary25: "#f68e92",
              primary: "#ed1c24",
            },
          })}
        />
      ) : (
        // </div>
        <Loader />
      )}
    </>
  );
}
