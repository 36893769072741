import React from "react";
import { cancel_svg } from "../../../Utils/SVGListing";

export default function SearchField({
  label,
  buttonLeftIcon,
  buttonLabel,
  placeholder,
  buttonRightIcon,
  handleClick,
  handleChange,
  hasButton,
  value,
  setSearchValue,
}) {
  return (
    <div className="w-full  relative">
      {label && (
        <label
          htmlFor="company"
          className="block text-sm font-medium text-primary-kirtiroadways"
        >
          {label}
        </label>
      )}
      <div className="flex justify-center w-full">
        <div className=" w-full flex-row flex  ">
          <div className="border-primary-kirtiroadways border-b-2 border-l-2 border-t-2  rounded-l-lg">
            <svg
              className="h-5 w-5 text-primary-kirtiroadways mt-2 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="flex flex-row w-72">
          <input
            // type="search"
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            className={`${
              value ? "" : " border-r-2 rounded-r-lg"
            }  border-primary-kirtiroadways
            form-control
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-primary-kirtiroadways
            bg-white bg-clip-padding
            transition
            ease-in-out
            m-0
            border-t-2  border-b-2
            focus:text-gray-700 focus:bg-white  focus:outline-none`}
            placeholder={placeholder}
          />
          {value && (
            <button
              className="-ml-px relative inline-flex items-center space-x-2 pr-2 border-y-2 border-r-2 border-primary-kirtiroadways text-sm font-medium rounded-r-md text-primary-kirtiroadways"
              onClick={(e) => setSearchValue("")}
            >
              {cancel_svg}
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}
