import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Loader from "./Components/UI/Loader";
import ScrollToTop from "./Components/UI/ScrollToTop";
import Login from "./Pages/Auth/Login";
import ProtectedRoute from "./Services/ProtectedRoute";
import Dashboard from "./Pages/Dashboard/Dashboard";
import BookingPending from "./Pages/Booking-Pending/BookingPending";
import Billty from "./Pages/Billty/Billty";
import CommissionEntry from "./Pages/Commission-Entry/CommissionEntry";
// import AddBooking from "./Pages/Add-Booking/AddBooking";
import AddEditBooking from "./Pages/Booking-Pending/AddEditBooking";
import Vehicle from "./Pages/Vehicle/Vehicle";
import VehicleOwnerImage from "./Pages/Vehicle/VehicleOwnerImage";
import Balance from "./Pages/Balance/Balance";
import Billing from "./Pages/Billing/Billing";
import AddEditBilling from "./Pages/Billing/AddEditBilling";
import BillingView from "./Pages/Billing/BillingView";
import AddEditVehicle from "./Pages/Vehicle/AddEditVehicle";
import Driver from "./Pages/Driver/Driver";
import AddEditDriver from "./Pages/Driver/AddEditDriver";
import AddEditBillty from "./Pages/Billty/AddEditBillty";
import AddEditCommision from "./Pages/Commission-Entry/AddEditCommision";
import AddVehicleDetails from "./Pages/Vehicle/AddVehicleDetails";
import LedgerDetails from "./Pages/Vehicle/LedgerDetails";
import CompanyName from "./Pages/Company/CompanyName";
import AddEditCompany from "./Pages/Company/AddEditCompany";
import BilltyInvoiceView from "./Pages/Billty/BilltyInvoiceView";
import UpdateBookingBuillty from "./Pages/UpdateBookingBillty/UpdateBookingBuillty";
import CommissionInvoice from "./Pages/Commission-Entry/CommissionInvoice";
import LCV from "./Pages/Transporter-Loading-Slip/LCV";
import TransporterLoadingSlip from "./Pages/Transporter-Loading-Slip/TransporterLoadingSlip";
import AddBooking from "./Pages/Booking-Pending/AddBooking";
import UpdateBooking from "./Pages/Booking-Pending/UpdateBooking";
import AddBillty from "./Pages/Billty/AddBillty";
import UpdateBuillty from "./Pages/Billty/UpdateBuillty";
import Builty from "./Pages/Billty/Buillty";
import Profile from "./Pages/Profile/Profile";
import MultiBuillty from "./Pages/Billty/MultiBuillty";
import UpdateCommission from "./Pages/Commission-Entry/UpdateCommission";
import Transporter from "./Pages/Trasnporter/Transporter";
import AddEditTransporter from "./Pages/Trasnporter/AddEditTransporter";
import BlankBuillty from "./Pages/Billty/BlankBuillty";
import KirtiRoadLineLedger from "./Pages/KirtiRoadLineLedger/KirtiRoadLineLedger";
import VehicleInvoicePrint from "./Pages/Vehicle/VehicleInvoice/VehicleInvoicePrint";
import VehicleInvoice from "./Pages/Vehicle/VehicleInvoice/VehicleInvoice";
import VehicleInvoiceView from "./Pages/Vehicle/VehicleInvoice/VehicleInvoiceView";
import TransporterInvoice from "./Pages/Trasnporter/TransporterInvoice/TransporterInvoice";
import TransporterInvoiceView from "./Pages/Trasnporter/TransporterInvoice/TransporterInvoiceView";
import CompanyInvoice from "./Pages/Company/CompanyInvoice/CompanyInvoice";
import CompanyInvoiceView from "./Pages/Company/CompanyInvoice/CompanyInvoiceView";
import PartiesList from "./Pages/Parties/PartiesList";
import AddEditParty from "./Pages/Parties/AddEditParty";
import LedgerView from "./Components/Functionality/Ledger/LedgerView";
import OtherParties from "./Pages/Parties/Other/OtherParties";
import AddEditOtherParty from "./Pages/Parties/Other/AddEditOtherParty";
import DefaultMultiView from "./Pages/Billty/DefaultMultiView";

// Company subRoutes
const CompanyRoutes = ({ path }) => {
  return (
    <>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-edit-company`} component={AddEditCompany} />
      </Switch>
      <Switch>
      <ProtectedRoute path={`${path}/company-invoice`} component={CompanyInvoice} />
    </Switch>
      <Switch>
      <ProtectedRoute path={`${path}/company-invoice-view`} component={CompanyInvoiceView} />
    </Switch>
    </>
  );
};

// Booking subRoutes
const BookingRoutes = ({ path }) => {
  return (
    <>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-edit-booking`} component={AddEditBooking} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-booking`} component={AddBooking} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/update-booking`} component={UpdateBooking} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/update-booking-buillty`} component={UpdateBookingBuillty} />
      </Switch>
    </>
  );
};
// Booking Buillty subRoutes
const BilltyRoutes = ({ path }) => {
  return (
    <>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-builty`} component={AddBillty} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-default-buillty`} component={DefaultMultiView} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/update-builty`} component={UpdateBuillty} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-Multiple-builty`} component={MultiBuillty} />
      </Switch>
      <Switch>
        <ProtectedRoute
          exact path={`${path}/add-edit-billty`} component={AddEditBillty} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/billty-invoice-view`} component={BilltyInvoiceView} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/blank-buillty`} component={BlankBuillty} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/edit-Buillty`} component={Builty} />
      </Switch>
    </>
  );
};
// Booking Billing subRoutes
const BillingRoutes = ({ path }) => {
  return (
    <>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-edit-billing`} component={AddEditBilling} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/billing-view`} component={BillingView} />
      </Switch>
    </>
  );
};

// Transporter Subroutes
const TransporterRoutes = ({ path }) => {
  return (
    <>
    <Switch>
      <ProtectedRoute path={`${path}/add-edit-transporter`} component={AddEditTransporter} />
    </Switch>
    <Switch>
      <ProtectedRoute path={`${path}/transporter-invoice`} component={TransporterInvoice} />
    </Switch>
    <Switch>
      <ProtectedRoute path={`${path}/transporter-invoice-view`} component={TransporterInvoiceView} />
    </Switch>
    </>
  );
};

// Commission subRoutes
const CommissionRoutes = ({ path }) => {
  return (
    <>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-edit-commission`} component={AddEditCommision} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/update-commission`} component={UpdateCommission} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/commission-invoice`} component={CommissionInvoice} />
      </Switch>
    </>
  );
};

// Commission Ledger subRoutes
const LedgerRoutes = ({ path }) => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/ledger-view`} component={LedgerView} />
    </Switch>
  );
};

// Commission LCV subRoutes
const LCVRoutes = ({ path }) => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/Transporter-Loading-Slip`} component={TransporterLoadingSlip} />
    </Switch>
  );
};

// Commission PARTY subRoutes
const PartiesRoute = ({ path }) => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/add-edit-parties`} component={AddEditParty} />
    </Switch>
  );
};
// Other PARTY subRoutes
const OtherRoute = ({ path }) => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/add-edit-parties`} component={AddEditOtherParty} />
    </Switch>
  );
};

// Vehicle subRoutes
const VehicleRoutes = ({ path }) => {
  return (
    <>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-edit-vehicle`} component={AddEditVehicle} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/add-vehicle-details`} component={AddVehicleDetails} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/vehicle-invoice`} component={VehicleInvoice} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/vehicle-invoice-view`} component={VehicleInvoiceView} />
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/vehicle-invoice-print`} component={VehicleInvoicePrint}/>
      </Switch>
      <Switch>
        <ProtectedRoute exact path={`${path}/vehicle-owner-image`} component={VehicleOwnerImage}/>
      </Switch>
    </>
  );
};

// Profile Bank details subRoutes ProfileRoutes
const ProfileRoutes = ({ path }) => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/add-bank-details`} component={TransporterLoadingSlip} />
    </Switch>
  );
};

// ----Extra driver subRoutes -----
const DriverRoutes = ({ path }) => {
  return (
    <Switch>
      <ProtectedRoute exact path={`${path}/add-edit-driver`} component={AddEditDriver} />
    </Switch>
  );
};

function App() {
  return (
    <div>
      {/* <Loader /> */}
      <BrowserRouter>
        <ScrollToTop />
        <div className="App">
          <div className="content">
            <Switch>
              <Route exact path="/" component={Login} />
              {/* Dashboard Route */}
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              {/* Common Ledger Route */}
              <ProtectedRoute exact path="/Ledger-Details" component={LedgerDetails} />
              <ProtectedRoute  path="/Ledger-Details" component={LedgerRoutes} />
              {/* Company Routes */}
              <ProtectedRoute exact path="/company" component={CompanyName} />
              <ProtectedRoute path="/company" component={CompanyRoutes} />
              {/* Booking routes */}
              <ProtectedRoute exact path="/pending-booking" component={BookingPending} />
              <ProtectedRoute path="/pending-booking" component={BookingRoutes} />
              {/* Booking buillty routes */}
              <ProtectedRoute exact path="/bilty" component={Billty} />
              <ProtectedRoute path="/bilty" component={BilltyRoutes} />
              {/* Booking billing Routes */}
              <ProtectedRoute exact path="/billing" component={Billing} />
              <ProtectedRoute path="/billing" component={BillingRoutes} />
              {/* Transporter Routes */}
              <ProtectedRoute exact path="/transporter" component={Transporter} />
              <ProtectedRoute path="/transporter" component={TransporterRoutes} />
              {/* Commission Routes */}
              <ProtectedRoute exact path="/commission-entry" component={CommissionEntry} />
              <ProtectedRoute path="/commission-entry" component={CommissionRoutes} />
              {/* Commission LCV routes */}
              <ProtectedRoute exact path="/LCV" component={LCV} />
              <ProtectedRoute path="/LCV" component={LCVRoutes} />
              {/* Vehicle Routes */}
              <ProtectedRoute exact path="/vehicle" component={Vehicle} />
              <ProtectedRoute path="/vehicle" component={VehicleRoutes} />
              {/* Third Party */}
              <ProtectedRoute exact path="/thirdParties" component={PartiesList} />
              <ProtectedRoute path="/party" component={PartiesRoute} />
              {/* Third Party */}
              <ProtectedRoute exact path="/otherParties" component={OtherParties} />
              <ProtectedRoute path="/otherParties" component={OtherRoute} />
              {/* Kirtii Roadline ledgpartyer */}
              <ProtectedRoute exact path="/kirtiroadline-ledger" component={KirtiRoadLineLedger} />
              {/* Profile Route */}
              <ProtectedRoute path="/profile" component={Profile} />
              <ProtectedRoute path="/profile" component={ProfileRoutes} />

              {/* -----Extra Routes-----  */}
              <ProtectedRoute exact path="/balance" component={Balance} />
              <ProtectedRoute exact path="/driver" component={Driver} />
              <ProtectedRoute path="/driver" component={DriverRoutes} />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
