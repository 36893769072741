import React from "react";
import {
  CAUTION,
  CAUTIONContnet,
  CompanyName,
  CompanyResponsible,
  InvoiceBilltyNote,
  CONSIGNMENTNOTE,
  CustomerDeclaration,
  InvoiceDeclartion,
  InvoicSubject,
  KIRTIROADLINES,
  OwnerRiskInsurance,
  PANNo,
  receiptDeclaration,
  TermsandCondition,
  TermsandConditionNote,
  THANKYOUFORBUSSINESSWITHUS,
  ServiceTaxLiabilityConsignor,
  // ConsignorName,
  // Name,
  Address,
} from "../../Utils/Constants";
import Loader from "./Loader";
// import InvoicePrint from "../../Components/UI/InvoicePrint";
import moment from "moment";
import PrintingHeader from "../Functionality/KirtiRoadline/PrintingHeader";
import sign from "../../Images/sign.png";
import { Tablebg } from "../../Utils/TableStyling";

const InvoicePrint = (props) => {
  // console.log(props);

  return (
    <>
      {props.data.createdAt ? (
        <>
          {props.i === 0 && props.copyFor === "CONSIGNOR" ? (
            <div
              style={{ minHeight: "100%" }}
              className={`${
                props.showBorder &&
                ` border-double  border-4 ${
                  props.blackWhite ? "border-black" : "border-red-200"
                } m-5`
              }`}
            >
              <div className="">
                <div className="flex  flex-row justify-between font-bold text-xs mx-1 mb-1">
                  <h5>{InvoicSubject}</h5>
                  <h5>{InvoiceDeclartion}</h5>
                </div>
                {/* Logo and Title Start */}
                <PrintingHeader
                  data={props?.data}
                  profileData={props?.profileData}
                  secondaryPhoneNo={props?.secondaryPhoneNo}
                  NoDetails
                  NoTaxTag
                  border={props.showBorder}
                  blackWhite={props.blackWhite}
                  mobilePrint={props.mobilePrint}
                />
                {/* Logo and Title End */}
              </div>

              <div className="flex flex-row justify-start items-center px-1 mt-1">
                <div
                  className={` ${
                    !props.blackWhite
                      ? "border-neutral-400 divide-neutral-400"
                      : "  border-black divide-black"
                  }  shadow-sm
                border 
                flex
                flex-row rounded-x-md w-full divide-x `}
                >
                  <div className="w-96 px-2 pb-1">
                    <h1 className="font-bold underline text-xs text-center">
                      {CAUTION}
                    </h1>
                    <ul className="text-justify font-serif text-xs list-outside mt-1">
                      <li className="" style={{ fontSize: "9px" }}>
                        {CAUTIONContnet}
                      </li>
                      <li style={{ fontSize: "9px" }}>{receiptDeclaration}</li>
                    </ul>
                  </div>

                  <div
                    className={` ${
                      !props.blackWhite
                        ? "border-neutral-400 divide-neutral-400"
                        : "  border-black divide-black"
                    }  flex w-full flex-col divide-y`}
                  >
                    <div
                      className={` ${
                        !props.blackWhite
                          ? "border-neutral-400 divide-neutral-400"
                          : "  border-black divide-black"
                      } h-2/5 flex flex-row divide-x`}
                    >
                      <div className="w-full self-center items-center font-bold text-xs">
                        <h1 className="underline text-center font-bold">
                          {PANNo} {props?.profileData?.panNo}
                        </h1>
                        <div className="self-center text-center font-bold">
                          {props.copyFor} COPY
                        </div>
                      </div>
                      <div className="w-96 items-center py-2">
                        <h1 className="font-bold text-xs underline text-center">
                          {OwnerRiskInsurance}
                        </h1>
                        <h4 className="text-xs text-center px-1">
                          {CustomerDeclaration}
                        </h4>
                      </div>
                    </div>

                    <div
                      className={` ${
                        !props.blackWhite
                          ? "border-neutral-400 divide-neutral-400"
                          : "  border-black divide-black"
                      } h-2/5 flex flex-row divide-x`}
                    >
                      <div className="w-full self-center font-bold">
                        <h1
                          className={`underline font-bold text-xs text-center ${
                            !props.blackWhite && "text-primary-kirtiroadways"
                          } `}
                        >
                          {" "}
                          {CONSIGNMENTNOTE}
                        </h1>
                      </div>
                      <div className="w-96 text-left text-xs">
                        <ul
                          className={` ${
                            !props.blackWhite
                              ? "border-neutral-400 divide-neutral-400"
                              : "  border-black divide-black"
                          }  text-xs divide-y`}
                        >
                          <li className="px-2 py-2 text-xs">
                            {CompanyName} :
                            {/* {""} {props.profileData.companyName} */}
                          </li>
                          <li className="px-2 py-3 text-xs">
                            Policy No : {""}
                            {/* {props.profileData.policyNo} */}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className={` ${
                        !props.blackWhite
                          ? "border-neutral-400 divide-neutral-400"
                          : "  border-black divide-black"
                      } h-4/5 flex flex-row divide-x text-xs`}
                    >
                      <div className="w-full">
                        <table className="border-collapse w-full text-xs mb-0 pb-0">
                          <thead>
                            <tr>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1 py-3`}
                              >
                                <span className="float-left">Fin. Year :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.financialYear}
                                </span>
                              </td>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1 border-l py-3`}
                              >
                                <span className="float-left">LR No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.lrNo}
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1  border-t py-3`}
                              >
                                <span className="float-left">LR Date :</span>
                                <span className="font-bold pl-1">
                                  {/* {props?.data?.lrDate
                                    ? props?.data?.lrDate.split("T")[0]
                                    : props?.data?.loadingDate.split("T")[0]} */}
                                  {props?.data?.lrDate
                                    ? moment(props?.data?.lrDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : " "}
                                </span>
                              </td>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1  border-t border-l py-3`}
                              >
                                <span className="float-left">Lorry No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.vehicleNo}
                                </span>
                              </td>
                            </tr>
                            <tr
                              className={` ${
                                !props.blackWhite
                                  ? "border-neutral-400 divide-neutral-400"
                                  : "  border-black divide-black"
                              }  border-t`}
                            >
                              <td colSpan={2} className="py-3 pl-1 text-xs ">
                                <span className="float-left ">
                                  Delivery At. :
                                </span>
                                <span className="font-bold pl-1">
                                  {""}
                                  {props?.data?.to}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="w-96">
                        <div className="w-full ">
                          <table className="border-collapse w-full text-xs mb-0 pb-0">
                            <thead>
                              <tr>
                                <td
                                  className="border-y border-t-0 border-l-0 border-neutral-400 px-2 py-3"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    Date :{" "}
                                    {/* {props?.data?.createdAt
                                  ? props?.data?.createdAt.split("T")[0]
                                  : props?.data?.createdAt.split("T")[0]} */}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="text-left">
                                <td
                                  colSpan="2"
                                  className="w-1/2 border-t-0 border-l-0 border-neutral-400 px-2 py-2"
                                >
                                  <span className="float-left">
                                    Amount :{" "}
                                    {/* {props?.data?.amountToPayOrPaid === null
                                      ? ""
                                      : props?.data?.amountToPayOrPaid} */}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Consignore and consignee */}
              <div
                className="mx-1 mt-1 border-gray-600 shadow-sm border rounded-l-md text-xs rounded-r-md
                       flex flex-col"
              >
                <table className="border-collapse text-xs">
                  <tbody>
                    <tr>
                      <td className="w-1/2 border-b border-r border-neutral-400 p-1 font-bold text-xs">
                        <span className="float-left">
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            {" "}
                            Consignor's Name :
                          </span>{" "}
                          {props?.data?.consignerName}
                        </span>
                      </td>
                      <td className="w-1/2 border-b  border-neutral-400 p-1 font-bold">
                        <span className="float-left">
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            {" "}
                            Consignee's Name :
                          </span>{" "}
                          {props?.data?.consigneeName}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-xs h-20">
                      <td className="w-1/2 border-b border-r border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consignerAddress}
                        </span>
                      </td>
                      <td className="w-1/2 border-b border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consigneeAddress}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-xs ">
                      <td className="w-1/2  border-r border-neutral-400 p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consignerGst === null
                            ? "NA"
                            : props?.data?.consignerGst.toUpperCase()}
                        </span>
                      </td>
                      <td className="w-1/2 p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2  pb-2"
                          }`}
                        >
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consigneeGst === null
                            ? "NA"
                            : props?.data?.consigneeGst.toUpperCase()}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* from and to*/}
              <div
                className="mx-1 my-1 border-gray-600 shadow-sm border rounded-l-md text-xs rounded-r-md
                                flex flex-col "
              >
                <table className="border-collapse text-xs">
                  <tbody>
                    <tr>
                      <td className="w-1/2 font-bold border-r border-neutral-400 p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            From:-
                          </span>{" "}
                          {props?.data?.from}
                        </span>
                      </td>
                      <td className="w-1/2 font-bold p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            To:-
                          </span>{" "}
                          {props?.data?.to}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Table */}
              <div className="mx-1 ">
                <table className="overflow-x-scroll w-full">
                  <thead className="text-xs">
                    <tr className="border">
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border  border-black"
                        } `}
                      >
                        Packages
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border  border-black"
                        } `}
                      >
                        Description (said to contains)
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border  border-black"
                        } `}
                      >
                        <h1
                          className={` ${
                            !props.blackWhite
                              ? "border-b-2 border-neutral-300"
                              : "border-b border-black"
                          }  ${props.mobilePrint && "-mt-2 pb-2"}`}
                        >
                          Weight
                        </h1>
                        <div
                          className={`w-full flex flex-row justify-evenly text-center  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <h1>Actual</h1>
                          <h1>Charged</h1>
                        </div>
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } `}
                      >
                        Rate
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } `}
                      >
                        <h1
                          className={` ${
                            !props.blackWhite
                              ? "border-b-2 border-neutral-300"
                              : "border-b border-black"
                          }${props.mobilePrint && "-mt-2 pb-2"} `}
                        >
                          Amount to Pay/Paid
                        </h1>
                        <h1 className={`${props.mobilePrint && "-mt-2 pb-2"}`}>
                          Rs.
                        </h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="align-top font-light text-center text-xs">
                    <tr
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } font-light`}
                    >
                      <td
                        rowSpan="2"
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } font-semibold items-center`}
                      >
                        {props?.data?.packages !== null && (
                          <>
                            <h1 className="">{props?.data?.packages}</h1>
                            {/* <h1 className="">NOS</h1> */}
                          </>
                        )}
                      </td>
                      <td className="font-semibold flex flex-col space-y-16 align-middle">
                        <h1 className="font-semibold mt-1">
                          {props?.data?.description}
                        </h1>
                        <h1 className="font-semibold mt-4">AS PER INVOICE</h1>
                      </td>
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } `}
                      >
                        <div className="flex flex-col space-y-7 ">
                          <div className="flex flex-row justify-evenly">
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.actualWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.chargedWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                          </div>
                          <div className="font-semibold space-y-6">
                            <h1
                              className={` ${
                                !props.blackWhite
                                  ? "border-y-2 border-neutral-300"
                                  : "border-y border-black"
                              } font-semibold  ${
                                props.mobilePrint && "-mt-2 pb-2"
                              } `}
                            >
                              Company Invoice No.
                            </h1>
                            <h1 className="font-semibold">
                            {props.i===0
                                ? props?.data?.invoiceNo
                                : ""}
                            </h1>
                          </div>
                        </div>
                      </td>
                      <td className="flex flex-col space-y-4">
                        <h1 className="font-semibold">Freight</h1>
                        <h1 className="font-semibold">Sur. Ch.</h1>
                        <h1 className="font-semibold">St. Ch.</h1>
                        <h1
                          className={`${`font-semibold ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}`}
                        >
                          Risk Ch.
                        </h1>
                      </td>
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } font-semibold `}
                      >
                        <div className=" mt-12 font-semibold text-black">
                          {props?.information || props?.data?.amountToPayOrPaid}
                        </div>
                      </td>
                    </tr>
                    <tr className="border-none">
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-r-2 border-b-2 border-neutral-300"
                            : "border-r border-b border-black"
                        } text-center justify-center text-xs  font-bold ${
                          props.mobilePrint && "-mt-2 pb-2"
                        }  `}
                      >
                        <h1>Private Marks</h1>
                      </td>
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-x-2 border-b-2 border-neutral-300"
                            : "border-x border-b  border-black"
                        } font-semibold text-left`}
                      >
                        <h1 className="font-semibold">
                          {props?.data?.privateMarks}
                        </h1>
                      </td>
                      <td
                        className={` text-md  text-center justify-center  font-bold ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white border-r-2 border-y-2  border-neutral-300"
                            : "border-r border-y border-black"
                        }`}
                      >
                        {" "}
                        <h1>TOTAL</h1>
                      </td>
                      <td
                        className={`font-mono text-md whitespace-nowrap	 text-center justify-center  font-bold ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white border-r-2 border-b-2 border-neutral-300"
                            : "border-r border-b border-black"
                        }`}
                      >
                        <span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className={` ${
                    !props.blackWhite ? " border-neutral-300" : " border-black"
                  } font-bold text-xs text-center border-b-0 border w-full ${
                    props.mobilePrint && "mt-2 h-6"
                  } `}
                >
                  <p style={{ fontSize: "10px" }}>
                    To Pay : {props?.data?.paidBy}
                  </p>
                </div>
                <div
                  className={` ${
                    !props.blackWhite ? " border-neutral-300" : " border-black"
                  } font-bold text-xs text-center border w-full ${
                    props.mobilePrint && " pb-2"
                  }  `}
                >
                  <span style={{ fontSize: "10px" }}>{InvoiceBilltyNote}</span>
                  <br />
                  <span style={{ fontSize: "10px" }}>{CompanyResponsible}</span>
                </div>
              </div>
              {/* Table End */}

              <div className="mx-4 mt-1">
                {/* bottom left and right start */}
                <div className="flex flex-row space-y-4 justify-between text-left">
                  <div className="max-w-md">
                    <h1 className="font-bold underline border-neutral-900  text-xs">
                      {TermsandCondition}
                    </h1>
                    <p
                      className="	text-justify"
                      style={{ fontSize: "10px", lineHeight: "1.2" }}
                    >
                      {TermsandConditionNote}
                    </p>
                  </div>
                  <div className="font-semibold text-xs text-right">
                    <h3 style={{ fontSize: "10px" }}>Value: AS PER INVOICE</h3>
                    <h3 style={{ fontSize: "10px" }}>
                      {ServiceTaxLiabilityConsignor}
                    </h3>
                    <div className="text-left  text-sm	flex justify-end">
                      <img src={sign} alt="signature" width={100} />
                    </div>
                    <h3 style={{ fontSize: "10px" }}>For {KIRTIROADLINES}</h3>
                  </div>
                </div>
                {/* bottom left and right end */}

                {/* <div className="relative">
                <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
          </div> */}
              </div>

              <div
                className={`${
                  props.mobilePrint
                    ? " text-center text-xs p-2 -mt-2 "
                    : `text-xs mt-0 mb-0 text-center font-bold rounded-md p-2 font-sans ${Tablebg(
                        props.blackWhite
                      )} `
                }`}
              >
                <span>{THANKYOUFORBUSSINESSWITHUS}</span>
              </div>
            </div>
          ) : (
            <div
              style={{ minHeight: "100%" }}
              className={`${
                props.showBorder &&
                ` border-double  border-4 ${
                  props.blackWhite ? "border-black" : "border-red-200"
                } ${
                  props.mobilePrint ? "mx-5 mt-5":"m-5"              }  pagebreak`
              }`}
            >
              <div className="">
                <div className="flex  flex-row justify-between font-bold text-xs mx-1 mb-1">
                  <h5>{InvoicSubject}</h5>
                  <h5>{InvoiceDeclartion}</h5>
                </div>
                {/* Logo and Title Start */}
                <PrintingHeader
                  mobilePrint={props.mobilePrint}
                  data={props?.data}
                  profileData={props?.profileData}
                  secondaryPhoneNo={props?.secondaryPhoneNo}
                  NoDetails
                  NoTaxTag
                  border={props.showBorder}
                  blackWhite={props.blackWhite}
                />
                {/* Logo and Title End */}
              </div>

              <div className="flex flex-row justify-start items-center px-1 mt-1">
                <div
                  className={` ${
                    !props.blackWhite
                      ? "border-neutral-400 divide-neutral-400"
                      : "  border-black divide-black"
                  }  shadow-sm
              border 
              flex
              flex-row rounded-x-md w-full divide-x `}
                >
                  <div className="w-96 px-2 pb-1">
                    <h1 className="font-bold underline text-xs text-center">
                      {CAUTION}
                    </h1>
                    <ul className="text-justify font-serif text-xs list-outside mt-1">
                      <li className="" style={{ fontSize: "9px" }}>
                        {CAUTIONContnet}
                      </li>
                      <li style={{ fontSize: "9px" }}>{receiptDeclaration}</li>
                    </ul>
                  </div>

                  <div
                    className={` ${
                      !props.blackWhite
                        ? "border-neutral-400 divide-neutral-400"
                        : "  border-black divide-black"
                    }  flex w-full flex-col divide-y`}
                  >
                    <div
                      className={` ${
                        !props.blackWhite
                          ? "border-neutral-400 divide-neutral-400"
                          : "  border-black divide-black"
                      } h-2/5 flex flex-row divide-x`}
                    >
                      <div className="w-full self-center items-center font-bold text-xs">
                        <h1 className="underline text-center font-bold">
                          {PANNo} {props?.profileData?.panNo}
                        </h1>
                        <div className="self-center text-center font-bold">
                          {props.copyFor} COPY
                        </div>
                      </div>
                      <div className="w-96 items-center py-2">
                        <h1 className="font-bold text-xs underline text-center">
                          {OwnerRiskInsurance}
                        </h1>
                        <h4 className="text-xs text-center px-1">
                          {CustomerDeclaration}
                        </h4>
                      </div>
                    </div>

                    <div
                      className={` ${
                        !props.blackWhite
                          ? "border-neutral-400 divide-neutral-400"
                          : "  border-black divide-black"
                      } h-2/5 flex flex-row divide-x`}
                    >
                      <div className="w-full self-center font-bold">
                        <h1
                          className={`underline font-bold text-xs text-center ${
                            !props.blackWhite && "text-primary-kirtiroadways"
                          } `}
                        >
                          {" "}
                          {CONSIGNMENTNOTE}
                        </h1>
                      </div>
                      <div className="w-96 text-left text-xs">
                        <ul
                          className={` ${
                            !props.blackWhite
                              ? "border-neutral-400 divide-neutral-400"
                              : "  border-black divide-black"
                          }  text-xs divide-y`}
                        >
                          <li className="px-2 py-2 text-xs">
                            {CompanyName} :
                            {/* {""} {props.profileData.companyName} */}
                          </li>
                          <li className="px-2 py-3 text-xs">
                            Policy No : {""}
                            {/* {props.profileData.policyNo} */}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className={` ${
                        !props.blackWhite
                          ? "border-neutral-400 divide-neutral-400"
                          : "  border-black divide-black"
                      } h-4/5 flex flex-row divide-x text-xs`}
                    >
                      <div className="w-full">
                        <table className="border-collapse w-full text-xs mb-0 pb-0">
                          <thead>
                            <tr>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1 py-3`}
                              >
                                <span className="float-left">Fin. Year :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.financialYear}
                                </span>
                              </td>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1 border-l py-3`}
                              >
                                <span className="float-left">LR No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.lrNo}
                                </span>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1  border-t py-3`}
                              >
                                <span className="float-left">LR Date :</span>
                                <span className="font-bold pl-1">
                                  {/* {props?.data?.lrDate
                                  ? props?.data?.lrDate.split("T")[0]
                                  : props?.data?.loadingDate.split("T")[0]} */}
                                  {props?.data?.lrDate
                                    ? moment(props?.data?.lrDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : " "}
                                </span>
                              </td>
                              <td
                                className={` ${
                                  !props.blackWhite
                                    ? "border-neutral-400 divide-neutral-400"
                                    : "  border-black divide-black"
                                } w-1/2 pl-1  border-t border-l py-3`}
                              >
                                <span className="float-left">Lorry No. :</span>
                                <span className="font-bold pl-1">
                                  {props?.data?.vehicleNo}
                                </span>
                              </td>
                            </tr>
                            <tr
                              className={` ${
                                !props.blackWhite
                                  ? "border-neutral-400 divide-neutral-400"
                                  : "  border-black divide-black"
                              }  border-t`}
                            >
                              <td colSpan={2} className="py-3 pl-1 text-xs ">
                                <span className="float-left ">
                                  Delivery At. :
                                </span>
                                <span className="font-bold pl-1">
                                  {""}
                                  {props?.data?.to}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="w-96">
                        <div className="w-full ">
                          <table className="border-collapse w-full text-xs mb-0 pb-0">
                            <thead>
                              <tr>
                                <td
                                  className="border-y border-t-0 border-l-0 border-neutral-400 px-2 py-3"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    Date :{" "}
                                    {/* {props?.data?.createdAt
                                ? props?.data?.createdAt.split("T")[0]
                                : props?.data?.createdAt.split("T")[0]} */}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="text-left">
                                <td
                                  colSpan="2"
                                  className="w-1/2 border-t-0 border-l-0 border-neutral-400 px-2 py-2"
                                >
                                  <span className="float-left">
                                    Amount :{" "}
                                    {/* {props?.data?.amountToPayOrPaid === null
                                    ? ""
                                    : props?.data?.amountToPayOrPaid} */}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Consignore and consignee */}
              <div
                className="mx-1 mt-1 border-gray-600 shadow-sm border rounded-l-md text-xs rounded-r-md
                     flex flex-col"
              >
                <table className="border-collapse text-xs">
                  <tbody>
                    <tr>
                      <td className="w-1/2 border-b border-r border-neutral-400 p-1 font-bold text-xs">
                        <span className="float-left">
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            {" "}
                            Consignor's Name :
                          </span>{" "}
                          {props?.data?.consignerName}
                        </span>
                      </td>
                      <td className="w-1/2 border-b  border-neutral-400 p-1 font-bold">
                        <span className="float-left">
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            {" "}
                            Consignee's Name :
                          </span>{" "}
                          {props?.data?.consigneeName}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-xs h-20">
                      <td className="w-1/2 border-b border-r border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consignerAddress}
                        </span>
                      </td>
                      <td className="w-1/2 border-b border-neutral-400 p-1">
                        <span className="float-left">
                          <span className="">{Address}</span>{" "}
                          {props?.data?.consigneeAddress}
                        </span>
                      </td>
                    </tr>

                    <tr className="align-top text-left text-xs pb-10">
                      <td className="w-1/2  border-r border-neutral-400 p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consignerGst === null
                            ? "NA"
                            : props?.data?.consignerGst.toUpperCase()}
                        </span>
                      </td>
                      <td className="w-1/2 p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2"
                          }`}
                        >
                          <span className="">GST No :</span>{" "}
                          {props?.data?.consigneeGst === null
                            ? "NA"
                            : props?.data?.consigneeGst.toUpperCase()}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* from and to*/}
              <div
                className="mx-1 my-1 border-gray-600 shadow-sm border rounded-l-md text-xs rounded-r-md
                              flex flex-col "
              >
                <table className="border-collapse text-xs">
                  <tbody>
                    <tr>
                      <td className="w-1/2 font-bold border-r border-neutral-400 p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            From:-
                          </span>{" "}
                          {props?.data?.from}
                        </span>
                      </td>
                      <td className="w-1/2 font-bold p-1">
                        <span
                          className={`float-left  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <span
                            className={` font-bold  ${
                              !props.blackWhite && "text-primary-kirtiroadways"
                            } `}
                          >
                            To:-
                          </span>{" "}
                          {props?.data?.to}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Table */}
              <div className="mx-1">
                <table className="overflow-x-scroll w-full">
                  <thead className="text-xs">
                    <tr className="border">
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border  border-black"
                        } `}
                      >
                        Packages
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border  border-black"
                        } `}
                      >
                        Description (said to contains)
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border  border-black"
                        } `}
                      >
                        <h1
                          className={` ${
                            !props.blackWhite
                              ? "border-b-2 border-neutral-300"
                              : "border-b border-black"
                          }  ${props.mobilePrint && "-mt-2 pb-2"}`}
                        >
                          Weight
                        </h1>
                        <div
                          className={`w-full flex flex-row justify-evenly text-center  ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}
                        >
                          <h1>Actual</h1>
                          <h1>Charged</h1>
                        </div>
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } `}
                      >
                        Rate
                      </th>
                      <th
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } `}
                      >
                        <h1
                          className={` ${
                            !props.blackWhite
                              ? "border-b-2 border-neutral-300"
                              : "border-b border-black"
                          } ${props.mobilePrint && "-mt-2 pb-2"} `}
                        >
                          Amount to Pay/Paid
                        </h1>
                        <h1 className={`${props.mobilePrint && "-mt-2 pb-2"}`}>
                          Rs.
                        </h1>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="align-top font-light text-center text-xs">
                    <tr
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } font-light`}
                    >
                      <td
                        rowSpan="2"
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } font-semibold items-center`}
                      >
                        {props?.data?.packages !== null && (
                          <>
                            <h1 className="">{props?.data?.packages}</h1>
                            {/* <h1 className="">NOS</h1> */}
                          </>
                        )}
                      </td>
                      <td className="font-semibold flex flex-col space-y-16 align-middle">
                        <h1 className="font-semibold mt-1">
                          {props?.data?.description}
                        </h1>
                        <h1 className="font-semibold mt-4">AS PER INVOICE</h1>
                      </td>
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } `}
                      >
                        <div className="flex flex-col space-y-7 ">
                          <div className="flex flex-row justify-evenly">
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.actualWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                            <div>
                              <h1 className="font-semibold">
                                {props?.data?.chargedWeight}{" "}
                                {props?.data?.weightType}
                              </h1>
                            </div>
                          </div>
                          <div className="font-semibold space-y-6">
                            <h1
                              className={` ${
                                !props.blackWhite
                                  ? "border-y-2 border-neutral-300"
                                  : "border-y border-black"
                              } font-semibold ${
                                props.mobilePrint && "-mt-2 pb-2"
                              } `}
                            >
                              Company Invoice No.
                            </h1>
                            <h1 className="font-semibold">
                              {props.i===0
                                ? props?.data?.invoiceNo
                                : ""}
                            </h1>
                          </div>
                        </div>
                      </td>
                      <td className="flex flex-col space-y-4">
                        <h1 className="font-semibold">Freight</h1>
                        <h1 className="font-semibold">Sur. Ch.</h1>
                        <h1 className="font-semibold">St. Ch.</h1>
                        <h1
                          className={`${`font-semibold ${
                            props.mobilePrint && "-mt-2 pb-2"
                          }`}`}
                        >
                          Risk Ch.
                        </h1>
                      </td>
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-2 border-neutral-300"
                            : "border border-black"
                        } font-semibold `}
                      >
                        <div className=" mt-12 font-semibold text-black">
                          {props?.information || props?.data?.amountToPayOrPaid}
                        </div>
                      </td>
                    </tr>
                    <tr className="">
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-r-2 border-b-2 border-neutral-300"
                            : "border-r border-b border-black"
                        } text-center justify-center text-xs  font-bold  ${
                          props.mobilePrint && "-mt-2 pb-2"
                        }  `}
                      >
                        <h1>Private Marks</h1>
                      </td>
                      <td
                        className={` ${
                          !props.blackWhite
                            ? "border-x-2 border-b-2 border-neutral-300"
                            : "border-x border-b  border-black"
                        } font-semibold text-left`}
                      >
                        <h1 className="font-semibold">
                          {props?.data?.privateMarks}
                        </h1>
                      </td>
                      <td
                        className={` text-md  text-center justify-center  font-bold ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white border-r-2 border-y-2  border-neutral-300"
                            : "border-r border-y border-black"
                        }`}
                      >
                        {" "}
                        <h1>TOTAL</h1>
                      </td>
                      <td
                        className={`font-mono text-md whitespace-nowrap	 text-center justify-center  font-bold ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white border-r-2 border-b-2 border-neutral-300"
                            : "border-r border-b border-black"
                        }`}
                      >
                        <span></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className={` ${
                    !props.blackWhite ? " border-neutral-300" : " border-black"
                  } font-bold text-xs text-center border-b-0 border w-full ${
                    props.mobilePrint && "mt-2 h-6"
                  } `}
                >
                  <p style={{ fontSize: "10px" }}>
                    To Pay : {props?.data?.paidBy}
                  </p>
                </div>
                <div
                  className={` ${
                    !props.blackWhite ? " border-neutral-300" : " border-black"
                  } font-bold text-xs text-center border w-full ${
                    props.mobilePrint && " pb-2"
                  }  `}
                >
                  <span style={{ fontSize: "10px" }}>{InvoiceBilltyNote}</span>
                  <br />
                  <span style={{ fontSize: "10px" }}>{CompanyResponsible}</span>
                </div>
              </div>
              {/* Table End */}

              <div className="mx-4 mt-1">
                {/* bottom left and right start */}
                <div className="flex flex-row space-y-4 justify-between text-left">
                  <div className="max-w-md">
                    <h1 className="font-bold underline border-neutral-900  text-xs">
                      {TermsandCondition}
                    </h1>
                    <h6 className="	text-xs text-justify">
                      {TermsandConditionNote}
                    </h6>
                  </div>
                  <div className="font-semibold text-xs text-right">
                    <h3>Value: AS PER INVOICE</h3>
                    <h3>{ServiceTaxLiabilityConsignor}</h3>
                    <div className="text-left  text-sm	flex justify-end">
                      <img src={sign} alt="signature" width={100} />
                    </div>
                    <h3 className="">For {KIRTIROADLINES}</h3>
                  </div>
                </div>
                {/* bottom left and right end */}

                {/* <div className="relative">
              <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
        </div> */}
              </div>

              <div
                className={`${
                  props.mobilePrint
                    ? " text-center text-xs p-2 -mt-2 "
                    : `text-xs mt-0 mb-0 text-center font-bold rounded-md p-2 font-sans ${Tablebg(
                        props.blackWhite
                      )} `
                }`}
              >
                <span>{THANKYOUFORBUSSINESSWITHUS}</span>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvoicePrint;
