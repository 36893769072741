import React, { useEffect, useState } from "react";
import axiosInstance from "../../Utils/API";
import { GET_PARTY_TABLE } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import Tooltip from "react-simple-tooltip";
// import Checkbox from "../UI/Checkbox";

export default function ThirdParty(props) {
  const [partyData, SetPartyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // for Search by Company Name.
  // const filterByCompanyName = ({ Name }) => {
  //   return Name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };

  // for Search by Person name
  // const filterByPersonName = ({ Contact_Person_name }) => {
  //   return Contact_Person_name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };

  const columns = [
    {
      Header: "Action",
      id: "Action",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div>
          <TableAction
            isLedger
            // isBilling
            data={row.row.original}
            redirectPath="party/add-edit-parties"
            redirectLedger="Ledger-Details"
            component="Third Party"
            getAPI={getAPI}
            // redirectBilling="party/party-invoice"
            //  redirectViewPath="billing/billing-view"
          />
        </div>
      ),
    },
    {
      Header: "Third Party Name",
      accessor: "name",
      maxWidth: 25,
      minWidth: 10,
      // width: 200,
    },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      maxWidth: 25,
      minWidth: 10,
      // width: 250,
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: ({row,value}) => (
        <Tooltip
          className="w-48"
          border="#ed1c24"
          color="black"
          background="#fabbbd"
          content={value}
          radius={5}
          // placement={row.index === 0 ? "bottom" : "top"}
          zIndex={100}
          padding={0}
          arrow={5}
        >
          <div className="text-left truncate">{value}</div>
        </Tooltip>
      ),
      maxWidth: 250,
      minWidth: 10,
      // width: 250,
    },
  ];
  useEffect(() => {
    getAPI();
    // eslint-disable-next-line
  }, [props.searchValue]);

  const getAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${GET_PARTY_TABLE}?search=${props.searchValue}&pageNo=&pageSize=`)
      .then((response) => {
        // console.log(response?.data?.data?.company)
        const data = response?.data?.data?.thirdPartyList;
        SetPartyData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };
  // console.log(props,"for table")
  return (
    <section>
      <div className="mt-4 ">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={partyData} columns={columns} />
        )}
      </div>
    </section>
  );
}
