import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashBoardLayout from "../../../Layouts/private/DashboardLayout";
import DatePicker from "react-datepicker";
import CompanyInvoiceTable from "../../../Components/DataTables/CompanyInvoiceTable";

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function CompanyInvoice(props) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const location = useLocation();
  useEffect(() => {}, [location]);

  const [pageTitle] = useState(`Company Invoice`);
  return (
    <DashBoardLayout pageTitle={pageTitle}>
      {/* <div className="flex flex-row "> */}
        <div className="flex flex-row space-x-2">
          <div className=" flex flex-col  text-left ">
            <Label label="Start Date" />
            <div className="flex flex-row justify-center w-full">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                className="peer	 w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                placeholderText={'Please select start date'} 
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>
          <div className=" flex flex-col  text-left ">
            <Label label="End Date" />
            <div className="flex flex-row justify-center ">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
        {/* <div className="flex flex-row space-x-2  items-start">
          <Button svg={setting_svg}></Button>s
        </div> */}
      {/* </div> */}
      <CompanyInvoiceTable
        ownerId={props?.location?.data?.data?.ownerId}
        startDate={startDate}
        endDate={endDate}
      />
    </DashBoardLayout>
  );
}
