import moment from "moment";


const billingDetails = async (invoice, profileData) => {
  // console.log(invoice, "invoice");
  const billingDetails = [
    {
      columns: [
        {
          alignment: "left",
          margin: [0, 0, 0, 0], // Set margin to 0
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 100,
                  h: 25,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: 20, y: -18 },
              text: "INVOICE TO",
              color: "#000",
              fontSize: 11,
              bold: true,
            },
          ],
        },
        {
          margin: [0, 0, 0, 0], // Set margin to 0
          alignment: "right",
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 100,
                  h: 25,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              alignment: "right",
              relativePosition: { x: -16, y: -18 },
              text: "OUR DETAILS",
              color: "#000",
              fontSize: 11,
              bold: true,
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          width: 300,
          stack: [
            {
              text: `${invoice?.companyName}`,
              color: "#000",
              fontSize: 11,
              bold: true,
              margin: [0, 5, 0, 0],
            },
            // {
            //   text: `ADDRESS :${invoice?.companyDetails.billToAddress}`,
            //   color: "#000",
            //   fontSize: 9,
            //   margin: [0, 5, 0, 0],
            // },
            // {
            //   text: `GST : ${invoice?.companyDetails.gst ? invoice?.companyDetails.gst.toUpperCase() : "-"}`,
            //   color: "#000",
            //   fontSize: 9,
            //   margin: [0, 5, 0, 0],
            // },
          ],
        },
        {
          text: "",
          width: "*",
        },
        {
          alignment: "right",
          width: 180,
          stack: [
            {
              text: `SERVICE : ${profileData?.service}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `PAN NO. : ${profileData?.panNo}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `STATE CODE : ${profileData?.stateCode}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              canvas: [
                {
                  type: "line",
                  x1: 200,
                  y1: 5,
                  x2: 20,
                  y2: 5,
                  lineWidth: 2,
                },
              ],
              margin: [0, 0, 0, 2],
            },
            {
              text: `INVOICE NO : ${
                invoice.invoiceNo || invoice?.bookingInvoiceNo
                  ? invoice?.invoiceNo
                    ? invoice?.invoiceNo
                    : invoice?.bookingInvoiceNo
                    ? invoice?.bookingInvoiceNo
                    : "--"
                  : invoice?.invoiceNo
                  ? invoice?.invoiceNo
                  : "--"
              }`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `DATE : ${moment().format("DD-MM-YYYY")}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
          ],
        },
      ],
    },
  ];
  return billingDetails;
};

const billingTable = async (invoice) => {
  const addEmptyRows = (count) => {
    let rows = [];
    for (let index = 0; index < count; index++) {
      let row = [
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
      ];
      rows.push(row);
    }

    return rows;
  };
  const mappingOfData = () => {
    const rows = invoice?.invoices?.map((data, index) => {
      return [
        {
          text: `${index + 1}`,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [false, false, true, false],
        },
        {
          text: `${moment(data?.date).format("DD-MM-YYYY")}`,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.debit ? data?.debit : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.credit ? data?.credit : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.balance ? data?.balance : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, false, false],
        },
      ];
    });
    return rows;
  };

  const rowCount = 13 - +mappingOfData().length;
  const tableBody =
    mappingOfData().length > 13
      ? mappingOfData()
      : [...mappingOfData(), ...addEmptyRows(rowCount)];
  const tableBody1 = [
    [
      {
        text: "#",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [false, true, true, true],
        margin: [0, 2, 0, 0],
      },
      {
        text: "DATE",
        bold: true,
        fontSize: 9,
        alignment: "center",
        margin: [0, 2, 0, 0],
      },
      {
        text: "DEBIT",
        bold: true,
        fontSize: 9,
        alignment: "center",
        margin: [0, 2, 0, 0],
      },
      {
        text: "CREDIT",
        bold: true,
        fontSize: 9,
        alignment: "center",
        margin: [0, 2, 0, 0],
      },
      {
        text: "BALANCE",
        bold: true,
        fontSize: 9,
        alignment: "center",
        margin: [0, 2, 0, 0],
        border: [true, true, false, true],
      },
    ],
    ...tableBody,
  ];

  const table = {
    heights: 15,
    widths: ["*", "*", "*", "*", "*"],
    headerRows: 1,
    fontSize: 9,
    bold: true,
    // margin: [0, 10, 0, 10],
    body: tableBody1,
  };

  const content = [
    // Other content
    {
      table,
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 ? 1 : 0.5; // Set to 1 for the bottom border of each cell in the first row, 0.5 for other rows
        },
        hLineColor: function (i) {
          return i === 0 ? "#000" : "#000"; // Set color for the bottom border of each cell in the first row
        },
        paddingTop: function () {
          return 4; // Adjust padding as needed
        },
        paddingBottom: function () {
          return 4; // Adjust padding as needed
        },
        vLineWidth: function (i) {
          return 1; // Set to 0 for no vertical borders
        },
        vLineColor: function () {
          return "#000"; // Set to white or your background color
        },
      },
    },
    // Other content
  ];

  return content;
};

export const LedgerContentLayout = async (
  invoice,
  WE_USING_PAYPHONE,
  profileData
) => {
  const BillingInfo = await billingDetails(invoice, profileData);

  const BillingTabDATA = await billingTable(
    invoice,
    WE_USING_PAYPHONE,
    profileData
  );

  return [
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
    BillingInfo,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
    BillingTabDATA,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
  ];
};

export const LedgerTotal = async (invoice) => {
  // console.log(invoice,'data')
  const LedgerData = [
    {
      margin: [0, 0, 0, 0],
      stack: [
        {
          columns: [
            {
              table: {
                alignment: "center",
                heights: 20,
                widths: ["*", "*", "*", "*", "*"],
                body: [
                  [
                    {
                      margin: [0, 5, 0, 5],
                      text: "",
                      fontSize: 10,
                      border: [true, true, true, true],
                      bold: true,
                      alignment: "center",
                    },
                    {
                      margin: [0, 5, 0, 5],
                      text: "TOTAL",
                      fontSize: 10,
                      border: [true, true, true, true],
                      bold: true,
                      alignment: "center",
                    },
                    {
                      margin: [0, 5, 0, 5],
                      text: invoice?.totalDebit ? invoice?.totalDebit : 0,
                      fontSize: 10,
                      border: [true, true, true, true],
                      bold: true,
                      alignment: "center",
                    },
                    {
                      margin: [0, 5, 0, 5],
                      text: invoice?.totalCredit ? invoice?.totalCredit : 0,
                      fontSize: 10,
                      border: [true, true, true, true],
                      bold: true,
                      alignment: "center",
                    },
                    {
                      margin: [0, 5, 0, 5],
                      text: invoice?.totalBalance ? invoice?.totalBalance : 0,
                      fontSize: 10,
                      border: [true, true, true, true],
                      bold: true,
                      alignment: "center",
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          characterSpacing: 0.5,
          margin: [0, 7, 0, 0],
          text: "AMOUNT CHARGEABLE (IN WORDS)",
          fontSize: 11,
        },
        {
          characterSpacing: 0.8,
          margin: [0, 6, 0, 0],
          text: `${invoice?.totalInWords.toUpperCase()} ONLY`,
          fontSize: 13,
        },
        {
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 2,
                  w: 520, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 1,
                  w: 520, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return LedgerData;
};
