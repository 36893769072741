import React,{useState} from "react";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
// import Divider from "../../Components/UI/Divider";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
// import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import BalanceTable from "../../Components/DataTables/BalanceTable";
import { setting_svg } from "../../Utils/SVGListing";



const Balance = () => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <DashBoardLayout pageTitle="Balance" >
    <div className="min-w-full space-y-4">
      {/* <Divider subtitle="Bilty"/> */}
      {/* Search and setting */}
      <div className="flex flex-row justify-between">
        <div>
          <SearchField placeholder="Searching By Date" handleChange={setSearchValue} value={searchValue}/>
        </div>
        <div className="flex flex-row space-x-2">
          <Button svg={setting_svg}></Button>
          <ButtonWithBG theme="primary" label="Add Balance" />
        </div>
      </div>
    </div>
    <BalanceTable searchValue={searchValue}/>
  </DashBoardLayout> 
  )
}

export default Balance;