import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// eslint-disable-next-line
const styles = {
  common: `text-xs px-2 py-1 font-bold inline-flex rounded-md justify-between space-x-2 cursor-pointer`,
  Pending: `bg-primary-kirtiroadways font-bold text-white flex items-center justify-center uppercase`,
  Paid: `bg-gray-300 font-bold text-gray-600`,
};
export default function VehicleInformation({
  defaultValue,
  id,
  type,
  onClickEvent,
  label,
  advance,
  balance,
  freight
}) {
  let [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line
  const [isEnabled, setEnabled] = useState(defaultValue);

  const openModal = (props) => {
      setIsOpen(true);
  };
  return (
    <section className="flex justify-center items-center py-1">
          <div className={` cursor-pointer underline decoration-kirtiroadways-900 underline-offset-4 decoration-2	`} onClick={()=>openModal()}>
          {label}
          </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30  flex justify-center  items-center bg-gray-100 bg-opacity-75 rounded-lg shadow-md"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen ml-4 px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 " />
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-2 border-primary-kirtiroadways inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-xl font-bold leading-6 text-center text-gray-900 mb-4"
              >
                Vehicle Information
              </Dialog.Title>
              <Dialog.Panel>
                  <div className="flex flex-wrap justify-center gap-5">
                      <div className="font-semibold">
                        Freight : {freight}
                      </div>
                      <div className="font-semibold">
                        Balance : {balance}
                      </div>
                      <div className="font-semibold">
                        Advance : {advance}
                      </div>
                  </div>
              </Dialog.Panel>
            </div>
          </div>
          {/* </>
        )} */}
        </Dialog>
      </Transition>
    </section>
  );
}
