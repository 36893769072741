import React, { useState } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { Tab } from "@headlessui/react";
import { CompanyInformation } from "./CompanyInformation";
import BankInformation from "./BankInformation/BankInformation";

const Profile = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <DashBoardLayout pageTitle="Your Profile">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex space-x-1 rounded-xl bg-red-900/20 p-1">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-4 text-lg font-medium leading-5 text-black-700",
                "ring-primary-kirtiroadways-hover ring-opacity-60 ring-offset-2 ring-offset-kirtiroadways-hover focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow text-primary-kirtiroadways font-bold"
                  : "text-primary-kirtiroadways hover:bg-primary-kirtiroadways/[0.12] "
              )
            }
          >
            Company Information
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-4 text-lg font-medium leading-5 text-black-700",
                "ring-primary-kirtiroadways-hover ring-opacity-60 ring-offset-2 ring-offset-kirtiroadways-hover focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow text-primary-kirtiroadways font-bold"
                  : "text-primary-kirtiroadways hover:bg-primary-kirtiroadways/[0.12] "
              )
            }
          >
            Bank Information
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
              <CompanyInformation/>
          </Tab.Panel>
          <Tab.Panel>
              <BankInformation/>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </DashBoardLayout>
  );
};

export default Profile;
