import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import { useEffect } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import { GETVIEWBOOKINGBYID } from "../../Utils/ApiGlobal";
import DefaultMultiBuillty from "./DefaultMultiBuillty";
import { sortingArray } from "../../Components/Functionality/Helper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DefaultMultiView() {
  const location = useLocation();
  const [builltyData, setBuilltyData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [newLrData, setLrdata] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0); // Initial tab index

  // Function to extract missing parameters from an object
  // Function to merge existing array objects with templateObject
  const mergeWithTemplate = (existingArray, templateObject) => {
    return existingArray.map((obj) => {
      const newObj = { ...templateObject, ...obj };
      return newObj;
    });
  };

  useEffect(() => {
    const BookingId = location?.state?.bookingId;
    GetBookingData(BookingId); // eslint-disable-next-line
  }, [location]);
  //Get Data by Booking ID
  const GetBookingData = async (bookingId) => {
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        // console.log(response?.data?.data?.bookingDetails?.lrDetails);
        // console.log(response?.data?.data?.bookingDetails);
        const ascendinLrNo = sortingArray(response?.data?.data?.billtyDetails, "LrNo");
        setBuilltyData(ascendinLrNo);
        setBookingData(response?.data?.data?.bookingDetails);
        // Template object containing all possible parameters with default values
        const templateObject = {
          ActualWeight: " ",
          BookingId: response?.data?.data?.bookingDetails?.bookingId,
          ChargedWeight: "",
          ConsigneeAddress:
            response?.data?.data?.bookingDetails?.consigneeAddress,
          ConsigneeGst: response?.data?.data?.bookingDetails?.consigneeGst,
          ConsigneeName: response?.data?.data?.bookingDetails?.consigneeName,
          ConsignerAddress:
            response?.data?.data?.bookingDetails?.consignerAddress,
          ConsignerGST: response?.data?.data?.bookingDetails?.consignerGst,
          ConsignerName: response?.data?.data?.bookingDetails?.consignerName,
          Createdby: localStorage.getItem("userId"),
          DeliveryAt: " ",
          Description: " ",
          Detention: 0,
          ExtraCharge: 0,
          From: " ",
          Hamali: 0,
          LrDate: new Date(),
          Packages: " ",
          To: " ",
          VehicleNo: response?.data?.data?.bookingDetails?.vehicleNo,
          WeightType: undefined,
        };
        const additionalParametersArray = mergeWithTemplate(
          response?.data?.data?.bookingDetails?.lrDetails,
          templateObject
        );
        setLrdata(additionalParametersArray);
        // console.log(additionalParametersArray, "Additional");
      });
    // Array of additional parameters for each object in the existing array
  };
  return (
    <DashBoardLayout pageTitle="Add Buillty">
      <div className="w-full  px-1  sm:px-0">

        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            {builltyData.map((subData, i) => (
              <Tab
                key={i}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-1 text-sm font-medium leading-5 text-black-700",
                    "ring-primary-kirtiroadways-hover ring-opacity-60 ring-offset-2 ring-offset-kirtiroadways-hover focus:outline-none focus:ring-2",
                    selected
                      ? "bg-white shadow text-primary-kirtiroadways font-bold"
                      : "text-primary-kirtiroadways hover:bg-primary-kirtiroadways/[0.12] "
                  )
                }
              >
                Buillty: {i + 1}, lrNo: {subData.lrNo}
                <br />
                From: {subData.from} - To: {subData.to}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {builltyData.map((subData, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames("rounded-xl bg-white p-3")}
              >
                <DefaultMultiBuillty
                  data={bookingData}
                  builltyDataOld={builltyData}
                  id={idx}
                  index={idx}
                  lrData={subData}
                  newLrData={newLrData}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </DashBoardLayout>
  );
}
