import axios from "axios";
import { APIURL } from "./Constants";

const axiosInstance = axios.create({
  baseURL: APIURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token !== null) config.headers.Authorization = "Bearer " + token;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
