import Swal from "sweetalert2";

export const ConfirormationModal = (message, icon,timer) => {
  Swal.fire({
    icon: icon,
    text: message,
    showConfirmButton: false,
    timer: timer,
  });
};
