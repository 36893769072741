import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import BankInformationTable from "../../../Components/DataTables/BankInformationTable";
import Loader from "../../../Components/UI/Loader";
import axiosInstance from "../../../Utils/API";
import { BANK_INFORMATION_LIST } from "../../../Utils/ApiGlobal";
import AddEditBankInfo from "./AddEditBankInfo";

const BankInformation = () => {
  const [BankInfoData, setBankInfoData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refetchAPI, setRefetchAPI] = useState(false);

  useEffect(() => {
    _fetchBankingInformationData();
  }, []);

  const _fetchBankingInformationData = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(`${BANK_INFORMATION_LIST}`)
      .then((response) => {
        const data = response?.data?.data;
        setBankInfoData(data);
        // console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row justify-end mt-4">
        <AddEditBankInfo
          refetchAPI={refetchAPI}
          setRefetchAPI={setRefetchAPI}
          getAPI={_fetchBankingInformationData}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <BankInformationTable
         getAPI={_fetchBankingInformationData}
          BankInfoData={BankInfoData}
          refetchAPI={refetchAPI}
          setRefetchAPI={setRefetchAPI}
        />
      )}
    </div>
  );
};

export default BankInformation;
