import React from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
// import VehicleTable from "../../Components/DataTables/VehicleTable";
import AddVehicleDetailsTable from "../../Components/DataTables/AddVehicleDetailsTable";

const AddVehicleDetails = () => {
  const location = useLocation();
  // console.log(location?.state?.data)
  const Name = location?.state?.data?.name?.toUpperCase();

  return (
    <DashBoardLayout pageTitle="Add Vehicle Details">
      <div className=" flex justify-center flex-col space-y-1 p-6 shadow-lg  ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
        {/* Information  */}
        <div className="bg-primary-kirtiroadways text-white py-1 rounded-lg">
          <p className="text-5xl font-bold ">{Name}</p>
          {/* <div className="flex  justify-center w-full mt-2">
          <div className="flex space-x-10">
            <div className="max-w-sm text-right">
              <span className="font-bold">PAN NO : </span>{" "}
              <span>{PanNumber}</span>
            </div>
            <div className="max-w-sm  text-left">
              <span className="font-bold">Phone NO :</span>{" "}
              {ContactNumber}
            </div>
          </div>
        </div> */}
        </div>
        {/* Table */}
        <div className="pt-5">
          <AddVehicleDetailsTable />
        </div>
      </div>
    </DashBoardLayout>
  );
};

export default AddVehicleDetails;
