import { Disclosure } from "@headlessui/react";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Formik, Form, Field } from "formik";
import OtherPartyName from "./OtherPartyName";
import PayTo from "./PayTo";
import ThirdPartyName from "./ThirdPartyName";

const DynamicGroupDisclosure = ({ items, setLrData, childButtonRef }) => {
  const [openDisclosure, setOpenDisclosure] = useState(null);
  // console.log(items,"items")
  const handleDisclosureClick = (itemId) => {
    setOpenDisclosure(itemId === openDisclosure ? null : itemId);
  };

  const handleSubmit = (values) => {
    // console.log(values, "Form values on submit");

    let maxIndex = 0;
    Object.keys(values).forEach((key) => {
      const index = key.match(/\d+/);
      if (index !== null && parseInt(index) > maxIndex) {
        maxIndex = parseInt(index);
      }
    });

    // Create arrays based on the maximum LR number index
    const lrDetails = [];
    for (let i = 0; i <= maxIndex; i++) {
      lrDetails.push({
        PaidBy: values[`payTo${i}`],
        // pay both
        ConsigneeAmount:
          values[`payTo${i}`] === "Both" ? values[`consigneeAmount${i}`] : 0,
        ConsignerAmount:
          values[`payTo${i}`] === "Both" ? values[`consignorAmount${i}`] : 0,
        IsThirdPartyPay: values[`isThirdPartyAvailable${i}`] ? true : false,
        // pay third  party
        ThirdPartyName: values[`isThirdPartyAvailable${i}`]
          ? values[`partyName${i}`]
          : "",
        ThirdPartyComment: values[`isThirdPartyAvailable${i}`]
          ? values[`thirdPartyComment${i}`]
          : "",
        ThirdPartyCommission: values[`isThirdPartyAvailable${i}`]
          ? values[`thirdPartyCommission${i}`]
          : 0,
        // Pay other
        otherPartyAddress:
          values[`payTo${i}`] === "OtherParty"
            ? values[`otherPartyAddress${i}`]
            : "",
        otherPartyGst:
          values[`payTo${i}`] === "OtherParty"
            ? values[`otherPartyGST${i}`]
            : "",
        otherPartyName:
          values[`payTo${i}`] === "OtherParty"
            ? values[`otherPartyName${i}`]
            : "",
        // need values
        BookingAmount: values[`bookingAmount${i}`],
        CompanyInvoiceNo: values[`companyInvoiceNumber${i}`],
        To: values[`to${i}`],
        From: values[`from${i}`],
        LrNo: items[i].lrValue,
      });
    }
    // console.log(lrDetails);
    localStorage.setItem("lrDetails", JSON.stringify(lrDetails));
  };

  return (
    <div className="space-y-2 mb-4">
      <Formik
        initialValues={{
          payTo: "",
          consignorAmount: "",
          consigneeAmount: "",
          otherPartyName: "",
          otherPartyGST: "",
          otherPartyAddress: "",
          isThirdPartyAvailable: false,
          partyName: "",
          thirdPartyCommission: "",
          thirdPartyComment: "",
          from: "",
          to: "",
          bookingAmount: "",
          lrNo: "",
          companyInvoiceNumber: "",
        }}
        onSubmit={handleSubmit}
        className="space-y-2 flex flex-col gap-2"
      >
        {({ values, setFieldValue }) => (
          <Form className="space-y-2">
            {items &&
              items.map((item, index) => (
                <Disclosure key={item.id} as="div" className="space-y-2">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-semibold text-primary-kirtiroadways hover:bg-kirtiroadways-300 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75"
                        onClick={() => handleDisclosureClick(item.id)}
                      >
                        {item.title}
                        <ChevronDownIcon
                          className={`w-5 h-5 transition-transform transform ${
                            open ? "rotate-180" : ""
                          }`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel
                        as="div"
                        className={`px-4 py-2 md:grid flex md:grid-cols-3 md:gap-4 flex-col space-y-4  md:space-y-0 min-w-full ${
                          open ? "rounded-b-lg" : "hidden"
                        }`}
                      >
                        {/* Pay To */}
                        <div className="flex flex-col text-left">
                          <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                            Invoice Pay to{" "}
                            {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                            *
                          </span> */}
                          </p>
                          <PayTo
                            name={`payTo${index}`}
                            defaultValue=""
                            handleOnChange={(e) => {
                              setFieldValue(`payTo${index}`, e.value);
                              if (e.value !== "Consigner") {
                                setFieldValue(
                                  `isThirdPartyAvailable${index}`,
                                  false
                                );
                              }
                            }}
                          />
                        </div>
                        {/* Consignor Amount */}
                        {values[`payTo${index}`] === "Both" && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Consignor Amount
                            </p>
                            <Field
                              name={`consignorAmount${index}`}
                              onChange={(e) => {
                                if (values[`payTo${index}`] === "Both") {
                                  setFieldValue(
                                    `consignorAmount${index}`,
                                    parseInt(e.target.value || 0)
                                  );
                                  setFieldValue(
                                    `bookingAmount${index}`,
                                    parseInt(e.target.value || 0) +
                                      parseInt(
                                        values[`consigneeAmount${index}`] || 0
                                      )
                                  );
                                }
                              }}
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                        )}
                        {/* Consignee Amount */}
                        {values[`payTo${index}`] === "Both" && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Consignee Amount
                            </p>
                            <Field
                              name={`consigneeAmount${index}`}
                              onChange={(e) => {
                                if (values[`payTo${index}`] === "Both") {
                                  setFieldValue(
                                    `consigneeAmount${index}`,
                                    parseInt(e.target.value || 0)
                                  );
                                  setFieldValue(
                                    `bookingAmount${index}`,
                                    parseInt(e.target.value || 0) +
                                      parseInt(
                                        values[`consignorAmount${index}`] || 0
                                      )
                                  );
                                }
                              }}
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                        )}
                        {/* Other Party Name */}
                        {values[`payTo${index}`] === "OtherParty" && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Other Party Name
                            </p>
                            <OtherPartyName
                              name={`otherPartyName${index}`}
                              defaultValue=""
                              handleOnChange={(e) => {
                                setFieldValue(
                                  `otherPartyName${index}`,
                                  e.label
                                );
                                setFieldValue(`otherPartyGST${index}`, e.value);
                                setFieldValue(
                                  `otherPartyAddress${index}`,
                                  e.add
                                );
                              }}
                            />
                          </div>
                        )}
                        {/* Other Party GST */}
                        {values[`payTo${index}`] === "OtherParty" && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Other Party GST
                            </p>
                            <Field
                              name={`otherPartyGST${index}`}
                              type="text"
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                        )}
                        {/* Other Party Address */}
                        {values[`payTo${index}`] === "OtherParty" && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Other Party Address
                            </p>
                            <Field
                              name={`otherPartyAddress${index}`}
                              type="text"
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                        )}
                        {/* Is Third Party Available Checkbox */}
                        {(values[`payTo${index}`] === "Consigner" ||
                          values[`payTo${index}`] === "Consignee") && (
                          <div className="flex flex-col pt-2 text-left items-start">
                            <label className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Is Third Party Available
                            </label>
                            <Field
                              type="checkbox"
                              name={`isThirdPartyAvailable${index}`}
                              className="accent-red-600 text-white"
                            />
                          </div>
                        )}

                        {/* Party Name */}
                        {values[`isThirdPartyAvailable${index}`] && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Party Name{" "}
                              <span className="ml-1 text-red-500 block text-sm font-medium">
                                *
                              </span>
                            </p>
                            <ThirdPartyName
                              name={`partyName${index}`}
                              // defaultValue=""
                              handleOnChange={(e) =>
                                setFieldValue(`partyName${index}`, e.label)
                              }
                            />
                          </div>
                        )}
                        {/* Third Party Commission */}
                        {values[`isThirdPartyAvailable${index}`] && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Third Party Commission
                            </p>
                            <Field
                              name={`thirdPartyCommission${index}`}
                              type="text"
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                        )}
                        {/* Third Party Comment */}
                        {values[`isThirdPartyAvailable${index}`] && (
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Comment
                            </p>
                            <Field
                              name={`thirdPartyComment${index}`}
                              type="text"
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                        )}
                        {/* From */}
                        <div className="flex flex-col text-left">
                          <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                            From{" "}
                            {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                            *
                          </span> */}
                          </p>
                          <Field
                            name={`from${index}`}
                            type="text"
                            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                          />
                        </div>
                        {/* To */}
                        <div className="flex flex-col text-left">
                          <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                            To{" "}
                            {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                            *
                          </span> */}
                          </p>
                          <Field
                            name={`to${index}`}
                            type="text"
                            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                          />
                        </div>
                        {/* Booking Amount */}
                        <div className="flex flex-col text-left">
                          <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                            Booking Amount{" "}
                            {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                            *
                          </span> */}
                          </p>
                          <Field
                            name={`bookingAmount${index}`}
                            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                          />
                        </div>
                        {/* LR No. */}
                        {/* <div className="flex flex-col text-left">
                        <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                          LR No.
                        </p>
                        <Field
                          name={`lrNo${index}`}
                          type="text"
                          className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                        />
                      </div> */}
                        {/* Company Invoice Number */}
                        <div className="flex flex-col text-left">
                          <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                            Company Invoice Number{" "}
                            {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                            *
                          </span> */}
                          </p>
                          <Field
                            name={`companyInvoiceNumber${index}`}
                            type="text"
                            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                          />
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}

            <button
              ref={childButtonRef}
              className="hidden"
              onClick={(e) => {
                e.preventDefault(); // Prevent default form submission behavior
                handleSubmit(values);
              }}
            >
              Child Button
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DynamicGroupDisclosure;
