import React from "react";
import moment from "moment";
import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import logo from "../../../Images/logo_transparent.png";
import black_logo from "../../../Images/black_logo.png";
import { address, borderToggle, heading } from "../../../Utils/TableStyling";

export default function PrintingHeader(props) {
  var Today_Date = moment(props?.date).format("DD-MM-YYYY");

  return (
    <>
      <div className="mt-2">
        {!props.NoTaxTag && (
          <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-xs">
            <h5>Tax Invoice</h5>
          </div>
        )}
        {/* Logo and Title Start */}
        {!props.noHeader && (
          <div className="m-2 items-center space-x-3 flex flex-row">
            <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
              <div className="flex flex-row justify-between">
                <div className="w-48 ml-2">
                  {props.blackWhite ? (
                    <img src={black_logo} alt="Kirti Roadlines" />
                  ) : (
                    <img src={logo} alt="Kirti Roadlines" />
                  )}
                </div>
                <div className=" flex flex-col ">
                  <span
                    style={{ fontSize: "55px", lineHeight: "1" }}
                    className={`${
                      !props.blackWhite && "text-primary-kirtiroadways"
                    } font-extrabold `}
                  >
                    {props.profileData.tagLine}
                  </span>
                  <span className="text-lg font-serif text-black">
                    {props.profileData.subTagLine}
                  </span>
                </div>
              </div>
              {/* Address Start */}
              <div
                className={`rounded-b-3xl  text-sm rounded-t-lg font-bold   p-1 w-full  ${address(
                  props.blackWhite
                )}`}
              >
                <div
                  className="flex flex-row justify-center items-start gap-1 w-full py-1"
                  // style={{
                  //   fontSize: "11px",
                  //   lineHeight: "16px",
                  // }}
                >
                  <span className="relative">{address_svg}</span>
                  <p className="">{props.profileData.address}</p>
                </div>
                <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                  <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                    <span>{call_svg} </span>{" "}
                    <span className="flex flex-row">
                      {props.secondaryPhoneNo.map((number, i) => (
                        <span
                          key={number}
                          className="tracking-wider flex flex-row"
                        >
                          {(i ? ", " : "") + number}
                        </span>
                      ))}
                    </span>
                  </div>
                  <div className="flex space-x-1 justify-center flex-row items-center ">
                    <span>{email_svg} </span>
                    <span>{props.profileData.email}</span>
                  </div>
                </div>
              </div>
              {/* Address End */}
            </div>
          </div>
        )}
        {/* Logo and Title End */}
      </div>

      {/* Invoice  No and details*/}
      {!props.NoDetails && (
        <div
          // className={`${
          //   props.noHeader && "mt-56"
          // } p-2 flex font-sans flex-row justify-between`}
          className={`p-2 flex font-sans flex-row justify-between`}
        >
          <div className="flex flex-col text-left w-2/3
">
            <div
              className={`uppercase text-md	py-1 mb-0 px-5 w-32 ${heading(
                props.blackWhite
              )}`}
            >
              Invoice to
            </div>
            <div className="w-full mt-2">
              <p className="text-left font-bold uppercase font-sans text-sm	subpixel-antialiased tracking-wide mt-0	">
                {props?.companyName ? props?.companyName?.toUpperCase() : null}
              </p>
            </div>
            {props?.companyAdd && (
              <div className="text-xs text-left">
                <div className="flex  flex-row space-x-1 mt-1">
                  <p className="w-16">Address :</p>
                  <p className="w-1/2 ">{props?.companyAdd}</p>
                </div>
              </div>
            )}
            {props.companyGst && (
              <div className="text-sm mt-1">
                <div className="flex flex-row space-x-1 text-left">
                  <p className="">GST :</p>
                  <p className="w-1/2">{props?.companyGst.toUpperCase()}</p>
                </div>
              </div>
            )}
          </div>

          {/* Right side */}
          <div className="flex  flex-col text-right justify-end items-end ">
            <span
              className={`uppercase text-md	py-1 mb-0 px-5 w-fit ${heading(
                props.blackWhite
              )}`}
            >
              OUR Details
            </span>
            <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
              <p className="flex flex-col">
                <span>Service : {props.profileData.service}</span>
                <span>Pan No. : {props.profileData.panNo}</span>
                <span>State Code : {props.profileData.stateCode}</span>
              </p>
              {/* <p className="flex flex-col">
                      <span>Transportation</span>
                      <span>AJGPD9182R</span>
                      <span>24</span>
                    </p> */}
            </div>

            <div
              className={`uppercase font-semibold	pt-1 mt-2 pl-16 border-t-2 ${borderToggle(
                props.blackWhite
              )} `}
            >
              <div className="flex flex-row text-sm text-right space-x-12">
                <span className="flex flex-col ">
                  <p className="text-right">
                    Invoice No :{" "}
                    {props?.data?.invoiceNo || props?.data?.bookingInvoiceNo
                      ? props?.data?.invoiceNo
                        ? props?.data?.invoiceNo
                        : props?.data?.bookingInvoiceNo
                        ? props?.data?.bookingInvoiceNo
                        : "--"
                      :props.invoiceNo ?props.invoiceNo: "--"}
                  </p>
                  {props.comapnyInvoiceNo && (
                    <p className="text-right min-w-max ">
                      COMPANY INVOICE NO :{" "}
                      {props?.data?.companyInvoiceNo
                        ? props?.data?.invoiceNo
                          ? props?.data?.invoiceNo
                          : props?.data?.companyInvoiceNo
                        : "--"}
                    </p>
                  )}
                  {/* <p className="text-right min-w-1/2">
                          Bill OF Loading :
                        </p> */}
                  <p className="text-right min-w-1/2">Date : {Today_Date}</p>
                  {/* <p className="text-right w-1/2">Transportation</p> */}
                </span>
                {/* <span className="flex flex-col "> */}
                {/* <p className="text-left min-w-1/2">--</p> */}
                {/* <p className="text-left min-w-1/2">--</p> */}
                {/* <p className="text-left min-w-1/2">{Today_Date}</p> */}
                {/* <p className="text-right w-1/2">Transportation</p> */}
                {/* </span> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}