import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import axiosInstance from "../../Utils/API";
// import DatePicker from "react-datepicker";
import moment from "moment";
import * as yup from "yup";
import {
  ADDLRDETAILS,
  ADD_BUILTY,
  GETVIEWBOOKINGBYID,
  GET_COMPANY_TABLE,
} from "../../Utils/ApiGlobal";
import Loader from "../../Components/UI/Loader";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import APILoader from "../../Components/UI/APILoader";
import { cancel_svg } from "../../Utils/SVGListing";
import ThirdPartyName from "../../Components/Functionality/ThirdPartyName";
import OtherPartyName from "../../Components/Functionality/OtherPartyName";
import PayTo from "../../Components/Functionality/PayTo";

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
function isNumber(str) {
  return !isNaN(str);
}
const MultiBuillty = ({ users }) => {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [consignorOptions, setConsignorOptions] = useState(false);
  const [buillties, setBuillty] = useState([]);
  const [Lr, setLr] = useState([]);
  const [initialObject, setInitialObject] = useState(buillties, Lr);
  const [lrValue] = useState(localStorage.getItem("lrValue"));
  const [bookingData, setBookingData] = useState({});
  const [ForBlankBuillty, setForBlankBuillty] = useState(false);
  const [BlankBuilltyIndex, setBlankBuilltyIndex] = useState("");
  // const [GetEmptyLRDate, setGetEmptyLRDate] = useState(true); 
  // const [LRDate, setLRDate] = useState(new Date());
  const [LRDate] = useState(new Date());
  const [LrDate] = useState(moment(bookingData?.loadingDate).toDate());
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  // Add buillty
  const campaginPopup = async (values, lrdetails) => {
    // e.preventDefault();
    // e.preventDefault();
    // setLoader({ ...loader, loading: true });
    values.forEach((object, index) => {
      // eslint-disable-line react-hooks/exhaustive-deps
      object.LrNo = parseInt(lrValue) + parseInt(index);
      object.BookingId = location?.state?.bookingId;
      object.Createdby = localStorage.getItem("userId");
      // object.LrDate = moment(bookingData?.loadingDate).toDate();
      if (
        object.ActualWeight.toLowerCase() === "fix" ||
        !isNumber(object.ActualWeight)
      ) {
        object.WeightType = "NA";
        // object.ChargedWeight = object.ChargedWeight
      } else {
        // object.WeightType = object.WeightType;
        object.ChargedWeight = object.ActualWeight;
      }
    });
    const rephaseArray = { Billties: values };
    // console.log(rephaseArray.Billties);
    // console.log(BlankBuilltyIndex)

    if (rephaseArray.Billties.length === 1) {
      if (
        rephaseArray.Billties[0].LrDate !== null &&
        rephaseArray.Billties[0].LrDate !== " "
      ) {
        setLoader({ ...loader, loading: true });
        await lrdetails.map((ADD_LR_DATA, index) => {
          return axiosInstance
            .post(ADDLRDETAILS, {
              ...ADD_LR_DATA,
              BookingId: bookingData.bookingId,
              LrNo: parseInt(lrValue) + index,
            })
            .then((result) => {
              if (result) {
                return result.data?.message?.message;
              }
            })
            .catch((error) => console.log(error));
        });
        axiosInstance
          .post(ADD_BUILTY, rephaseArray)
          .then((response) => {
            //  console.log(response)
            ConfirormationModal("Buillty added successfully", "success", 2000);
            history.push({
              pathname: "/bilty/billty-invoice-view",
              state: { bookingId: location?.state?.bookingId },
              openModel: true,
            });
          })
          .catch((error) => {
            ConfirormationModal(error.message, "error", 2000);
            return;
          });
        // console.log("Form Submit");
      } else {
        alert("Please Fill LR Date");
        return;
      }
    } else {
      if (ForBlankBuillty === false) {
        if (rephaseArray.Billties[0].LrDate !== null) {
          setLoader({ ...loader, loading: true });
          await lrdetails.map((ADD_LR_DATA, index) => {
            return axiosInstance
              .post(ADDLRDETAILS, {
                ...ADD_LR_DATA,
                BookingId: bookingData.bookingId,
                LrNo: parseInt(lrValue) + index,
              })
              .then((result) => {
                if (result) {
                  return result.data?.message?.message;
                }
              })
              .catch((error) => console.log(error));
          });
          axiosInstance
            .post(ADD_BUILTY, rephaseArray)
            .then((response) => {
              //  console.log(response)
              ConfirormationModal(
                "Buillty added successfully",
                "success",
                2000
              );
              history.push({
                pathname: "/bilty/billty-invoice-view",
                state: { bookingId: location?.state?.bookingId },
                openModel: true,
              });
            })
            .catch((error) => {
              ConfirormationModal(error.message, "error", 2000);
              return;
            });
          // console.log("Form Submit");
        } else {
          alert("Please Fill First Buillty LR Date");
          return;
        }
      } else if (ForBlankBuillty === true) {
        if (
          rephaseArray.Billties[BlankBuilltyIndex].LrDate !== null &&
          rephaseArray.Billties[BlankBuilltyIndex].LrDate !== " " &&
          rephaseArray.Billties[0].LrDate !== null
        ) {
          setLoader({ ...loader, loading: true });
          await lrdetails.map((ADD_LR_DATA, index) => {
            return axiosInstance
              .post(ADDLRDETAILS, {
                ...ADD_LR_DATA,
                BookingId: bookingData.bookingId,
                LrNo: parseInt(lrValue) + index,
              })
              .then((result) => {
                if (result) {
                  return result.data?.message?.message;
                }
              })
              .catch((error) => console.log(error));
          });
          axiosInstance
            .post(ADD_BUILTY, rephaseArray)
            .then((response) => {
              //  console.log(response)
              ConfirormationModal(
                "Buillty added successfully",
                "success",
                2000
              );
              history.push({
                pathname: "/bilty/billty-invoice-view",
                state: { bookingId: location?.state?.bookingId },
                openModel: true,
              });
            })
            .catch((error) => {
              ConfirormationModal(error.message, "error", 2000);
              return;
            });
          // console.log("Form Submit");
        } else {
          alert("Please Fill Blank Builty LR Date");
        }
      }
    }
  };

  // Error conditions and messages using YUP
  const validationSchema = yup.object().shape({
    builltiesData: yup.array().of(
      yup.object().shape({
        // Packages: yup.string().required("Please enter Packages"),
        // ActualWeight: yup.string().required("Please enter Actual Weight"),
        // ChargedWeight: yup
        //   .string()
        //   .when("ActualWeight", (ActualWeight, schema) => {
        //     if (
        //       (ActualWeight === ActualWeight.toLowerCase()) === "fix" ||
        //       !ActualWeight ||
        //       !isNumber(ActualWeight)
        //     ) {
        //       return schema.min(1);
        //     }
        //     return schema;
        //   }),
        // offerValue: yup
        //   .number()
        //   .required("Please enter offer value")
        //   .min(0, "Value must be minimum 1"),
      })
    ),
  });

  // Form Empty array for array field
  const generateEmptyObj = (bookingData) => {
    return {
      FinancialYear: bookingData?.financialYear,
      LrNo: lrValue,
      LrDate: LrDate === " " ? null : LrDate,
      DeliveryAt: bookingData?.from,
      VehicleNo: bookingData?.vehicleNo,
      ConsignerName: bookingData?.consignerName,
      ConsignerAddress: bookingData?.consignerAddress,
      ConsignerGST: bookingData?.consignerGst,
      ConsigneeName: bookingData?.consigneeName,
      ConsigneeAddress: bookingData?.consigneeAddress,
      ConsigneeGst: bookingData?.consigneeGst,
      From: bookingData?.from,
      To: bookingData?.to,
      Packages: "",
      Description: "",
      ActualWeight: "",
      ChargedWeight: "",
      WeightType: "KG",
      PaidBy: "",
      IsThirdPartyPay: false,
      ThirdPartyName: "",
      ThirdPartyAddress: "",
      ThirdPartyCommission: 0,
      ThirdPartyComment: "",
      otherPartyName: "",
      otherPartyGst: "",
      otherPartyAddress: "",
      BookingAmount: 0,
      ConsignerAmount: 0,
      ConsigneeAmount: 0,
      CompanyInvoiceNo: "",
      //   ChargedWeight: "",
    };
  };

  const generateEmptyLrObj = (bookingData) => {
    return {
      PaidBy: "",
      IsThirdPartyPay: false,
      ThirdPartyName: "",
      ThirdPartyAddress: "",
      ThirdPartyCommission: 0,
      ThirdPartyComment: "",
      otherPartyName: "",
      otherPartyGst: "",
      otherPartyAddress: "",
      BookingAmount: 0,
      ConsignerAmount: 0,
      ConsigneeAmount: 0,
      CompanyInvoiceNo: "",
      //   ChargedWeight: "",
    };
  };

  useEffect(() => {
    const BookingId = location?.state?.bookingId;
    GetBookingData(BookingId);
    axiosInstance
      .get(`${GET_COMPANY_TABLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        setLoader({ ...loader, loading: false });
        // console.log(response?.data?.data?.company);
        if (response?.data?.data?.company.length > 0) {
          const options = response?.data?.data?.company;
          setConsignorOptions(options);
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Window Back btn path set
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.location.pathname = "/pending-booking";
        history.replace("/pending-booking");
      }
    };
  });
  const GetBookingData = async (bookingId) => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        // console.log(data)
        setBookingData(response?.data?.data?.bookingDetails);
        setTimeout(() => {
          setInitialObject({
            builltiesData: [
              generateEmptyObj(response?.data?.data?.bookingDetails),
            ],
            lrData: [generateEmptyLrObj()],
          });
          setIsLoading(false);
        }, 2000);
        return;
      })
      .catch((error) => {
        return;
      });
  };

  return (
    <DashBoardLayout pageTitle="Add Buillty">
      <APILoader loader={loader} />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="px-6 pb-6 space-y-2 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md">
            {/* Form  */}
            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={initialObject}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={(values) => {
                for (const item of values.builltiesData) {
                  if (item.LrDate) {
                    item.LrDate = moment(LRDate).toDate();
                  }
                }
                setBuillty(values.builltiesData);
                setLr(values.lrData);
                // console.log(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                dirty,
                resetForm,
                isSubmitting,
              }) => (
                <Form onSubmit={campaginPopup}>
                  <FieldArray name="builltiesData">
                    <>
                      {values.builltiesData?.map((coupon, index) => {
                        const builltyValue = values.builltiesData[index] || {};
                        const LrValueData = values.lrData[index] || {};
                        return (
                          <div
                            key={index}
                            className="border border-black rounded-md p-3 my-3"
                          >
                            <div className="card ">
                              <div className="flex mb-3 flex-row justify-between">
                                <p className=" underline mb-2 font-semibold">
                                  Lr No {+lrValue + index}
                                </p>
                                <div className="flex flex-row align-end space-x-3">
                                  {values.builltiesData.length - 1 ===
                                  0 ? null : (
                                    <ButtonWithBG
                                      theme="danger"
                                      label="Remove Buillty"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        let items = [...values.builltiesData];
                                        let LrItems = [...values.lrData];
                                        items.splice(index, 1);
                                        LrItems.splice(index, 1);
                                        setInitialObject({
                                          builltiesData: items,
                                          lrData: LrItems,
                                        });
                                        setBuillty(items);
                                        setLr(LrItems);
                                        // console.log(items)
                                      }}
                                    />
                                  )}
                                  <ButtonWithBG
                                    theme="secondary"
                                    label="Convert into Blank Buillty"
                                    type="reset"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setForBlankBuillty(true);
                                      setBlankBuilltyIndex(index);
                                      // setFieldValue(
                                      //   `builltiesData.${index}.LrDate`,
                                      //   // LrDate
                                      //   " "
                                      // );
                                      setFieldValue(
                                        `builltiesData.${index}.DeliveryAt`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ConsignerName`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ConsignerAddress`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ConsignerGST`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ConsigneeName`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ConsigneeAddress`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ConsigneeGst`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.From`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.To`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.Packages`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.Description`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ActualWeight`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.WeightType`,
                                        ""
                                      );
                                      setFieldValue(
                                        `builltiesData.${index}.ChargedWeight`,
                                        ""
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="flex md:flex-row flex-wrap flex-col gap-2">
                                {/* Pay To */}
                                <div className="flex md:w-[32%] px-2  flex-col  text-left">
                                  <Label
                                    label="Invoice Pay To."
                                    isRequired="true"
                                  />
                                  <PayTo
                                    name={`lrData.${index}.PaidBy`}
                                    defaultValue={LrValueData.PaidBy}
                                    handleOnChange={(e) =>
                                      setFieldValue(
                                        `lrData.${index}.PaidBy`,
                                        e.value
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={`lrData.${index}.PaidBy`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                {/* Consignor Amount */}
                                {values?.lrData[index]?.PaidBy === "Both" && (
                                  <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                    <Label
                                      label="Consignor amount"
                                      isRequired="true"
                                    />
                                    <Field
                                      value={LrValueData.ConsignerAmount}
                                      name={`lrData.${index}.ConsignerAmount`}
                                      type="text"
                                      className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.ConsignerAmount`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* Consignee Amount */}
                                {values?.lrData[index]?.PaidBy === "Both" && (
                                  <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                    <Label
                                      label="Consignee amount"
                                      isRequired="true"
                                    />
                                    <Field
                                      value={LrValueData.ConsigneeAmount}
                                      name={`lrData.${index}.ConsigneeAmount`}
                                      type="text"
                                      className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.ConsigneeAmount`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* Other Party Name */}
                                {values?.lrData[index]?.PaidBy ===
                                  "OtherParty" && (
                                  <div className="flex md:w-[32%] px-2 flex-col  text-left">
                                    <Label
                                      label="Other party name"
                                      isRequired="true"
                                    />
                                    <OtherPartyName
                                      value={LrValueData.otherPartyName}
                                      name={`lrData.${index}.otherPartyName`}
                                      defaultValue=""
                                      handleOnChange={(e) => {
                                        setFieldValue(
                                          `lrData.${index}.otherPartyName`,
                                          e.label
                                        );
                                        setFieldValue(
                                          `lrData.${index}.otherPartyAddress`,
                                          e.add
                                        );
                                        setFieldValue(
                                          `lrData.${index}.otherPartyGst`,
                                          e.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.otherPartyName`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* Other Party GST */}
                                {values?.lrData[index]?.PaidBy ===
                                  "OtherParty" && (
                                  <div className="flex md:w-[32%] px-2  flex-col  text-left">
                                    <Label
                                      label="Other party gst"
                                      isRequired="true"
                                    />
                                    <Field
                                      value={LrValueData.otherPartyGst}
                                      name={`lrData.${index}.otherPartyGst`}
                                      type="text"
                                      className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.otherPartyGst`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* Other Party Address */}
                                {values?.lrData[index]?.PaidBy ===
                                  "OtherParty" && (
                                  <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                    <Label
                                      label="Other party address"
                                      isRequired="true"
                                    />
                                    <Field
                                      value={LrValueData.otherPartyAddress}
                                      name={`lrData.${index}.otherPartyAddress`}
                                      type="text"
                                      className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.otherPartyAddress`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* Is Third Party Available Checkbox */}
                                {(values?.lrData[index]?.PaidBy ===
                                  "Consigner" ||
                                  values?.lrData[index]?.PaidBy ===
                                    "Consignee") && (
                                  <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                    <Label
                                      label="Is third party available"
                                      isRequired="true"
                                    />
                                    <Field
                                      type="checkbox"
                                      name={`lrData.${index}.isThirdPartyAvailable`}
                                      className="accent-red-600 text-white"
                                    />
                                  </div>
                                )}
                                {/* Party Name */}
                                {values?.lrData[index]
                                  ?.isThirdPartyAvailable && (
                                  <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                    <Label
                                      label="Party name"
                                      isRequired="true"
                                    />
                                    <ThirdPartyName
                                      name={`lrData.${index}.partyName`}
                                      defaultValue={LrValueData.partyName}
                                      handleOnChange={(e) =>
                                        setFieldValue(
                                          `lrData.${index}.partyName`,
                                          e.label
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.partyName`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* Third Party Commission */}
                                {values?.lrData[index]
                                  ?.isThirdPartyAvailable && (
                                  <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                    <Label
                                      label="Third party commission"
                                      isRequired="true"
                                    />
                                    <Field
                                      value={LrValueData.thirdPartyCommission}
                                      name={`lrData.${index}.thirdPartyCommission`}
                                      type="text"
                                      className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.thirdPartyCommission`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* Third Party Comment */}
                                {values?.lrData[index]
                                  ?.isThirdPartyAvailable && (
                                  <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                    <Label
                                      label="Third party comment"
                                      isRequired="true"
                                    />
                                    <Field
                                      value={LrValueData.thirdPartyComment}
                                      name={`lrData.${index}.thirdPartyComment`}
                                      type="text"
                                      className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                    />
                                    <ErrorMessage
                                      name={`lrData.${index}.thirdPartyComment`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                )}
                                {/* From */}
                                <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                  <Label label="From" isRequired="true" />
                                  <Field
                                    onChange={(e) => {
                                      setFieldValue(
                                        `builltiesData.${index}.From`,
                                        e.target.value
                                      );
                                      setFieldValue(
                                        `lrData.${index}.From`,
                                        e.target.value
                                      );
                                    }}
                                    value={LrValueData.From}
                                    name={`lrData.${index}.From`}
                                    type="text"
                                    className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                  />
                                  <ErrorMessage
                                    name={`lrData.${index}.From`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                {/* To */}
                                <div className="flex md:w-[32%] px-2   flex-col  text-left">
                                  <Label label="To" isRequired="true" />
                                  <Field
                                    onChange={(e) => {
                                      setFieldValue(
                                        `builltiesData.${index}.To`,
                                        e.target.value
                                      );
                                      setFieldValue(
                                        `lrData.${index}.To`,
                                        e.target.value
                                      );
                                    }}
                                    value={LrValueData.to}
                                    name={`lrData.${index}.To`}
                                    type="text"
                                    className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                  />
                                  <ErrorMessage
                                    name={`lrData.${index}.To`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                {/* Booking Amount */}
                                <div className=" flex md:w-[32%] px-2   flex-col  text-left">
                                  {" "}
                                  <Label
                                    label="Booking amount"
                                    isRequired="true"
                                  />
                                  <Field
                                    name={`lrData.${index}.BookingAmount`}
                                    type="text"
                                    className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                    step="any"
                                    value={LrValueData.BookingAmount}
                                  />
                                  <ErrorMessage
                                    name={`lrData.${index}.BookingAmount`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                {/* Company Invoice Number */}
                                <div className=" flex md:w-[32%] px-2   flex-col  text-left">
                                  {" "}
                                  <Label
                                    label="company Invoice No."
                                    isRequired="true"
                                  />
                                  <Field
                                    key={index}
                                    name={`lrData.${index}.CompanyInvoiceNo`}
                                    type="text"
                                    as="input"
                                    autoComplete="off"
                                    value={LrValueData.CompanyInvoiceNo}
                                    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                  />
                                  <ErrorMessage
                                    name={`lrData.${index}.CompanyInvoiceNo`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                              <hr className="my-3" />
                              <div className="flex flex-row justify-between">
                                <p className="  mb-2 font-semibold">
                                  <span className="underline">
                                    Buillty {index + 1}
                                  </span>{" "}
                                  <span>({+lrValue + index})</span>
                                </p>
                              </div>
                              <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4 mt-3">
                                <div className=" w-full">
                                  <div className=" text-left space-y-6">
                                    {/* Fin year,Lr no,lr date */}
                                    {/* <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label
                                          label="Fin. Year"
                                          isRequired="true"
                                        />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.FinancialYear`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.FinancialYear}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.FinancialYear`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label
                                          label="LR No."
                                          isRequired="true"
                                        />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.LrNo`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={parseInt(lrValue) + index}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.LrNo`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label
                                          label="LR Date"
                                          isRequired={`${
                                            index === 0 ? true : " "
                                          }`}
                                        />
                                        <div key={index}>
                                          <DatePicker
                                            key={index}
                                            dateFormat="dd-MM-yyyy"
                                            className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                            selected={builltyValue.LrDate}
                                            onChange={(date) => {
                                              // console.log(`builltiesData.${index}.LrDate`)
                                              setLRDate(date);
                                              setFieldValue(
                                                `builltiesData.${index}.LrDate`,
                                                date
                                              );
                                            }}
                                            name={`builltiesData.${index}.LrDate`}
                                            value={builltyValue.LrDate}
                                          />
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* Delievery at,Vehicle_No, consignor name */}
                                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Delivery At" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.DeliveryAt`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.DeliveryAt}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.DeliveryAt`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label
                                          label="Vehicle No."
                                          isRequired="true"
                                        />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.VehicleNo`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.VehicleNo}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.VehicleNo`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Consignor Name" />
                                        {/* <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsignerName`}
                                        type="text"
                                        as="select"
                                        autoComplete="off"
                                        value={builltyValue.ConsignerName}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      /> */}
                                        <div className="flex flex-row">
                                          <Field
                                            key={index}
                                            name={`builltiesData.${index}.ConsignerName`}
                                            type="text"
                                            as="select"
                                            onChange={(e) => {
                                              const selectOption = e.target;
                                              var selectedIndex =
                                                selectOption.options
                                                  .selectedIndex;
                                              setFieldValue(
                                                `builltiesData.${index}.ConsignerName`,
                                                e.target.value
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsignerAddress`,
                                                selectOption.options[
                                                  selectedIndex
                                                ].getAttribute("shiptoadd")
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsignerGST`,
                                                selectOption.options[
                                                  selectedIndex
                                                ].getAttribute("gst")
                                              );
                                            }}
                                            autoComplete="off"
                                            value={builltyValue.ConsignerName}
                                            className="border-l rounded-l-lg
                                                form-control
                                                block
                                                w-full
                                                px-3
                                                py-2
                                                text-base
                                                font-normal
                                                bg-white bg-clip-padding
                                                transition
                                                ease-in-out
                                                m-0
                                                border-t  border-b border-r-0
                                                border border-gray-300 placeholder-gray-500  "
                                          >
                                            <option value="">
                                              {" "}
                                              -- select an option --{" "}
                                            </option>
                                            {consignorOptions.map((option) => {
                                              return (
                                                <option
                                                  className="bg-white"
                                                  value={option.name}
                                                  id={option.name}
                                                  billtoadd={
                                                    option.billToAddress
                                                  }
                                                  shiptoadd={
                                                    option.shipToAddress
                                                  }
                                                  gst={option.gst}
                                                  key={option.companyId}
                                                  label={option.name}
                                                >
                                                  {option.name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                          <button
                                            className="-ml-px pl-2 relative inline-flex items-center space-x-2 pr-2 border-y border-r border-grey-300 text-sm font-medium rounded-r-md "
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setFieldValue(
                                                `builltiesData.${index}.ConsignerName`,
                                                ""
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsignerAddress`,
                                                ""
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsignerGST`,
                                                ""
                                              );
                                            }}
                                          >
                                            {cancel_svg}
                                          </button>
                                        </div>
                                        {/* <ErrorMessage
                                        name={`builltiesData.${index}.ConsignerName`}
                                        component="div"
                                        className="invalid-feedback"
                                      /> */}
                                      </div>
                                    </div>
                                    {/* Consignor Address,Gst,consignee name */}
                                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Consignor's Address" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ConsignerAddress`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.ConsignerAddress}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.ConsignerAddress`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Consignor's GST" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ConsignerGST`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.ConsignerGST}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.ConsignerGST`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Consignee's Name" />
                                        {/* <Field
                                        key={index}
                                        name={`builltiesData.${index}.ConsigneeName`}
                                        type="text"
                                        as="input"
                                        autoComplete="off"
                                        value={builltyValue.ConsigneeName}
                                        className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                      /> */}
                                        <div className="flex flex-row ">
                                          <Field
                                            key={index}
                                            name={`builltiesData.${index}.ConsigneeName`}
                                            type="text"
                                            as="select"
                                            onChange={(e) => {
                                              const selectOption = e.target;
                                              var selectedIndex =
                                                selectOption.options
                                                  .selectedIndex;
                                              setFieldValue(
                                                `builltiesData.${index}.ConsigneeName`,
                                                e.target.value
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsigneeAddress`,
                                                selectOption.options[
                                                  selectedIndex
                                                ].getAttribute("shiptoadd")
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsigneeGst`,
                                                selectOption.options[
                                                  selectedIndex
                                                ].getAttribute("gst")
                                              );
                                            }}
                                            autoComplete="off"
                                            value={builltyValue.ConsigneeName}
                                            className="border-l rounded-l-lg
                                          form-control
                                          block
                                          w-full
                                          px-3
                                          py-2
                                          text-base
                                          font-normal
                                          bg-white bg-clip-padding
                                          transition
                                          ease-in-out
                                          m-0
                                          border-t  border-b border-r-0
                                          border border-gray-300 placeholder-gray-500  "
                                          >
                                            <option value="">
                                              {" "}
                                              -- select an option --{" "}
                                            </option>

                                            {consignorOptions.map((option) => {
                                              return (
                                                <option
                                                  className="bg-white"
                                                  value={option.name}
                                                  id={option.name}
                                                  billtoadd={
                                                    option.billToAddress
                                                  }
                                                  shiptoadd={
                                                    option.shipToAddress
                                                  }
                                                  gst={option.gst}
                                                  key={option.companyId}
                                                  label={option.name}
                                                >
                                                  {option.name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                          <button
                                            className="-ml-px pl-2 relative inline-flex items-center space-x-2 pr-2 border-y border-r border-grey-300 text-sm font-medium rounded-r-md "
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setFieldValue(
                                                `builltiesData.${index}.ConsigneeName`,
                                                ""
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsigneeAddress`,
                                                ""
                                              );
                                              setFieldValue(
                                                `builltiesData.${index}.ConsigneeGst`,
                                                ""
                                              );
                                            }}
                                          >
                                            {cancel_svg}
                                          </button>
                                        </div>
                                        <ErrorMessage
                                          name={`builltiesData.${index}.ConsigneeName`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </div>
                                    {/* Consignee_add,consignee gst from */}
                                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Consignee's Address" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ConsigneeAddress`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.ConsigneeAddress}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.ConsigneeAddress`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Consignee's GST" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ConsigneeGst`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.ConsigneeGst}
                                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.ConsigneeGst`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="From" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.From`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.From}
                                          className="peer w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.From`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </div>
                                    {/* To, Pakages ,description*/}
                                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="To" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.To`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.To}
                                          className="peer w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Packages" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.Packages`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.Packages}
                                          className="peer w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.Packages`}
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Description" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.Description`}
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.Description}
                                          className="peer w-full px-3 py-2  uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                      </div>
                                    </div>
                                    {/*Actual weight, weight unit and charged weight*/}
                                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Actual/Charged Weight" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ActualWeight`}
                                          as="input"
                                          autoComplete="off"
                                          value={builltyValue.ActualWeight}
                                          className="peer w-full px-3 py-2  uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.ActualWeight`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        <Label label="Weight Unit" />
                                        {builltyValue.ActualWeight.toLowerCase() ===
                                          "fix" ||
                                        !builltyValue.ActualWeight ||
                                        !isNumber(builltyValue.ActualWeight) ? (
                                          <p
                                            className="
                                      block
                                      w-full
                                      px-3
                                      py-1.5
                                      text-base
                                      font-normal
                                      text-gray-700
                                      bg-gray-100 bg-clip-padding
                                      border border-solid border-gray-300
                                      rounded
                                      transition
                                      ease-in-out
                                      m-0
                                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                          >
                                            NA
                                          </p>
                                        ) : (
                                          <>
                                            <Field
                                              key={index}
                                              name={`builltiesData.${index}.WeightType`}
                                              type="text"
                                              as="select"
                                              autoComplete="off"
                                              value={builltyValue.WeightType}
                                              className="peer  w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                            >
                                              <option
                                                className="bg-white"
                                                value="KG"
                                              >
                                                KG
                                              </option>
                                              <option
                                                className="bg-white "
                                                value="Liter"
                                              >
                                                LITER
                                              </option>
                                              <option
                                                className="bg-white "
                                                value="QUINTAL"
                                              >
                                                QUINTAL
                                              </option>
                                              <option
                                                className="bg-white"
                                                value="UNIT"
                                              >
                                                UNIT
                                              </option>
                                              <option
                                                className="bg-white"
                                                value="TON"
                                              >
                                                TON
                                              </option>
                                            </Field>
                                            <ErrorMessage
                                              name={`builltiesData.${index}.WeightType`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </>
                                        )}
                                      </div>
                                      <div className=" flex md:w-1/2  flex-col  text-left">
                                        {" "}
                                        <Label label="Charged Weight" />
                                        <Field
                                          key={index}
                                          name={`builltiesData.${index}.ChargedWeight`}
                                          // name={
                                          //   builltyValue.ActualWeight.toLowerCase() ===
                                          //     "fix" ||
                                          //   !builltyValue.ActualWeight ||
                                          //   !isNumber(builltyValue.ActualWeight)
                                          //     ? `builltiesData.${index}.ChargedWeight`
                                          //     : `builltiesData.${index}.ActualWeight`
                                          // }
                                          type="text"
                                          as="input"
                                          autoComplete="off"
                                          value={
                                            builltyValue.ActualWeight.toLowerCase() ===
                                              "fix" ||
                                            !builltyValue.ActualWeight ||
                                            !isNumber(builltyValue.ActualWeight)
                                              ? builltyValue.ActualWeight
                                              : builltyValue.ActualWeight
                                          }
                                          className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
                                        />
                                        <ErrorMessage
                                          name={`builltiesData.${index}.ActualWeight`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </FieldArray>

                  {/* Action Buttons */}
                  <div className="items-center w-full">
                    <div className=" mt-4 space-x-2">
                      <ButtonWithBG
                        theme="secondary"
                        label="Add New Buillty"
                        type="submit"
                        // disabled={!validationSchema.isValidSync(values)}
                        onClick={(e) => {
                          e.preventDefault();
                          setInitialObject({
                            lrData: [...values.lrData, generateEmptyLrObj()],
                            builltiesData: [
                              ...values.builltiesData,
                              generateEmptyObj(bookingData),
                            ],
                          });
                          let couponItems = values.builltiesData;
                          let couponLr = values.lrData;
                          setBuillty(couponItems);
                          setLr(couponLr);
                          // console.log(bookingData, "test");
                          // console.log(values.builltiesData)
                        }}
                      />
                      <ButtonWithBG
                        theme="primary"
                        label="SAVE"
                        // disabled={!validationSchema.isValidSync(values)}
                        onClick={(e) => {
                          e.preventDefault();
                          let builltyData = values.builltiesData;
                          let LrDatas = values.lrData;
                          campaginPopup(builltyData, LrDatas);
                        }}
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {/* Create Button */}
          </div>
        </div>
      )}
    </DashBoardLayout>
  );
};

export default MultiBuillty;
