import React, { useState } from "react";
import TablePagination from "../UI/Table/TablewithPagination";
import Loader from "../UI/Loader";
import Tooltip from "react-simple-tooltip";
import AddEditBankInfo from "../../Pages/Profile/BankInformation/AddEditBankInfo";
import RemoveInformation from "../../Pages/Profile/BankInformation/RemoveInformation";

export default function BankInformationTable(props) {
  const [isLoading] = useState(false);
  const columns = [
    {
      Header: "Bank",
      accessor: "bankName",
      Cell:row => <div className=" uppercase">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Account No",
      accessor: "accountNo",
      Cell:row => <div className=" uppercase">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "BRANCH",
      accessor: "branch",
      Cell:row => <div className=" uppercase">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "IFSC CODE",
      accessor: "ifscCode",
      Cell:row => <div className=" uppercase">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Edit",
      maxWidth: 5,
      minWidth: 5,
      width: 5,
      Cell: ({ cell }) => (
        <div className="flex justify-start ml-10">
          <Tooltip
            style={{ fontWeight: "600", letterSpacing: 0.5 }}
            border="#ed1c24"
            color="black"
            background="#fabbbd"
            content={"Edit"}
            radius={5}
            placement="right"
            zIndex={100}
            padding={10}
            arrow={5}
          >
            <AddEditBankInfo data={cell.row.original} getAPI={props} componentType="edit" />
          </Tooltip>
        </div>
      ),
    },
    {
      Header: "Delete",
      maxWidth: 5,
      minWidth: 5,
      width: 5,
      Cell: ({ cell }) => (
        <div className="flex justify-start ml-10">
          <Tooltip
            style={{ fontWeight: "600", letterSpacing: 0.5 }}
            border="#ed1c24"
            color="black"
            background="#fabbbd"
            content={"Delete"}
            radius={5}
            placement="left"
            zIndex={100}
            padding={10}
            arrow={5}
          >
            <RemoveInformation data={cell.row.original} getAPI={props}/>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <section>
      <div className="mt-2 ">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={props.BankInfoData} columns={columns} />
        )}
      </div>
    </section>
  );
}
