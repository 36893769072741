import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import VehicleTable from "../../Components/DataTables/VehicleTable";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { VehicleClickDescription } from "../../Utils/Constants";
// import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import { setting_svg } from "../../Utils/SVGListing";

const Vehicle = () => {
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  // To redirect to add page
  const redirectToAdd = () => {
    history.push({
      pathname: "/vehicle/add-edit-vehicle",
      state: { label: "Add" },
    });
  };

  return (
    <DashBoardLayout pageTitle="Vehicle Owner">
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Pending Booking" /> */}
        {/* Search and setting */}
        <div className="flex md:flex-row flex-col md:justify-between  gap-2">
          <div>
            <SearchField
              placeholder="Serach By Vehicle Owner"
              handleChange={setSearchValue}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </div>
          <div className="flex flex-row space-x-2 items-start  md:order-last	order-first">
            <Button
              svg={setting_svg}
              onClick={() => history.push("/profile")}
            ></Button>
            <ButtonWithBG
              handleClick={redirectToAdd}
              theme="primary"
              label="Add Owner"
            />
          </div>
        </div>
      </div>
      <p className="text-left font-semibold	 text-xs	mt-2">
        {VehicleClickDescription}
      </p>
      <VehicleTable searchValue={searchValue} />
    </DashBoardLayout>
  );
};

export default Vehicle;
