import email_logo from "../../Images/images/email.png";
import location_logo from "../../Images/images/location.png";
import phone_logo from "../../Images/images/phone.png";
import black_logo from "../../Images/black_logo.png";
import sign from "../../Images/sign.png";
import border from "../../Images/images/border.png";

export async function BillingHeader(
  profileData,
  secondaryPhoneNo,
  type,
  isHeader = true,
  isA5 = false
) {
  async function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", url, error);
        reject(error);
      };
      img.src = url;
    });
  }
  const headerImages = {
    backgroundImage: await getBase64ImageFromURL(black_logo),
    Sign: await getBase64ImageFromURL(sign),
    Locate: await getBase64ImageFromURL(location_logo),
    phone: await getBase64ImageFromURL(phone_logo),
    email: await getBase64ImageFromURL(email_logo),
    border: await getBase64ImageFromURL(border),
  };
  if (isA5){
    if (isHeader) {
      return [
        // Row 1
        {
          columns:
            type === "billing"
              ? [
                  // {
                  //   text: [
                  //     { text: ' \uf095 ', style: "icon" }, //icon gift
                  //     " my present",
                  //   ],
                  // },
                  {
                    text: "TAX INVOICE",
                    color: "#000",
                    fontSize: 7.5,
                    alignment: "center",
                    bold: true,
                    margin: [15, -5, 15, 0],
                  },
                ]
              : [
                  {
                    text: "SUBJECT TO AHMEDABAD JURISDICTION",
                    color: "#000",
                    fontSize: 9,
                    bold: true,
                    margin: [15, 10, 0, 0],
                  },
                  {
                    text: "GOODS CONSIGNMENT AT OWNER'S RISK",
                    color: "#000",
                    fontSize: 9,
                    bold: true,
                    margin: [0, 10, 15, 0],
                    alignment: "right",
                  },
                ],
          columnGap: 8,
        },
  
        // Row 2
        {
          alignment: "justify",
          columns: [
            {
              image: headerImages.backgroundImage,
              width: 80,
              // height: 55,
              margin: [0, 5, 0, 0],
            },
            {
              alignment:'right',
              stack: [
                {
                  text: `${profileData?.tagLine}`,
                  color: "#000",
                  fontSize: 30,
                  bold: true,
                  margin: [0, -5, 0, 0],
                  lineHeight: 1,
                  font: "PTSerifRegular",
                },
                {
                  text: `${profileData?.subTagLine.toUpperCase()}`,
                  color: "#000",
                  fontSize: 10,
                  bold: true,
                  margin: [0, -8, 0, 0],
                  font: "PTSerifRegular",
                },
              ],
            },
          ],
          columnGap: 2,
        },
  
        // row 3
        {
          columns: [
            {
              stack: [
                {
                  // Add a rounded border using headerImages.border
                  image: headerImages.border,
                  width: 373,
                  height: 44,
                  alignment: "center",
                },
              ],
              margin: [-1, 0, 0, 0],
            },
            {
              margin: [10, 0, 10, 0],
              relativePosition: { x: -355, y: 7 },
              table: {
                widths: ["auto", 300],
                body: [
                  [
                    {
                      image: headerImages.Locate,
                      width: 7,
                      height: 7,
                      alignment: "right",
                    },
                    {
                      text: `${profileData.address
                        .replace(/\s+/g, "  ")
                        .trim()
                        .toUpperCase()}`,
                      color: "#000",
                      fontSize: 7,
                      bold: true,
                      alignment: "center",
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
            {
              // margin: [10, 0, 10, 0],
              relativePosition: { x: -720, y: 26 },
              table: {
                widths: ["auto", "auto", "auto", "auto"],
                body: [
                  [
                    {
                      image: headerImages.phone,
                      width: 7,
                      height: 7,
                      alignment: "right",
                    },
                    {
                      text: `${secondaryPhoneNo}`,
                      color: "#000",
                      fontSize: 7,
                      alignment: "left",
                      bold: true,
                    },
                    {
                      image: headerImages.email,
                      width: 7,
                      height: 7,
                      alignment: "right",
                    },
                    {
                      text: `${profileData.email.toUpperCase()}`,
                      color: "#000",
                      fontSize: 7,
                      alignment: "left",
                      bold: true,
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ],
        },
      ];
    } else {
      return [
        {
          columns: [
            {
              text: "TAX INVOICE",
              color: "#000",
              fontSize: 7.5,
              alignment: "center",
              bold: true,
              margin: [15, -10, 15, 0],
            },
          ],
        },
      ];
    }
  }
  if (isHeader) {
    return [
      // Row 1
      {
        columns:
          type === "billing"
            ? [
                // {
                //   text: [
                //     { text: ' \uf095 ', style: "icon" }, //icon gift
                //     " my present",
                //   ],
                // },
                {
                  text: "TAX INVOICE",
                  color: "#000",
                  fontSize: 9,
                  alignment: "center",
                  bold: true,
                  margin: [0, 0, 0, 0],
                },
              ]
            : [
                {
                  text: "SUBJECT TO AHMEDABAD JURISDICTION",
                  color: "#000",
                  fontSize: 8,
                  bold: true,
                  margin: [0, 5, 0, 0],
                },
                {
                  text: "GOODS CONSIGNMENT AT OWNER'S RISK",
                  color: "#000",
                  fontSize: 8,
                  bold: true,
                  margin: [0, 5, 0, 0],
                  alignment: "right",
                },
              ],
        columnGap: 8,
      },

      // Row 2
      {
        alignment: "justify",
        columns: [
          {
            image: headerImages.backgroundImage,
            width: 135,
            // height: 75,
            margin: [0, 8, 0, 0],
          },
          {
            alignment:'right',
            stack: [
              {
                text: `${profileData?.tagLine}`,
                color: "#000",
                fontSize: 44,
                bold: true,
                margin: [0, -5, 0, 0],
                lineHeight: 1,
                font: "PTSerifRegular",

              },
              {
                text: `${profileData?.subTagLine.toUpperCase()}`,
                color: "#000",
                fontSize: 13,
                bold: true,
                margin: [0, -10, 25, 0],
                font: "PTSerifRegular",
              },
            ],
          },
        ],
        columnGap: 2,
      },

      // row 3
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            stack: [
              {
                // Add a rounded border using headerImages.border
                image: headerImages.border,
                width: 525,
                height: 45,
                alignment: "center",
              },
            ],
          },
          {
            margin: [10, 0, 10, 0],
            relativePosition: { x: -510, y: 8 },
            table: {
              widths: ["auto", 460],
              body: [
                [
                  {
                    image: headerImages.Locate,
                    width: 10,
                    height: 10,
                    alignment: "right",
                    margin:[-12,0,0,0]
                  },
                  {
                    text: `${profileData.address
                      .replace(/\s+/g, "  ")
                      .trim().toUpperCase()}`,
                    color: "#000",
                    fontSize: 8,
                    bold: true,
                    alignment: "center",
                  },
                ],
              ],
            },
            layout: "noBorders",
          },
          {
            margin: [10, 0, 10, 0],
            relativePosition: { x: -980, y: 27 },
            table: {
              widths: ["auto", "auto", "auto", "auto"],
              body: [
                [
                  {
                    image: headerImages.phone,
                    width: 10,
                    height: 10,
                    alignment: "right",
                  },
                  {
                    text: `${secondaryPhoneNo}`,
                    color: "#000",
                    fontSize: 8,
                    alignment: "left",
                    bold: true,
                  },
                  {
                    image: headerImages.email,
                    width: 10,
                    height: 10,
                    alignment: "right",
                  },
                  {
                    text: `${profileData.email.toUpperCase()}`,
                    color: "#000",
                    fontSize: 8,
                    alignment: "left",
                    bold: true,
                  },
                ],
              ],
            },
            layout: "noBorders",
          },
        ],
      },
    ];
  } else {
    return [
      {
        columns: [
          {
            text: "TAX INVOICE",
            color: "#000",
            fontSize: 9,
            alignment: "center",
            bold: true,
            margin: [35, -20, 35, 0],
          },
        ],
      },
    ];
  }
}
