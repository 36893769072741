import React from "react";
import { useEffect, useState } from "react";
import axiosInstance from "../../Utils/API";
import Loader from "../../Components/UI/Loader";
import CommissionInvoiceView from "./CommissionInvoice/CommissionInvoiceView";
import { GET_COMPANY_INFORMATION_DATA } from "../../Utils/ApiGlobal";

const CommissionInvoice = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);

  useEffect(() => {
    _FetchProfileAPI();
  }, []);

  const _FetchProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(data);
        setProfileData(data);
        setsecondaryPhoneNo(response?.data?.data?.secondaryPhoneNo);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="min-w-[1240px]">
          <CommissionInvoiceView
            data={props?.location?.data?.data}
            profileData={profileData}
            secondaryPhoneNo={secondaryPhoneNo}
            setIsLoading={setIsLoading}
          />
        </div>
      )}
    </>
  );
};

export default CommissionInvoice;
