import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ButtonWithBG from "../../../Components/UI/ButtonMain";
import moment from "moment";
import PrintingHeader from "../../../Components/Functionality/KirtiRoadline/PrintingHeader";
import {
  TabletheadRedLastStyle,
  TabletheadRedStyle,
  TabletheadStyle,
} from "../../../Utils/TableStyling";
import PrintingFooter from "../../../Components/Functionality/KirtiRoadline/PrintingFooter";
import PDFHeader from "../../../Components/Functionality/KirtiRoadline/PDFHeader";
import PDFFooter from "../../../Components/Functionality/KirtiRoadline/PDFFooter";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "../../../Utils/PTSerif-Regular";
import { robotoBlack } from "../../../Utils/Roboto-Black";
import { PTSerifBold } from "../../../Utils/PTSerif-Bold";
import { background } from "../../../Layouts/pdfLayout/BackgroundLayout";
import { BillingHeader } from "../../../Layouts/pdfLayout/BillingHeaderLayout";
import {
  TransporterBillingContentLayout,
} from "../../../Layouts/pdfLayout/TransporterBillingContentLayout";
import { WE_USING_PAYPHONE } from "../../../Utils/Constants";
import { NotoSansDevanagariBold } from "../../../Utils/NotoSansDevanagari-Bold";
import { NotoSansDevanagariRegular } from "../../../Utils/NotoSansDevanagari-Regular";
const pdfMakeX = require("pdfmake/build/pdfmake.js");
const pdfFontsX = require("pdfmake-unicode/dist/pdfmake-unicode.js");
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
//importing the fonts whichever present inside vfs_fonts file
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["PTSerif-Regular.ttf"] = font;
window.pdfMake.vfs["PTSerif-Bold.ttf"] = PTSerifBold;
window.pdfMake.vfs["Roboto-Black.ttf"] = robotoBlack;
window.pdfMake.vfs["NotoSansDevanagari-Bold.ttf"] = NotoSansDevanagariBold;
window.pdfMake.vfs["NotoSansDevanagari-Regular.ttf"] =
  NotoSansDevanagariRegular;

const TransporterInvoiceViewDownload = (props) => {
  const location = useLocation();
  let componentRef = useRef();
  //   const pageStyle = `@page {
  //     size: A4;
  //     margin: 10pt;
  //   }
  //   @media print {
  //     .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  // }
  //   }`;
  // const generatePDFAndDisplay = async () => {
  //   try {
  //     // Get the entire content from the useReactToPrint reference
  //     const content = componentRef.current;
  //     // console.log(content, "click");

  //     // Generate PDF us  ing html2pdf
  //     const pdfOptions = {
  //       margin: 0,
  //       filename:
  //         `Transporter Invoice of ` +
  //         location?.name?.transporterName.replace(/\./g, " ") -
  //         location?.data?.invoiceNo,
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     };
  //     html2pdf().set(pdfOptions).from(content).save();

  //     // const pdfBlob = await html2pdf()
  //     //   .set(pdfOptions)
  //     //   .from(content)
  //     //   .outputPdf("blob");

  //     // // Convert PDF to data URI (Blob URL)
  //     // const pdfBlobUrl = URL.createObjectURL(pdfBlob);

  //     // // Display the generated PDF in a new window
  //     // window.open(pdfBlobUrl, "_blank");
  //   } catch (error) {
  //     // Handle errors appropriately
  //   }
  // };
  const sanitizeFilename = (filename) => {
    // Replace special characters with underscores
    // Download pdf
    let checkNull = filename.includes("null");
    if (checkNull) {
      return filename.replace(/[^\w\s]/gi, "_")?.replace("null", "");
    } else if (!checkNull) {
      return filename.replace(/[^\w\s]/gi, "_");
    }
  };

  async function handleDownloadPdf(action) {
    props.setIsLoading(true);

    //Specifying the fonts which we are going to use in our PDF
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Black.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      PTSerifRegular: {
        normal: "PTSerif-Regular.ttf",
        bold: "PTSerif-Bold.ttf",
        italics: "PTSerif-Regular.ttf",
        bolditalics: "PTSerif-Regular.ttf",
      },
      NotoSansDevanagari: {
        normal: "NotoSansDevanagari-Regular.ttf",
        bold: "NotoSansDevanagari-Bold.ttf",
        italics: "NotoSansDevanagari-Regular.ttf",
        bolditalics: "NotoSansDevanagari-Regular.ttf",
      },
    };

    const locate = location?.data;
    const invoicedata = props.data;
    const bankBranch = props.bankBranch;
    const profileData = props.profileData;
    const companyName = props?.name;
    const companyAdd = props?.address;
    const secondaryPhoneNo = props.secondaryPhoneNo;

    const contentRender = async () => {
      try {
        const BillingHeaderContent = await BillingHeader(
          profileData,
          secondaryPhoneNo,
          "billing",
          !props.noHeader
        );

        const BillingContent = await TransporterBillingContentLayout(
          invoicedata,
          WE_USING_PAYPHONE,
          bankBranch,
          profileData,
          companyName,
          companyAdd,
          locate
        );


        return [
          BillingHeaderContent,
          BillingContent,

        ];
      } catch (error) {
        // Handle errors if necessary
        console.error("Error in contentRender:", error);
        throw error; // Re-throw the error to propagate it
      }
    };

    const sanitizedFilename = sanitizeFilename(location?.data?.invoiceNo);

    const fileName = `${sanitizedFilename}.pdf`;

    const pdfDocDefinition = {
      pageMargins: [35, 35, 35, 35],
      content: await contentRender().then((result) => result),
      info:{title: fileName},
      styles: {
        table: {
          margin: [0, 0, 0, 0], // Adjust the top margin of the entire table
        },
        tableHeader: {
          fontSize: 9,
          alignment: "start", // 'start' aligns text to the start of the cell
          bold: true,
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
          borderBottom: "1px solid #28328c",
        },
        cellStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
        },
      },
      background: props.showBorder ? background : () => {},
    };

    const callbackFunction = function () {
      // Here implement function for hide waiting loader
      props.setIsLoading(false);
    };
    const handlePrint = () => {
      pdfDocGenerator.print();
      props.setIsLoading(false);
    };


    const newLocal = pdfMake.createPdf(pdfDocDefinition);
    const pdfDocGenerator = newLocal;

    action === "download"
      ? pdfDocGenerator.download(fileName, callbackFunction)
      : handlePrint();
  }

  useEffect(() => {
    // console.log(props, "print");
  }, [props]);
  return (
    <>
      {props?.data && (
        <div className="hidden">
          <div ref={componentRef}>
            <div
              className={`${
                props.showBorder &&
                ` border-double  border-4 ${
                  props.blackWhite ? "border-black" : "border-red-200"
                } `
              } 
              } pageStyle  block align-top m-5 text-center bg-center bg-no-repeat  bg-[length:65%]`}
              // style={{
              //   backgroundImage: "url(" + logo_oposity + ")",
              // }}
            >
              {props.mobilePrint ? (
                <PDFHeader
                  data={props?.data}
                  profileData={props?.profileData}
                  secondaryPhoneNo={props?.secondaryPhoneNo}
                  companyName={props?.name}
                  companyAdd={props?.address}
                  border={props.showBorder}
                  blackWhite={props.blackWhite}
                  mobilePrint={props.mobilePrint}
                />
              ) : (
                <PrintingHeader
                  data={props?.data}
                  profileData={props?.profileData}
                  secondaryPhoneNo={props?.secondaryPhoneNo}
                  companyName={props?.name}
                  companyAdd={props?.address}
                  border={props.showBorder}
                  blackWhite={props.blackWhite}
                  mobilePrint={props.mobilePrint}
                />
              )}

              {/* Table start*/}
              <div
                style={{ minHeight: "250px" }}
                className=" p-2 w-full border-t-0 border-neutral-900"
              >
                <table className=" border-spacing-y-2 border-separate w-full border-neutral-900 text-xs font-sans">
                  {/* header */}
                  <thead style={{ maxHeight: "5px" }} className="mb-2">
                    <tr className=" border-neutral-900 ">
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        Loading
                        <br />
                        Date
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        From
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        To
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        Freight
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        Advance
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        Balance
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        Hamali
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-4"
                        } `}
                      >
                        Detention
                      </th>
                      <th
                        style={{
                          maxHeight: "8px",
                          minHeight: "8px",
                          verticalAlign: "middle",
                        }}
                        className={`${TabletheadRedLastStyle(
                          props.blackWhite
                        )}  ${props.mobilePrint && "pb-4"} `}
                      >
                        Extra
                        <br />
                        Charge
                      </th>
                      {/* <th
                        style={{ maxHeight: "5px" }}
                        className="bg-primary-kirtiroadways border-neutral-900 pr-2"
                      >
                        Total
                      </th> */}
                    </tr>
                  </thead>
                  {/* Rows map function */}
                  <tbody className="text-xs font-bold align-top text-center bg-bottom bg-no-repeat bg-[length:80%_90%]">
                    {props?.data?.commission.map((data, i) => (
                      <tr height="20px" className="border-neutral-900 " key={i}>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 pb-3`}
                        >
                          <span className="mt-2">
                            {moment(data?.loadingDate).format("DD-MM-YYYY")}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-32 pb-3`}
                        >
                          <span className="mt-2">
                            {data?.from.toUpperCase()}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-32 pb-3`}
                        >
                          <span className="mt-2">{data?.to.toUpperCase()}</span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-32 pb-3`}
                        >
                          <span className="ml-2 mt-2 text-center">
                            {data?.transporterFreigth
                              ? data?.transporterFreigth
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 pb-3`}
                        >
                          <span className="mt-2 text-center">
                            {data?.transporterAdvance
                              ? data?.transporterAdvance
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 pb-3`}
                        >
                          <span className="mt-2 text-center">
                            {" "}
                            {data?.transporterBalance
                              ? data?.transporterBalance
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 pb-3`}
                        >
                          <span className="mt-2 text-center">
                            {data?.transporterHamali
                              ? data?.transporterHamali
                              : 0}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 pb-3`}
                        >
                          <span className="mt-2 text-center">
                            {data?.transporterDetention
                              ? data?.transporterDetention
                              : 0}
                          </span>
                        </td>
                        <td height="20px" className="w-28 mr-2 pb-3">
                          <span className="mt-2 text-center">
                            {data?.transporterExtraCharge
                              ? data?.transporterExtraCharge
                              : 0}
                          </span>
                        </td>
                        {/* <td height="20px" className=" w-28 mr-2">
                          <span className="mt-2 text-center">
                            {data?.transporterTotal
                              ? data?.transporterTotal
                              : 0}
                          </span>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {props.mobilePrint ? (
                <PDFFooter
                  bankBranch={props.bankBranch}
                  Dropdown={props.Dropdown}
                  showBorder={props.showBorder}
                  blackWhite={props.blackWhite}
                  data={location?.data}
                  profileData={props.profileData}
                  totalInwords={location?.data?.grandTotalInWords}
                  mobilePrint={props.mobilePrint}
                />
              ) : (
                <PrintingFooter
                  bankBranch={props.bankBranch}
                  Dropdown={props.Dropdown}
                  showBorder={props.showBorder}
                  blackWhite={props.blackWhite}
                  data={location?.data}
                  profileData={props.profileData}
                  totalInwords={location?.data?.grandTotalInWords}
                  mobilePrint={props.mobilePrint}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {/* printing */}
      <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
        <ButtonWithBG
          onClick={() => handleDownloadPdf("download")}
          label="Download"
        />
        <ButtonWithBG
          onClick={() => handleDownloadPdf("print")}
          label="Print"
        />
      </div>
    </>
  );
};

export default TransporterInvoiceViewDownload;
