import React, { useEffect } from "react";
// import Table from "../UI/Table";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import axiosInstance from "../../Utils/API";
import { GET_BUILTY_TABLE } from "../../Utils/ApiGlobal";
import { useState } from "react";
import Loader from "../UI/Loader";
import moment from "moment";
// import Pagination from "../UI/Pagination/Pagination";

const columns = [
  {
    Header: "Action",
    id: "Action",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (row) => (
      <div>
        <TableAction
          data={row.row.original}
          redirectPath="bilty/edit-Buillty"
          redirectViewPath="bilty/billty-invoice-view"
        />
      </div>
    ),
  },
  {
    Header: "Id",
    accessor: (d,index) => {
      return index +1
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Date",
    accessor: d => {
      return moment(d.createdAt)
        .format("DD-MM-YYYY")
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "L.Date",
    accessor: d => {
      return moment(d.lrDate)
        .format("DD-MM-YYYY")
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Vehicle No",
    accessor: "vehicleNo",
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    Header: "COMPANY NAME",
    accessor: "Company_Name",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "FROM",
    accessor: "from",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    Header: "To",
    accessor: "to",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "BOOKING AMOUNT",
    accessor: "amountToPayOrPaid",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "LR No.",
    accessor: "lrNo",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "BILL NO.",
    accessor: "billtyNo",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "FREIGHT",
    accessor: "fright",
  },
  {
    Header: "Advance",
    accessor: "advance",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Balance",
    accessor: "balance",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Hamali",
    accessor: "hamali",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Detection",
    accessor: "detention",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Extra Charge",
    accessor: "extraCharge",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Total",
    accessor: "total",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  // {
  //   Header: "Vehicle No",
  //   accessor: "vehicleNo",
  //   maxWidth: 100,
  //   minWidth: 100,
  //   width: 100,
  // },
  {
    Header: "DRIVER PAID BALANCE",
    accessor: "driverPaidBalance",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "BALANCE PAID BILL NO.",
    accessor: "booking_Id",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "BILLTY RECEIVED OR NOT",
    accessor: (d) => {
      if (d.isBiltyReceive === true) {
        return "Yes"
      } else {
        return "No";
      }
    },
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Note",
    accessor: "note",
  },
];
export default function BiltyTable(props) {
  const [builttyData, SetBuilttyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(props,"for table")

  // for Search by Date.
  // const filterByDate = ({Date}) => {
  //     return Date.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  //   };
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`${GET_BUILTY_TABLE}?search=${props.searchValue}&pageNo=&pageSize=`)
      .then((response) => {
        const data = response?.data?.data?.billty;
        SetBuilttyData(data);
        setIsLoading(false);
        // console.log(data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.searchValue]);

  return (
    <section>
      <div className="mt-4 pt-4">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={builttyData} columns={columns} />
        )}
      </div>
    </section>
  );
}
