import React, { useState } from "react";
import Button from "../../Components/UI/Button/Button";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { setting_svg } from "../../Utils/SVGListing";
import LCVTable from "../../Components/DataTables/LCVTable";

const LCV = () => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <DashBoardLayout pageTitle="L. C. V.">
      <div className="min-w-full space-y-4">
        <div className="flex flex-row justify-between">
          <div>
            <SearchField
              placeholder="Searching By Date"
              handleChange={setSearchValue}
              value={searchValue}
            />
          </div>
          <div className="flex flex-row space-x-2">
            <Button svg={setting_svg}></Button>
          </div>
        </div>
      </div>
      <LCVTable searchValue={searchValue} />
    </DashBoardLayout>
  );
};

export default LCV;
