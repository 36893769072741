import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import APILoader from "../../../Components/UI/APILoader";
import Button from "../../../Components/UI/ButtonMain";
import { ConfirormationModal } from "../../../Components/UI/ConfirormationModal";
import axiosInstance from "../../../Utils/API";
import { REMOVE_BANK_INFORMATION } from "../../../Utils/ApiGlobal";
import { REMOVE_CONFORM } from "../../../Utils/Constants";
import { delete_svg } from "../../../Utils/SVGListing";

// import Notification from "./Notification";

export default function RemoveInformation(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  // const [notif, setNotif] = useState(false);
  // const [notifOpts, setNotifOpts] = useState({});

  const openModal = (props) => {
    setIsOpen(true);
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };

  const handleRemoveBtn = () => {
    // handle all the action in the main view
    setLoader({ ...loader, loading: true });
    let REMOVE_ID = {
        AccountId:props?.data?.accountId
    };
    axiosInstance
      .post(REMOVE_BANK_INFORMATION, REMOVE_ID)
      .then((response) => {
        setLoader({
          loading: false,
          message: "",
          icon: "",
        });
        ConfirormationModal(response.data.data.message, "success", 2000);
        handleButtonClick();
        props.getAPI.getAPI();
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
        handleButtonClick();
        props.getAPI.getAPI();
      });
  };

  return (
    <>
      <button onClick={openModal}>{delete_svg}</button>
      <APILoader loader={loader} />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30  flex justify-center  items-center bg-gray-100 bg-opacity-75 rounded-lg shadow-md"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen ml-4 px-4 text-center">
            <Dialog.Overlay className="fixed inset-0" />
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            {/* <Notification
              show={notif}
              setShow={setNotif}
              {...notifOpts}
            ></Notification> */}
            <div className="border-2 border-primary-kirtiroadways inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-center text-gray-900 mb-4"
              >
                {REMOVE_CONFORM} Bank Information ?
              </Dialog.Title>

              <div className=" mt-4 space-x-2 flex justify-center">
                <Button onClick={handleRemoveBtn} theme={`primary`}>
                  Remove
                </Button>
                <Button onClick={handleButtonClick} theme="secondary">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          {/* </>
        )} */}
        </Dialog>
      </Transition>
    </>
  );
}
