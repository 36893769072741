import email_logo from "../../Images/images/email.png";
import location_logo from "../../Images/images/location.png";
import phone_logo from "../../Images/images/phone.png";
import black_logo from "../../Images/black_logo.png";
import sign from "../../Images/sign.png";

export async function BillingFooter(
  billing,
  isA5 = false,
  isVehicleFooter = false
) {
  async function getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", url, error);
        reject(error);
      };
      img.src = url;
    });
  }
  const headerImages = {
    backgroundImage: await getBase64ImageFromURL(black_logo),
    Sign: await getBase64ImageFromURL(sign),
    Locate: await getBase64ImageFromURL(location_logo),
    phone: await getBase64ImageFromURL(phone_logo),
    email: await getBase64ImageFromURL(email_logo),
  };
  if (isA5) {
    return [
      {
        margin: [0, 0, 0, 0],
        stack: [
          {
            margin: [0, 3, 0, 0],
            columns: [
              {
                text: "TERMS & CONDITIONS",
                fontSize: 8,
              },
              {
                text: "E. & O.E.",
                fontSize: 8,
                alignment: "center",
              },
              {
                text: "FOR,",
                fontSize: 8,
                alignment: "center",
              },
              {
                text: "KIRTI ROADLINES",
                fontSize: 8,
                bold: true,
                alignment: "right",
              },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 5,
                x2: 120,
                y2: 5,
                lineWidth: 2,
              },
            ],
            margin: [0, 0, 0, 2],
          },
          {
            margin: [0, 2, 0, 0],
            columns: [
              {
                text: "SUBJECT TO AHMEDABAD JURISDICTION",
                fontSize: 8,
              },
              {
                image: headerImages.Sign,
                width: 60,
              },
            ],
          },
          {
            margin: [0, 3, 0, 0],
            columns: [
              {
                width: "70%",
                text: `PAYMENT BY PAYEE'S A/C CHEQUE / DRAFS ONLY IN FAVOUR OF THE KIRTI ROADLINES`,
                fontSize: 8,
              },
              {
                text: "AUTHORIZED SIGNATORY",
                fontSize: 8,
                bold: true,
                alignment: "right",
              },
            ],
          },
          {
            margin: [0, 0, 0, 3],
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 5,
                w: 370, // width of the rectangle
                h: 2, // height of the rectangle
                color: "black",
              },
            ],
          },
          {
            table: {
              widths: ["100%"],
              headerRows: 1,
              bold: true,
              heights: 13,
              body: [
                [
                  {
                    margin: [0, 2, 0, 0],
                    alignment: "center",
                    // absolutePosition:{x:0, y:86},
                    text: "THIS IS A COMPUTER GENERATED INVOICE",
                    bold: true,
                    fontSize: 9,
                  },
                ],
              ],
            },
          },
        ],
      },
    ];
  }

  if (billing === "billing") {
    return [
      {
        margin: [0, 3, 0, 0],
        stack: [
          {
            margin: [0, 3, 0, 0],
            columns: [
              {
                text: "TERMS & CONDITIONS",
                fontSize: 9,
              },
              {
                text: "E. & O.E.",
                fontSize: 9,
                alignment: "center",
              },
              {
                text: "FOR,",
                fontSize: 9,
                alignment: "center",
              },
              isVehicleFooter
                ? {
                    image: headerImages.Sign,
                    width: 60,
                  }
                : {
                    text: "KIRTI ROADLINES",
                    fontSize: 9,
                    bold: true,
                    alignment: "right",
                  },
            ],
          },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 5,
                x2: 150,
                y2: 5,
                lineWidth: 2,
              },
            ],
            margin: [0, 0, 0, 2],
          },
          {
            margin: [0, 3, 0, 0],
            height: 70,
            columns: [
              {
                text: "SUBJECT TO AHMEDABAD JURISDICTION",
                fontSize: 9,
              },
              isVehicleFooter
                ? {
                    text: "",
                    fontSize: 9,
                  }
                : {
                    image: headerImages.Sign,
                    width: 60,
                  },
            ],
          },
          {
            margin: [0, 3, 0, 0],
            columns: [
              {
                width: "70%",
                text: isVehicleFooter
                  ? ""
                  : `PAYMENT BY PAYEE'S A/C CHEQUE / DRAFS ONLY IN FAVOUR OF THE KIRTI ROADLINES`,
                fontSize: 9,
              },
              {
                text: "AUTHORIZED SIGNATORY",
                fontSize: 9,
                bold: true,
                alignment: "right",
              },
            ],
          },
          {
            margin: [0, 0, 0, 5],
            canvas: [
              {
                type: "rect",
                x: 0,
                y: 5,
                w: 525, // width of the rectangle
                h: isVehicleFooter ? 1 : 2, // height of the rectangle
                color: "black",
              },
            ],
          },
          {
            table: {
              widths: ["100%"],
              headerRows: 1,
              fontSize: 9,
              bold: true,
              heights: 15,
              body: [
                [
                  {
                    margin: [0, 2, 0, 0],
                    alignment: "center",
                    // absolutePosition:{x:0, y:86},
                    text: "THIS IS A COMPUTER GENERATED INVOICE",
                    bold: true,
                    fontSize: 10,
                  },
                ],
              ],
            },
          },
        ],
      },
    ];
  } else {
    return [
      {
        columns: [
          {
            text: `TERM'S & CONDITION'S OF CARRIAGE OWNER'S RISK`,
            // absolutePosition: { x: 20, y: 20 },
            color: "#000",
            fontSize: 9,
            bold: true,
            margin: [15, 10, 0, 0],
            decoration: "underline",
          },
          {
            text: `VALUE: AS PER INVOICE SERVICE TAX LIABILITY OF CONSIGNOR`,
            // absolutePosition: { x: 295, y: 20 },
            color: "#000",
            fontSize: 7,
            bold: false,
            margin: [0, 10, 15, 0],
            alignment: "right",
          },
        ],
      },
      {
        alignment: "justify",
        columns: [
          {
            text: `WHERE THE GOODS HAVE BEEN LOST DESTROYED, DAMAGED OR HAVE DETERIORATED, THE COMPENSATION SHALL NOT BE PAYABLE BY THE TRANSPORT OPERATOR. IF NATURE CALAMITY OR ANY TYPE OF ACCIDENT OCCURS, BOTH PARTIES CONSIGNOR AND CONSIGNEE ARE LIABLE FOR THE GOODS PAYMENTS.`,
            // absolutePosition: { x: 20, y: 35 },
            color: "#000",
            fontSize: 7,
            bold: false,
            margin: [15, 10, 0, 0],
          },
          {
            absolutePosition: { x: 480, y: 35 },
            image: headerImages.Sign,
            width: 90,
          },
          {
            text: `FOR KIRTI ROADLINES`,
            absolutePosition: { x: 475, y: 60 },
            color: "#000",
            fontSize: 9,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            text: `THANK YOU FOR BUSINESS WITH US`,
            color: "#000",
            fontSize: 10,
            bold: true,
            alignment: "center",
            margin: [0, 20, 0, 0],
            font: "PTSerifRegular",
          },
        ],
      },
    ];
  }
}
