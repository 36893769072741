import React from "react";
import logo from "../../Images/logo_transparent.png";
import black_logo from "../../Images/black_logo.png";
import {
  CAUTION,
  CAUTIONContnet,
  CompanyName,
  CompanyResponsible,
  CONSIGNMENTNOTE,
  CustomerDeclaration,
  InvoiceDeclartion,
  InvoicSubject,
  KIRTIROADLINES,
  InvoiceBilltyNote,
  OwnerRiskInsurance,
  PANNo,
  receiptDeclaration,
  TermsandCondition,
  TermsandConditionNote,
  THANKYOUFORBUSSINESSWITHUS,
  ServiceTaxLiabilityConsignor,
  Address,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import Loader from "./Loader";
// import InvoicePrint from "../../Components/UI/InvoicePrint";
import moment from "moment";
import { address } from "../../Utils/TableStyling";
import sign from "../../Images/sign.png";

const InvoiceView = (props) => {

  const actualWeight = props?.data?.actualWeight
  const chargedWeight = props?.data?.chargedWeight

  const actualWeightPrefix = (!actualWeight || actualWeight?.toLowerCase() === "fix" || actualWeight === "" || actualWeight === " ") ? actualWeight?.toLowerCase() === "fix" ? actualWeight : "" : actualWeight
  const chargedWeightPrefix = (!chargedWeight || chargedWeight?.toLowerCase() === "fix" || chargedWeight === "" || chargedWeight === " ") ? chargedWeight?.toLowerCase() === "fix" ? chargedWeight : "" : chargedWeight

  // console.log(props)
  return (
    <>
      <div style={{ minWidth: "1240px" }} className="">
        {/* <button onClick={()=>{console.log(props?.data)}}>Click</button> */}
        {props.data.createdAt ? (
          <div
            className={`${
              props.showBorder &&
              ` border-double  border-4 ${
                props.blackWhite ? "border-black" : "border-red-200"
              } `
            } 
            pageStyle  block  min-h-screen m-10   align-top text-center bg-center bg-no-repeat  bg-[length:65%]`}
          >
            {" "}
            <div className="flex  flex-row justify-between font-bold sm:mx-8 mx-2 mb-3 text-xs">
              <p className="text-xs">{InvoicSubject}</p>
              <p className="text-xs">{InvoiceDeclartion}</p>
            </div>
            {/* Logo and Title Start */}
            <div className="m-2 items-center space-x-3 flex flex-row">
              <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                <div className="flex flex-row justify-start items-start ">
                  <div className="w-72 ml-2 ">
                    {props.blackWhite ? (
                      <img src={black_logo} alt="Kirti Roadlines"  />
                    ) : (
                      <img src={logo} alt="Kirti Roadlines" />
                    )}{" "}
                  </div>
                  <div className=" flex flex-col w-full">
                    <span
                      className={`kirtiroadline-headline ${
                        !props.blackWhite && "text-primary-kirtiroadways"
                      } font-extrabold font-serif`}
                    >
                      {" "}
                      {props.profileData.tagLine}
                    </span>
                    <span className="2xl:text-4xl text-3xl font-serif text-black">
                      {props.profileData.subTagLine}
                    </span>
                  </div>
                </div>
                {/* Address Start */}
                <div
                  className={`rounded-b-3xl rounded-t-lg font-bold   p-2 w-full  ${address(
                    props.blackWhite
                  )} flex flex-col space-y-1 text-center text-sm`}
                >
                  {" "}
                  <p className="flex  text-md justify-center flex-row items-start">
                    <span className="text-md w-5">{address_svg}</span>{" "}
                    <span className="text-md ml-1">
                      {props.profileData.address}
                    </span>
                  </p>
                  <div className="  flex space-x-4 justify-center flex-row items-start ">
                    <div className="flex space-x-1 text-md justify-center flex-row items-center ">
                      <span>{call_svg} </span>{" "}
                      <span className="flex flex-row">
                        {props.secondaryPhoneNo.map((number, i) => (
                          <span
                            key={number}
                            className="text-sm font-semibold tracking-wider flex flex-row"
                          >
                            {(i ? ", " : "") + number}
                          </span>
                        ))}
                      </span>
                    </div>
                    <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                      <span>{email_svg} </span>
                      <span>{props.profileData.email}</span>
                    </div>
                  </div>
                </div>
                {/* Address End */}
              </div>
            </div>
            {/* Logo and Title End */}
            <div className="font-md flex flex-row p-4">
              <div
                className="
                shadow-sm
                border 
                flex
                flex-row rounded-x-md w-full divide-x border-neutral-400 divide-neutral-400"
              >
                <div className="w-1/3 ">
                  {/* <h1 className="font-bold underline text-lg">{CAUTION}</h1>
                  <ul className="text-justify font-serif text-xs ml-5 mr-5 list-outside">
                    <li className="mt-2">{CAUTIONContnet}</li>
                    <li>{receiptDeclaration}</li>
                  </ul> */}
                  <div className="">
                    <div className="w-full ">
                      <table className="border-collapse text-base w-full">
                        <thead>
                          <tr>
                            <td className="w-1/2 p-2">
                              <div className="flex flex-row">
                                <p className="">Fin. Year :</p>
                                <p className="font-bold ml-1">
                                  {props?.data?.financialYear}
                                </p>
                              </div>
                            </td>
                            <td className="border-l border-b border-neutral-400 pl-2">
                              <div className="flex flex-row">
                                <p className="">LR No. :</p>
                                <p className="font-bold ml-1">
                                  {props?.data?.lrNo}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="items-starts ">
                            <td className="border-t border-neutral-400 p-2 flex flex-row ">
                              <div className="flex flex-row">
                                <p className=" items-start">LR Date : </p>
                                <p className="font-bold ml-1">
                                  {/* {props?.data?.lrDate
                                    ? props?.data?.lrDate.split("T")[0]
                                    : props?.data?.loadingDate.split("T")[0]} */}

                                  {props?.data?.lrDate
                                    ? moment(props?.data?.lrDate).format(
                                        "DD-MM-YYYY"
                                      )
                                    : " "}
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-t border-neutral-400 text-left">
                            <td colSpan={2} className="p-2">
                              <div className="flex flex-row">
                                <span className="">Lorry No. :</span>
                                <span className="font-bold ml-1">
                                  {props?.data?.vehicleNo}
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-t border-neutral-400 text-left">
                            <td colSpan={2} className="p-2">
                              <span className="">Delivery At. :</span>
                              <span className="font-bold pl-1">
                                {props?.data?.to}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="w-2/3 flex flex-col divide-y border-neutral-400 divide-neutral-400 ">
                  <div className=" flex flex-row divide-x border-neutral-400 divide-neutral-400">
                    <div className="w-1/2 self-center font-bold text-lg">
                      <h1 className="underline text-center font-bold text-lg">
                        {PANNo} {props?.profileData?.panNo}
                      </h1>
                      <div className={`self-center font-bold text-lg`}>
                        {props.copyFor} COPY
                      </div>
                    </div>
                    <div className="w-1/2">
                      <ul className="divide-y divide-neutral-400 text-md">
                        <li className="p-2 text-md text-start">
                          {CompanyName} :{""}
                          {/* {props.profileData.companyName.toUpperCase()} */}
                        </li>
                        <li className="p-2 text-md text-start">
                          Policy No : {""}
                          {/* {props.profileData.policyNo} */}
                        </li>
                      </ul>
                      {/* <h1 className="font-bold text-lg underline">
                        {OwnerRiskInsurance}
                      </h1>
                      <h4 className="text-xs">{CustomerDeclaration}</h4> */}
                    </div>
                  </div>

                  <div className="h-1/2 flex flex-row divide-x border-neutral-400 divide-neutral-400">
                    <div className="w-1/2 self-center font-bold text-lg">
                      <h1
                        className={`underline font-bold text-lg ${
                          !props.blackWhite && "text-primary-kirtiroadways"
                        } `}
                      >
                        {CONSIGNMENTNOTE}
                      </h1>
                    </div>
                    <div className="w-1/2 text-left text-md">
                      <div>
                        <div className="w-full ">
                          <table className="border-collapse text-base w-full">
                            <thead>
                              <tr>
                                <td
                                  className="border-y border-t-0 border-neutral-400 p-2"
                                  colSpan="2"
                                >
                                  <span className="float-left">
                                    Date :{" "}
                                    {/* {props?.data?.createdAt
                                  ? props?.data?.createdAt.split("T")[0]
                                  : props?.data?.createdAt.split("T")[0]} */}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  colSpan="2"
                                  className="w-1/2 border-neutral-400 p-2"
                                >
                                  <span className="float-left">
                                    Amount :{" "}
                                    {/* {props?.data?.amountToPayOrPaid === null
                                    ? ""
                                    : props?.data?.amountToPayOrPaid} */}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                {/* <td className="w-1/2 border-t border-r border-neutral-400 p-2">
                                <span className="float-left">
                                  Date :{" "}
                                  {props?.data?.createdAt
                                  ? props?.data?.createdAt.split("T")[0]
                                  : props?.data?.createdAt.split("T")[0]}
                                </span>
                              </td>
                              <td className="border-t  border-neutral-400 p-2">
                                <span className="float-left">
                                  Amount :{" "}
                                  {props?.data?.amountToPayOrPaid === null
                                    ? ""
                                    : props?.data?.amountToPayOrPaid}
                                </span>
                              </td> */}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Consignore and consignee Start*/}
            <div
              className="mx-4 mb-4 border-gray-600 shadow-sm border rounded-l-md  text-sm rounded-r-md
                                flex flex-col "
            >
              <table className="border-collapse text-base">
                <tbody>
                  <tr>
                    <td className="w-1/2  border-b border-r border-neutral-400 p-1 text-lg">
                      <span className="float-left font-bold">
                        <span
                          className={` font-bold  ${
                            !props.blackWhite && "text-primary-kirtiroadways"
                          } `}
                        >
                          Consignor's Name :
                        </span>{" "}
                        {props?.data?.consignerName}
                      </span>
                    </td>
                    <td className="w-1/2 font-bold border-b border-neutral-400 p-1 text-lg">
                      <span className="float-left">
                        <span
                          className={` font-bold  ${
                            !props.blackWhite && "text-primary-kirtiroadways"
                          } `}
                        >
                          {" "}
                          Consignee's Name :
                        </span>{" "}
                        {props?.data?.consigneeName}
                      </span>
                    </td>
                  </tr>

                  <tr className="align-top text-left h-20">
                    <td className="w-1/2  border-b border-r border-neutral-400 p-1">
                      <span className="float-left">
                        <span className="">{Address}</span>{" "}
                        {props?.data?.consignerAddress}
                      </span>
                    </td>
                    <td className="w-1/2 border-b align-top text-left border-neutral-400 p-1">
                      <span className="float-left">
                        <span className="">{Address}</span>{" "}
                        {props?.data?.consigneeAddress}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-1/2 border-r border-neutral-400 p-1">
                      <span className="float-left">
                        <span className="">GST No :</span>{" "}
                        {props?.data?.consignerGst === null
                          ? "NA"
                          : props?.data?.consignerGst.toUpperCase()}
                      </span>
                    </td>
                    <td className="w-1/2 p-1">
                      <span className="float-left">
                        <span className="">GST No :</span>{" "}
                        {props?.data?.consigneeGst === null
                          ? "NA"
                          : props?.data?.consigneeGst.toUpperCase()}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Consignore and consignee End*/}
            {/* from and to Start*/}
            <div
              className="mx-4 mb-4 border-gray-600 shadow-sm border rounded-l-md  text-sm rounded-r-md
                                flex flex-col "
            >
              <table className="border-collapse text-base">
                <tbody>
                  <tr>
                    <td className="w-1/2 border-r border-neutral-400 p-2">
                      <span className="float-left font-bold">
                        <span
                          className={` font-bold  ${
                            !props.blackWhite && "text-primary-kirtiroadways"
                          } `}
                        >
                          {" "}
                          From:-
                        </span>{" "}
                        {props?.data?.from}
                      </span>
                    </td>
                    <td className="w-1/2 font-bold p-1">
                      <span className="float-left">
                        <span
                          className={` font-bold  ${
                            !props.blackWhite && "text-primary-kirtiroadways"
                          } `}
                        >
                          To:-
                        </span>{" "}
                        {props?.data?.to}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* from and to End*/}
            {/* Table */}
            <div className=" mx-4 mt-4">
              {/* Table Start */}
              <table className="w-full  text-sm">
                <thead>
                  <tr className="border ">
                    <th
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } `}
                    >
                      Packages
                    </th>
                    <th
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } `}
                    >
                      Description (said to contains)
                    </th>
                    <th
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } `}
                    >
                      <h1
                        className={` ${
                          !props.blackWhite
                            ? "border-b-2 border-neutral-300"
                            : "border-b border-black"
                        } `}
                      >
                        Weight
                      </h1>
                      <div className="w-full  flex flex-row justify-evenly text-center">
                        <h1>Actual</h1>
                        <h1>Charged</h1>
                      </div>
                    </th>
                    <th
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } `}
                    >
                      Rate
                    </th>
                    <th
                      className={` ${
                        !props.blackWhite
                          ? "border border-neutral-300"
                          : "border border-black"
                      } `}
                    >
                      <h1
                        className={` ${
                          !props.blackWhite
                            ? "border-b-2 border-neutral-300"
                            : "border-b border-black"
                        } `}
                      >
                        Amount to Pay/Paid
                      </h1>
                      <h1>Rs.</h1>
                    </th>
                  </tr>
                </thead>
                <tbody className="align-top font-light text-center">
                  <tr
                    className={` ${
                      !props.blackWhite
                        ? "border-2 border-neutral-300"
                        : "border border-black"
                    } `}
                  >
                    <td
                      rowSpan="2"
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } font-semibold items-center`}
                    >
                      {props?.data?.packages !== null && (
                        <>
                          <h1 className="">{props?.data?.packages}</h1>
                          {/* <h1 className="">NOS</h1> */}
                        </>
                      )}
                    </td>
                    <td className="font-semibold flex flex-col space-y-24 align-middle">
                      <h1 className="font-semibold">
                        {props?.data?.description}
                      </h1>
                      <h1 className="font-semibold">AS PER INVOICE</h1>
                    </td>
                    <td
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } `}
                    >
                      <div className="flex flex-col space-y-8">
                        <div className="flex flex-row justify-evenly">
                          <div>
                            <h1 className="font-semibold">
                              {isNaN(+actualWeightPrefix) ? 'fix' : actualWeightPrefix} {" "}
                              {isNaN(+actualWeightPrefix) ? "" : props.data.actualWeight?.toLowerCase() !== 'fix'
                                ? props.data.weightType && !props.data.actualWeight ? "-" : props.data.weightType
                                : 'NA'}
                            </h1>
                          </div>
                          <div>
                            <h1 className="font-semibold">
                            {isNaN(+chargedWeightPrefix) ? 'fix' : chargedWeightPrefix} {" "}
                              {isNaN(+chargedWeightPrefix) ? "" : props.data.chargedWeight?.toLowerCase() !== 'fix'
                                ? props.data.weightType && !props.data.chargedWeight ? "-" : props.data.weightType
                                : 'NA'}
                            </h1>
                          </div>
                        </div>
                        <div className="font-semibold space-y-8">
                          <h1
                            className={` ${
                              !props.blackWhite
                                ? "border-y-2 border-neutral-300"
                                : "border-y border-black"
                            } font-semibold `}
                          >
                            Company Invoice No.
                          </h1>
                          <div className="font-semibold mt-4">
                            {props?.bookingInvoiceNo}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="flex flex-col space-y-4">
                      <h1 className="font-semibold">Freight</h1>
                      <h1 className="font-semibold">Sur. Ch.</h1>
                      <h1 className="font-semibold">St. Ch.</h1>
                      <h1 className="font-semibold">Risk Ch.</h1>
                    </td>
                    <td
                      className={` ${
                        !props.blackWhite
                          ? "border-2 border-neutral-300"
                          : "border border-black"
                      } font-semibold `}
                    >
                      <div className=" mt-14 font-semibold flex flex-row justify-center items-center">
                        {props?.information || props?.data?.amountToPayOrPaid}
                        {/* {props?.data?.amountToPayOrPaid === null
                               ? "NA"
                          : props?.data?.amountToPayOrPaid} */}
                      </div>
                      {/* <div className="uppercase pt-16 font-semibold  bg-red self-bottom">
                          To Pay {props?.data?.to}
                        </div> */}
                      {/* <h1 className="uppercase font-semibold absolute bottom-0">
                      TO PAY {props?.data?.to}
                    </h1> */}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={` ${
                        !props.blackWhite
                          ? "border-y-2 border-neutral-300"
                          : "border-y  border-black"
                      } font-semibold text-left`}
                      // className="font-semibold border-t-2 text-left border-b-2 border-neutral-300"
                    >
                      <h1 className="ml-2">Private Marks:</h1>
                    </td>
                    <td
                      className={` ${
                        !props.blackWhite
                          ? "border-x-2 border-b-2 border-neutral-300"
                          : "border-x border-b  border-black"
                      } font-semibold text-left`}
                    >
                      <h1 className="font-semibold">
                        {props?.data?.privateMarks}
                      </h1>
                    </td>
                    <td
                      className={` text-md  text-center justify-center  font-bold ${
                        !props.blackWhite
                          ? "bg-primary-kirtiroadways text-white border-r-2 border-y-2  border-neutral-300"
                          : "border-r border-y border-black"
                      }`}
                    >
                      <h1>TOTAL</h1>
                    </td>
                    <td
                      className={`font-mono text-md whitespace-nowrap	 text-center justify-center  font-bold ${
                        !props.blackWhite
                          ? "bg-primary-kirtiroadways text-white border-r-2 border-b-2 border-neutral-300"
                          : "border-r border-b border-black"
                      }`}
                    >
                      <span></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="w-full flex flex-row justify-center mt-2">
                <div className="font-bold border-b-0 border w-full border-neutral-400">
                  <span style={{ fontSize: "10px" }}>To Pay :</span>{" "}
                  <span style={{ fontSize: "10px" }}>
                    {props?.paidBy}
                  </span>
                </div>
              </div>
              <div className="font-bold text-xs border border-b-0 border-neutral-400">
                <p style={{ fontSize: "10px" }}>{InvoiceBilltyNote}</p>
                <p style={{ fontSize: "10px" }}>{CompanyResponsible}</p>
              </div>
              <div className="font-bold text-xs border border-neutral-400">
                <span
                  style={{ fontSize: "10px" }}
                  className="font-bold text-lg underline"
                >
                  {OwnerRiskInsurance}
                </span>
                <span style={{ fontSize: "10px" }}>
                  {" "}
                  : {CustomerDeclaration}
                </span>
              </div>
            </div>
            <p className="font-bold underline text-xs border-t-2 pt-1 text-start ml-5 mr-5">
              {CAUTION}
            </p>
            <ul className="text-justify  text-xs ml-5 mr-5 ">
              <ol className="mt-1" style={{ fontSize: "10px" }}>
                1. {CAUTIONContnet}
              </ol>
              <ol style={{ fontSize: "10px" }}>2. {receiptDeclaration} </ol>
            </ul>
            {/* Table End */}
            <div className="mx-6 mt-5">
              {/* bottom left and right start */}
              <div className="flex flex-row space-y-0 justify-between text-left">
                <div className="max-w-xl">
                  <h1 className="font-bold underline border-neutral-400  text-sm">
                    {TermsandCondition}
                  </h1>
                  <h6 className="	text-xs text-justify">
                    {TermsandConditionNote}
                  </h6>
                </div>
                <div className="font-semibold text-sm text-right">
                  <h3>Value: AS PER INVOICE</h3>
                  <h3>{ServiceTaxLiabilityConsignor}</h3>
                  <div className="text-left  text-sm	flex justify-end">
                    <img src={sign} alt="signature" width={100} />
                  </div>
                  <h3 className="">For {KIRTIROADLINES}</h3>
                </div>
              </div>
              {/* bottom left and right end */}

              {/* <div className="relative">
                <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
          </div> */}
            </div>
            <div
              className={`  border	 ${
                !props.blackWhite && "bg-primary-kirtiroadways text-white "
              } w-full mt-2 font-bold rounded-md `}
            >
              <p style={{ fontSize: "12px" }}>{THANKYOUFORBUSSINESSWITHUS}</p>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default InvoiceView;
