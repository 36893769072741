// function

// Pdf table th black and white
export function TablethStyle(condition) {
  return condition ? "border border-neutral-900" : "bg-primary-kirtiroadways";
}
// Pdf table td black and white
export function TabletdStyle(condition) {
  return condition ? "border border-neutral-900" : "border-r-2 border-black";
}
// Pdf table headed black and white
export function TabletheadStyle(condition) {
  return condition
    ? "border-r border-black text-black border-b border-t pb-2"
    : "bg-slate-800 text-white pb-2";
}
// Pdf table headed black and white
export function TabletheadRedStyle(condition) {
  return condition
    ? "border-r border-black text-black border-b border-t "
    : "bg-primary-kirtiroadways text-white";
}
// Pdf table headed black and white
export function TabletheadRedLastStyle(condition) {
  return condition
    ? "border-black text-black border-b border-t"
    : "bg-primary-kirtiroadways text-white";
}
// Pdf table background black and white
export function Tablebg(condition) {
  return condition
    ? "border-2 border-black text-black"
    : " bg-primary-kirtiroadways text-white border-white ";
}
// Pdf table address black and white
export function address(condition) {
  return condition
    ? " rounded-md border border-black "
    : "bg-gradient-to-r from-red-200 to-white-900 "
}
// Pdf table address black and white
export function heading(condition) {
  return condition
    ? "text-black border border-black rounded-lg "
    : "text-white rounded-lg bg-primary-kirtiroadways "
}

export function borderToggle(condition){
    return condition ?"border-black" :"border-primary-kirtiroadways"
}
export function bgToggle(condition){
    return condition ?" text-black  border-x border-black" :"bg-primary-kirtiroadways text-white"
}