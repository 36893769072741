const styles = `
  inline-flex 
  justify-center 
  px-4 
  py-2 
  border 
  border-gray-300 
  shadow-sm 
  text-sm 
  font-medium 
  rounded-md 
  text-gray-700 
  bg-white 
  hover:bg-gray-50 
  hover:text-black
  focus:outline-none 
  focus:ring-2 
  focus:ring-offset-2 
  focus:ring-primary-kirtiroadways
`;

export default function ReactiveButton({ text, ...props }) {
  return (
    <button className={styles} {...props}>
      {text}
    </button>
  );
}
