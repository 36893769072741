import React, { useEffect, useState } from "react";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import axiosInstance from "../../Utils/API";
import { GET_VEHICLE } from "../../Utils/ApiGlobal";
import OwnerAndVehicleNoCreate from "./OwnerAndVehicleNoCreate";

const animatedComponents = makeAnimated();

export default function CreatableSearchVehicleNo({
  handleOnChange,
  defaultValue,
}) {
  const [optionsVehicleNo, setOptions] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null); // State for selected vehicle
  const [open, setOpen] = useState(false);
  const [defaultVehicleNo, setDefaultVehicleNo] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`${GET_VEHICLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        if (response?.data?.data?.vehicles.length > 0) {
          const options = response?.data?.data.vehicles.map(
            ({
              vehicleNo,
              vehicleId,
              ownerId,
              ownerName,
              ownerCommissionType,
              ownerCommissionAmount,
            }) => ({
              label: vehicleNo,
              value: vehicleId,
              ownerId,
              ownerName,
              ownerCommissionType,
              ownerCommissionAmount,
            })
          );
          setOptions(options);
        }
      });
  }, []);

  const handleCreateNew = (inputValue) => {
    setDefaultVehicleNo(inputValue)
    setOpen(true);
  };

  const handleSubmit = (data) => {
    // Create new option to add to the select options
    const newOption = {
      label: data?.vehicleNo,
      value: data?.vehicleId, // Assuming you can use the same value as the label for this example
      ownerId: data?.ownerId,
      ownerName: data?.ownerName,
      ownerCommissionType: data?.ownerCommissionType,
      ownerCommissionAmount: data?.ownerCommissionAmount,
    };

    // Update the options and set the selected vehicle
    setOptions((prevOptions) => [...prevOptions, newOption]);
    setSelectedVehicle(newOption); // Set the selected vehicle to the newly created option

    // Update the parent component with the new option
    handleOnChange(newOption);
  }

  return (
    <>
      <OwnerAndVehicleNoCreate open={open} setOpen={setOpen} handleSubmit={handleSubmit} defaultVehicleNo={defaultVehicleNo} setDefaultVehicleNo={setDefaultVehicleNo}/>
      <CreatableSelect
        options={optionsVehicleNo}
        value={selectedVehicle} // Control the selected vehicle
        defaultValue={
          defaultValue ? { label: defaultValue, value: defaultValue } : null
        }
        getOptionLabel={(option) => `${option.label} - ${option.ownerName ? option.ownerName : ""}`}
        placeholder=""
        components={animatedComponents}
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === "create-option") {
            handleCreateNew(newValue.label); // Pass the input value to the modal
          } else {
            setSelectedVehicle(newValue); // Set the selected vehicle
            handleOnChange(newValue); // Update the parent component
          }
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            primary25: "#f68e92",
            primary: "#ed1c24",
          },
        })}
      />
    </>
  );
}
