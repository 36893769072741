import React from "react";
import {  Transition } from "@headlessui/react";
import { Link, NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { DASHBOARD_SIDE_NAV } from "../../../Utils/Constants";
import { Popover } from "@headlessui/react";
import { Fragment } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import logo from "../../../Images/logo.png";
import {
  commison_svg,
  logOut_svg,
  SVG_PENDING,
  COMPANY_SVG,
  TRANSPORTER_SVG,
} from "../../../Utils/SVGListing";

export default function ResponsiveNav() {
  let history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem('LoggedIn',false);
    localStorage.removeItem('token',null)
    history.push("/login");
  };

  return (
    <>
      <nav className="bg-white md:hidden fixed top-0 w-screen md:top-0  z-100">
        <div className="p-3 items-center flex flex-row justify-between  min-w-full px-4">
          <div>
            {/* <h2 className="text-2xl shrink  font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Kirti Roadways
            </h2> */}
            <p className=" shrink pt-3 pl-2 text-2xl font-bold  text-gray-900  sm:truncate">
              <Link to="/" target="_blank">
                {/* <span className="md:block hidden">
                            Kirti Roadlines
                          </span> */}

                <img src={logo} alt="Kirti Roadlines" width={90} height={50} />
              </Link>
            </p>
          </div>
          <Popover className="align-end">
            {({ open }) => (
              <>
                <Popover.Button
                  className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-end rounded-md bg-primary-kirtiroadways px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  {!open ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-full -translate-x-1/2 transform">
                    <div className="max-w-6  rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="bg-white  ">
                        {DASHBOARD_SIDE_NAV.map((item, index) => (
                          <span key={item.id}>
                            {item.title !== "BOOKING" && item.title  !== "TRANSPORTER" && (
                              <NavLink
                                // onChange={() =>{setMainScreen(Menu.main); mainScreenShow(mainScreen);console.log(mainScreen,"Menu.main")}}
                                to={item.route}
                                key={item.id}
                                className={`flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-2 hover:text-black hover:bg-primary-kirtiroadways-hover "
                                ${item.gap ? "mt-9" : "mt-1"} 
                                ${index === 0 && ""}
                                `}
                                style={(isActive) => ({
                                  backgroundColor: isActive ? "#f68e92" : "",
                                  color: isActive ? "black" : "",
                                })}

                                // activeClassName="text-white bg-red-500"
                                //   style={isActive => ({
                                //     color: isActive ? "green" : "blue",
                                //     backgroundColor: isActive ? "white" :"black"
                                //  })}
                                // className={({ isActive }) => (isActive ? 'active' : 'inactive')}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-6 w-6"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d={item.path}
                                  />
                                </svg>
                                <span
                                  className={`${
                                    !{ open } && "hidden"
                                  } origin-left duration-700`}
                                >
                                  {item.title}
                                </span>
                              </NavLink>
                             )}
                                {/* Booking */}
                              {item.title === "BOOKING" && (
                              <Menu
                                as="div"
                                key={item.id}
                                className="text-left"
                              >
                                <Menu.Button
                                  onClick={open === false}
                                  className={`flex rounded-md w-full font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4 hover:text-black hover:bg-primary-kirtiroadways-hover "
                                ${item.gap ? "mt-9" : "mt-2"} ${
                                    index === 0 && ""
                                  } `}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    {item.path}
                                  </svg>
                                  <span>{item.title}</span>
                                  <ChevronDownIcon
                                    className="ml-2 -mr-1 h-5 w-5 "
                                    aria-hidden="true"
                                  />
                                </Menu.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="pl-2">
                                    <NavLink
                                      to="/company"
                                      className={`flex rounded-md w-full font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                     ${item.gap ? "mt-9" : "mt-2"} ${
                                        index === 0 && ""
                                      } `}
                                      style={(isActive) => ({
                                        backgroundColor: isActive
                                          ? "#f68e92"
                                          : "",
                                        color: isActive ? "black" : "",
                                      })}
                                    >
                                      {COMPANY_SVG}
                                      <span
                                        className={`${
                                          !{ open } && "hidden"
                                        } origin-left duration-700 text-left `}
                                      >
                                       Company
                                      </span>
                                    </NavLink>
                                    <NavLink
                                      to="/pending-booking"
                                      className={`flex rounded-md w-full font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                     ${item.gap ? "mt-9" : "mt-2"} ${
                                        index === 0 && ""
                                      } `}
                                      style={(isActive) => ({
                                        backgroundColor: isActive
                                          ? "#f68e92"
                                          : "",
                                        color: isActive ? "black" : "",
                                      })}
                                    >
                                      {SVG_PENDING}
                                      <span
                                        className={`${
                                          !{ open } && "hidden"
                                        } origin-left duration-700 text-left `}
                                      >
                                       Booking Details
                                      </span>
                                    </NavLink>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                                {/* Commssion */}
                               {item.title === "TRANSPORTER" && (
                              <Menu
                                as="div"
                                key={item.id}
                                className="text-left"
                              >
                                <Menu.Button
                                  onClick={open === false}
                                  className={`flex rounded-md w-full font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4 hover:text-black hover:bg-primary-kirtiroadways-hover "
                                ${item.gap ? "mt-9" : "mt-2"} ${
                                    index === 0 && ""
                                  } `}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                  >
                                    {item.path}
                                  </svg>
                                  <span>{item.title}</span>
                                  <ChevronDownIcon
                                    className="ml-2 -mr-1 h-5 w-5 "
                                    aria-hidden="true"
                                  />
                                </Menu.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="pl-2">
                                  <NavLink
                                      to="/transporter"
                                      className={`flex rounded-md w-full font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                     ${item.gap ? "mt-9" : "mt-2"} ${
                                        index === 0 && ""
                                      } `}
                                      style={(isActive) => ({
                                        backgroundColor: isActive
                                          ? "#f68e92"
                                          : "",
                                        color: isActive ? "black" : "",
                                      })}
                                    >
                                      {TRANSPORTER_SVG}
                                      <span
                                        className={`${
                                          !{ open } && "hidden"
                                        } origin-left duration-700 text-left `}
                                      >
                                      Transporter
                                      </span>
                                    </NavLink>
                                    <NavLink
                                      to="/commission-entry"
                                      className={`flex rounded-md w-full font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-4  hover:text-black hover:bg-primary-kirtiroadways-hover "
                                     ${item.gap ? "mt-9" : "mt-2"} ${
                                        index === 0 && ""
                                      } `}
                                      style={(isActive) => ({
                                        backgroundColor: isActive
                                          ? "#f68e92"
                                          : "",
                                        color: isActive ? "black" : "",
                                      })}
                                    >
                                      {commison_svg}
                                      <span
                                        className={`${
                                          !{ open } && "hidden"
                                        } origin-left duration-700 text-left `}
                                      >
                                      Commission
                                      </span>
                                    </NavLink>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                          </span>
                        ))}

                        <Link
                          to="/login"
                          // onChange={() =>{setMainScreen(Menu.main); mainScreenShow(mainScreen);console.log(mainScreen,"Menu.main")}}
                          onClick={handleLogout}
                          className={`mt-1 flex rounded-md font-bold p-2 cursor-pointer text-primary-kirtiroadways text-sm items-center gap-x-2  hover:text-black hover:bg-primary-kirtiroadways-hover "
                           `}
                        >
                          {logOut_svg}
                          <span
                            className={`${
                              !{ open } && "hidden"
                            } origin-left duration-700`}
                          >
                            Logout
                          </span>
                        </Link>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
        <hr />
      </nav>
    </>
  );
}
