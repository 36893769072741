import { getBase64ImageFromURL } from "../../Components/Functionality/Helper";
import axisBarcode from "../../Images/qrcode.jpg";
export const billingPaymentA5 = async (
  invoice,
  WE_USING_PAYPHONE,
  bankBranch,
  note
) => {
  const Images = {
    axisbarcode: await getBase64ImageFromURL(axisBarcode),
  };

  const buildOptionalColumns = (data, columns) => {
    // console.log(data);
    var dataHeader = [];
    columns.forEach(function (column, i) {
      // console.log(column,"column")
      if (data[column] !== 0 && data[column] !== null) {
        dataHeader.push({
          margin: [0, 3, 0, 0],
          border: [true, true, true, true],
          alignment: "center",
          text: data[column],
          color: "#000",
          fontSize: 8,
          bold: true,
        });
      }
    });

    return dataHeader;
  };

  const buildOptionalColumnsWidths = (data, columns) => {
    // console.log(data,"data")
    var dataHeader = [];
    columns.forEach(function (column, i) {

      if (data[column] !== 0 && data[column] !== null) {
        dataHeader.push(25);
      }
    });

    return dataHeader;
  };

  // console.log(note)
  const billingPaymentA5 = [
    {
      margin:[0,10,0,0],
      stack: [
        {
          margin: [0, 0, 0, 4],
          columns: [
            note
              ? {
                  stack: [
                    {
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 180,
                          h: 28,
                          r: 5,
                          lineColor: "black",
                        },
                      ],
                    },
                    {
                      margin: [0, 0, 10, 0],
                      relativePosition: { x: 8, y: -26 },
                      columns: [
                        {
                          width: 30,
                          text: "NOTE:",
                          fontSize: 8,
                          bold: true,
                        },
                        {
                          width: 140,
                          text: `${note}`,
                          fontSize: 6.8,
                          bold: true,
                        },
                      ],
                    },
                  ],
                }
              : {
                  text: "",
                },
            {
              margin: [0,4,0,0],
              width: "auto",
              table: {
                heights: 15,
                widths: [
                  "*",
                  25,
                  25,
                  ...buildOptionalColumnsWidths(invoice, [
                    "transporterHamali",
                    "transporterDetention",
                    "transporterExtraCharge",
                  ]),
                ],
                body: [
                  [
                    {
                      text: "",
                      border: [false, false, false, false],
                    },
                    {
                      margin: [0, 3, 0, 0],
                      border: [true, true, true, true],
                      alignment: "center",
                      text: "Total",
                      color: "#000",
                      fontSize: 8,
                      bold: true,
                    },
                    {
                      margin: [0, 3, 0, 0],
                      border: [true, true, true, true],
                      alignment: "center",
                      text: invoice?.transporterBalance,
                      color: "#000",
                      fontSize: 8,
                      bold: true,
                    },
                    ...buildOptionalColumns(invoice, [
                      "transporterHamali",
                      "transporterDetention",
                      "transporterExtraCharge",
                    ]),
                  ],
                ],
              },
            },
          ],
        },
        {
          table: {
            margin: [0, 3, 0, 0],
            widths: ["65%", "*"],
            headerRows: 1,
            fontSize: 9,
            bold: true,
            heights: [30],
            body: [
              [
                {
                  margin: [0, 3, 0, 0],
                  border: [false, true, false, false],
                  stack: [
                    {
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 220,
                          h: 25,
                          r: 5,
                          lineColor: "black",
                        },
                      ],
                    },
                    {
                      relativePosition: { x: 70, y: -22 },
                      text: "OUR BANK DETAILS",
                      color: "#000",
                      fontSize: 8,
                      bold: true,
                    },
                    {
                      relativePosition: { x: 7, y: -12 },
                      text: "(YOU MAY ALSO PAY USING ANY UPI APP BY SCANNING A BARCODE)",
                      color: "#000",
                      fontSize: 6,
                    },
                  ],
                },
                {
                  alignment: "right",
                  margin: [0, 3, 0, 0],
                  border: [false, true, false, false],
                  stack: [
                    {
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 115,
                          h: 25,
                          r: 5,
                          lineColor: "black",
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      relativePosition: { x: 15, y: -16 },
                      text: `GRAND TOTAL: ${" "}`,
                      color: "#000",
                      fontSize: 8,
                      bold: true,
                    },
                    {
                      alignment: "left",
                      relativePosition: { x: 80, y: -16 },
                      text: ` ₹ ${invoice?.transporterTotal}`,
                      color: "#000",
                      fontSize: 8,
                      bold: true,
                      font: "NotoSansDevanagari",
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          columns: [
            {
              width: 220,
              stack: [
                {
                  columns: [
                    {
                      image: Images.axisbarcode,
                      width: 50,
                      height: 50,
                      margin: [0, 5, 2, 0],
                    },
                    {
                      margin: [2, 2, 0, 0],
                      stack: [
                        {
                          margin: [2, 3, 0, 0],
                          text: `BANK NAME: ${bankBranch.bank}`,
                          fontSize: 7,
                        },
                        {
                          margin: [2, 3, 0, 0],
                          text: `BRANCH: ${bankBranch.branch}`,
                          fontSize: 7,
                        },
                        {
                          margin: [2, 3, 0, 0],
                          text: `A/C. NO: ${bankBranch.accNo}`,
                          fontSize: 7,
                        },
                        {
                          margin: [2, 3, 0, 0],
                          text: `IFSC CODE: ${bankBranch.ifsc}`,
                          fontSize: 7,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          margin: [0, 5, 0, 0],
          stack: [
            {
              text: WE_USING_PAYPHONE.toUpperCase(),
              fontSize: 8,
            },
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 370, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
            {
              characterSpacing: 0.2,
              margin: [0, 3, 0, 0],
              text: "AMOUNT CHARGEABLE (IN WORDS)",
              fontSize: 9,
            },
            {
              characterSpacing: 0.6,
              margin: [0, 3, 0, 0],
              text: `${invoice?.totalInWords} ONLY`,
              fontSize: 11,
            },
            {
              characterSpacing: 0.6,
              margin: [0, 3, 0, 0],
              text: "as per MSME law you kindly release payment in 45 days".toUpperCase(),
              fontSize: 8.3,
              bold: true,
            },
            {
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 2,
                      w: 370, // width of the rectangle
                      h: 1, // height of the rectangle
                      color: "black",
                    },
                  ],
                },
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 1,
                      w: 370, // width of the rectangle
                      h: 1, // height of the rectangle
                      color: "black",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      unbreakable: true,
    },
  ];
  return billingPaymentA5;
};

export const billingPayment = async (
  locate,
  WE_USING_PAYPHONE,
  bankBranch,
  freight,
  noBalance
) => {
  const Images = {
    axisbarcode: await getBase64ImageFromURL(axisBarcode),
  };
  const billingDetails = [
    {
      stack: [
        {
          table: {
            margin: [0, 7, 0, 0],
            widths: ["100%"],
            headerRows: 1,
            fontSize: 9,
            bold: true,
            body: [
              [
                {
                  text: "",
                  border: [false, true, false, false],
                },
              ],
            ],
          },
        },
        {
          columns: [
            {
              width: "70%",
              stack: [
                {
                  table: {
                    margin: [0, 0, 0, 0],
                    widths: ["100%"],
                    headerRows: 1,
                    fontSize: 9,
                    bold: true,
                    heights: [30],
                    body: [
                      [
                        {
                          margin: [0, 0, 0, 0],
                          border: [false, false, false, false],
                          stack: [
                            {
                              canvas: [
                                {
                                  type: "rect",
                                  x: 0,
                                  y: 0,
                                  w: 250,
                                  h: 30,
                                  r: 10,
                                  lineColor: "black",
                                },
                              ],
                            },
                            {
                              absolutePosition: { x: 80, y: 12 },
                              text: "OUR BANK DETAILS",
                              color: "#000",
                              fontSize: 11,
                              bold: true,
                            },
                            {
                              absolutePosition: { x: 18, y: 27 },
                              text: "(YOU MAY ALSO PAY USING ANY UPI APP BY SCANNING A BARCODE)",
                              color: "#000",
                              fontSize: 7,
                            },
                          ],
                        },
                      ],
                    ],
                  },
                },
                {
                  columns: [
                    {
                      image: Images.axisbarcode,
                      width: 60,
                      margin: [0, 5, 2, 0],
                    },
                    {
                      margin: [2, 2, 0, 0],
                      stack: [
                        {
                          margin: [2, 5, 0, 0],
                          text: `BANK NAME: ${bankBranch?.bank}`,
                          fontSize: 8,
                        },
                        {
                          margin: [2, 5, 0, 0],
                          text: `BRANCH: ${bankBranch?.branch}`,
                          fontSize: 8,
                        },
                        {
                          margin: [2, 5, 0, 0],
                          text: `A/C. NO: ${bankBranch?.accNo}`,
                          fontSize: 8,
                        },
                        {
                          margin: [2, 5, 0, 0],
                          text: `IFSC CODE: ${bankBranch?.ifsc}`,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              width: "30%",
              alignment: "right",
              stack: [
                !noBalance
                  ? {
                      border: [false, true, false, false],
                      columns: [
                        {
                          text: `BALANCE :`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          alignment: "right",
                        },
                        {
                          columns: [
                            {
                              text: `₹ ${
                                locate?.totalFreight ? locate?.totalFreight : 0
                              }`,
                              color: "#000",
                              bold: true,
                              fontSize: 10,
                              margin: [0, 5, 10, 0],
                              alignment: "right",
                              font: "NotoSansDevanagari",
                            },
                          ],
                        },
                      ],
                    }
                  : {
                      // margin: [0, 5, 0, 0],
                      text: "",
                    },
                locate?.totalFreight && {
                  border: [false, true, false, false],
                  columns: [
                    {
                      text: `FREIGHT :`,
                      color: "#000",
                      bold: true,
                      fontSize: 9,
                      margin: [0, 5, 0, 0],
                      alignment: "right",
                    },
                    {
                      columns: [
                        {
                          text: `₹ ${
                            locate?.totalFreight ? locate?.totalFreight : 0
                          }`,
                          color: "#000",
                          bold: true,
                          fontSize: 10,
                          margin: [0, 5, 10, 0],
                          alignment: "right",
                          font: "NotoSansDevanagari",
                        },
                      ],
                    },
                  ],
                },
                locate?.totalHamali !== 0
                  ? {
                      border: [false, true, false, false],
                      columns: [
                        {
                          text: `HAMALI :`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          alignment: "right",
                        },
                        {
                          columns: [
                            {
                              text: `₹ ${
                                locate?.totalHamali ? locate?.totalHamali : 0
                              }`,
                              color: "#000",
                              bold: true,
                              fontSize: 10,
                              margin: [0, 5, 10, 0],
                              alignment: "right",
                              font: "NotoSansDevanagari",
                            },
                          ],
                        },
                      ],
                    }
                  : {
                      margin: [0, 5, 0, 0],
                      text: "",
                    },
                locate?.totalDetention !== 0
                  ? {
                      border: [false, true, false, false],
                      columns: [
                        {
                          text: `DETENTION :`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          alignment: "right",
                        },
                        {
                          columns: [
                            {
                              text: `₹ ${
                                locate?.totalDetention
                                  ? locate?.totalDetention
                                  : 0
                              }`,
                              color: "#000",
                              bold: true,
                              fontSize: 10,
                              margin: [0, 5, 10, 0],
                              alignment: "right",
                              font: "NotoSansDevanagari",
                            },
                          ],
                        },
                      ],
                    }
                  : {
                      margin: [0, 5, 0, 0],
                      text: "",
                    },
                locate?.totalExtraCharge !== 0
                  ? {
                      border: [false, true, false, false],
                      columns: [
                        {
                          text: `EXTRA CHARGE :`,
                          color: "#000",
                          bold: true,
                          fontSize: 9,
                          margin: [0, 5, 0, 0],
                          alignment: "right",
                        },
                        {
                          columns: [
                            {
                              text: `₹ ${
                                locate?.totalExtraCharge
                                  ? locate?.totalExtraCharge
                                  : 0
                              }`,
                              color: "#000",
                              bold: true,
                              fontSize: 10,
                              margin: [0, 5, 10, 0],
                              alignment: "right",
                              font: "NotoSansDevanagari",
                            },
                          ],
                        },
                      ],
                    }
                  : {
                      text: "",
                      margin: [0, 5, 0, 0],
                    },
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 160,
                      h: 25,
                      r: 5,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  alignment: "center",
                  relativePosition: { x: 0, y: -25 },
                  columns: [
                    {
                      width: "50%",
                      margin: [0, 8, 0, 0],
                      text: "GRAND TOTAL",
                      color: "#000",
                      fontSize: 9,
                      bold: true,
                    },
                    {
                      width: 10,
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 2,
                          h: 25,
                          color: "black",
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      margin: [0, 8, 10, 0],
                      width: "*",
                      text: `₹ ${locate?.grandTotal}`,
                      color: "#000",
                      fontSize: 10,
                      font: "NotoSansDevanagari",
                      bold: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          margin: [0, 10, 0, 0],
          stack: [
            {
              text: WE_USING_PAYPHONE.toUpperCase(),
              fontSize: 9,
            },
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 525, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
            {
              characterSpacing: 0.5,
              margin: [0, 7, 0, 0],
              text: "AMOUNT CHARGEABLE (IN WORDS)",
              fontSize: 11,
            },
            {
              characterSpacing: 0.8,
              margin: [0, 6, 0, 0],
              text: `${locate?.grandTotalInWords} ONLY`,
              fontSize: 13,
            },
            {
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 2,
                      w: 525, // width of the rectangle
                      h: 1, // height of the rectangle
                      color: "black",
                    },
                  ],
                },
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 1,
                      w: 525, // width of the rectangle
                      h: 1, // height of the rectangle
                      color: "black",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      unbreakable: true,
    },
  ];
  return billingDetails;
};
