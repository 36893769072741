import { Button } from "@aws-amplify/ui-react";
import { Dialog } from "@headlessui/react";
import React, { useState } from "react";
import { triggerBase64Download } from "react-base64-downloader";
import "react-datepicker/dist/react-datepicker.css";
import ImageUploading from "react-images-uploading";
import Select from "react-select";
import ReactiveButton from "../../Components/UI/Button/ReactiveButton";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import axiosInstance from "../../Utils/API";
import { ADD_VEHICLE, ADD_VEHICLE_OWNER } from "../../Utils/ApiGlobal";
import { CommissionOptions } from "../../Utils/Constants";

const Input = (props) => (
  <input
    className="peer uppercase w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function OwnerAndVehicleNoCreate({
  open,
  setOpen,
  handleSubmit,
  defaultVehicleNo,
  setDefaultVehicleNo,
}) {
  const [commission, setCommission] = useState(false);
  const [commissionType, setCommissionType] = useState();
  const [loader, setLoader] = useState(false);
  const [images, setImages] = useState([]);
  const [delteImages, setDeleteImages] = useState([]);

  const SelectHandler = (e) => {
    setCommissionType(e.value);
    if (e.value === "Manual") {
      setCommission(true);
    } else {
      setCommission(false);
    }
  };

  // Image On changes
  const onChangeImages = (imageList, addUpdateIndex, name) => {
    setImages(imageList);

    if (addUpdateIndex) {
      let modify = imageList;
      var newdata = modify.pop();
      Object.assign(newdata, { name: "" });
      imageList.push(newdata);
    }
  };

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };
  //   Form Submit click event
  const submitForms = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setLoader(true);
    var Image_files = [];
    if (images !== undefined) {
      for (var i = 0; i < images.length; i++) {
        var imageArray = {};
        // imageArray = images[i].data_url;
        if (!images[i].id) {
          Object.assign(imageArray, {
            ImageName: images[i].name,
            ImageUrl: images[i].data_url,
          });
          // console.log(imageArray, "obj");
          // imageArray = images[i].name;
          Image_files.push(imageArray);
        }
      }
    }
    // console.log(Image_files, "delteImages");
    var obj = formValueConvertor(getFormJSON(ev.target));
    localStorage.setItem("objData", JSON.stringify({ ...obj }));
    try {
      let VEHICLE_OWNER_DATA = {
        Name: obj?.Owner_Name?.toUpperCase(),
        PanNo: obj?.Pan_No?.trim() ? obj?.Pan_No?.toUpperCase() : "NA",
        ContactNo: obj?.Contact_No,
        CreatedBy: localStorage.getItem("userId"),
        CommissionType: commissionType,
        Amount:
          commissionType === "Manual" ? parseInt(obj.manualCommission) : 0,
        Images: Image_files,
      };

      const OwnerData = await axiosInstance.post(
        ADD_VEHICLE_OWNER,
        VEHICLE_OWNER_DATA
      );

      if (OwnerData?.data?.data?.id) {
        const owner_id = OwnerData.data.data.id;
        const POST_VEHICLE_DATA = {
          OwnerId: owner_id,
          VehicleNo: obj?.Vehicle_No?.toUpperCase(),
        };

        const VehicleData = await axiosInstance.post(
          ADD_VEHICLE,
          POST_VEHICLE_DATA
        );

        if (VehicleData?.data?.data?.id) {
          setDefaultVehicleNo("");
          handleSubmit({
            vehicleNo: POST_VEHICLE_DATA.VehicleNo,
            vehicleId: VehicleData.data.data.id,
            ownerId: owner_id,
            ownerName: VEHICLE_OWNER_DATA.Name,
            ownerCommissionType: VEHICLE_OWNER_DATA.CommissionType,
            ownerCommissionAmount: VEHICLE_OWNER_DATA.Amount,
          });
          setOpen(false);
          setLoader(false);
          return;
        }
      }
    } catch (error) {
      console.error("Error in submitting forms:", error);
      setLoader(false);
    }
  };
  return (
    <Dialog
      as="div"
      open={open}
      static={open}
      onClose={setOpen}
      className="relative  z-100"
    >
      <div className=" fixed inset-0 z-10 overflow-y-auto">
        <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="border-2 border-primary-kirtiroadways bg-white rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-2/3">
            <div className="p-6 flex flex-col gap-5 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md h-full ">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Add Vehicle Details
              </Dialog.Title>
              <form onSubmit={submitForms} className="flex flex-col gap-4">
                <div className="h-full">
                  <div className=" text-left flex flex-col gap-3  h-full">
                    <div className="flex md:flex-row flex-col gap-3">
                      {/* Owner Name */}
                      <div className=" flex flex-col  text-left md:w-1/2 h-full">
                        <Label label="Owner Name" isRequired="true" />
                        <Input name="Owner_Name" required />
                      </div>
                      <div className="w-1/2">
                        <Label label="Commission Type" />
                        <Select
                          onChange={SelectHandler}
                          options={CommissionOptions}
                          placeholder="Select Commission Type"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 5,
                            colors: {
                              ...theme.colors,
                              primary25: "#f68e92",
                              primary: "#ed1c24",
                            },
                          })}
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 ">
                      {commission && (
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Manual Commission Amount" />
                          <Input
                            type="text"
                            name="manualCommission"
                            // required
                            autoComplete="off"
                          />
                        </div>
                      )}
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Vehicle No" isRequired="true" />
                        <Input
                          type="text"
                          name="Vehicle_No"
                          defaultValue={defaultVehicleNo}
                          required
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex ">
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChangeImages}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper space-x-2    ">
                        <ButtonWithBG
                          className="px-4 py-2  border border-transparent text-sm font-medium  rounded-md focus:outline-none focus:ring-2 focus:outline-kirtiroadways-300 focus:ring-offset-2 text-center text-white bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover "
                          style={isDragging ? { color: "red" } : undefined}
                          handleClick={onImageUpload}
                          {...dragProps}
                        >
                          Click or Drop Image
                        </ButtonWithBG>
                        &nbsp;
                        {imageList.length > 0 && (
                          <ButtonWithBG
                            theme="secondary"
                            handleClick={() => {
                              let imageDeleteAll = [];
                              // if(isKeyPresent){
                              imageList.map((image, index) => {
                                if (image.id) {
                                  return imageDeleteAll.push(image.id);
                                }
                                return null;
                              });
                              // console.log(imageDeleteAll);
                              setDeleteImages(imageDeleteAll);
                              // }
                              onImageRemoveAll();
                            }}
                          >
                            Remove all images
                          </ButtonWithBG>
                        )}
                        <div className="flex flex-row  justify-center w-full space-x-7 flex-wrap m-5">
                          {imageList.map((image, index, name) => (
                            <div
                              key={index}
                              className="image-item my-5 flex flex-row "
                            >
                              <div className=" flex flex-col  items-center  space-y-2">
                                <div className="border-2 border-primary-kirtiroadways-hover p-1">
                                  <img
                                    src={image["data_url"]}
                                    alt=""
                                    style={{
                                      height: "200px",
                                      maxHeight: "200px",
                                      width: "250px",
                                      maxWidth: "250px",
                                    }}
                                  />
                                  {/* <p className=" mt-2  border-t border-primary-kirtiroadways">{ image["file"].name}</p> */}
                                </div>
                                <div>
                                  <Input
                                    defaultValue={image.name}
                                    name={name}
                                    onChange={(e) => {
                                      // images.map((l) => {
                                      Object.assign(imageList[index], {
                                        name: e.target.value,
                                      });
                                      // console.log(imageList);
                                      // });
                                    }}
                                  />
                                </div>
                                <div className="image-item__btn-wrapper space-x-2">
                                  {/* {location?.state?.label === "Add" && (
                                <ButtonWithBG
                                  handleClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </ButtonWithBG>
                              )} */}
                                  <ReactiveButton
                                    type="button"
                                    id="Download"
                                    text="Download"
                                    onClick={() => {
                                      // console.log(image);
                                      triggerBase64Download(
                                        image.data_url,
                                        image.name
                                      );
                                    }}
                                  >
                                    download
                                  </ReactiveButton>
                                  <ButtonWithBG
                                    theme="danger"
                                    handleClick={() => {
                                      onImageRemove(index);
                                      setDeleteImages((oldArray) => [
                                        ...oldArray,
                                        image.id,
                                      ]);
                                      // setDeleteImages(image.id)
                                    }}
                                  >
                                    Remove
                                  </ButtonWithBG>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="items-center">
                  <Button
                    isLoading={loader}
                    color={"white"}
                    colorTheme="error"
                    variation="primary"
                    className="flex items-center justify-center border h-10 w-28 !capitalize rounded-md !bg-kirtiroadways-900 !text-sm !font-semibold text-white "
                    loadingText="Saving..."
                    type="submit"
                  >
                    Create
                  </Button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
