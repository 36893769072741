import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { setting_svg } from "../../Utils/SVGListing";
import ThirdParty from "../../Components/DataTables/ThirdParty";

export default function PartiesList() {
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  // To redirect to add page
  const redirectToAdd = () => {
    history.push({
      pathname: "/party/add-edit-parties",
      state: { label: "Add" },
    });
  };
  return (
    <DashBoardLayout pageTitle="Third Party">
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Pending Booking" /> */}
        {/* Search and setting */}
        <div className="flex md:flex-row flex-col md:justify-between  gap-2">
          <div>
            <SearchField
              setSearchValue={setSearchValue}
              placeholder="Search BY Party Name"
              handleChange={setSearchValue}
              value={searchValue}
            />
          </div>
          <div className="flex flex-row space-x-2 items-start  md:order-last	order-first">
            <Button
              svg={setting_svg}
              onClick={() => history.push("/profile")}
            ></Button>
            <ButtonWithBG
              handleClick={redirectToAdd}
              theme="primary"
              label="Add Party"
            />
          </div>
        </div>
      </div>
      <ThirdParty searchValue={searchValue} />
    </DashBoardLayout>
  );
}
