import React, { useEffect, useRef } from "react";
// import { useLocation } from "react-router-dom";
import {
  InvoicSubject,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  TOTALPAYMENTPWNER,
  LESSCOMMISSION,
  TOTALPAIDDRIVER,
  // GPAYCONTENT,
  // KIRTIROADLINES,
  // PaymentbypayeeCchequeDrafs,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../../Utils/Constants";
import ButtonWithBG from "../../../Components/UI/ButtonMain";
import moment from "moment";
import PrintingHeader from "../../../Components/Functionality/KirtiRoadline/PrintingHeader";
import {
  Tablebg,
  TabletheadRedLastStyle,
  TabletheadRedStyle,
  TabletheadStyle,
} from "../../../Utils/TableStyling";
import sign from "../../../Images/sign.png";
import PDFHeader from "../../../Components/Functionality/KirtiRoadline/PDFHeader";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "../../../Utils/PTSerif-Regular";
import { robotoBlack } from "../../../Utils/Roboto-Black";
import { PTSerifBold } from "../../../Utils/PTSerif-Bold";
import { background } from "../../../Layouts/pdfLayout/BackgroundLayout";
import { WE_USING_PAYPHONE } from "../../../Utils/Constants";
import { NotoSansDevanagariBold } from "../../../Utils/NotoSansDevanagari-Bold";
import { NotoSansDevanagariRegular } from "../../../Utils/NotoSansDevanagari-Regular";
import { BillingHeader } from "../../../Layouts/pdfLayout/BillingHeaderLayout";
import { BillingFooter } from "../../../Layouts/pdfLayout/BillingFooterLayout";
import {
  BillingTotal,
  VehicleBillingLayout,
} from "../../../Layouts/pdfLayout/VehicleBillingLayout";
const pdfMakeX = require("pdfmake/build/pdfmake.js");
const pdfFontsX = require("pdfmake-unicode/dist/pdfmake-unicode.js");
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
//importing the fonts whichever present inside vfs_fonts file
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["PTSerif-Regular.ttf"] = font;
window.pdfMake.vfs["PTSerif-Bold.ttf"] = PTSerifBold;
window.pdfMake.vfs["Roboto-Black.ttf"] = robotoBlack;
window.pdfMake.vfs["NotoSansDevanagari-Bold.ttf"] = NotoSansDevanagariBold;
window.pdfMake.vfs["NotoSansDevanagari-Regular.ttf"] =
  NotoSansDevanagariRegular;

const VehicleInvoiceDownloadView = (props) => {
  // const location = useLocation();
  let componentRef = useRef();
  const pageStyle = `@page {
    size: A4;
    margin: 10pt;
  }
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
  }`;

  useEffect(() => {
    // console.log(props, "print");
  }, [props]);

  // Download pdf
  const sanitizeFilename = (filename) => {
    // Replace special characters with underscores
    // Download pdf
    let checkNull = filename.includes("null");
    if (checkNull) {
      return filename.replace(/[^\w\s]/gi, "_")?.replace("null", "");
    } else if (!checkNull) {
      return filename.replace(/[^\w\s]/gi, "_");
    }
  };
  async function handleDownloadPdf(action) {
    props.setIsLoading(true);
    //Specifying the fonts which we are going to use in our PDF
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Black.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      PTSerifRegular: {
        normal: "PTSerif-Regular.ttf",
        bold: "PTSerif-Bold.ttf",
        italics: "PTSerif-Regular.ttf",
        bolditalics: "PTSerif-Regular.ttf",
      },
      NotoSansDevanagari: {
        normal: "NotoSansDevanagari-Regular.ttf",
        bold: "NotoSansDevanagari-Bold.ttf",
        italics: "NotoSansDevanagari-Regular.ttf",
        bolditalics: "NotoSansDevanagari-Regular.ttf",
      },
    };

    const invoicedata = props.commissionData;
    const profileData = props.profileData;
    const secondaryPhoneNo = props.secondaryPhoneNo;

    const contentRender = async () => {
      try {
        const BillingHeaderContent = await BillingHeader(
          profileData,
          secondaryPhoneNo,
          "billing",
          !props.noHeader
        );

        const BillingContent = await VehicleBillingLayout(
          invoicedata,
          WE_USING_PAYPHONE,
          profileData
        );

        const BillingTotalContent = await BillingTotal(invoicedata);

        const BillingFooterContent = await BillingFooter(
          "billing",
          false,
          true
        );

        return [
          BillingHeaderContent,
          BillingContent,
          {
            stack: [BillingTotalContent, BillingFooterContent],
            unbreakable: true,
          },
        ];
      } catch (error) {
        // Handle errors if necessary
        console.error("Error in contentRender:", error);
        throw error; // Re-throw the error to propagate it
      }
    };

    const sanitizedFilename = sanitizeFilename(
      props?.data?.invoiceNo || props?.data?.bookingInvoiceNo
        ? props?.data?.invoiceNo
          ? props?.data?.invoiceNo
          : props?.data?.bookingInvoiceNo
          ? props?.data?.bookingInvoiceNo
          : "--"
        : props.invoiceNo
        ? props.invoiceNo
        : "--"
        );
        const fileName = `${sanitizedFilename}.pdf`;

    const pdfDocDefinition = {
      pageMargins: [35, 35, 35, 35],
      content: await contentRender().then((result) => result),
      info:{
        title:fileName
      },
      styles: {
        table: {
          margin: [0, 0, 0, 0], // Adjust the top margin of the entire table
        },
        tableHeader: {
          fontSize: 9,
          alignment: "start", // 'start' aligns text to the start of the cell
          bold: true,
          fillColor: "#fff",
          color: "#000",
          margin: [1, 0, 0, 0], // Adjust the margin for each header cell
          borderBottom: "1px solid #28328c",
        },
        cellStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
        },
      },
      background: props.showBorder ? background : () => {},
    };


    const callbackFunction = function () {
      // Here implement function for hide waiting loader
      props.setIsLoading(false);
    };
    const handlePrint = () => {
      pdfDocGenerator.print();
      props.setIsLoading(false);
    };

    const newLocal = pdfMake.createPdf(pdfDocDefinition);
    const pdfDocGenerator = newLocal;
    action === "download"
      ? pdfDocGenerator.download(fileName, callbackFunction)
      : handlePrint();
  }
  return (
    <>
      {props?.commissionData && (
        <div className="hidden">
          <div ref={componentRef}>
            <style>{pageStyle}</style>
            <div
              className={`pageStyle  block ${
                props.showBorder &&
                ` border-double  border-4 ${
                  props.blackWhite ? "border-black" : "border-red-200"
                } `
              } m-5  align-top text-center bg-center bg-no-repeat  bg-[length:65%]`}
              // style={{
              //   backgroundImage: "url(" + logo_oposity + ")",
              // }}
            >
              {/* <PrintingHeader
                data={props?.data}
                profileData={props?.profileData}
                secondaryPhoneNo={props?.secondaryPhoneNo}
                companyName={props?.commissionData?.ownerName}
                companyAdd={props.commissionData?.address}
                border={props.showBorder}
                blackWhite={props.blackWhite}
                companyGst={props?.commissionData?.panNo}
                invoiceNo={props.invoiceNo}
                mobilePrint={props.mobilePrint}
              /> */}
              {props.mobilePrint ? (
                <PDFHeader
                  data={props?.data}
                  profileData={props?.profileData}
                  secondaryPhoneNo={props?.secondaryPhoneNo}
                  companyName={props?.commissionData?.ownerName}
                  companyAdd={props.commissionData?.address}
                  border={props.showBorder}
                  blackWhite={props.blackWhite}
                  companyGst={props?.commissionData?.panNo}
                  invoiceNo={props.invoiceNo}
                  mobilePrint={props.mobilePrint}
                />
              ) : (
                <PrintingHeader
                  data={props?.data}
                  profileData={props?.profileData}
                  secondaryPhoneNo={props?.secondaryPhoneNo}
                  companyName={props?.commissionData?.ownerName}
                  companyAdd={props.commissionData?.address}
                  border={props.showBorder}
                  blackWhite={props.blackWhite}
                  companyGst={props?.commissionData?.panNo}
                  invoiceNo={props.invoiceNo}
                />
              )}
              {/* Table start*/}
              <div
                style={{ minHeight: "400px" }}
                className="p-2 w-full border-t-0 border-neutral-900"
              >
                <table className=" border-spacing-y-2 border-separate w-full border-neutral-900 text-xs font-sans">
                  <thead style={{ maxHeight: "5px" }} className="mb-2">
                    <tr className=" border-neutral-900 text-white ">
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Loading
                        <br />
                        Date
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Truck
                        <br />
                        No.
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Freight
                      </th>
                      <th
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Advance
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Balance
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Hamali
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Detention
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadRedStyle(props.blackWhite)}  ${
                          props.mobilePrint && "pb-5"
                        } `}
                      >
                        Extra
                        <br />
                        Charge
                      </th>
                      <th
                        style={{ maxHeight: "5px" }}
                        className={`${TabletheadRedLastStyle(
                          props.blackWhite
                        )}  ${props.mobilePrint && "pb-5"} `}
                      >
                        Commission
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className=" align-top font-bold text-center "
                    // style={{
                    //   backgroundImage: "url(" + logo_oposity + ")",
                    // }}
                  >
                    {props?.commissionData?.details.map((data, i) => (
                      <tr height="20px" className="border-neutral-900 " key={i}>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 `}
                        >
                          <h1 className="mb-4">
                            {moment(data?.loadingDate).format("DD-MM-YYYY")}
                          </h1>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-32 `}
                        >
                          <h1 className="mb-4">
                            {data?.truckNo?.toUpperCase()}
                          </h1>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 `}
                        >
                          <h1 className="ml-2  text-center mb-4">
                            {data?.freight ? data?.freight : 0}
                          </h1>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 `}
                        >
                          <h1 className=" text-center mb-4">
                            {data?.advance ? data?.advance : 0}
                          </h1>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 `}
                        >
                          <h1 className=" text-center mb-4">
                            {data?.balance ? data?.balance : 0}
                          </h1>
                        </td>
                        {data?.hamali && (
                          <td
                            height="20px"
                            className={` ${
                              props.blackWhite
                                ? "border-black border-r"
                                : "border-r-2 border-black-300"
                            }   items-center  w-28 `}
                          >
                            <h1 className=" text-center mb-4">
                              {data?.hamali ? data?.hamali : 0}
                            </h1>
                          </td>
                        )}
                        {data?.detention && (
                          <td
                            height="20px"
                            className={` ${
                              props.blackWhite
                                ? "border-black border-r"
                                : "border-r-2 border-black-300"
                            }   items-center  w-28 mb-4 `}
                          >
                            <h1 className=" text-center">
                              {data?.detention ? data?.detention : 0}
                            </h1>
                          </td>
                        )}

                        {data?.extraCharge && (
                          <td
                            height="20px"
                            className={` ${
                              props.blackWhite
                                ? "border-black border-r"
                                : "border-r-2 border-black-300"
                            }   items-center  w-28 mb-4 `}
                          >
                            <h1 className=" text-center">
                              {data?.extraCharge ? data?.extraCharge : 0}
                            </h1>
                          </td>
                        )}

                        {data?.commission && (
                          <td height="20px" className=" w-28 mr-2">
                            <h1 className=" text-center mb-4">
                              {data?.commission ? data?.commission : 0}
                            </h1>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <table
                className={` ${
                  props.blackWhite
                    ? "border-black border-t"
                    : "border-primary-kirtiroadways border-t-2"
                } w-full font-bold   font-sans`}
              >
                <thead style={{ maxHeight: "5px" }}>
                  <tr height="20px" className="border-neutral-900 ">
                    <td height="20px" style={{ width: "50%" }}>
                      <div className="font-semibold text-left text-xs pl-2">
                        <div>
                          {TOTALPAYMENTPWNER} :{" "}
                          {props?.commissionData?.totalOwnerPayment
                            ? props?.commissionData?.totalOwnerPayment
                            : 0}
                        </div>
                        <div>
                          {LESSCOMMISSION} :{" "}
                          {props?.commissionData.totalCommission
                            ? props?.commissionData.totalCommission
                            : 0}
                        </div>
                        <div>
                          {TOTALPAIDDRIVER} :{" "}
                          {props?.commissionData.totalPaidToOwner
                            ? props?.commissionData.totalPaidToOwner
                            : 0}
                        </div>
                      </div>
                    </td>
                    <td height="20px" className="w-28">
                      <h1
                        className={`font-bold  text-center  ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white"
                            : "border-x border-black"
                        } ${props.mobilePrint && "pb-5"} py-2 `}
                      >
                        {" "}
                        Total
                      </h1>
                    </td>
                    <td height="20px" className="w-28">
                      <h1
                        className={`font-bold  text-center  ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white border-x border-white"
                            : "border-x border-black"
                        }  ${props.mobilePrint && "pb-5"}  py-2 `}
                      >
                        {" "}
                        {props?.commissionData?.totalBalance
                          ? props?.commissionData?.totalBalance
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-28">
                      <h1
                        className={`font-bold  text-center  ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white"
                            : "border-x border-black"
                        }  ${props.mobilePrint && "pb-5"}  py-2 `}
                      >
                        {" "}
                        {props?.commissionData?.totalHamali
                          ? props?.commissionData?.totalHamali
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-28">
                      <h1
                        className={`font-bold  text-center  ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white"
                            : "border-x border-black"
                        }  ${props.mobilePrint && "pb-5"}  py-2 `}
                      >
                        {" "}
                        {props?.commissionData?.totalDetention
                          ? props?.commissionData?.totalDetention
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-28">
                      <h1
                        className={`font-bold  text-center  ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white"
                            : "border-x border-black"
                        }  ${props.mobilePrint && "pb-5"}  py-2 `}
                      >
                        {" "}
                        {props?.commissionData?.totalExtraCharge
                          ? props?.commissionData?.totalExtraCharge
                          : 0}
                      </h1>
                    </td>
                    <td height="20px" className="w-28">
                      <h1
                        className={`font-bold  text-center  ${
                          !props.blackWhite
                            ? "bg-primary-kirtiroadways text-white border-l border-white"
                            : "border-l border-black"
                        }  ${props.mobilePrint && "pb-5"}  py-2 `}
                      >
                        {" "}
                        {props?.commissionData?.totalCommission
                          ? props?.commissionData?.totalCommission
                          : 0}
                      </h1>
                    </td>
                  </tr>
                </thead>
              </table>

              {/* Extra Information */}
              <div
                className={` ${
                  props.blackWhite
                    ? "border-black border-t"
                    : "border-primary-kirtiroadways border-t-2"
                } flex flex-col justify-start items-start ml-2 font-sans mt-2`}
              >
                <p>Amount Chargeable (in words)</p>
                <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left pb-2">
                  {/* Four Thousand Four Hundred Fifty */}
                  {props?.commissionData?.totalInWords} ONLY
                </p>
              </div>
              {/* terms and condition */}
              <div className="flex  flex-row text-sm	 justify-between mt-2 mx-2 font-sans">
                <div className="text-left flex flex-col space-y-1">
                  <p
                    className={` ${
                      props.mobilePrint && "pb-2"
                    } border-black border-b-4 w-40 py-1`}
                  >
                    Terms & Conditions
                  </p>
                  <p>{InvoicSubject}</p>
                </div>
                <div className="text-left text-sm">E. & O.E.</div>
                <div className="text-left text-sm ">FOR,</div>
                {/* Extra informations */}
                <div className="text-lg font-bold text-right ">
                  {/* {KIRTIROADLINES} */}
                  <img src={sign} alt="signature" width={100} />
                </div>
              </div>
              {/* gpay*/}
              {/* <div className="text-left ml-2 text-sm	">
              {GPAYCONTENT} ({KIRTIROADLINES})
            </div> */}
              <div className="flex border-black border-b-2 pb-2 flex-row justify-between items-start mx-2 mb-2 font-sans text-sm	">
                <div className="text-left ">
                  {/* <p>{PaymentbypayeeCchequeDrafs}{" "}{KIRTIROADLINES}</p> */}
                </div>
                <div>
                  <p className="font-bold">{AuthorizedSignatory}</p>
                </div>
              </div>
              <h2
                className={`${
                  props.mobilePrint
                    ? " text-center text-xs p-2 -mt-2 "
                    : `text-xs mt-0 mb-0 text-center font-bold rounded-md p-2 font-sans ${Tablebg(
                        props.blackWhite
                      )} `
                }`}
              >
                {" "}
                {computerGeneratedInvoice}
              </h2>
            </div>
          </div>
        </div>
      )}
      {/* printing */}
      <div className="mb-5 flex flex-row justify-center items-center space-x-5 mt-10 w-full">
        {/* <ReactToPrint
          documentTitle={
            `Vehicle Invoice of ` +
            props?.commissionData?.ownerName +
            " - " +
            (props?.data?.invoiceNo || props?.data?.bookingInvoiceNo
              ? props?.data?.invoiceNo
                ? props?.data?.invoiceNo
                : props?.data?.bookingInvoiceNo
                ? props?.data?.bookingInvoiceNo
                : "--"
              : props.invoiceNo
              ? props.invoiceNo
              : "--")
          }
          trigger={() => <ButtonWithBG label="Genrate Invoice"></ButtonWithBG>}
          content={() => componentRef.current}
        /> */}
        <ButtonWithBG
          onClick={() => handleDownloadPdf("download")}
          label="Download"
        />
        <ButtonWithBG
          onClick={() => handleDownloadPdf("print")}
          label="Print"
        />
      </div>
    </>
  );
};

export default VehicleInvoiceDownloadView;
