import React from 'react';
import {Line}   from "react-chartjs-2";
// import { Bar } from 'react-chartjs-2';
// import { Chart, registerables } from 'chart.js';
// Chart.register(...registerables);


function LineChart({chartData}) {

  const Data = {
    labels: chartData.month,
    datasets: [
      {
        label: 'DEBIT',
        data: chartData.debit,
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 2
    },
    {
      label: 'CREDIT',
      data: chartData.credit,
      backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
    },
    {
      label: 'GROSS',
      data: chartData.gross,

      backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 2
    }
  ],
}

  // const option = {
  //   title: {
  //     display: true,
  //     text: 'Line Chart'
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           min: 0,
  //           max: 10,
  //           stepSize: 1,
  //         }
  //       }
  //     ]
  //   }
  // }

  // scales: {
  //   yAxes: [{
  //       ticks: {
  //           beginAtZero:true,
  //           min: 0,
  //           max: 100    
  //       }
  //     }]
  //  }

   
  return (
    <>
      {/* <Line data={data}/> */}
      <div>
        <Line 
           data={Data} 
          //  options={option}
           />
    </div>
    </>
  )
}

export default LineChart;