import React from "react";
import {
  AuthorizedSignatory,
  GPAYCONTENT,
  InvoicSubject,
  KIRTIROADLINES,
  OURBANKDETAILS,
  PaymentbypayeeCchequeDrafs,
  SCANQRCONTENT,
  WE_USING_PAYPHONE,
  computerGeneratedInvoice,
} from "../../../Utils/Constants";
import qrcode from "../../../Images/qrcode.jpg";
import { Tablebg, borderToggle } from "../../../Utils/TableStyling";
import sign from "../../../Images/sign.png";

const PDFFooter = (props) => {
  return (
    <>
      {/* Bannk Detail Start */}
      <div className={`flex flex-row justify-between font-sans font-semibold text-left text-xs mx-2  border-t-2 ${borderToggle(
          props.blackWhite
        ) }`}>
        {!props.noBankDetails && (
          <div className="mt-2 ">
            <div className="flex flex-row space-x-2 ">
              <div
                className={`${
                  props.mobilePrint
                    ? ""
                    : `px-5 py-2 leading-snug	text-center  rounded-lg uppercase font-semibold ${Tablebg(
                  props.blackWhite
                  )} `
                }`}
              >
                <p className={`${
                  props.mobilePrint}`
                    ? "text-lg underline":"tracking-wide text-sm font-bold"}>{OURBANKDETAILS}</p>
                <p style={{ fontSize: "11px" }}>( {SCANQRCONTENT} )</p>
              </div>
            </div>
            <div>
              <div className="flex flex-row space-x-1 ">
                {/* QR code */}
                <div className="mt-1">
                  <img width={95} src={qrcode} alt="Kirti Roadlines" />
                </div>
                <div className="space-y-1 mt-2 font-bold">
                  <h5  style={{fontSize:"10px"}}>Bank Name</h5>
                  <h5  style={{fontSize:"10px"}}>Branch</h5>
                  <h5  style={{fontSize:"10px"}}>A/c. No</h5>
                  <h5  style={{fontSize:"10px"}}>IFSC Code</h5>
                </div>
                {props?.Dropdown === false ? (
                  <div className="space-y-1 mt-2 font-bold">
                    <h5  style={{fontSize:"10px"}}>: State Bank of India</h5>
                    <h5  style={{fontSize:"10px"}}>: Prahlad Nagar Ahmedabad</h5>
                    <h5  style={{fontSize:"10px"}}>: 005167983000351</h5>
                    <h5  style={{fontSize:"10px"}}>: SBIT0CKMNSB (FIFTH CHARACTER IS ZERO)</h5>
                  </div>
                ) : (
                  <div className="space-y-1 mt-2 font-bold">
                    <h5 style={{fontSize:"10px"}}>: {props?.bankBranch?.bank}</h5>
                    <h5 style={{fontSize:"10px"}}>: {props?.bankBranch?.branch}</h5>
                    <h5 style={{fontSize:"10px"}}>: {props?.bankBranch?.accNo}</h5>
                    <h5 style={{fontSize:"10px"}}>: {props?.bankBranch?.ifsc}</h5>
                  </div>
                )}
              </div>
              <span style={{fontSize:"10px"}}>{WE_USING_PAYPHONE}</span>
            </div>
          </div>
        )}

        {!props.noTotal && (
          <div
            // className={`flex flex-row  justify-end mt-4 border-t-2 w-1/3 ${borderToggle(
            //   props.blackWhite
            // )}`}
            className={`flex flex-row  justify-end mt-2  w-1/3`}
          >
            <div className="space-y-1  mt-2  text-right w-32">
              {!props.noBalance && <h5 className="pr-2 font-bold">BALANCE :</h5>}
              {props.fright && <h5 className="pr-2 font-bold">FREIGHT :</h5>}
              <div className="h-14 font-bold">
                {props?.data?.totalHamali > 0 && (
                  <h5 className=" pr-2 font-bold">Hamali :</h5>
                )}
                {props?.data?.totalDetention > 0 && (
                  <h5 className="pr-2 font-bold">Detention :</h5>
                )}
                {props?.data?.totalExtraCharge > 0 && (
                  <h5 className=" pr-2 font-bold">Extra Charge :</h5>
                )}
              </div>
              <h5
              className={`${
                props.mobilePrint
                  ?  `pr-2 pb-4 text-sm px-2  tracking-wide text-md whitespace-nowrap rounded-l-lg  w-full uppercase font-bold ${Tablebg(
                    props.blackWhite
                  )}`
                  : `pr-2 text-sm px-2 py-2 tracking-wide text-md whitespace-nowrap rounded-l-lg  w-full uppercase font-bold ${Tablebg(props.blackWhite
                  )}`}`}

              >
                Grand Total 
              </h5>
            </div>
            <div className="space-y-1 mt-2 text-right w-28 font-bold">
              {!props.noBalance && (
                <h5>
                  &#8377;{" "}
                  {props?.data?.totalFreight ? props?.data?.totalFreight : 0}
                </h5>
              )}
              {props.fright && (
                <h5>
                  &#8377;{" "}
                  {props?.data?.totalFreight ? props?.data?.totalFreight : 0}
                </h5>
              )}
              <div className="h-14">
                {props?.data?.totalHamali > 0 && (
                  <h5>
                    &#8377;{" "}
                    {props?.data?.totalHamali ? props?.data?.totalHamali : 0}
                  </h5>
                )}
                {props?.data?.totalDetention > 0 && (
                  <h5>
                    &#8377;{" "}
                    {props?.data?.totalDetention
                      ? props?.data?.totalDetention
                      : 0}
                  </h5>
                )}
                {props?.data?.totalExtraCharge > 0 && (
                  <h5>
                    {" "}
                    &#8377;{" "}
                    {props?.data?.totalExtraCharge
                      ? props?.data?.totalExtraCharge
                      : 0}
                  </h5>
                )}
              </div>
              <h5
              className={`${
                props.mobilePrint
                  ?  `border-l-2 text-sm whitespace-nowrap rounded-r-lg pr-2 pb-4 tracking-wide b-r-2 w-full uppercase ${Tablebg(
                    props.blackWhite
                  )}`
                  : `border-l-2 text-sm whitespace-nowrap rounded-r-lg pr-2 px-5 py-2 tracking-wide b-r-2 w-full uppercase ${Tablebg(
                    props.blackWhite
                  )}`}`}
              >
                &#8377; {props?.data?.grandTotal ? props?.data?.grandTotal : 0}
              </h5>
            </div>
          </div>
        )}
      </div>

      {/* Extra Information */}
      <div
        // className={`flex flex-col justify-start items-start mx-2 font-sans  border-t-2 mt-2  ${borderToggle(
        //   props.blackWhite
        // )} `}
        className={`flex flex-col justify-start items-start mx-2 font-sans  border-t-2 mt-2  ${borderToggle(
          props.blackWhite
        )} `}
      >
        <span className="text-xs font-semibold">Amount Chargeable (in words)</span>
        <p className="font-mono text-xl  tracking-widest pb-2  w-full text-left font-semibold">
          {/* Four Thousand Four Hundred Fifty */}
          {props.totalInwords}
          {""}ONLY
        </p>
      </div>
      {/* terms and condition */}
      <div className="flex  flex-row text-sm	 justify-between mx-2 font-sans border-double border-spacing-2 border-black border-t-4">
        <div className="text-left flex flex-col ">
          <p className="border-black border-b-4 w-40 pb-2 font-semibold">
            Terms & Conditions
          </p>
          <p className="text-xs">{InvoicSubject}</p>
        </div>
        <div className="text-left text-sm font-semibold">E. & O.E.</div>
        <div className="text-left text-sm font-semibold ">FOR,</div>
        {/* Extra informations */}
        <div className="text-lg font-bold text-right ">
          {KIRTIROADLINES}
          <div className="text-left ml-2 text-sm mt-2	flex items-end justify-end">
            <img src={sign} alt="signature" width={90} />
          </div>
        </div>
      </div>
      {/* gpay*/}
      {props.gpayContent && (
        <div className="text-left ml-2 text-xs	w-full font-semibold">
          {GPAYCONTENT} ({KIRTIROADLINES})
        </div>
      )}
      <div className="flex border-black border-b-2 pb-2 flex-row justify-between items-start mx-2 mb-1 font-sans text-xs	">
        <div className="text-left font-semibold ">
          <p>
            {PaymentbypayeeCchequeDrafs} {KIRTIROADLINES}
          </p>
        </div>
        <div>
          <p className="font-bold">{AuthorizedSignatory}</p>
        </div>
      </div>

      <h2
      className={`${
          props.mobilePrint
            ? " text-center text-xs p-2 -mt-2 font-semibold "
            :`text-xs mt-0 mb-0 text-center font-bold rounded-md p-2 font-sans ${Tablebg(
              props.blackWhite
              )} `
            }`}
      >
        {computerGeneratedInvoice}
      </h2>
    </>
  );
};

export default PDFFooter;
