import React from "react";
import moment from "moment";
import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import logo from "../../../Images/logo_transparent.png";
import black_logo from "../../../Images/black_logo.png";
import {  borderToggle } from "../../../Utils/TableStyling";
export default function PDFHeader(props) {
  var Today_Date = moment(props?.date).format("DD-MM-YYYY");

  return (
    <>
      <div className="mt-2">
        {!props.NoTaxTag && (
          <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-xs">
            <h5>Tax Invoice</h5>
          </div>
        )}
        {/* Logo and Title Start */}
        {!props.noHeader && (
          <div className="m-2 items-center space-x-3 flex flex-row">
            <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
              <div className="flex flex-row justify-between">
                <div className="w-48 ml-2">
                  {props.blackWhite ? (
                    <img src={black_logo} alt="Kirti Roadlines" />
                  ) : (
                    <img src={logo} alt="Kirti Roadlines" />
                  )}
                </div>
                <div className=" flex flex-col -mt-2">
                  <span
                    style={{ fontSize: "55px", lineHeight: "1" }}
                    className={`${
                      !props.blackWhite && "text-primary-kirtiroadways"
                    } font-extrabold `}
                  >
                    {props.profileData.tagLine}
                  </span>
                  <span className={`text-lg font-serif text-black mt-2`}>
                    {props.profileData.subTagLine}
                  </span>
                </div>
              </div>
              {/* Address Start */}
              {/* ${address(
                  props.blackWhite
                )} */}
              <div
                style={{
                  width: "100%",
                  height: "75px",
                  // Set the container height to full viewport height
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start", // Center the content vertically
                  alignItems: "center",
                  borderRadius: "1.5rem",
                  padding: "3px",
                  fontSize: "12px",
                  background: !props.blackWhite
                    ? "linear-gradient(to right, #FFC7C7, #FFFFFF)"
                    : "white",
                  border: props.blackWhite ? "1px solid black" : "",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "start", // Center the content vertically
                    gap: "4px", // Adjust spacing between SVG and text
                    marginLeft: "2px",
                  }}
                >
                  <span className="relative top-2 left-3">{address_svg}</span>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center", // Center the content vertically
                      letterSpacing: "0px",
                      textRendering: "optimizeLegibility", // Improve text rendering for better readability
                      WebkitFontSmoothing: "antialiased", // For WebKit browsers (e.g., Chrome, Safari)
                      MozOsxFontSmoothing: "grayscale", // For Firefox on macOS
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {props.profileData.address}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2px",
                    letterSpacing: "0px",
                    textRendering: "optimizeLegibility", // Improve text rendering for better readability
                    WebkitFontSmoothing: "antialiased", // For WebKit browsers (e.g., Chrome, Safari)
                    MozOsxFontSmoothing: "grayscale", // For Firefox on macOS
                    fontFamily: "sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center", // Center the content vertically
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "end", // Center the content vertically
                        placeItems: "end",
                      }}
                    >
                      <span className="relative top-2 mr-1">{call_svg}</span>
                      {props.secondaryPhoneNo.map((number, i) => (
                        <span key={number}>{(i ? ", " : "") + number}</span>
                      ))}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      spaceX: "1",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span className="relative top-2 mr-1 ml-2">
                      {email_svg}
                    </span>

                    <span>{props.profileData.email}</span>
                  </div>
                </div> 
              </div>
              {/* Address End */}
            </div>
          </div>
        )}
        {/* Logo and Title End */}
      </div>

      {/* Invoice  No and details*/}
      {!props.NoDetails && (
        <div
          // className={`${
          //   props.noHeader && "mt-56"
          // } p-2 flex font-sans flex-row justify-between`}
          className={`px-2 flex font-sans flex-row justify-between`}
        >
          <div className="flex flex-col text-left w-1/2">
            <div
              style={{
                position: "relative",
                height: "30px",
                top: "0px",
                width: "150px",
                textTransform: "uppercase",
                marginBottom: "0",
                fontSize:"18px",
                textDecoration:"underline",
                fontWeight:"500",
              }}
            >
              Invoice to
            </div>

            <div className="w-96">
              <p className="text-left font-bold uppercase font-sans text-sm	subpixel-antialiased tracking-wide mt-0	">
                {props?.companyName ? props?.companyName?.toUpperCase() : null}
              </p>
            </div>
            {props?.companyAdd && (
              <div className="text-xs text-left">
                <div className="flex  flex-row space-x-1 mt-1">
                  <p className="w-24 flex-nowrap font-semibold">Address :</p>
                  <p className="w-96 font-semibold">{props?.companyAdd}</p>
                </div>
              </div>
            )}
            {props.companyGst && (
              <div className="text-sm mt-1">
                <div className="flex flex-row space-x-1 text-left">
                  <p className="font-semibold">GST :</p>
                  <p className="w-1/2 font-semibold">{props?.companyGst.toUpperCase()}</p>
                </div>
              </div>
            )}
          </div>

          {/* Right side */}
          <div className="flex  flex-col text-right justify-start items-end ">
          <div
              style={{
                position: "relative",
                height: "30px",
                top: "0px",
                width: "150px",
                textTransform: "uppercase",
                marginBottom: "0",
                fontSize:"18px",
                textDecoration:"underline",
                fontWeight:"500",
              }}
            >
            Our Details
            </div>
            <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
              <p className="flex flex-col">
                <span>Service : {props.profileData.service}</span>
                <span>Pan No. : {props.profileData.panNo}</span>
                <span>State Code : {props.profileData.stateCode}</span>
              </p>
              {/* <p className="flex flex-col">
                      <span>Transportation</span>
                      <span>AJGPD9182R</span>
                      <span>24</span>
                    </p> */}
            </div>

            <div
              className={`uppercase font-semibold	pt-1 mt-2 pl-16 border-t-2 ${borderToggle(
                props.blackWhite
              )} `}
            >
              <div className="flex flex-row text-sm text-right space-x-12">
                <span className="flex flex-col ">
                  <p className="text-right min-w-1/2">
                    Invoice No :{" "}
                    {props?.data?.invoiceNo || props?.invoiceNo
                      ? props?.data?.invoiceNo
                        ? props?.data?.invoiceNo
                        : props?.invoiceNo
                        ? props?.invoiceNo
                        : "--"
                      : props.invoiceNo
                      ? props.invoiceNo
                      : "--"}
                  </p>
                  {props.comapnyInvoiceNo && (
                    <p className="text-right min-w-1/2">
                      COMPANY INVOICE NO :{" "}
                      {props?.data?.companyInvoiceNo
                        ? props?.data?.invoiceNo
                          ? props?.data?.invoiceNo
                          : props?.data?.companyInvoiceNo
                        : "--"}
                    </p>
                  )}
                  {/* <p className="text-right min-w-1/2">
                          Bill OF Loading :
                        </p> */}
                  <p className="text-right min-w-1/2">Date : {Today_Date}</p>
                  {/* <p className="text-right w-1/2">Transportation</p> */}
                </span>
                {/* <span className="flex flex-col "> */}
                {/* <p className="text-left min-w-1/2">--</p> */}
                {/* <p className="text-left min-w-1/2">--</p> */}
                {/* <p className="text-left min-w-1/2">{Today_Date}</p> */}
                {/* <p className="text-right w-1/2">Transportation</p> */}
                {/* </span> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
