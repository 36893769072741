import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";

// const Input = (props) => (
//   <input
//     className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
//     {...props}
//   />
// );
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function AddEditBilling({ props }) {
  let history = useHistory();
  const location = useLocation();
  const [heading, setHeading] = useState("");
  // const [inputValue, setInputValue] = useState({BillNo:"", LRNo:"", Detention:"", Extra_Charge:"", Balance_Paid_Bill_No:"", Billty_Received_or_Not:"", Note:"" })
  // const [data, setData] = useState([]);
  const BillNoRef = useRef(null);
  const LRNoRef = useRef();
  const DetectionRef = useRef();
  const ExtraChargeRef = useRef();
  const Balance_Paid_Bill_NoRef = useRef();
  const Billty_Received_or_NotRef = useRef();
  const NoteRef = useRef();

  // To fetch Data from previous route
  useEffect(() => {
    if (location?.state?.label === "Add") {
      setHeading("Add Billing");
    } else {
      setHeading("Edit Billing");
    }
  }, [location]);

  const FormSubmitHandler = (e) => {
    e.preventDefault();
    const BillNo = BillNoRef.current.value;
    const LRNo = LRNoRef.current.value;
    const Detection = DetectionRef.current.value;
    const ExtraCharge = ExtraChargeRef.current.value;
    const Balance_Paid_Bill_No = Balance_Paid_Bill_NoRef.current.value;
    const Billty_Received_or_Not = Billty_Received_or_NotRef.current.value;
    const Note = NoteRef.current.value;

    BillNoRef.current.value = " ";
    LRNoRef.current.value = " ";
    DetectionRef.current.value = " ";
    ExtraChargeRef.current.value = " ";
    Balance_Paid_Bill_NoRef.current.value = " ";
    Billty_Received_or_NotRef.current.value = " ";
    NoteRef.current.value = " ";

    // To redirect to Billing Table
    history.push({
      pathname: "/billing",
      state: {
        label: {
          BillNo: BillNo,
          LRNo: LRNo,
          Detection: Detection,
          ExtraCharge: ExtraCharge,
          Balance_Paid_Bill_No: Balance_Paid_Bill_No,
          Billty_Received_or_Not: Billty_Received_or_Not,
          Note: Note,
        },
      },
    });
  };

  return (
    <DashBoardLayout pageTitle={heading}>
      <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
        <div className=" text-left mb-6">
          <h1>Bill To</h1>
          <hr className="border-black" />
        </div>
        <form onSubmit={FormSubmitHandler}>
          <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4">
            {/* left side */}
            <div className="md:w-2/3 w-full">
              <div className=" text-left space-y-6">
                {/* Bill No. */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label
                      htmlFor="Bill_No"
                      label="Bill No."
                      isRequired="true"
                    />
                    {/* <Input
                      defaultValue={location?.state?.data?.data?.Bill_No}
                      name="Bill_No"
                      ref={BillNoRef}
                      type="text"
                      id="Bill_No"
                    /> */}
                    <input
                      className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      defaultValue={location?.state?.data?.data?.Bill_No}
                      name="Bill_No"
                      ref={BillNoRef}
                      // value={inputValue.BillNo}
                      // onChange={(e)=>{setInputValue({BillNo : e.target.value})
                      // }}
                      type="text"
                      id="Bill_No"
                      required
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="LR No." isRequired="true" />
                    {/* <Input
                      defaultValue={location?.state?.data?.data?.LR_No}
                      name="LR_No"
                     ref={LRNoRef}
                      type="text"
                    /> */}
                    <input
                      className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      defaultValue={location?.state?.data?.data?.LR_No}
                      name="LR_No"
                      ref={LRNoRef}
                      // value={inputValue.LRNo}
                      // onChange={(e)=>{setInputValue({LRNo : e.target.value})
                      // }}
                      type="text"
                      required
                    />
                  </div>
                </div>
                {/* Detection */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Detention" isRequired="true" />
                    {/* <Input
                      defaultValue={location?.state?.data?.data?.Detection}
                      name="Detection"
                      ref={DetectionRef}
                      type="text"
                    /> */}
                    <input
                      className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      type="text"
                      defaultValue={location?.state?.data?.data?.Detection}
                      name="Detection"
                      ref={DetectionRef}
                      // value={inputValue.Detention}
                      // onChange={(e)=>{setInputValue({Detention : e.target.value})
                      // }}
                      required
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Extra Charge" isRequired="true" />
                    {/* <Input
                      defaultValue={location?.state?.data?.data?.Extra_Charge}
                      name="Extra_Charge"
                      ref={ExtraChargeRef}
                      type="text"
                    /> */}
                    <input
                      className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      type="text"
                      defaultValue={location?.state?.data?.data?.Extra_Charge}
                      name="Extra_Charge"
                      ref={ExtraChargeRef}
                      // value={inputValue.Extra_Charge}
                      // onChange={(e)=>{setInputValue({Extra_Charge : e.target.value})
                      // }}
                      required
                    />
                  </div>
                </div>
                {/* Vehicles Number */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Balance Paid Bill No." isRequired="true" />
                    {/* <Input
                      defaultValue={location?.state?.data?.data?.Balance_Paid_Bill_No}
                      name="Balance"
                      ref={Balance_Paid_Bill_NoRef}
                      type="text"
                    /> */}
                    <input
                      className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      type="text"
                      defaultValue={
                        location?.state?.data?.data?.Balance_Paid_Bill_No
                      }
                      name="Balance"
                      ref={Balance_Paid_Bill_NoRef}
                      // value={inputValue.Balance_Paid_Bill_No}
                      // onChange={(e)=>{setInputValue({Balance_Paid_Bill_No : e.target.value})
                      // }}
                      required
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Billty Received or Not" isRequired="true" />
                    {/* <Input
                      defaultValue={location?.state?.data?.data?.Billty_Received_or_Not}
                      name="Billty"
                      ref={Billty_Received_or_NotRef}
                      type="text"
                    /> */}
                    <input
                      className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      type="text"
                      defaultValue={
                        location?.state?.data?.data?.Billty_Received_or_Not
                      }
                      name="Billty"
                      ref={Billty_Received_or_NotRef}
                      // value={inputValue.Billty_Received_or_Not}
                      // onChange={(e)=>{setInputValue({Billty_Received_or_Not : e.target.value})
                      // }}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* right Side */}
            <div className=" md:space-x-4 md:space-y-0 space-y-4 md:w-1/3 w-full">
              <div className=" flex flex-col text-left ">
                <Label label="Note" isRequired="true" />
                <textarea
                  className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                  name="Note"
                  id=""
                  cols="30"
                  rows="9"
                  defaultValue={location?.state?.data?.data?.Note}
                  ref={NoteRef}
                  // value={inputValue.Note}
                  // onChange={(e)=>{setInputValue({Note : e.target.value})
                  // }}
                  type="text"
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div className="items-ceter mt-4">
            <ButtonWithBG theme="primary" label="SAVE" type="submit" />
          </div>
        </form>
      </div>
    </DashBoardLayout>
  );
}
