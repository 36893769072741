import React, { useEffect, useState } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import axiosInstance from "../../Utils/API";
import {
  GET_VEHICLE_OWNERBYID,
  UPDATE_COMMISSION_DATA,
} from "../../Utils/ApiGlobal";
// import SearchVelicleOwner from "../../Components/Functionality/SearchVelicleOwner";
import SearchVehicleNo from "../../Components/Functionality/SearchVehicleNo";
import moment from "moment";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import { ChevronDownIcon } from "@heroicons/react/solid";
import TransporterName from "../../Components/Functionality/TransporterName";
import Checkbox from "../../Components/UI/Checkbox";
import APILoader from "../../Components/UI/APILoader";
import Loader from "../../Components/UI/Loader";
import Notification from "../../Components/UI/Notification";

// import { CompanyOptions } from "../../Utils/Constants";

// const theme = (theme) => ({
//   ...theme,
//   spacing: {
//     ...theme.spacing,
//     controlHeight: 10,
//     baseUnit: 0,
//   },
// });

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
const error_notification = (props) => ({
  mode: "failure",
  timeout: 5000,
  top: 10,
  title: props.title,
});

export default function UpdateCommission({ props }) {
  const location = useLocation();
  const history = useHistory();
  const [LRDate, setLRDate] = useState(new Date());
  // const [startDate, setStartDate] = useState(new Date());
  const [transporter, setTransporter] = useState(
    location?.state?.data?.data?.transporterName
  );
  const [transporterId, setTransporterId] = useState();
  const [vehicleOwnerId, setVehicleOwnerId] = useState();
  const [vehicleOwnerName, setVehicleOwnerName] = useState(
    location?.state?.data?.data?.vehicleOwnerName
  );
  const [vehicleNumber, setVehicleNumber] = useState(
    location?.state?.data?.data?.vehicleNo
  );
  const [vehicleOwnerCommission, setVehicleOwnerCommission] = useState();
  const [vehicleOwnerCommissionAmount, setVehicleOwnerCommissionAmount] =
    useState();
  // const [newvehicleOwnerName, setNewVehicleOwnerName] = useState();
  const [from, setFrom] = useState(location?.state?.data?.data?.from);
  const [to, setTo] = useState(location?.state?.data?.data?.to);
  const [addFormValues, setAddFormValues] = useState({
    TransporterFreight: 0,
    TransporterAdvance: 0,
    VehicleFreight: 0,
    VehicleAdvance: 0,
    vehicleHamali: 0,
    vehicleDetention: 0,
    vehicleExtraCharge: 0,
    transporterHamali: 0,
    transporterDetention: 0,
    transporterExtraCharge: 0,
    toPay: 0,
  });
  const [showTransportation, setShowTransportation] = useState(true);
  const [showVehicle, setShowVehicle] = useState(true);
  const [notif, setNotif] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [notifOpts, setNotifOpts] = useState({});
  const [commissionData, setCommissionData] = useState({});
  const [showTransporterHamali, setShowTransporterHamali] = useState(false);
  const [transporterTotalCal, setTransporterTotalCal] = useState();
  const [showVehicleHamali, setshowVehicleHamali] = useState(false);
  const [balanceCal, setBalanceCal] = useState(0);
  const [VehBalanceCal, setVehBalanceCal] = useState(0);
  const [vehTotalCal, setvehTotalCal] = useState();
  const [isBuilltyRecivedDate, setIsBuilltyRecivedDate] = useState(new Date());
  const [isBuilltyAvailable, setIsBuilltyAvailable] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  // For Loading
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setIsLoading(false);
    };
    loadData();
  }, []);

  // To fetch Data from previous route
  useEffect(() => {
    // console.log(location?.state?.data?.data);
    const lrDate = moment(location?.state?.data?.data?.loadingDate).toDate();
    const Commissiondata = location?.state?.data?.data;
    setCommissionData(Commissiondata);
    setLRDate(lrDate);
    setVehicleOwnerCommission(
      location?.state?.data?.data?.vehicleCommissionType
    );
    setVehicleOwnerCommissionAmount(
      location?.state?.data?.data?.vehicleCommissionAmount
    );
    setTransporterId(location?.state?.data?.data?.transporterId);
    setVehicleOwnerId(location?.state?.data?.data?.vehicleOwnerId);
    setIsBuilltyAvailable(location?.state?.data?.data?.isBilltyReceive);
    const builltyDate = moment(
      location?.state?.data?.data?.billtyReceiveDate
    ).toDate();
    setIsBuilltyRecivedDate(builltyDate);
    // On change array transfer default values so in calcultation we already got default values
    const newValues = {
      ...addFormValues,
      TransporterFreight: parseInt(
        Commissiondata?.transporterFreigth === null
          ? 0
          : Commissiondata?.transporterFreigth
      ),
      TransporterAdvance: parseInt(
        Commissiondata?.transporterAdvance === null
          ? 0
          : Commissiondata?.transporterAdvance
      ),
      toPay: parseInt(
        Commissiondata?.toPay === null ? 0 : Commissiondata?.toPay
      ),
      transporterHamali: parseInt(
        Commissiondata?.transporterHamali === null
          ? 0
          : Commissiondata?.transporterHamali
      ), //Data accepts INT format
      transporterDetention: parseInt(
        Commissiondata?.transporterDetention === null
          ? 0
          : Commissiondata?.transporterDetention
      ), //Data accepts INT format
      transporterExtraCharge: parseInt(
        Commissiondata?.transporterExtraCharge === null
          ? 0
          : Commissiondata?.transporterExtraCharge
      ),
      VehicleFreight: parseInt(
        Commissiondata?.vehicleFreight === null
          ? 0
          : Commissiondata?.vehicleFreight
      ),
      VehicleAdvance: parseInt(
        Commissiondata?.vehicleAdvance === null
          ? 0
          : Commissiondata?.vehicleAdvance
      ),
      vehicleHamali: parseInt(
        Commissiondata?.vehicleHamali === null
          ? 0
          : Commissiondata?.vehicleHamali
      ), //Data accepts INT format
      vehicleDetention: parseInt(
        Commissiondata?.vehicleDetention === null
          ? 0
          : Commissiondata?.vehicleDetention
      ), //Data accepts INT format
      vehicleExtraCharge: parseInt(
        Commissiondata?.vehicleExtraCharge === null
          ? 0
          : Commissiondata?.vehicleExtraCharge
      ),
    };
    //Tranporter Balance calcultation
    let vehicleDifference =
      parseInt(Commissiondata?.vehicleFreight) -
      parseInt(Commissiondata?.vehicleAdvance);
    setVehBalanceCal(parseInt(vehicleDifference));
    let transporterDifference =
      parseInt(Commissiondata?.transporterFreigth) -
      parseInt(Commissiondata?.transporterAdvance) -
      parseInt(Commissiondata?.toPay);
    setBalanceCal(parseInt(transporterDifference));
    // Show manual commission text field
    setAddFormValues(newValues);
    //Calculation for grand total and show hamali section conditions
    if (
      Commissiondata?.transporterHamali === 0 &&
      Commissiondata?.transporterDetention === 0 &&
      Commissiondata?.transporterExtraCharge === 0
    ) {
      let diffrence =
        parseInt(Commissiondata?.transporterFreigth) -
        parseInt(Commissiondata?.transporterAdvance) -
        parseInt(Commissiondata?.toPay);
      setvehTotalCal(diffrence);
      setshowVehicleHamali(false);
    } else {
      const total =
        parseInt(
          Commissiondata?.transporterFreigth === null
            ? 0
            : Commissiondata?.transporterFreigth
        ) -
        parseInt(
          Commissiondata?.transporterAdvance === null
            ? 0
            : Commissiondata?.transporterAdvance
        ) +
        parseInt(
          Commissiondata?.transporterHamali === null
            ? 0
            : Commissiondata?.transporterHamali
        ) -
        +parseInt(
          Commissiondata?.transporterDetention === null
            ? 0
            : Commissiondata?.transporterDetention
        ) +
        parseInt(
          Commissiondata?.transporterExtraCharge === null
            ? 0
            : Commissiondata?.transporterExtraCharge
        );
      setTransporterTotalCal(parseInt(total));
      // if (Commissiondata?.commissionType === "5% fix") {
      //   const grandTotal = (5 / total) * 100;
      //   setvehTotalCal(grandTotal);
      // } else if (Commissiondata?.commissionType === "Manual") {
      //   const grandTotal = total + parseInt(Commissiondata?.commission);
      //   setvehTotalCal(grandTotal);
      // } else if (Commissiondata?.commissionType === "Not Applicable") {
      //   setvehTotalCal(total);
      // }
      setAddFormValues(newValues);
      setShowTransporterHamali(true);
    }
    if (
      Commissiondata?.vehicleHamali === 0 &&
      Commissiondata?.vehicleDetention === 0 &&
      Commissiondata?.vehicleExtraCharge === 0
    ) {
      let diffrence =
        parseInt(Commissiondata?.vehicleFreight) -
        parseInt(Commissiondata?.vehicleAdvance);
      setvehTotalCal(diffrence);
      setshowVehicleHamali(false);
    } else {
      const total =
        parseInt(
          Commissiondata?.vehicleFreight === null
            ? 0
            : Commissiondata?.vehicleFreight
        ) -
        parseInt(
          Commissiondata?.vehicleAdvance === null
            ? 0
            : Commissiondata?.vehicleAdvance
        ) +
        parseInt(
          Commissiondata?.vehicleHamali === null
            ? 0
            : Commissiondata?.vehicleHamali
        ) +
        parseInt(
          Commissiondata?.vehicleDetention === null
            ? 0
            : Commissiondata?.vehicleDetention
        ) +
        parseInt(
          Commissiondata?.vehicleExtraCharge === null
            ? 0
            : Commissiondata?.vehicleExtraCharge
        );
      setvehTotalCal(parseInt(total));
      // if (Commissiondata?.commissionType === "5% fix") {
      //   const grandTotal = (5 / total) * 100;
      //   setvehTotalCal(grandTotal);
      // } else if (Commissiondata?.commissionType === "Manual") {
      //   const grandTotal = total + parseInt(Commissiondata?.commission);
      //   setvehTotalCal(grandTotal);
      // } else if (Commissiondata?.commissionType === "Not Applicable") {
      //   setvehTotalCal(total);
      // }
      setAddFormValues(newValues);
      setshowVehicleHamali(true);
    }
  }, [location?.state?.data?.data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  // Select Vehicle Owner
  // const handleOnChange = (e) => {
  //   setVehicleOwnerId(e.value);
  //   setVehicleOwnerName(e.label);
  //   setVehicleOwnerCommission(e.commissionType);
  //   setVehicleOwnerCommissionAmount(e.amount);
  // };
  // Select handle Transporter
  const handleTransporterOnChange = (e) => {
    setTransporter(e.label);
    setTransporterId(e.value);
  };
  // Select handle Vehicle Number
  const handleVehicleOnChange = (e) => {
    // console.log(e.label)
    // console.log(e.value)
    // console.log("Vehicle Owner", e.ownerId)
    setVehicleNumber(e.label);
    setVehicleOwnerId(e.ownerId);
    // setNewVehicleOwnerName(e.vehicleOwner);
  };
  // Get Vehicle Owner Name By ID
  useEffect(() => {
    axiosInstance
      .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${vehicleOwnerId}`)
      .then((response) => {
        // console.log(response)
        // console.log(response?.data?.data[0].ownerName)
        setVehicleOwnerName(response?.data?.data[0].ownerName);
        setVehicleOwnerCommission(response?.data?.data[0].ownerCommissionType);
        setVehicleOwnerCommissionAmount(
          response?.data?.data[0].ownerCommissionType
        );
      });
  }, [vehicleOwnerId]);

  //Onchange values handleClick event
  const values_handler = (e) => {
    // console.log(e.target.value);
    let name = e.target.name;
    let value = e.target.value;
    let newValue;
    if (typeof value === "string" && value.trim().length === 0) {
      newValue = 0;
    } else {
      newValue = parseInt(value);
    }
    const newValues = {
      ...addFormValues,
      [name]: newValue,
    };
    setAddFormValues(newValues);
    if (name === "TransporterFreight" || name === "TransporterAdvance"||name === "toPay") {
      calTotal(newValues, "transporter");
      calCommissionTotal(newValues, "transporter");
    } else if (name === "VehicleFreight" || name === "VehicleAdvance") {
      calTotal(newValues, "vehicle");
      calCommissionTotal(newValues, "vehicle");
    } else if (
      name === "transporterHamali" ||
      name === "transporterDetention" ||
      name === "transporterExtraCharge"
    ) {
      calCommissionTotal(newValues, "transporter");
    } else if (
      name === "vehicleHamali" ||
      name === "vehicleDetention" ||
      name === "vehicleExtraCharge"
    ) {
      calCommissionTotal(newValues, "vehicle");
    }
  };

  // Total Calculation (Freight - Advance + hamali + detention+ extraCharge + commission)
  const calCommissionTotal = (addFormValues, type) => {
    if (type === "transporter") {
      // console.log(addFormValues);
      const {
        TransporterFreight,
        TransporterAdvance,
        toPay,
        transporterHamali,
        transporterDetention,
        transporterExtraCharge,
      } = addFormValues;
      const total =
        parseInt(TransporterFreight) -
        parseInt(TransporterAdvance)-
        parseInt(toPay)  +
        parseInt(transporterHamali) +
        parseInt(transporterDetention) +
        parseInt(transporterExtraCharge);
      // console.log(total, "total");
      setTransporterTotalCal(parseInt(total));
    } else if (type === "vehicle") {
      // console.log(addFormValues);
      const {
        VehicleFreight,
        VehicleAdvance,
        vehicleHamali,
        vehicleDetention,
        vehicleExtraCharge,
      } = addFormValues;
      const total =
        parseInt(VehicleFreight) -
        parseInt(VehicleAdvance) +
        parseInt(vehicleHamali) +
        parseInt(vehicleDetention) +
        parseInt(vehicleExtraCharge);
      // console.log(total, "total");
      setvehTotalCal(parseInt(total));
    }
  };

  // Total Calculation (Freight - Advance)
  const calTotal = (addFormValues, type) => {
    // console.log(addFormValues.TransporterFreight);
    if (type === "transporter") {
      const { TransporterFreight, TransporterAdvance,toPay } = addFormValues;
      const balance =
        parseInt(TransporterFreight) - parseInt(TransporterAdvance)- parseInt(toPay);
      setBalanceCal(parseInt(balance));
    } else if (type === "vehicle") {
      const { VehicleFreight, VehicleAdvance } = addFormValues;
      const balance = parseInt(VehicleFreight) - parseInt(VehicleAdvance);
      setVehBalanceCal(parseInt(balance));
      // setvehTotalCal(balance);
    }
  };

  //   Form Submit click event(Commission Add API )
  const FormSubmitHandler = async (ev) => {
    ev.preventDefault();
    setLoader({ ...loader, loading: true });
    if (!transporter) {
      setFormErrors({ TransporterName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please select Transporter Name` })
      );
      return;
    } else if (!vehicleOwnerName) {
      setFormErrors({ vehicleOwnerName: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Owner Name` })
      );
      return;
    } else if (!vehicleNumber) {
      setFormErrors({ vehicleNumber: true });
      setNotif(true);
      setNotifOpts(
        error_notification({ title: `Please enter vehicle Number` })
      );
      return;
    } else if (!from) {
      setFormErrors({ from: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please enter From Value` }));
      return;
    } else if (!to) {
      setFormErrors({ to: true });
      setNotif(true);
      setNotifOpts(error_notification({ title: `Please enter To Value` }));
      return;
    }
    const obj = formValueConvertor(getFormJSON(ev.target));
    let ADDCOMMISSION_DATA = {
      CommissionId: location?.state?.data?.data?.commissionId,
      LoadingDate: moment(LRDate).toDate(),
      TransporterName: transporter.toUpperCase(),
      TransporterId: transporterId,
      VehicleOwnerId: vehicleOwnerId,
      VehicleOwnerName: vehicleOwnerName.toUpperCase(),
      VehicleNo: vehicleNumber.toUpperCase(),
      From: from.toUpperCase(),
      To: to.toUpperCase(),
      TransporterFreigth: parseInt(addFormValues.TransporterFreight),
      TransporterAdvance: parseInt(addFormValues.TransporterAdvance),
      ToPay: parseInt(addFormValues.toPay),
      TransporterBalance: parseInt(balanceCal),
      TransporterHamali: parseInt(addFormValues.transporterHamali),
      TransporterDetention: parseInt(addFormValues.transporterDetention),
      TransporterExtraCharge: parseInt(addFormValues.transporterExtraCharge),
      TransporterTotal: transporterTotalCal,
      VehicleFreight: parseInt(addFormValues.VehicleFreight),
      VehicleAdvance: parseInt(addFormValues.VehicleAdvance),
      VehicleBalance: parseInt(VehBalanceCal),
      VehicleHamali: parseInt(addFormValues.vehicleHamali),
      VehicleDetention: parseInt(addFormValues.vehicleDetention),
      VehicleExtraCharge: parseInt(addFormValues.vehicleExtraCharge),
      VehicleTotal: vehTotalCal,
      DriverPaidBalance: obj.Driver_paid_bal,
      BalancePaidBillNo: obj.Balance_Paid_Bill_No,
      Note: obj.Note.toUpperCase(),
      UpdatedBy: localStorage.getItem("userId"),
      IsBilltyReceive: isBuilltyAvailable,
      BilltyReceiveDate: moment(isBuilltyRecivedDate).toDate(),
    };
    // console.log(ADDCOMMISSION_DATA)
    await axiosInstance
      .post(UPDATE_COMMISSION_DATA, ADDCOMMISSION_DATA)
      .then((response) => {
        setLoader({
          loading: false,
          message: response.data.data.message,
          icon: "success",
        });
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/commission-entry",
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };

  return (
    <DashBoardLayout pageTitle="Edit Commission">
      <APILoader loader={loader} />
      <Notification
        show={notif}
        setShow={setNotif}
        {...notifOpts}
        supportContent={true}
      ></Notification>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
          <form className="space" onSubmit={FormSubmitHandler}>
            <div className="text-left space-y-6">
              {/* Loading date and Transporter Name */}
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setShowTransportation(!showTransportation);
                }}
                className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-semibold text-primary-kirtiroadways hover:bg-kirtiroadways-300 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75"
              >
                Transportation Information
                <ChevronDownIcon className="h-5 w-5 ui-open:rotate-90 ui-open:transform" />
              </div>
              {showTransportation && (
                <>
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="L. Date" isRequired="true" />
                      <div className="flex flex-row justify-center ">
                        {/* loadingDate */}
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={LRDate}
                          className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                          onChange={(date) => setLRDate(date)}
                          name="L_date"
                        />
                      </div>
                    </div>
                    {/* Transporter Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Name" isRequired="true" />
                      <TransporterName
                        defaultValue={commissionData?.transporterName}
                        handleOnChange={handleTransporterOnChange}
                      />
                      {formErrors.TransporterName === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please select Transporter Name
                        </p>
                      )}
                    </div>
                    {/* Vehicle Number. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle No." isRequired="true" />
                      <SearchVehicleNo
                        defaultValue={vehicleNumber}
                        // vehicleOwnerId={vehicleOwnerId}
                        handleOnChange={handleVehicleOnChange}
                      />
                      {formErrors.vehicleNumber === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter vehicle Number
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Vehicle Owner Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Owner Name" isRequired="true" />
                      {/* <SearchVelicleOwner
                      defaultValue={
                        location?.state?.data?.data?.vehicleOwnerName
                      }
                      newvehicleOwnerName={newvehicleOwnerName}
                      handleOnChange={handleOnChange}
                    /> */}
                      <div className="peer uppercase  w-full px-3 py-2  border border-gray-300 placeholder-gray-500    rounded-md focus:outline-none  focus:z-10 sm:text-sm ">
                        {vehicleOwnerName}
                      </div>
                      {formErrors.vehicleOwnerName === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter vehicle Owner Name
                        </p>
                      )}
                    </div>
                    {/* From */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="From" isRequired="true" />
                      <Input
                        name="From"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                        required
                      />
                      {formErrors.from === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter From Value
                        </p>
                      )}
                    </div>
                    {/* To */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="To" isRequired="true" />
                      <Input
                        name="To"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        required
                      />
                      {formErrors.to === true && (
                        <p className="text-red-500 font-bold text-left text-xs">
                          Please enter To Value
                        </p>
                      )}
                    </div>
                  </div>

                  {/* Transporter Fright, advance and balance */}
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Freight */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Transporter Freight" isRequired="true" />
                      <Input
                        defaultValue={commissionData?.transporterFreigth}
                        type="text"
                        name="TransporterFreight"
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                    {/* Advance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Advance" />
                      <Input
                        defaultValue={commissionData?.transporterAdvance}
                        type="text"
                        name="TransporterAdvance"
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                      {/* Two pay */}
                      {/* <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="To Pay" />
                      <Input
                        defaultValue={addFormValues.toPay}
                        type="text"
                        name="toPay"
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div> */}
                    {/* Balance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Balance" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {balanceCal}
                      </p>
                    </div>
                  </div>

                  {/* Hamali, Detention & Extra Charge  */}
                  <div className="mt-4">
                    <div className=" flex flex-row space-x-5 mt-5 text-left ">
                      <button
                        className="font-bold pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover p-2 rounded-md text-white "
                        type="button"
                        onClick={() => {
                          setShowTransporterHamali(true);
                        }}
                      >
                        +
                      </button>
                      <button
                        className="font-bold  pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover py-2 px-3 rounded-md text-white "
                        type="button"
                        onClick={() => {
                          setShowTransporterHamali(false);
                        }}
                      >
                        -
                      </button>
                    </div>
                  </div>
                  {showTransporterHamali && (
                    <div className="mt-4 flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Transporter Hamali" isRequired="true" />
                        <Input
                          defaultValue={commissionData?.transporterHamali}
                          onChange={values_handler}
                          name="transporterHamali"
                          type="text"
                          step="any"
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label
                          label="Transporter Detention"
                          isRequired="true"
                        />
                        <Input
                          defaultValue={commissionData?.transporterDetention}
                          onChange={values_handler}
                          name="transporterDetention"
                          type="text"
                          step="any"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label
                          label="Transporter Extra Charge"
                          isRequired="true"
                        />
                        <Input
                          defaultValue={commissionData?.transporterExtraCharge}
                          onChange={values_handler}
                          name="transporterExtraCharge"
                          type="text"
                          step="any"
                        />
                      </div>
                    </div>
                  )}
                  {/*Transporter Total */}
                  <div className="flex mt-5 md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className="md:w-1/2 flex  w-full  flex-col  text-left">
                      <Label label="Transporter Total" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {" "}
                        {transporterTotalCal != null ? transporterTotalCal : 0}
                      </p>
                    </div>
                  </div>
                </>
              )}

              {/* ---Vehicle Information --- */}
              <div
                onClick={() => setShowVehicle(!showVehicle)}
                className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-semibold text-primary-kirtiroadways hover:bg-kirtiroadways-300 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75"
              >
                Vehicle Information
                <ChevronDownIcon className="h-5 w-5 ui-open:rotate-90 ui-open:transform" />
              </div>

              {showVehicle && (
                <>
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="L. Date" isRequired="true" />
                      <div className="flex flex-row justify-center min-w-full ">
                        {/* loadingDate */}
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={LRDate}
                          className="peer  min-w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                          onChange={(date) => setLRDate(date)}
                          name="L_date"
                        />
                      </div>
                    </div>
                    {/* Transporter Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Transporter Name" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        min-w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {transporter}
                      </p>
                    </div>
                    {/* Vehicle Number. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle No." isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {vehicleNumber}
                      </p>
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Vehicle Owner Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Owner Name" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        truncate 
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {vehicleOwnerName}
                      </p>
                    </div>
                    {/* From */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="From" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {from}
                      </p>
                    </div>
                    {/* To */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="To" isRequired="true" />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {to}
                      </p>{" "}
                    </div>
                  </div>

                  {/* Vehicle Fright, advance and balance */}
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Freight */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Vehicle Freight" isRequired="true" />
                      <Input
                        type="text"
                        name="VehicleFreight"
                        defaultValue={commissionData.vehicleFreight}
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                    {/* Advance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Advance" />
                      <Input
                        type="text"
                        name="VehicleAdvance"
                        defaultValue={commissionData.vehicleAdvance}
                        // placeholder="Default value 0"
                        onChange={values_handler}
                      />
                    </div>
                    {/* Balance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Balance" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {VehBalanceCal}
                      </p>
                    </div>
                  </div>

                  {/* Hamali, Detention & Extra Charge  */}
                  <div className="mt-4">
                    <div className=" flex flex-row space-x-5 mt-5 text-left ">
                      <button
                        className="font-bold pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover p-2 rounded-md text-white "
                        type="button"
                        onClick={() => {
                          setshowVehicleHamali(true);
                        }}
                      >
                        +
                      </button>
                      <button
                        className="font-bold  pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover py-2 px-3 rounded-md text-white "
                        type="button"
                        onClick={() => {
                          setshowVehicleHamali(false);
                        }}
                      >
                        -
                      </button>
                    </div>
                  </div>
                  {showVehicleHamali && (
                    <div className="mt-4 flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Vehicle Hamali" isRequired="true" />
                        <Input
                          defaultValue={commissionData?.vehicleHamali}
                          onChange={values_handler}
                          name="vehicleHamali"
                          type="text"
                          step="any"
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Vehicle Detention" isRequired="true" />
                        <Input
                          defaultValue={commissionData?.vehicleDetention}
                          onChange={values_handler}
                          name="vehicleDetention"
                          type="text"
                          step="any"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Vehicle Extra Charge" isRequired="true" />
                        <Input
                          defaultValue={commissionData?.vehicleExtraCharge}
                          onChange={values_handler}
                          name="vehicleExtraCharge"
                          type="text"
                          step="any"
                        />
                      </div>
                    </div>
                  )}
                  {/* Vehicle Total */}
                  <div className="flex mt-5 md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className="md:w-1/2 flex  w-full  flex-col  text-left">
                      <Label label="Vehicle Total" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {" "}
                        {vehTotalCal != null ? vehTotalCal : 0}
                      </p>
                    </div>
                    {/* Vehicle commission type */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label
                        label="Vehicle Commission Type"
                        isRequired="true"
                      />
                      <p
                        className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        uppercase
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {vehicleOwnerCommission}
                      </p>
                    </div>
                    {/* Commission Amount */}
                    {vehicleOwnerCommissionAmount && (
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label
                          label="Vehicle Manual Commission Amount"
                          isRequired="true"
                        />
                        <p
                          className="
                      cursor-not-allowed	
                        block
                        w-full
                        h-9
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        >
                          {vehicleOwnerCommissionAmount}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {/* Common Section */}
            <div className="mt-4 flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
              {/*Driver Paid Balance  */}
              {/* <div className="  flex flex-col md:w-1/2 text-left w-full">
              <Label label="Driver Paid Balance" isRequired="true" />
              <Input
                defaultValue={
                  location?.state?.data?.data?.driverPaidBalance === null
                    ? ""
                    : location?.state?.data?.data?.driverPaidBalance
                }
                onChange={values_handler}
                name="Driver_paid_bal"
                required
              />
            </div> */}
              {/*Balance Paid Bill No. */}
              {/* <div className=" flex md:w-1/2 w-full  flex-col  text-left">
              <Label label="Balance Paid Bill No." isRequired="true" />
              <Input
                defaultValue={
                  location?.state?.data?.data?.balancePaidBillNo === null
                    ? ""
                    : location?.state?.data?.data?.balancePaidBillNo
                }
                onChange={values_handler}
                name="Balance_Paid_Bill_No"
                required
              />
            </div> */}
              {/*Note */}
              <div className=" flex md:w-1/2 w-full  flex-col  text-left">
                <Label label="Note" />
                <Input
                  defaultValue={
                    location?.state?.data?.data?.note === null
                      ? ""
                      : location?.state?.data?.data?.note
                  }
                  onChange={values_handler}
                  name="Note"
                />
              </div>
            </div>

            <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
              <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-4 space-y-4">
                {/* Is buillty available */}
                <div className="w-24 flex  flex-col mt-4  text-center">
                  <Label label="Is Buillty Available" />
                  <Checkbox
                    defaultChecked={isBuilltyAvailable}
                    onClick={(ev) => {
                      setIsBuilltyAvailable(ev.target.checked);
                      // console.log(ev.target.checked)
                    }}
                    className="accent-red-600	text-white"
                  />
                </div>
                <div className="w-1/2 flex flex-col  text-left ">
                  <Label label="Buillty Date" isRequired="true" />
                  <div className="min-w-full flex flex-row justify-center ">
                    {/* Buillty Avilable Date */}
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      className="peer  min-w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      selected={isBuilltyRecivedDate}
                      onChange={(date) => setIsBuilltyRecivedDate(date)}
                      name="builltyDate"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="items-ceter pt-8">
              <ButtonWithBG theme="primary" label="SAVE" type="submit" />
            </div>
          </form>
        </div>
      )}
    </DashBoardLayout>
  );
}
