import React from "react";
// import Table from "../UI/Table";
import TableAction from "../UI/Table/TableAction";
import { BALANCE_DATA } from "../../Utils/Constants";
import TablePagination from "../UI/Table/TablewithPagination";
// import Pagination from "../UI/Pagination/Pagination";

const columns = [
  {
    Header: "Action",
    id: "Action",
    maxWidth: 10,
    minWidth: 10,
    width: 10,
    Cell: (row) => (
      <div>
        <TableAction
          data={row.row.original}
          redirectPath="/billing/add-edit-Billing"
        />
      </div>
    ),
  },
  {
    Header: "Date",
    accessor: "Date",
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    Header: "L.Date",
    accessor: "L_Date",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "TRUCK NO.",
    accessor: "Truck_no",
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    Header: "COMPANY NAME",
    accessor: "Company_Name",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "FROM",
    accessor: "From",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "To",
    accessor: "To",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "BOOKING Amount",
    accessor: "Booking_Amount",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "LR No.",
    accessor: "LR_No",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "BILL NO.",
    accessor: "Bill_No",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "Freight",
    accessor: "Freight",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "Advance",
    accessor: "Advance",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "Balance",
    accessor: "Balance",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "Hamali",
    accessor: "Hamali",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "Detection",
    accessor: "Detection",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "Extra Charge",
    accessor: "Extra_Charge",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "total",
    accessor: "total",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "Vehicle Owner Name",
    accessor: "Vehicle_Owner_Name",
    maxWidth: 200,
    minWidth: 200,
    width: 200
  },
  {
    Header: "DRIVER PAID BALANCE",
    accessor: "Driver_Paid_Balance",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "BALANCE PAID BILL NO.",
    accessor: "Balance_Paid_Bill_No",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "BILLTY RECEIVED OR NOT",
    accessor: "Billty_Received_Or_Not",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
  {
    Header: "NOTE",
    accessor: "Note",
    maxWidth: 100,
    minWidth: 100,
    width: 100
  },
];
function BalanceTable(props) {
  // console.log(props,"for table")

  // for Search by date
  const filterByDate = ({ Date }) => {
    return Date.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  };

  // const filterByOwnerName = ({ Vehicle_Owner_Name}) => {
  //   return Vehicle_Owner_Name.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };

  return (
    <section>
      <div className="mt-4 ">
        <TablePagination
          data={BALANCE_DATA.filter(filterByDate)}
          columns={columns}
        />
      </div>
    </section>
  );
}

export default BalanceTable;
