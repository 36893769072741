import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axiosInstance from "../../Utils/API";
import { GET_COMPANY_TABLE, GET_LR, UPDATE_BUILTY } from "../../Utils/ApiGlobal";
import moment from "moment";
import { WeightUnitOptions } from "../../Utils/Constants";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import APILoader from "../../Components/UI/APILoader";
import Notification from "../../Components/UI/Notification";
import { cancel_svg } from "../../Utils/SVGListing";

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
function isNumber(str) {
  return !isNaN(str);
}
export default function DefaultMultiBuillty({
  index,
  data,
  builltyDataOld,
  lrData,
  newLrData,
  selectedTab,
  setSelectedTab,
}) {
  const history = useHistory();
  // console.log(newLrData, "newLrData");
  const [notifOpts, setNotifOpts] = useState({});
  const [notif, setNotif] = useState(false);
  // const replaceArrayElementByLrNo = (array, lrNo, newArrayElement) => {
  //   return array.map((obj) => {
  //     if (obj.lrNo === lrNo) {
  //       return newArrayElement;
  //     }
  //     return obj;
  //   });
  // }; // eslint-disable-next-line
  const [builltyData, setbuilltyData] = useState(builltyDataOld[index]);
  const [year] = useState(data?.financialYear);
  const [startDate, setStartDate] = useState(moment(data?.lrDate).toDate());
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  const [showHamali, setShowHamali] = useState(false);  //true if want to add hamali
  const [weightUnit, setWeightUnit] = useState(
    builltyData?.weightType ? builltyData?.weightType : ""
  );
  const [onChangeWeight, SetonChangeWeight] = useState(
    builltyData?.actualWeight ? builltyData?.actualWeight : ""
  );
  const [consignorOptions, setConsignorOptions] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`${GET_COMPANY_TABLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        // console.log(response?.data?.data?.company);
        if (response?.data?.data?.company.length > 0) {
          const options = response?.data?.data?.company;
          setConsignorOptions(options);
        }
      });
  }, []);

  // Window Back btn path set
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.location.pathname = "/pending-booking";
        history.replace("/pending-booking");
      }
    };
  });
  const handleChangeWight = (e) => {
    setWeightUnit(e.value);
  };
  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  function validateInput(inputValue) {
    const fixPattern = /^Fix/i; // Modified to match only at the beginning
    const numberPattern = /^\d+(\.\d+)?$/;

    // Early exit if purely numeric
    if (numberPattern.test(inputValue)) {
      setNotif(false);
      SetonChangeWeight(inputValue);
      return true;
    }

    // Immediate error if the first letter isn't part of "Fix"
    if (inputValue.length > 0 && !fixPattern.test(inputValue)) {
      setNotif(true);
      setNotifOpts(
        error_notification({
          title:
            'If alphabets are present, the input must start with "Fix" (case-insensitive)',
        })
      );
      return false;
    }

    // Only perform detailed checks if the length is at least 3
    if (inputValue.length >= 3) {
      if (fixPattern.test(inputValue)) {
        setNotif(false);
        SetonChangeWeight(inputValue);
        return true;
      } else {
        // (Existing error message if it doesn't match "Fix" after 3 characters)
      }
    }

    // If no error conditions hit, let them continue typing
    SetonChangeWeight(inputValue);
  }

  const error_notification = (props) => ({
    mode: "failure",
    timeout: 5000,
    top: 10,
    title: props.title,
  });
  const FormSubmitHandler = async (e, path) => {
    e.preventDefault();
    // if (!onChangeWeight) {
    //   setNotif(true);
    //   setNotifOpts(
    //     error_notification({
    //       title: `Please add weight either "Fix" or Numbers`,
    //     })
    //   );
    //   return;
    // }
    // if (onChangeWeight.toLowerCase() !== "fix" && !weightUnit) {
    //   setNotif(true);
    //   setNotifOpts(error_notification({ title: `Please add weight unit` }));
    //   return;
    // }

    let btnValue = e.nativeEvent.submitter.value;
    if (btnValue === "saveNext" && selectedTab !== builltyDataOld.length - 1) {
      setSelectedTab(selectedTab + 1);
    } else if (
      btnValue === "saveNext" &&
      selectedTab === builltyDataOld.length - 1
    ) {
      path = "/bilty/billty-invoice-view";
    } else if (btnValue === "saveAndaddBuillty") {
      path = "/bilty/add-Multiple-builty";
      axiosInstance
        .get(GET_LR)
        .then((response) => {
          localStorage.setItem("lrValue", response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (btnValue === "save") {
      path = "/bilty/billty-invoice-view";
    }

    // setLoader({ ...loader, loading: true });
    const obj = formValueConvertor(getFormJSON(e.target));

    const ADD_BILLTY_DATA = {
      BookingId: builltyData?.bookingId,
      BilltyId: builltyData?.billtyId,
      FinancialYear: obj.Year,
      LrNo: obj.LrNo,
      LrDate: startDate,
      VehicleNo: obj.Vehicle_No,
      DeliveryAt: obj.Delivery_At,
      ConsignerName: obj.Consignor_Name.name || obj.Consignor_Name === " " ? builltyData?.consignerName : obj.Consignor_Name,
      ConsignerGST: obj.Consignor_GST_No,
      ConsignerAddress: obj.Consignor_Address,
      ConsigneeName: obj.Consignee_Name.name || obj.Consignee_Name === " " ? builltyData?.consigneeName : obj.Consignee_Name,
      ConsigneeGst: obj.Consignee_GST_No,
      ConsigneeAddress: obj.Consignee_Address,
      From: obj.From,
      To: obj.To,
      Packages: obj.Pakages,
      Detention: obj.detention === " " ? 0 : obj.detention,
      ExtraCharge: obj.extraCharge === " " ? 0 : obj.extraCharge,
      Hamali: obj.hamali === " " ? 0 : obj.hamali,
      Description: obj.Description,
      ActualWeight: obj.Actual_Weight,
      ChargedWeight: onChangeWeight || obj.Actual_Weight,
      WeightType: weightUnit || obj.WeightType,
      // onChangeWeight.toLowerCase() === "fix" ||
      // !onChangeWeight ||
      // !isNumber(onChangeWeight)
      //   ? "NA"
      //   : weightUnit,
      Createdby: localStorage.getItem("userId"),
    };
    // console.log(obj.LR_No);

    // let updatedArray;
    // if (selectedTab === builltyData.length - 1) {
    //   updatedArray = replaceArrayElementByLrNo(
    //     builltyData.length > 1 ? dataHistory : newLrData,
    //     obj.LrNo,
    //     ADD_BILLTY_DATA
    //   );
    // } else {
    //   const newArry = replaceArrayElementByLrNo(
    //     selectedTab === 0 ? newLrData : dataHistory,
    //     obj.LrNo,
    //     ADD_BILLTY_DATA
    //   );
    //   setDataHistory(newArry);
    // }

    if (ADD_BILLTY_DATA) {
      // let builly = { Billties: updatedArray };
      setLoader({ ...loader, loading: true });
      await axiosInstance
        .post(UPDATE_BUILTY, ADD_BILLTY_DATA)
        .then((response) => {
          if (
            btnValue === "saveNext" &&
            selectedTab === builltyDataOld.length - 1
          ) {
            ConfirormationModal(response.data.data.message, "success", 2000);
          } else if (btnValue === "save") {
            ConfirormationModal(response.data.data.message, "success", 2000);
          }
          history.push({
            pathname: path,
            openModel: true,
            state: { bookingId: builltyData?.bookingId },
          });
        })
        .catch((error) => {
          ConfirormationModal(error.message, "error", 2000);
        });
    }
    // console.log(updatedArray, "updatedArray");
    // console.log(ADD_BILLTY_DATA, "Addbuillty");
  };

  return (
    <>
      {/* <DashBoardLayout pageTitle="Update Buillty"> */}
      <APILoader loader={loader} />
      <Notification
        show={notif}
        setShow={setNotif}
        {...notifOpts}
        supportContent={true}
      ></Notification>
      <div className="">
        <div className="my-2 text-end">
          <ButtonWithBG
            theme="primary"
            label="Convert into Blank Buillty"
            type="reset"
            handleClick={() => {
              setbuilltyData({
                ...builltyData,
                consigneeAddress: "",
                consigneeName: "",
                consigneeGst: "",
                consignerName: "",
                consignerGst: "",
                consignerAddress: "",
                packages: "",
                description: "",
                actualWeight: "",
                extraCharge: "",
                detention: "",
                hamali: "",
                from: "",
                to: "",
              });
              SetonChangeWeight("");
            }}
          />
        </div>
        {/* <button onClick={()=>{console.log(data.id)}}>Click</button> */}
        <form onSubmit={FormSubmitHandler}>
          <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4">
            <div className=" w-full">
              <div className=" text-left space-y-6">
                {/* Fin year,Lr no, lr date*/}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Fin. Year" isRequired="true" />
                    <Input type="text" name="Year" defaultValue={year} />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="LR No." isRequired="true" />
                    <Input
                      type="text"
                      name="LrNo"
                      defaultValue={lrData?.lrNo}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label
                      label="LR Date"
                      isRequired={`${data.id === 0 ? "true" : "false"}`}
                    />
                    <div className="flex flex-row justify-center ">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                  </div>
                </div>
                {/* Delievery at,Vehicle_No, consignor name */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Delivery At" />
                    <Input
                      type="text"
                      name="Delivery_At"
                      value={builltyData?.from}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Vehicle No." isRequired="true" />
                    <Input
                      type="text"
                      name="Vehicle_No"
                      defaultValue={builltyData?.vehicleNo}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Consignor's Name" isRequired="true" />
                    <div className="flex flex-row">
                      <select
                        name="Consignor_Name"
                        type="text"
                        as="select"
                        onChange={(e) => {
                          const inputValue = JSON.parse(e.target.value); // Get the input text

                          setbuilltyData({
                            ...builltyData,
                            consignerName: inputValue.value,
                            consignerGst: inputValue.address,
                            consignerAddress: inputValue.label,
                          });
                        }}
                        autoComplete="off"
                        value={builltyData?.consignerName}
                        className="border-l rounded-l-lg
                                                form-control
                                                block
                                                w-full
                                                px-3
                                                py-2
                                                text-base
                                                font-normal
                                                bg-white bg-clip-padding
                                                transition
                                                ease-in-out
                                                m-0
                                                border-t  border-b border-r-0
                                                border border-gray-300 placeholder-gray-500  "
                      >
                        <option value="">
                          {builltyData.consignerName ||
                            "-- select an option --"}
                        </option>
                        {consignorOptions.map((option, index) => {
                          return (
                            <option
                              className="bg-white"
                              value={JSON.stringify({
                                value: option.name,
                                label: option.gst,
                                address: option.shipToAddress,
                              })}
                              id={option.name}
                              key={option.companyId}
                              label={option.name}
                            >
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                      <button
                        type="button"
                        className="-ml-px pl-2 relative inline-flex items-center space-x-2 pr-2 border-y border-r border-grey-300 text-sm font-medium rounded-r-md "
                        onClick={(e) => {
                          setbuilltyData({
                            ...builltyData,
                            consignerName: "",
                            consignerGst: "",
                            consignerAddress: "",
                          });
                        }}
                      >
                        {cancel_svg}
                      </button>
                    </div>
                    {/* <Label label="Consignor's Name" />
                    <Input
                      type="text"
                      name="Consignor_Name"
                      defaultValue={builltyData?.consignerName}
                      autoComplete="off"
                    /> */}
                  </div>
                </div>
                {/* Consignor Address,Gst,consignee name */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignor's Address" />
                    <Input
                      type="text"
                      name="Consignor_Address"
                      value={builltyData?.consignerAddress}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Consignor's GST" />
                    <Input
                      type="text"
                      name="Consignor_GST_No"
                      value={builltyData?.consignerGst}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's Name" isRequired="true" />
                    <div className="flex flex-row">
                      <select
                        name="Consignee_Name"
                        type="text"
                        as="select"
                        onChange={(e) => {
                          const inputValue = JSON.parse(e.target.value); // Get the input text

                          setbuilltyData({
                            ...builltyData,
                            consigneeName: inputValue.value,
                            consigneeGst: inputValue.label,
                            consigneeAddress: inputValue.address,
                          });
                        }}
                        autoComplete="off"
                        value={builltyData?.consigneeName}
                        className="border-l rounded-l-lg
                                                form-control
                                                block
                                                w-full
                                                px-3
                                                py-2
                                                text-base
                                                font-normal
                                                bg-white bg-clip-padding
                                                transition
                                                ease-in-out
                                                m-0
                                                border-t  border-b border-r-0
                                                border border-gray-300 placeholder-gray-500  "
                      >
                        <option value="">
                          {builltyData.consigneeName ||
                            "-- select an option --"}
                        </option>
                        {consignorOptions.map((option, index) => {
                          return (
                            <option
                              className="bg-white"
                              value={JSON.stringify({
                                value: option.name,
                                label: option.gst,
                                address: option.shipToAddress,
                              })}
                              id={option.name}
                              key={option.companyId}
                              label={option.name}
                            >
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                      <button
                        type="button"
                        className="-ml-px pl-2 relative inline-flex items-center space-x-2 pr-2 border-y border-r border-grey-300 text-sm font-medium rounded-r-md "
                        onClick={(e) => {
                          setbuilltyData({
                            ...builltyData,
                            consigneeAddress: "",
                            consigneeName: "",
                            consigneeGst: "",
                          });
                        }}
                      >
                        {cancel_svg}
                      </button>
                    </div>
                    {/* <Label label="Consignee's Name" />
                    <Input
                      type="text"
                      name="Consignee_Name"
                      defaultValue={builltyData?.consigneeName}
                      autoComplete="off"
                    /> */}
                  </div>
                </div>
                {/* Consignee_add,consignee gst from */}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's Address" />
                    <Input
                      type="text"
                      name="Consignee_Address"
                      value={builltyData?.consigneeAddress}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="Consignee's GST" />
                    <Input
                      type="text"
                      name="Consignee_GST_No"
                      value={builltyData?.consigneeGst}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="From" />
                    <Input
                      type="text"
                      name="From"
                      value={builltyData?.from}
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* To, Pakages ,description*/}
                <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                  <div className=" flex md:w-1/2  flex-col  text-left">
                    <Label label="To" />
                    <Input
                      type="text"
                      name="To"
                      defaultValue={builltyData?.to}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Packages" />
                    <Input
                      type="text"
                      name="Pakages"
                      defaultValue={builltyData?.packages}
                      autoComplete="off"
                    />
                  </div>
                  <div className=" flex flex-col text-left w-1/2">
                    <Label label="Description" />
                    <Input
                      type="text"
                      className="peer w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                      name="Description"
                      cols="30"
                      rows="5"
                      defaultValue={builltyData?.description}
                      autoComplete="off"
                    />
                  </div>
                </div>
                {/*Actual weight, weight unit and charged weight*/}
                <div className="flex   md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4  text-left">
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Actual Weight" />
                    <Input
                      name="Actual_Weight"
                      // required
                      autoComplete="off"
                      defaultValue={builltyData?.actualWeight}
                      onChange={(e) => {
                        validateInput(e.target.value);
                      }}
                      // SetonChangeWeight
                    />
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Weight Unit" />
                    {!isNumber(onChangeWeight) &&
                    !isNumber(data?.data?.actualWeight) ? (
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        NA
                      </p>
                    ) : (
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 5,
                          colors: {
                            ...theme.colors,
                            primary25: "#f68e92",
                            primary: "#ed1c24",
                          },
                        })}
                        defaultValue={{
                          label: weightUnit,
                          value: weightUnit,
                        }}
                        onChange={handleChangeWight}
                        options={WeightUnitOptions}
                      />
                    )}
                  </div>
                  <div className=" flex flex-col  text-left md:w-1/2">
                    <Label label="Charged Weight" />
                    <Input
                      defaultValue={
                        onChangeWeight || builltyData?.chargedWeight
                      }
                      step="any"
                      name="Charged_Weight"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div>
                  <div className=" flex flex-row space-x-5 text-left ">
                    <button
                      className="font-bold pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover p-2 rounded-md text-white "
                      type="button"
                      onClick={() => {
                        setShowHamali(true);
                      }}
                    >
                      +
                    </button>
                    <button
                      className="font-bold  pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover py-2 px-3 rounded-md text-white "
                      type="button"
                      onClick={() => {
                        setShowHamali(false);
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
                {showHamali && (
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Hamali" />
                      <Input
                        defaultValue={builltyData?.hamali}
                        //   onChange={values_handler}
                        name="hamali"
                        type="text"
                        step="any"
                      />
                    </div>
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Detention" />
                      <Input
                        //   onChange={values_handler}
                        defaultValue={builltyData?.detention}
                        name="detention"
                        type="text"
                        step="any"
                      />
                    </div>
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Extra Charge" />
                      <Input
                        defaultValue={builltyData?.extraCharge}
                        name="extraCharge"
                        type="text"
                        step="any"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="items-center mt-4 space-x-2">
            {builltyDataOld.length > 1 && (
              <ButtonWithBG
                value="saveNext"
                theme="primary"
                label="SAVE & Next"
                type="submit"
              />
            )}
            <ButtonWithBG
              value="save"
              theme="primary"
              label="SAVE"
              type="submit"
            />
            <ButtonWithBG
              type="submit"
              value="saveAndaddBuillty"
              theme="secondary"
              label="Save and add buillty"
            />
          </div>
        </form>
      </div>
      {/* </DashBoardLayout> */}
    </>
  );
}

// const renderInputWeigtchange  = onChangeWeight.toLowerCase() === "fix" ||
//                     !isNumber(onChangeWeight) ||
//                     !isNumber(data?.data?.actualWeight)
