import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import axiosInstance from "../../Utils/API";
import { ADD_BUILTY, GETVIEWBOOKINGBYID } from "../../Utils/ApiGlobal";
import Loader from "../../Components/UI/Loader";
import moment from "moment";
import { WeightUnitOptions } from "../../Utils/Constants";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import Notification from "../../Components/UI/Notification";

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
function isNumber(str) {
  return !isNaN(str);
}
// const error_notification = (props) => ({
//   mode: "failure",
//   timeout: 5000,
//   top: 10,
//   title: props.title,
// });
export default function AddBillty(update) {
  const location = useLocation();
  const history = useHistory();
  const [year] = useState(new Date().getFullYear());
  const [bookingData, setBookingData] = useState({});
  const [startDate, setStartDate] = useState(
    moment(bookingData?.lrDate).toDate()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [weightUnit, setWeightUnit] = useState();
  const [onChangeWeight, SetonChangeWeight] = useState("");
  const [notif, setNotif] = useState(false); // eslint-disable-next-line
  const [formErrors, setFormErrors] = useState({}); // eslint-disable-next-line
  const [notifOpts, setNotifOpts] = useState({});

  // To fetch Data from previous route
  useEffect(() => {
    // console.log(location)
    const BookingId = location?.state?.bookingId;
    GetBookingData(BookingId);
  }, [location]);

  const GetBookingData = async (bookingId) => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        const data = response?.data?.data?.bookingDetails;
        // console.log(data)
        setBookingData(data);
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        return;
      });
  };

  const handleChangeWight = (e) => {
    setWeightUnit(e.value);
  };
  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";

      return result;
    }, {});
  };

  const FormSubmitHandler = async (e) => {
    e.preventDefault();
    const obj = formValueConvertor(getFormJSON(e.target));
    // if (isNumber(onChangeWeight)) {
    //   // console.log(weightUnit)
    //   if (weightUnit === undefined || weightUnit === null) {
    //     setFormErrors({ weightType: true });
    //     setNotif(true);
    //     setNotifOpts(
    //       error_notification({ title: `Please select Weight Type` })
    //     );
    //     return;
    //   }
    // }
    const ADD_BILLTY_DATA = {
      Billties: [
        {
          BookingId: location?.state?.bookingId,
          FinancialYear: obj.Year,
          LrNo: obj.LR_No,
          LrDate: startDate,
          VehicleNo: obj.Vehicle_No,
          DeliveryAt: obj.Delivery_At,
          ConsignerName: obj.Consignor_Name,
          ConsignerGST: obj.Consignor_GST_No,
          ConsignerAddress: obj.Consignor_Address,
          ConsigneeName: obj.Consignee_Name,
          ConsigneeGst: obj.Consignee_GST_No,
          ConsigneeAddress: obj.Consignee_Address,
          From: obj.From,
          To: obj.To,
          Packages: obj.Pakages,
          Description: obj.Description,
          ActualWeight: obj.Actual_Weight,
          ChargedWeight: obj.Charged_Weight,
          WeightType:
            onChangeWeight.toLowerCase() === "fix" ||
            !onChangeWeight ||
            !isNumber(onChangeWeight)
              ? "NA"
              : weightUnit,
          Createdby: localStorage.getItem("userId"),
        },
      ],
    };
    await axiosInstance
      .post(ADD_BUILTY, ADD_BILLTY_DATA)
      .then((response) => {
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/bilty/billty-invoice-view",
          data: ADD_BILLTY_DATA.Billties[0],
          state: { bookingId: location?.state?.bookingId },
        });
        return;
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };
  return (
    <>
      <DashBoardLayout pageTitle="Add Buillty">
        <Notification
          show={notif}
          setShow={setNotif}
          {...notifOpts}
          supportContent={true}
        ></Notification>
        {isLoading ? (
          <Loader />
        ) : (
          <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
            <form onSubmit={FormSubmitHandler}>
              <div className="flex flex-col md:flex-row md:space-x-4 md:space-y-0 space-y-4">
                <div className=" w-full">
                  <div className=" text-left space-y-6">
                    {/* Fin year,Lr no, lr date*/}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Fin. Year" isRequired="true" />
                        <Input type="text" name="Year" defaultValue={year} />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="LR No." isRequired="true" />
                        <Input
                          type="text"
                          name="LR_No"
                          defaultValue={bookingData?.lrNo}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="LR Date" isRequired="true" />
                        <div className="flex flex-row justify-center ">
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* Delievery at,Vehicle_No, consignor name */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Delivery At" isRequired="true" />
                        <Input
                          type="text"
                          name="Delivery_At"
                          defaultValue={bookingData?.from}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Vehicle No." isRequired="true" />
                        <Input
                          type="text"
                          name="Vehicle_No"
                          defaultValue={bookingData?.vehicleNo}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Consignor's Name" isRequired="true" />
                        <Input
                          type="text"
                          name="Consignor_Name"
                          defaultValue={bookingData?.consignerName}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    {/* Consignor Address,Gst,consignee name */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignor's Address" isRequired="true" />
                        <Input
                          type="text"
                          name="Consignor_Address"
                          defaultValue={bookingData?.consignerAddress}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Consignor's GST" />
                        <Input
                          type="text"
                          name="Consignor_GST_No"
                          defaultValue={bookingData?.consignerGst}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignee's Name" isRequired="true" />
                        <Input
                          type="text"
                          name="Consignee_Name"
                          defaultValue={bookingData?.consigneeName}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    {/* Consignee_add,consignee gst from */}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignee's Address" isRequired="true" />
                        <Input
                          type="text"
                          name="Consignee_Address"
                          defaultValue={bookingData?.consigneeAddress}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Consignee's GST" />
                        <Input
                          type="text"
                          name="Consignee_GST_No"
                          defaultValue={bookingData?.consigneeGst}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="From" isRequired="true" />
                        <Input
                          type="text"
                          name="From"
                          defaultValue={bookingData?.from}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    {/* To, Pakages ,description*/}
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="To" isRequired="true" />
                        <Input
                          type="text"
                          name="To"
                          defaultValue={bookingData?.to}
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Packages" isRequired="true" />
                        <Input
                          type="text"
                          name="Pakages"
                          required
                          autoComplete="off"
                        />
                      </div>
                      <div className=" flex flex-col text-left w-1/2">
                        <Label label="Description" />
                        <Input
                          type="text"
                          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                          name="Description"
                          cols="30"
                          rows="5"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    {/*Actual weight, weight unit and charged weight*/}
                    <div className="flex   flex-row md:space-x-4 md:space-y-0 space-y-4  text-left">
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Actual Weight" isRequired="true" />
                        <Input
                          name="Actual_Weight"
                          // required
                          autoComplete="off"
                          onChange={(e) => {
                            SetonChangeWeight(e.target.value);
                          }}
                          // SetonChangeWeight
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Weight Unit" isRequired="true" />
                        {onChangeWeight.toLowerCase() === "fix" ||
                        !onChangeWeight ||
                        !isNumber(onChangeWeight) ? (
                          <p
                            className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          >
                            NA
                          </p>
                        ) : (
                          <Select
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 5,
                              colors: {
                                ...theme.colors,
                                primary25: "#f68e92",
                                primary: "#ed1c24",
                              },
                            })}
                            onChange={handleChangeWight}
                            options={WeightUnitOptions}
                          />
                        )}
                        {formErrors.weightType === true && (
                          <p className="text-red-500 font-bold text-left text-xs">
                            Please select Weight Type
                          </p>
                        )}
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Charged Weight" isRequired="true" />
                        <Input
                          defaultValue={onChangeWeight}
                          type="text"
                          step="any"
                          name="Charged_Weight"
                          autoComplete="off"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="items-ceter mt-4">
                <ButtonWithBG theme="primary" label="SAVE" type="submit" />
              </div>
            </form>
          </div>
        )}
      </DashBoardLayout>
    </>
  );
}
