import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import {
  COMPANY_PRINT_LEDGER,
  GET_COMPANY_LEDGER,
  GET_LEDGER_OTHERPARTY,
  GET_LEDGER_PARTY,
  GET_LEDGER_TRANSPORTER,
  GET_VEHICLEOWNER_LEDGER,
  ISLEDGERPRINT,
  OTHERPARTY_PRINT_LEDGER,
  THIRDPARTY_PRINT_LEDGER,
  TRANSPORTER_PRINT_LEDGER,
  VEHICLEOWNER_PRINT_LEDGER,
} from "../../Utils/ApiGlobal";
// import { Lendger } from "../../Utils/Constants";
import TablePagination from "../UI/Table/TablewithPagination";
import moment from "moment";
import Loader from "../UI/Loader";
import DatePicker from "react-datepicker";
import Checkbox from "../UI/Checkbox";
import ButtonWithBG from "../UI/ButtonMain";
import { CheckboxDes } from "../../Utils/Constants";

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function LendgerTable(props) {
  // for Search
  const location = useLocation();
  const history = useHistory();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerData, SetLedgerData] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalCredit, setCredit] = useState(0);
  const [totalDebit, setDebit] = useState(0);
  const [error, setError] = useState(false);
  // const filterLedger = ({ Date }) => {
  //   return Date.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };
  const columns = [
    {
      Header: "",
      id: "invoiceId",
      maxWidth: 10,
      minWidth: 10,
      width: 10,
      Cell: (row) => (
        <div>
          <Checkbox
            name={row.row.original.type}
            value={
              localStorage.getItem("component") === "Vehicle Owner"
                ? row.row.original.ownerPaymentId
                : localStorage.getItem("component") === "Transporter"
                ? row.row.original?.transporterLedgerId
                : row.row.original?.ledgerId
            }
            className="accent-red-600	text-white"
          />
        </div>
      ),
    },
    {
      Header: "#",
      accessor: (d, index) => {
        return index + 1;
      },
      maxWidth: 10,
      minWidth: 10,
      width: 10,
    },
    {
      Header: "Date",
      accessor: (d) => {
        return moment(d.date ? d.date : d.loadingDate).format("DD-MM-YYYY");
      },
    },
    {
      Header: "Is Print",
      accessor: (d) => {
        if (d.isPrint === true) {
          return <span>Yes</span>;
        } else {
          return <span>No</span>;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left">Total Amount:</span>,
    },
    {
      Header: "Debit",
      accessor: (d) => {
        if (d.creditOrDebit === "Debit") {
          return d.amount;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{totalDebit}</span>,
    },
    {
      Header: "Credit",
      accessor: (d) => {
        if (d.creditOrDebit === "Credit") {
          return d.amount;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{totalCredit}</span>,
    },
    {
      Header: "Balance",
      accessor: "amount",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{totalPayment} Dr</span>,
    },
  ];
  useEffect(() => {
    getLedger(location);
    // console.log(location?.state?.data?.data);
  }, [location, startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps
  // Get ledger
  const getLedger = async (location) => {
    SetLedgerData([]);
    setIsLoading(true);
    let API_CALL;
    let StartDate =
      startDate === undefined ? "" : moment(startDate).format("YYYY-MM-DD");
    let EndDate =
      endDate === undefined ? "" : moment(endDate).format("YYYY-MM-DD");
    if (localStorage.getItem("component") === "Vehicle Owner") {
      API_CALL = `${GET_VEHICLEOWNER_LEDGER}?vehicleOwnerId=${localStorage.getItem(
        "id"
      )}&firstDate=${StartDate}&lastDate=${EndDate}`;
    } else if (localStorage.getItem("component") === "Company") {
      API_CALL = `${GET_COMPANY_LEDGER}?companyId=${localStorage.getItem(
        "id"
      )}&firstDate=${StartDate}&lastDate=${EndDate}`;
    } else if (localStorage.getItem("component") === "Transporter") {
      API_CALL = `${GET_LEDGER_TRANSPORTER}?transporterId=${localStorage.getItem(
        "id"
      )}&firstDate=${StartDate}&lastDate=${EndDate}`;
    } else if (localStorage.getItem("component") === "Third Party") {
      API_CALL = `${GET_LEDGER_PARTY}?thirdPartyId=${localStorage.getItem(
        "id"
      )}&firstDate=${StartDate}&lastDate=${EndDate}`;
    } else if (localStorage.getItem("component") === "Other Party") {
      //Other Party Addition
      API_CALL = `${GET_LEDGER_OTHERPARTY}?otherPartyId=${localStorage.getItem(
        "id"
      )}&firstDate=${StartDate}&lastDate=${EndDate}`;
    }
    await axiosInstance
      .get(API_CALL)
      .then((response) => {
        // console.log(response);
        if (localStorage.getItem("component") === "Vehicle Owner") {
          if (response?.data?.data?.payments.length > 0) {
            const data = response?.data?.data?.payments;
            // console.log(response?.data?.data);
            SetLedgerData(data);
            setTotalPayment(response?.data?.data?.total);
          }
        } else if (localStorage.getItem("component") === "Company") {
          if (response?.data?.data?.ledgers.length > 0) {
            const data = response?.data?.data?.ledgers;
            // console.log(response?.data?.data);
            SetLedgerData(data);
            setTotalPayment(response?.data?.data?.total);
          }
        } else if (localStorage.getItem("component") === "Transporter") {
          if (response?.data?.data?.transporterLedger.length > 0) {
            const data = response?.data?.data?.transporterLedger;
            setTotalPayment(response?.data?.data?.grandTotal);

            SetLedgerData(data);
          }
        } else if (localStorage.getItem("component") === "Third Party") {
          // console.log("here");
          if (response?.data?.data?.ledgers?.length > 0) {
            const data = response?.data?.data?.ledgers;
            SetLedgerData(data);
            setTotalPayment(response?.data?.data?.total);
          }
        } else if (localStorage.getItem("component") === "Other Party") {
          //Other Party Addition
          // console.log("here");
          if (response?.data?.data?.ledgers?.length > 0) {
            const data = response?.data?.data?.ledgers;
            SetLedgerData(data);
          }
        }
        setCredit(response?.data?.data?.totalCredit);
        setDebit(response?.data?.data?.totalDebit);
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
        return;
      });
  };

  // Get Print Invoice API
  const PrintBtnClick = async () => {
    setIsLoading(true);
    let checkedBoxes = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );
    let InvoicesPara = Array.from(checkedBoxes).map((x) => {
      return x.value;
    });
    let ledgerDetails = {
      CompanyId: localStorage.getItem("id"),
      Invoices: InvoicesPara,
    };
    let API_CALL;
    if (localStorage.getItem("component") === "Company") {
      API_CALL = `${COMPANY_PRINT_LEDGER}`;
    } else if (localStorage.getItem("component") === "Vehicle Owner") {
      API_CALL = `${VEHICLEOWNER_PRINT_LEDGER}`;
    } else if (localStorage.getItem("component") === "Third Party") {
      API_CALL = `${THIRDPARTY_PRINT_LEDGER}`;
    } else if (localStorage.getItem("component") === "Transporter") {
      API_CALL = `${TRANSPORTER_PRINT_LEDGER}`;
    } else if (localStorage.getItem("component") === "Other Party") {
      API_CALL = `${OTHERPARTY_PRINT_LEDGER}`;
    }

    let Data = {
      Id: localStorage.getItem("id"),
      Type: "VehicleBookingInvoice",
    };
    // Ledger print
    await axiosInstance
      .post(API_CALL, ledgerDetails)
      .then((response) => {
        if (response?.data?.data?.invoiceNo) {
          axiosInstance
            .post(ISLEDGERPRINT, Data)
            .then((response) => {
              // console.log(response);
            })
            .catch((error) => {
              console.log("error");
            });
          history.push({
            pathname: "/Ledger-Details/ledger-view",
            data: response?.data?.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
      });
  };
  // console.log(props,"for table")
  return (
    <section>
      <div className="flex flex-row space-x-2">
        <div className=" flex flex-col  text-left ">
          <Label label="Start Date" />
          <div className="flex flex-row justify-center w-full">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              className="peer	 w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
              placeholderText={"Please select start date"}
              onChange={(date) => {
                // console.log(date);
                setStartDate(date);
              }}
            />
          </div>
        </div>
        <div className=" flex flex-col  text-left ">
          <Label label="End Date" />
          <div className="flex flex-row justify-center ">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
              selected={endDate}
              onChange={(date) => {
                // console.log(date);
                setEndDate(date);
              }}
            />
          </div>
        </div>
      </div>
      <p className="text-left font-semibold	 text-xs	mt-2">
        {CheckboxDes} ledgers.
      </p>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mt-2">
          {error ? (
            <p className="text-warn-error">No data found</p>
          ) : (
            <TablePagination
              data={ledgerData}
              columns={columns}
              isFooter
              noPagination
            />
          )}
          <div className="flex mt-10 flex-row space-x-2 justify-center items-center">
            {/* {invoiceData?.details && ( */}
            <ButtonWithBG
              label="Genrate Ledger"
              handleClick={PrintBtnClick}
            ></ButtonWithBG>
          </div>
        </div>
      )}
    </section>
  );
}
