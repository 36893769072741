import React from "react";

export default function BillingBothPaid({ 
    invoice, profileData ,heading,blackWhite,location,Today_Date,invoiceNo,borderToggle
}) {
  return (
    <div
      className={`p-2 flex font-sans flex-row justify-between`}
    >
      <div className="flex flex-col text-left w-1/2">
        <div
          className={`font-bold uppercase text-md	py-1 mb-0 px-5 w-36  ${heading(
            blackWhite
          )}`}
        >
          {" "}
          Invoice to
        </div>
        <div>
          <h1 className="text-left uppercase font-bold font-sans text-2xl	subpixel-antialiased tracking-wide mt-0	">
            {invoice?.name}
          </h1>
        </div>
        <div className="text-sm text-left">
          <div className="flex  flex-row mt-1 ">
            <p className="">
              Address : {invoice?.address}
            </p>
          </div>
        </div>
        <div className="text-sm mt-1">
          <div className="flex flex-row text-left">
            <p className="">
              GST : {invoice?.gst}
            </p>
          </div>
        </div>
      </div>

      {/* Right side */}
      <div className="flex  flex-col text-right justify-end items-end">
        <div
          className={`font-bold uppercase text-md	py-1 mb-0 px-5  ${
            blackWhite
              ? "text-black border border-black rounded-lg"
              : "text-white rounded-lg bg-primary-kirtiroadways"
          } `}
        >
          {" "}
          OUR Details
        </div>
        <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
          <p className="flex flex-col text-md">
            <span>Service : {profileData.service}</span>
            <span>Pan No. : {profileData.panNo}</span>
            <span>State Code : {profileData.stateCode}</span>
          </p>
        </div>

        <div
          className={`uppercase font-semibold	pt-1 mt-2 pl-16 border-t-2 ${borderToggle(
            blackWhite
          )} `}
        >
          <div className="flex flex-row text-sm text-right space-x-12">
            <span className="flex flex-col ">
              <p className="text-right min-w-1/2">
                Invoice No : {invoiceNo}
                {/* {location?.data?.data?.bookingInvoiceNo} */}
              </p>
              {/* <p className="text-right min-w-1/2">
                Company Invoice No : {location?.data?.data?.companyInvoiceNo}
              </p> */}
              <p className="text-right min-w-1/2">Date : {Today_Date}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
