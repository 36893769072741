import moment from "moment";

const billingDetails = async (invoice, profileData) => {
  // console.log(invoice); // eslint-disable-next-line
  // eslint-disable-next-line
  const billingDetails = [
    {
      table: {
        widths: ["50%", "50%"],
        headerRows: 1,
        fontSize: 9,
        bold: true,
        heights: [25],
        body: [
          [
            {
              border: [false, false, false, false],
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 100,
                      h: 25,
                      r: 5,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  absolutePosition: { x: 23, y: 8 },
                  text: "INVOICE TO",
                  color: "#000",
                  fontSize: 11,
                  bold: true,
                },
              ],
            },
            {
              alignment: "right",
              border: [false, false, false, false],
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 100,
                      h: 25,
                      r: 5,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  alignment: "center",
                  absolutePosition: { x: 455, y: 8 },
                  text: "OUR DETAILS",
                  color: "#000",
                  fontSize: 11,
                  bold: true,
                },
              ],
            },
          ],
        ],
      },
    },
    {
      columns: [
        {
          width: 300,
          stack: [
            {
              text: `${invoice?.transporterName?.toUpperCase()}`,
              color: "#000",
              fontSize: 11,
              bold: true,
              margin: [0, 5, 0, 0],
            },
            {
              text: `ADDRESS :${invoice?.address}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
            },
            {
              text: `GST : ${
                invoice?.companyGst ? invoice?.companyGst.toUpperCase() : "-"
              }`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
            },
          ],
        },
        {
          text: "",
          width: "*",
        },
        {
          alignment: "right",
          width: 180,
          stack: [
            {
              text: `SERVICE : ${profileData?.service}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `PAN NO. : ${profileData?.panNo}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `STATE CODE : ${profileData?.stateCode}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              canvas: [
                {
                  type: "line",
                  x1: 200,
                  y1: 5,
                  x2: 20,
                  y2: 5,
                  lineWidth: 2,
                },
              ],
              margin: [0, 0, 0, 2],
            },
            {
              text: `INVOICE NO : ${
                invoice.invoiceNo || invoice?.bookingInvoiceNo
                  ? invoice?.invoiceNo
                    ? invoice?.invoiceNo
                    : invoice?.bookingInvoiceNo
                    ? invoice?.bookingInvoiceNo
                    : "--"
                  : invoice?.invoiceNo
                  ? invoice?.invoiceNo
                  : "--"
              }`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `DATE : ${moment().format("DD-MM-YYYY")}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
          ],
        },
      ],
    },
  ];
  const billingDetailsA5 = [
    {
      columns: [
        {
          alignment: "left",
          margin: [0, 0, 0, 5], // Set margin to 0
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 85,
                  h: 20,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: 18, y: -15 },
              text: "INVOICE TO",
              color: "#000",
              fontSize: 9,
              bold: true,
            },
          ],
        },
        {
          margin: [0, 0, 0, 0], // Set margin to 0
          alignment: "right",
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 85,
                  h: 20,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              alignment: "right",
              relativePosition: { x: -16, y: -15 },
              text: "OUR DETAILS",
              color: "#000",
              fontSize: 9,
              bold: true,
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          width: 180,
          stack: [
            {
              text: `${invoice?.transporterName?.toUpperCase()}`,
              color: "#000",
              fontSize: 10,
              bold: true,
              margin: [0, 0, 0, 0],
            },
            {
              text: `ADDRESS : ${invoice?.address}`,
              color: "#000",
              fontSize: 8,
              margin: [0, 5, 0, 0],
            },
          ],
        },
        {
          text: "",
          width: "*",
        },
        {
          alignment: "right",
          width: 120,
          stack: [
            {
              text: `SERVICE : ${profileData?.service}`,
              color: "#000",
              fontSize: 7,
              margin: [0, 0, 0, 0],
              alignment: "right",
            },
            {
              text: `PAN NO. : ${profileData?.panNo}`,
              color: "#000",
              fontSize: 7,
              margin: [0, 3, 0, 0],
              alignment: "right",
            },
            {
              text: `STATE CODE : ${profileData?.stateCode}`,
              color: "#000",
              fontSize: 7,
              margin: [0, 3, 0, 0],
              alignment: "right",
            },
            {
              canvas: [
                {
                  type: "line",
                  x1: 140,
                  y1: 5,
                  x2: 20,
                  y2: 5,
                  lineWidth: 2,
                },
              ],
              margin: [0, 0, 0, 2],
            },
            {
              text: `INVOICE NO : ${
                invoice.invoiceNo || invoice?.bookingInvoiceNo
                  ? invoice?.invoiceNo
                    ? invoice?.invoiceNo
                    : invoice?.bookingInvoiceNo
                    ? invoice?.bookingInvoiceNo
                    : "--"
                  : invoice?.invoiceNo
                  ? invoice?.invoiceNo
                  : "--"
              }`,
              color: "#000",
              fontSize: 7,
              margin: [0, 3, 0, 0],
              alignment: "right",
            },
            {
              text: `DATE : ${moment().format("DD-MM-YYYY")}`,
              color: "#000",
              fontSize: 7,
              margin: [0, 3, 0, 0],
              alignment: "right",
            },
          ],
        },
      ],
    },
  ];
  return billingDetailsA5;
};

const billingTable = async (invoice) => {
  const headerColumns = (column) => {
    // console.log(column);
    if (column === "transporterExtraCharge") return "EXTRA CHARGE";
    if (column === "transporterDetention") return "DETENTION";
    if (column === "transporterHamali") return "HAMALI";
    if (column === "transporterFreigth") return "FREIGHT";
    if (column === "transporterBalance") return "BALANCE";
    if (column === "transporterAdvance") return "ADVANCE";
    if (column === "loadingDate") return "LOADING DATE";
    if (column === "vehicleNo") return "TRUCK NO.";
    if (column === "from") return "FROM";
    if (column === "to") return "TO";
  };

  function getColumnsWidths(invoices) {
    const hasHamali = invoices.some(
      (invoice) => invoice.transporterHamali !== 0 && invoice.hamali !== null
    );

    const hasDetention = invoices.some(
      (invoice) =>
        invoice.transporterDetention !== 0 && invoice.detention !== null
    );

    const hasExtraCharge = invoices.some(
      (invoice) =>
        invoice.transporterExtraCharge !== 0 && invoice.extraCharge !== null
    );

    let checkExtraRow = hasHamali && hasDetention && hasExtraCharge;

    let widthManipulate = checkExtraRow ? "auto" : "*";

    let widths = [
      "*",
      "*",
      "*",
      widthManipulate,
      widthManipulate,
      widthManipulate,
      widthManipulate,
    ];

    // Check for hamali column
    if (
      invoices.some(
        (invoice) => invoice.transporterHamali !== 0 && invoice.hamali !== null
      )
    ) {
      widths.push("auto");
    }

    if (
      invoices.some(
        (invoice) =>
          invoice.transporterDetention !== 0 && invoice.detention !== null
      )
    ) {
      widths.push("auto");
    }
    if (
      invoices.some(
        (invoice) =>
          invoice.transporterExtraCharge !== 0 && invoice.extraCharge !== null
      )
    ) {
      widths.push("auto");
    }

    return widths;
  }

  function selectHeaders(data, columns) {
    var body = [];

    data.forEach(function (row) {
      var dataRow = [];

      columns.forEach(function (column) {
        if (row[column] !== 0 && row[column] !== null) {
          dataRow.push(column);
        }
      });

      body.push(...dataRow);
    });

    return body;
  }

  function buildTableBody(data, columns) {
    var body = [];
    const hasHamali = data?.some(
      (invoice) => invoice.transporterHamali !== 0 && invoice.hamali !== null
    );

    const hasDetention = data?.some(
      (invoice) =>
        invoice.transporterDetention !== 0 && invoice.detention !== null
    );

    const hasExtraCharge = data?.some(
      (invoice) =>
        invoice.transporterExtraCharge !== 0 && invoice.extraCharge !== null
    );

    let checkExtraColumn = hasHamali || hasDetention || hasExtraCharge;

    let fontSizeManipulate = checkExtraColumn ? 6 : 7;

    data.forEach(function (row) {
      var dataHeader = [];

      columns.forEach(function (column, i) {
        // console.log(headerColumns(column));
        if (row[column] !== 0 && row[column] !== null) {
          dataHeader.push({
            text: headerColumns(column),
            bold: true,
            fontSize: fontSizeManipulate,
            alignment: "center",
            border: [
              i === 0 ? false : true,
              true,
              i === columns.length - 1 ? false : true,
              true,
            ],
          });
        }
      });

      body.push(dataHeader);
    });

    data.forEach(function (row) {
      var dataRow = [];

      columns.forEach(function (column, i) {
        if (row[column] !== 0 && row[column] !== null) {
          dataRow.push({
            text:
              column === "loadingDate"
                ? moment(row[column]).format("DD-MM-YYYY")
                : row[column],
            bold: true,
            fontSize: fontSizeManipulate - 0.5,
            alignment: "center",
            border: [
              i === 0 ? false : true,
              false,
              i === columns.length - 1 ? false : true,
              false,
            ],
          });
        }
      });

      body.push(dataRow);
    });

    return body;
  }

  const headers = selectHeaders(
    [invoice],
    [
      "loadingDate",
      "vehicleNo",
      "from",
      "to",
      "transporterFreigth",
      "transporterAdvance",
      "transporterBalance",
      "transporterHamali",
      "transporterDetention",
      "transporterExtraCharge",
    ]
  );

  // console.log(getColumnsWidths([invoice]));
  const table = {
    heights: 11,
    headerRows: 1,
    widths: getColumnsWidths([invoice]),
    bold: true,
    margin: [0, 10, 10, 10],
    body: buildTableBody([invoice], headers),
  };

  const content = [
    // Other content
    {
      table,
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 ? 1 : 0.5; // Set to 1 for the bottom border of each cell in the first row, 0.5 for other rows
        },
        hLineColor: function (i) {
          return i === 0 ? "#000" : "#000"; // Set color for the bottom border of each cell in the first row
        },
        paddingTop: function () {
          return 4; // Adjust padding as needed
        },
        paddingBottom: function () {
          return 4; // Adjust padding as needed
        },
        vLineWidth: function (i) {
          return 1; // Set to 0 for no vertical borders
        },
        vLineColor: function () {
          return "#000"; // Set to white or your background color
        },
      },
    },
    // Other content
  ];

  return content;
};

export const CommisionBillingContent = async (
  invoice,
  WE_USING_PAYPHONE,
  bankBranch,
  profileData
) => {
  const BillingInfo = await billingDetails(invoice, profileData);

  const BillingTabDATA = await billingTable(invoice);

  return [
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
    BillingInfo,
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
    BillingTabDATA,
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
  ];
};
