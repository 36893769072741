import React, { useEffect, useState } from "react";
import { useRef } from "react";
import InvoicePrint from "../../Components/UI/InvoicePrint";
import InvoiceView from "../../Components/UI/InvoiceView";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import Button from "../../Components/UI/ButtonMain";
import moment from "moment";
import {
  GETVIEWBOOKINGBYID,
  GET_ADDPAY,
  GET_COMPANY_INFORMATION_DATA,
} from "../../Utils/ApiGlobal";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import Loader from "../../Components/UI/Loader";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Checkbox from "../../Components/UI/Checkbox";
// import Scrollbars from "react-custom-scrollbars-2";
import MobileBuillty from "./MobileBuillty";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
//importing the encoded font file into our project
import { font } from "../../Utils/PTSerif-Regular";
import { robotoBlack } from "../../Utils/Roboto-Black";
import { PTSerifBold } from "../../Utils/PTSerif-Bold";
import { header } from "../../Layouts/pdfLayout/HeaderLayout";
import { footer } from "../../Layouts/pdfLayout/FooterLayout";
import { background } from "../../Layouts/pdfLayout/BackgroundLayout";
import { sortingArray } from "../../Components/Functionality/Helper";

const pdfMakeX = require("pdfmake/build/pdfmake.js");
const pdfFontsX = require("pdfmake-unicode/dist/pdfmake-unicode.js");
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
//importing the fonts whichever present inside vfs_fonts file
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["PTSerif-Regular.ttf"] = font;
window.pdfMake.vfs["PTSerif-Bold.ttf"] = PTSerifBold;
window.pdfMake.vfs["Roboto-Black.ttf"] = robotoBlack;

//Adding our own font into the vfs

const BilltyInvoiceView = (props) => {
  const pageStyle = `@page {
    size: A4;
  }
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
  }
`;
  let componentRef = useRef();
  const location = useLocation();
  // console.log(location?.data?.data?.bookingId, "ocation?.state");

  const history = useHistory();
  const [builltyData, setBuilltyData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const ref = useRef(null);
  const [open, setOpen] = useState(location?.openModel);
  const [information, setInformation] = useState("");
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [bookingInvoiceNo, setBookingInvoiceNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showBorder, setShowBorder] = useState(true);
  const [blackWhite] = useState(true); // eslint-disable-next-line
  const [mobilePrint, setMobilePrint] = useState(true);
  // const [builltyUpdateData, setBuilltyUpdatedata] = useState();
  // let open = location?.openModel;
  // let setOpen = location?.setOpenModel

  // console.log(props, "props");

  useEffect(() => {
    _FetchProfileAPI();
  }, []);
  const _FetchProfileAPI = async () => {
    setIsLoading(true);
    await axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(data);
        setProfileData(data);
        setsecondaryPhoneNo(response?.data?.data?.secondaryPhoneNo);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  // Window Back btn path set
  useEffect(() => {
    const BookingId =
      location?.state?.bookingId || location?.data?.data?.bookingId;
    GetBookingData(BookingId);
  }, [location]);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.location.pathname = "/pending-booking";
        history.replace("/pending-booking");
      }
    };
  });
  const generatePDFAndDisplay = async (action) => {
    try {
      const consigneeName = localStorage.getItem("consigneeName");
      // let token = localStorage.getItem("token");
      // // Get the entire content from the useReactToPrint reference
      // console.log("content", content);
      setIsLoading(true);
      // await handleDownloadPdf(consigneeName);
      handleDownloadPdf(consigneeName, builltyData, action)
        .then(() => {
          // Handle success if needed
          // console.log("PDF generated successfully");
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error generating PDF:", error);
        });
    } catch (error) {
      console.error("Error:", error);
      // Handle errors appropriately
    }
  };
  // Download pdf
  const sanitizeFilename = (filename) => {
    // Replace special characters with underscores
    let checkNull = filename.includes("null");
    if (checkNull) {
      return filename.replace(/[^\w\s]/gi, "_")?.replace("null", "");
    } else if (!checkNull) {
      return filename.replace(/[^\w\s]/gi, "_");
    }
  };

  async function handleDownloadPdf(consigneeName, data, action) {
    //Specifying the fonts which we are going to use in our PDF
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Black.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      PTSerifRegular: {
        normal: "PTSerif-Regular.ttf",
        bold: "PTSerif-Bold.ttf",
        italics: "PTSerif-Regular.ttf",
        bolditalics: "PTSerif-Regular.ttf",
      },
    };
    let lrNumbers = data.map((obj) => obj.lrNo);
    const lrNumbersString = lrNumbers.join("_");
    const sanitizedFilename = sanitizeFilename(lrNumbersString);
    const fileName = `${sanitizedFilename}.pdf`;
    // console.log(fileName,"fileName");
    // console.log(data,"data");
    const pdfDocDefinition = {
      pageMargins: [35, 175, 35, 120],
      header: await header(profileData, secondaryPhoneNo),
      content: await generateContent(data),
      info: {
        title: fileName,
      },
      footer: await footer(),
      styles: {
        table: {
          margin: [0, 0, 0, 0], // Adjust the top margin of the entire table
        },
        tableHeader: {
          fontSize: 9,
          alignment: "start", // 'start' aligns text to the start of the cell
          bold: true,
          fillColor: "#fff",
          color: "#000",
          margin: [0, 2, 0, 2], // Adjust the margin for each header cell
          borderBottom: "1px solid #28328c",
        },
        cellStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
        },
        header: {
          fontSize: 8,
          bold: true,
        },
        informationStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 1, 0, 0], // Adjust the margin for each header cell
        },
      },
      background: showBorder ? background : () => {},
      defaultStyle: {
        characterSpacing: 0.2,
      },
    };

    const callbackFunction = function () {
      // Here implement function for hide waiting loader
      setIsLoading(false);
    };
    // const sanitizedFilename = sanitizeFilename("Buillty of " + consigneeName);
    // const fileName = `${sanitizedFilename}.pdf`;

    const newLocal = pdfMake.createPdf(pdfDocDefinition);
    const pdfDocGenerator = newLocal;
    const handlePrint = () => {
      pdfDocGenerator.print();
      callbackFunction();
    };
    action === "download"
      ? pdfDocGenerator.download(fileName, callbackFunction)
      : handlePrint();

    // pdfDocGenerator.getBlob((dataBlob) => {
    //   const url = URL.createObjectURL(dataBlob);

    //   const a = document.createElement("a");
    //   a.href = url;
    //   // a.download = fileName;
    //   window.open(url, "_blank");
    //   document.body.appendChild(a);
    //   a.click();

    //   document.body.removeChild(a);
    //   URL.revokeObjectURL(url);

    //   // history.push("/bilty/billty-invoice-view");
    //   setIsLoading(false);
    // });

    //     const pdfDocGenerator = pdfMake.createPdf(pdfDocDefinition);

    //     pdfDocGenerator.getBlob((dataBlob) => {
    //       let link = document.createElement('a');
    // link.download = 'hello.txt';
    // link.href = URL.createObjectURL(dataBlob);
    //       // Open the PDF in a new tab

    //       link.click();

    //       URL.revokeObjectURL(link.href);
    //       setIsLoading(false);
    //     });
  }
  async function generateContent(data) {
    // Ensure 'data' is an array before calling 'map'
    if (!Array.isArray(data)) {
      throw new Error("'data' should be an array.");
    }

    // Use map to create an array of promises
    const contentPromises = data.map(async (subData, i) => {
      const pagesContent = [];

      //***pages for CONSIGNOR, CONSIGNEE, DRIVER
      pagesContent.push(
        ...(await generatePageContent(subData, "CONSIGNOR", i)),
        ...(await generatePageContent(subData, "CONSIGNEE", i)),
        ...(await generatePageContent(subData, "DRIVER", i))
      );

      if (i < data.length - 1) {
        // Add page break if not the last iteration
        pagesContent.push({ text: "", pageBreak: "after" });
      }

      return pagesContent;
    });

    // Wait for all promises to resolve and flatten the array
    return (await Promise.all(contentPromises)).flat();
  }

  async function generatePageContent(data, role, i) {
    return [
      await productTable(data, profileData, "CAUTION", role),
      {
        margin: [0, 7, 0, 0],
        style: "color",
        text: "",
      },
      await productTable(data, profileData, "CONSIGNOR", role),
      {
        margin: [0, 7, 0, 0],
        style: "color",
        text: "",
      },
      await productTable(data, profileData, "from", role),
      {
        margin: [0, 7, 0, 0],
        style: "color",
        text: "",
      },
      await productTable(data, profileData, "items", role),
      {
        margin: [0, 7, 0, 0],
        style: "color",
        text: "",
      },
      await productTable(data, profileData, "Pay", role),
      {
        margin: [0, 7, 0, 0],
        style: "color",
        text: "",
      },
      await productTable(data, profileData, "INVOICE", role),
      {
        margin: [0, 7, 0, 0],
        style: "color",
        text: "",
      },
    ];
  }
  async function productTable(data, profileData, type, role) {
    const CAUTION = [
      {
        caution1: `THE CONSIGMENT WILL NOT BE DETAINED DIVERTED, RE-RIUTED OR RE-BOOKED WITHOUT CONSIGNEE BANK WRITE AND PERMISSION WILL BE DELIVERED AT THE DESTINATION.`,
        caution2: `THE CONSIGNMENT COVERED BY THE SET OF SPECIAL LORRY RECEIPT FROM SHALL BE STORED AT THE TRANSPORT OPERATOR AND SHALL BE DELIVERED TO OR TO THE ORDER OF THE CONSIGNEE BANK WHOSE NAME IS MENTIONED IN THE LORRY RECEIPT IT WILL UNDER NO CIRUMSTANCE BE DELIVERED TO ANY ON WITHOUT THE WRITTEN AUTHORITY FROM THE CONSIGNEE BANK OR ITS ORDER ENDORSED ONE THE CONSIGNEE COPY OR ON A SEPARATE LETTER OF AUTHORITY.`,
        route: `PAN NO.:${profileData?.panNo}`,
        route1: "AT OWNER'S RISK ASSURANCE",
      },
    ];
    // const CONSIGNOR = [
    //   {
    //     consignerDetail: `ADDRESS: ${data?.consignerAddress}.`,
    //     consigneeDetail: `ADDRESS: ${data?.consigneeAddress}.`,
    //   },
    //   {
    //     consignerDetail: `GST NO: ${data?.consignerGst}`,
    //     consigneeDetail: `GST NO: ${data?.consigneeGst}`,
    //   },
    //   // Add more items as needed
    // ];

    const actualWeightPrefix =
      !data?.actualWeight ||
      data?.actualWeight?.toLowerCase() === "fix" ||
      data?.actualWeight === "" ||
      data?.actualWeight === " "
        ? data?.actualWeight?.toLowerCase() === "fix"
          ? data?.actualWeight
          : ""
        : data?.actualWeight;
    const chargedWeightPrefix =
      !data?.chargedWeight ||
      data?.chargedWeight?.toLowerCase() === "fix" ||
      data?.chargedWeight === "" ||
      data?.chargedWeight === " "
        ? data?.chargedWeight?.toLowerCase() === "fix"
          ? data?.chargedWeight
          : ""
        : data?.chargedWeight;

    const Items = [
      {
        package: `${data?.packages}`.toUpperCase(),
        description: `${data?.description}`.toUpperCase(),
        actualWeight: `${
          isNaN(+actualWeightPrefix) ? "fix" : actualWeightPrefix
        } ${
          isNaN(+actualWeightPrefix)
            ? ""
            : data?.actualWeight?.toLowerCase() !== "fix"
            ? data.weightType && !data.actualWeight
              ? "-"
              : data.weightType
            : "NA"
        }`.toUpperCase(),
        chargedWeight: `${
          isNaN(+chargedWeightPrefix) ? "fix" : chargedWeightPrefix
        } ${
          isNaN(+chargedWeightPrefix)
            ? ""
            : data?.chargedWeight?.toLowerCase() !== "fix"
            ? data.weightType && !data.chargedWeight
              ? "-"
              : data.weightType
            : "NA"
        }`.toUpperCase(),
        rate: [`FREIGHT`, `SUR. CH.`, `ST. CH.`, `RISK CH.`],
        amount: data?.amountToPayOrPaid
          ? `${data?.amountToPayOrPaid}`.toUpperCase()
          : information !== ""
          ? information
          : "",
      },
      // Add more items as needed
    ];

    // eslint-disable-next-line
    if (type == "CAUTION") {
      // eslint-disable-next-line

      const tableBody = [
        [
          {
            text: [
              `FIN. YEAR : `,
              { text: `${data?.financialYear}`, bold: true },
            ],
            fontSize: 8,
          },
          {
            text: [`LR NO : `, { text: `${data?.lrNo}`, bold: true }],
            fontSize: 8,
          },
          {
            stack: [
              {
                text: CAUTION[0].route ? CAUTION[0].route : "-",
                fontSize: 9,
                bold: true,
                alignment: "center",
                decoration: "underline",
              },
              {
                text: `${role} COPY`,
                fontSize: 9,
                alignment: "center",
                margin: [0, 3, 0, 3],
              },
            ],
            rowSpan: 2,
            bold: true,
            fontSize: 8,
            alignment: "center",
          },
          {
            text: `COMPANY NAME :`,
            fontSize: 8,
          },
        ],
        [
          {
            colSpan: 2,
            text: [
              `LR DATE : `,
              {
                text: `${moment(data?.lrDate).format("DD-MM-YYYY")}`,
                bold: true,
              },
            ],
            fontSize: 8,
          },
          {
            text: "",
          },
          {
            text: "",
          },
          {
            text: `POLICY NO :${
              profileData?.policyNo !== "NA" ? profileData?.policyNo : ""
            }`,
            fontSize: 8,
            margin: [35, 0, 0, 0],
          },
        ],
        [
          {
            colSpan: 2,
            // text:'PLOT NO. 64, HARI OM INDUSTRIAL ESTATE GIDC,',
            text: [`LORRY NO : `, { text: `${data?.vehicleNo}`, bold: true }],
            fontSize: 8,
          },
          {
            text: "",
          },
          {
            text: "CONSIGNMENT NOTE:",
            rowSpan: 2,
            style: "tableHeader",
            alignment: "center",
            decoration: "underline",
            margin: [0, 5, 0, 5],
            characterSpacing: 0.5,
          },
          {
            text: "DATE :",
            fontSize: 8,
          },
        ],
        [
          {
            colSpan: 2,
            text: [
              `DELIVERY AT : `,
              { text: `${data?.to?.toUpperCase()}`, bold: true },
            ],
            fontSize: 8,
          },
          {
            text: "",
          },
          {
            text: "",
          },
          {
            text: "AMOUNT :",
            fontSize: 8,
          },
        ],
      ];

      const table = {
        widths: ["16.66%", "16.66%", "33.33%", "33.33%"],
        headerRows: 1,
        body: tableBody,
      };

      const content = [
        // Other content
        {
          table,
          layout: {
            hLineWidth: function (i, node) {
              return i === 0 ? 1 : 0.5; // Set to 1 for the bottom border of each cell in the first row, 0.5 for other rows
            },
            hLineColor: function (i) {
              return i === 0 ? "#000" : "#000"; // Set color for the bottom border of each cell in the first row
            },
            paddingTop: function () {
              return 4; // Adjust padding as needed
            },
            paddingBottom: function () {
              return 4; // Adjust padding as needed
            },
            vLineWidth: function (i) {
              return 1; // Set to 0 for no vertical borders
            },
            vLineColor: function () {
              return "#000"; // Set to white or your background color
            },
          },
        },
        // Other content
      ];

      return content;
    }
    // eslint-disable-next-line
    if (type == "CONSIGNOR") {
      const table = {
        alignment: "start",
        widths: ["50%", "50%"],
        heights: [24, 35, "*"],
        // headerRows: 1,
        fontSize: 9,
        bold: true,
        body: [
          [
            {
              text: `CONSIGNOR'S NAME : ${data?.consignerName}`,
              fontSize: 8,
              alignment: "start",
              border: [false, false, true, false],
              style: "informationStyle",
              bold: true,
              margin: data?.consignerName
                ? `${data?.consignerName}`?.split(" ").length > 5
                  ? [0, 2, 0, 0]
                  : [0, 6, 0, 0]
                : [0, 6, 0, 0],
            },
            {
              text: `CONSIGNEE'S NAME : ${data?.consigneeName}`,
              fontSize: 8,
              alignment: "start",
              bold: true,
              border: [false, false, false, false],
              style: "informationStyle",
              margin: data?.consigneeName
                ? `${data?.consigneeName}`?.split(" ").length > 5
                  ? [0, 2, 0, 0]
                  : [0, 6, 0, 0]
                : [0, 6, 0, 0],
            },
          ],
          [
            {
              text: `ADDRESS: ${data?.consignerAddress}.`,
              fontSize: 8,
              alignment: "start",
              border: [false, true, true, true],
              style: "informationStyle",
              bold: false,
              margin: [0, 3, 0, 0],
            },
            {
              text: `ADDRESS: ${data?.consigneeAddress}.`,
              fontSize: 8,
              alignment: "start",
              bold: false,
              border: [false, true, false, true],
              style: "informationStyle",
              margin: [0, 3, 0, 0],
            },
          ],
          [
            {
              text: `GST NO: ${data?.consignerGst}`,
              fontSize: 8,
              alignment: "start",
              border: [false, false, true, false],
              style: "informationStyle",
              bold: false,
              margin: [0, 2, 0, 2],
            },
            {
              text: `GST NO: ${data?.consigneeGst}`,
              fontSize: 8,
              alignment: "start",
              bold: false,
              border: [false, false, false, false],
              style: "informationStyle",
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      };

      const content = [
        {
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 525,
                  h: 90,
                  r: 4,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: 0, y: -89 },
              table,
            },
          ],
        },
        // Other content
      ];

      return content;
    } // eslint-disable-next-line
    if (type == "from") {
      const table = {
        widths: ["50%", "50%"],
        heights: ["*"],
        bold: true,
        body: [
          [
            {
              text: `FROM:- ${data?.from?.toUpperCase()}`,
              fontSize: 8,
              alignment: "start",
              border: [false, false, true, false],
              style: "informationStyle",
              bold: true,
              margin: [0, 3, 0, 3],
            },
            {
              text: `TO:- ${data?.to?.toUpperCase()}`,
              fontSize: 8,
              alignment: "start",
              bold: true,
              border: [false, false, false, false],
              style: "informationStyle",
              margin: [0, 3, 0, 3],
            },
          ],

          // ...tableBody,
        ],
      };

      const content = [
        {
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 525,
                  h: 21,
                  r: 4,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: 0, y: -21 },
              table,
            },
          ],
        },
        // Other content
      ];

      return content;
    } // eslint-disable-next-line
    if (type == "Pay") {
      const content = [
        {
          columns: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 525,
                  h: 62,
                  r: 4,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: -525, y: 0 },
              table: {
                widths: ["*"],
                layout: "noBorders",
                body: [
                  [
                    {
                      margin: [0, 2, 0, 2],
                      border: [false, false, false, true],
                      text: `TO PAY: ${findLrNo(data, "paidBy")}`.toUpperCase(),
                      fontSize: 7,
                      alignment: "center",
                      bold: true,
                    },
                  ],
                  [
                    {
                      margin: [0, 2, 0, 2],
                      border: [false, false, false, true],
                      text: `THE INVOICE SHOULD NOT BE PAID TO THE DRIVER.\nTHE COMPANY IS NOT RESPONSIVE FOR LEAKAGE, BREAKAGE & ILLEGAL GOODS`.toUpperCase(),
                      fontSize: 7,
                      alignment: "center",
                      bold: true,
                    },
                  ],
                  [
                    {
                      margin: [0, 2, 0, 2],
                      border: [false, false, false, false],
                      columns: [
                        {
                          width: 180,
                          alignment: "right",
                          text: `${
                            CAUTION[0].route1 ? CAUTION[0].route1 : "-"
                          } `,
                          decoration: "underline",
                        },
                        {
                          text: `: THE CUSTOMER HAS STARED THAT HE HAS INSURED THE CONSIGNMENT`,
                        },
                      ],
                      fontSize: 7,
                      bold: true,
                    },
                  ],
                ],
              },
            },
          ],
        },

        // ...tableBody,

        // Other content
      ];

      return content;
    } // eslint-disable-next-line
    if (type == "INVOICE") {
      const content = [
        { text: "CAUTION", decoration: "underline", fontSize: 9, bold: true },
        {
          fontSize: 8,
          ol: [
            {
              text: CAUTION[0].caution1 ? CAUTION[0].caution1 : "-",
              alignment: "justify",
              fontSize: 7,
              lineHeight: 1,
              font: "PTSerifRegular",
              margin: [0, 4, 0, 0],
            },
            {
              text: CAUTION[0].caution2 ? CAUTION[0].caution2 : "-",
              alignment: "justify",
              fontSize: 7,
              lineHeight: 1,
              font: "PTSerifRegular",
              margin: [0, 4, 0, 0],
            },
          ],
        },
      ];

      return content;
    } // eslint-disable-next-line
    if (type == "items") {
      const tableBody1 = [
        [
          {
            rowSpan: 2,
            text: "PACKAGES",
            margin: [0, 12, 0, 10],
            fontSize: 8,
            bold: true,
            alignment: "center",
          },
          {
            text: "DESCRIPTION (SAID TO CONTAINS)",
            margin: [0, 12, 0, 10],
            rowSpan: 2,
            fontSize: 8,
            bold: true,
            alignment: "center",
          },
          {
            text: "WEIGHT",
            colSpan: 2,
            fontSize: 8,
            bold: true,
            alignment: "center",
          },
          {
            text: "",
            alignment: "center",
          },
          {
            text: "RATE",
            rowSpan: 2,
            fontSize: 8,
            bold: true,
            margin: [0, 12, 0, 10],
            alignment: "center",
          },
          {
            text: "AMOUNT TO PAY/PAID",
            bold: true,
            fontSize: 8,
            alignment: "center",
          },
        ],
        [
          "",
          "",
          {
            text: "ACTUAL",
            fontSize: 7,
            style: "tableHeader",
            border: [true, true, true, true],
            alignment: "center",
          },
          {
            text: "CHARGED",
            fontSize: 7,
            style: "tableHeader",
            border: [false, true, true, true],
            alignment: "center",
          },
          "",
          {
            text: "RS",
            style: "tableHeader",
            alignment: "center",
            border: [false, true, true, true],
          },
        ],
        [
          {
            rowSpan: 6,
            text: Items[0].package ? Items[0].package : "-",
            fontSize: 8,
            border: [true, true, true, true],
            style: "cellStyle",
          },
          {
            rowSpan: 4,
            text: Items[0].description ? Items[0].description : "-",
            fontSize: 8,
            border: [true, true, true, false],
            style: "cellStyle",
          },
          {
            rowSpan: 2,
            colSpan: 1,
            text: Items[0].actualWeight,
            fontSize: 8,
            style: "cellStyle",
            border: [true, true, true, true],
          },
          {
            rowSpan: 2,
            colSpan: 1,
            text: Items[0].chargedWeight,
            fontSize: 8,
            style: "cellStyle",
            border: [false, true, true, true],
          },
          {
            rowSpan: 5,
            style: "cellStyle",
            stack: Items[0].rate.map((rate) => {
              return {
                margin: [0, 7.5, 0, 7.5],
                text: rate ? rate : "-",
                fontSize: 8,
                bold: true,
              };
            }),
            bold: true,
          },
          {
            margin:
              Items[0].amount?.length > 20
                ? Items[0].amount?.length > 40
                  ? [0, 30, 0, 0]
                  : [0, 35, 0, 0]
                : [0, 40, 0, 0],
            text: Items[0].amount ? Items[0].amount : " ",
            fontSize: 8,
            rowSpan: 5,
            style: "cellStyle",
          },
        ],
        [
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
        ],
        [
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          {
            text: "COMPANY INVOICE NO.",
            colSpan: 2,
            fontSize: 8,
            style: "cellStyle",
            margin: [0, 0, 0, 3],
            bold: true,
          },
          {},
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
        ],
        [
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          {
            text: `${findLrNo(data) ? findLrNo(data)?.toUpperCase() : ""}`,
            rowSpan: 2,
            colSpan: 2,
            fontSize: 8,
            style: "cellStyle",
            margin: [0, 10, 0, 10],
          },
          {},
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
        ],
        [
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          {
            text: "AS PER INVOICE",
            fontSize: 8,
            border: [true, false, true, false],
            alignment: "center",
          },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
          { text: "", border: [true, false, true, false], style: "cellStyle" },
        ],
        [
          { text: "", border: [true, false, true, true], style: "cellStyle" },
          {
            text: `PRIVATE MARKS: ${
              data?.privateMarks !== null
                ? (data?.privateMarks).toUpperCase()
                : "-"
            }`,
            fontSize: 8,
          },
          { colSpan: 2, text: "", style: "cellStyle" },
          {},
          { text: "TOTAL", fontSize: 8, style: "cellStyle", bold: true },
          { text: "", fontSize: 8, style: "cellStyle" },
        ],
      ];

      const table = {
        widths: ["10%", "40%", "11%", "11%", "8%", "20%"],
        heights: [10, 10, 10, 10, 10, 10, 10, 10],
        body: tableBody1,
      };

      const content = [
        {
          table,
          layout: {
            paddingTop: function () {
              return 6; // Adjust padding as needed
            },
            paddingBottom: function () {
              return 4; // Adjust padding as needed
            },
          },
        },

        // Other content
      ];

      return content;
    }
    return null;
  }

  //Get Data by Booking ID
  const GetBookingData = async (bookingId) => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        setIsLoading(false);
        // console.log(response?.data?.data?.billtyDetails)
        if (response?.data?.data?.billtyDetails.length > 0) {
          setBuilltyData(sortingArray(response?.data?.data?.billtyDetails, "lrNo"));
          setInformation(
            response?.data?.data?.billtyDetails[0].amountToPayOrPaid
          );
          setBookingData(response?.data?.data?.bookingDetails);

          // console.log(response?.data?.data?.billtyDetails)
        } else {
          setBookingData(response?.data?.data?.bookingDetails);
        }
        setBookingInvoiceNo(
          response?.data?.data?.bookingDetails?.bookingInvoiceNo
        );
      });
  };
  // console.log(builltyData, "builty data");
  //Update Billty Handler

  const findLrNo = (subData, required = "companyInvoiceNo") => {
    const lrDetails = bookingData?.lrDetails;
    const lr = lrDetails.filter((data) => data.lrNo === subData.lrNo);
    // return lr[0][required];
    return lr?.length > 0 ? lr[0][required] : "";
  };

  const PopUpSubmitHandler = () => {
    let Amount_Paid_Data = {
      BookingId: location?.state?.bookingId,
      AmountToPayOrPaid: information?.toUpperCase(),
    };
    axiosInstance
      .post(GET_ADDPAY, Amount_Paid_Data)
      .then((response) => {
        // console.log("GOOD")
        setOpen(false);
      })
      .catch((error) => {
        // console.log("Error")
        return;
      });
  };
  useEffect(() => {
    const BookingId =
      location?.state?.bookingId || location?.data?.data?.bookingId;
    GetBookingData(BookingId);
  }, [location]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {open && (
            <Transition show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10 "
                initialFocus={ref}
                onClose={() => null}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-300 bg-opacity-95 transition-opacity" />
                </Transition.Child>

                <div className=" fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Panel className="border-2 border-primary-kirtiroadways  rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-1/2">
                        <div className="bg-white px-8 pt-4 pb-4">
                          <div className="">
                            <div className=" text-center ">
                              <div className="mt-2">
                                <p className="text-xl text-center align-center font-medium  text-gray-900">
                                  Amount to Pay/Paid
                                </p>
                                <hr />

                                <div className="w-full mt-4 space-y-2">
                                  <div className="text-sm w-full space-y-2 flex flex-col items-start">
                                    <div className="flex w-full flex-col items-start space-x-1">
                                      <textarea
                                        onChange={(val) =>
                                          setInformation(
                                            val.target.value.toUpperCase()
                                          )
                                        }
                                        value={information}
                                        className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                        name="note"
                                        id=""
                                        cols="30"
                                        rows="3"
                                        type="text"
                                        required
                                      ></textarea>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className=" px-4 py-3 flex justify-center  sm:px-6">
                                <Button
                                  onClick={PopUpSubmitHandler}
                                  // ref={ref }
                                  theme="primary"
                                >
                                  OK
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          )}

          {/* <Scrollbars
        universal
        autoHeight
        autoHeightMax={1000}
        className="shadow border-b border-gray-200  w-full"
      > */}

          <div className="flex flex-row gap-4 justify-center mt-5">
            <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">With Border</p>
              <Checkbox
                defaultChecked={showBorder}
                handleClick={(e) => setShowBorder(e.target.checked)}
              />
            </div>
            {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">Black and white</p>
              <Checkbox
                defaultChecked={blackWhite}
                handleClick={(e) => setBlackWhite(e.target.checked)}
              />
            </div> */}
            {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">Mobile Print</p>
              <Checkbox
                defaultChecked={blackWhite}
                handleClick={(e) => setMobilePrint(e.target.checked)}
              />
            </div> */}
          </div>
          <div
            // style={{ minWidth: "1100px" }}
            className="overflow-x-scroll w-full"
          >
            {builltyData.length > 0 ? (
              <>
                {" "}
                {builltyData.map((subData, i) => (
                  <span key={subData.billtyId}>
                    <InvoiceView
                      paidBy={findLrNo(subData, "paidBy")}
                      i={0}
                      index={i}
                      information={information}
                      data={subData}
                      copyFor="CONSIGNOR"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                      bookingInvoiceNo={findLrNo(subData)}
                      showBorder={showBorder}
                      blackWhite={blackWhite}
                    />
                    <hr className="my-12 border-gray-400" />
                    <InvoiceView
                      paidBy={findLrNo(subData, "paidBy")}
                      index={i}
                      information={information}
                      data={subData}
                      copyFor="CONSIGNEE"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                      bookingInvoiceNo={findLrNo(subData)}
                      showBorder={showBorder}
                      blackWhite={blackWhite}
                    />
                    <hr className="my-12 border-gray-400" />
                    <InvoiceView
                      paidBy={findLrNo(subData, "paidBy")}
                      index={i}
                      information={information}
                      data={subData}
                      copyFor="DRIVER"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                      bookingInvoiceNo={findLrNo(subData)}
                      showBorder={showBorder}
                      blackWhite={blackWhite}
                    />
                  </span>
                ))}
              </>
            ) : (
              <span>
                <InvoiceView
                  information={information}
                  i={0}
                  data={bookingData}
                  copyFor="CONSIGNOR"
                  profileData={profileData}
                  secondaryPhoneNo={secondaryPhoneNo}
                  bookingInvoiceNo={bookingInvoiceNo}
                  showBorder={showBorder}
                  blackWhite={blackWhite}
                />
                <hr className="my-12 border-gray-400" />
                <InvoiceView
                  information={information}
                  data={bookingData}
                  copyFor="CONSIGNEE"
                  profileData={profileData}
                  secondaryPhoneNo={secondaryPhoneNo}
                  bookingInvoiceNo={bookingInvoiceNo}
                  showBorder={showBorder}
                  blackWhite={blackWhite}
                />
                <hr className="my-12 border-gray-400" />
                <InvoiceView
                  information={information}
                  data={bookingData}
                  copyFor="DRIVER"
                  profileData={profileData}
                  secondaryPhoneNo={secondaryPhoneNo}
                  bookingInvoiceNo={bookingInvoiceNo}
                  showBorder={showBorder}
                  blackWhite={blackWhite}
                />
              </span>
            )}

            <div className="hidden">
              <div ref={componentRef}>
                {mobilePrint ? (
                  builltyData.length > 0 ? (
                    <>
                      {builltyData.map((subData, i) => (
                        <>
                          <section className="pdf-item">
                            <MobileBuillty
                              information={information}
                              i={i}
                              data={subData}
                              copyFor="CONSIGNOR"
                              profileData={profileData}
                              secondaryPhoneNo={secondaryPhoneNo}
                              bookingInvoiceNo={bookingInvoiceNo}
                              showBorder={showBorder}
                              blackWhite={blackWhite}
                              mobilePrint={mobilePrint}
                            />
                          </section>
                          <section className="pdf-item">
                            <MobileBuillty
                              information={information}
                              i={i}
                              data={subData}
                              copyFor="CONSIGNEE"
                              profileData={profileData}
                              secondaryPhoneNo={secondaryPhoneNo}
                              bookingInvoiceNo={bookingInvoiceNo}
                              showBorder={showBorder}
                              blackWhite={blackWhite}
                              mobilePrint={mobilePrint}
                            />
                          </section>
                          {/* <div  style={{pageBreakInside:"avoid",pageBreakAfter:"always",display:"block"}}> hi</div> */}
                          <section className="pdf-item">
                            <MobileBuillty
                              information={information}
                              i={i}
                              data={subData}
                              copyFor="DRIVER"
                              profileData={profileData}
                              secondaryPhoneNo={secondaryPhoneNo}
                              bookingInvoiceNo={bookingInvoiceNo}
                              showBorder={showBorder}
                              blackWhite={blackWhite}
                              mobilePrint={mobilePrint}
                            />
                          </section>
                        </>
                      ))}
                    </>
                  ) : (
                    <span>
                      <MobileBuillty
                        information={information}
                        i={0}
                        data={bookingData}
                        copyFor="CONSIGNOR"
                        profileData={profileData}
                        secondaryPhoneNo={secondaryPhoneNo}
                        showBorder={showBorder}
                        blackWhite={blackWhite}
                        mobilePrint={mobilePrint}
                      />

                      <MobileBuillty
                        information={information}
                        data={bookingData}
                        copyFor="CONSIGNEE"
                        profileData={profileData}
                        secondaryPhoneNo={secondaryPhoneNo}
                        showBorder={showBorder}
                        blackWhite={blackWhite}
                        mobilePrint={mobilePrint}
                      />

                      <MobileBuillty
                        information={information}
                        data={bookingData}
                        copyFor="DRIVER"
                        profileData={profileData}
                        secondaryPhoneNo={secondaryPhoneNo}
                        showBorder={showBorder}
                        blackWhite={blackWhite}
                        mobilePrint={mobilePrint}
                      />
                    </span>
                  )
                ) : builltyData.length > 0 ? (
                  <>
                    {builltyData.map((subData, i) => (
                      <>
                        <InvoicePrint
                          paidBy={findLrNo(subData, "paidBy")}
                          information={information}
                          i={i}
                          data={subData}
                          copyFor="CONSIGNOR"
                          profileData={profileData}
                          secondaryPhoneNo={secondaryPhoneNo}
                          bookingInvoiceNo={findLrNo(subData)}
                          showBorder={showBorder}
                          blackWhite={blackWhite}
                          mobilePrint={mobilePrint}
                        />
                        <InvoicePrint
                          paidBy={findLrNo(subData, "paidBy")}
                          information={information}
                          i={i}
                          data={subData}
                          copyFor="CONSIGNEE"
                          profileData={profileData}
                          secondaryPhoneNo={secondaryPhoneNo}
                          bookingInvoiceNo={findLrNo(subData)}
                          showBorder={showBorder}
                          blackWhite={blackWhite}
                          mobilePrint={mobilePrint}
                        />
                        <InvoicePrint
                          paidBy={findLrNo(subData, "paidBy")}
                          information={information}
                          i={i}
                          data={subData}
                          copyFor="DRIVER"
                          profileData={profileData}
                          secondaryPhoneNo={secondaryPhoneNo}
                          bookingInvoiceNo={findLrNo(subData)}
                          showBorder={showBorder}
                          blackWhite={blackWhite}
                          mobilePrint={mobilePrint}
                        />
                      </>
                    ))}
                  </>
                ) : (
                  <span>
                    <InvoicePrint
                      information={information}
                      i={0}
                      data={bookingData}
                      copyFor="CONSIGNOR"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                      showBorder={showBorder}
                      blackWhite={blackWhite}
                      mobilePrint={mobilePrint}
                    />

                    <InvoicePrint
                      information={information}
                      data={bookingData}
                      copyFor="CONSIGNEE"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                      showBorder={showBorder}
                      blackWhite={blackWhite}
                      mobilePrint={mobilePrint}
                    />

                    <InvoicePrint
                      information={information}
                      data={bookingData}
                      copyFor="DRIVER"
                      profileData={profileData}
                      secondaryPhoneNo={secondaryPhoneNo}
                      showBorder={showBorder}
                      blackWhite={blackWhite}
                      mobilePrint={mobilePrint}
                    />
                  </span>
                )}
              </div>
            </div>

            <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
              <style>{pageStyle}</style>
              {/* <ReactToPrint
                pageStyle={pageStyle}
                documentTitle={
                  "Buillty of " + location?.data?.data?.consignerName
                }
                trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
                content={() => componentRef.current}
              /> */}
              <ButtonWithBG
                onClick={() => generatePDFAndDisplay("download")}
                label="Download"
              />
              <ButtonWithBG
                onClick={() => generatePDFAndDisplay("print")}
                label="Print"
              />
            </div>
          </div>

          {/* </Scrollbars> */}
        </div>
      )}
    </>
  );
};

export default BilltyInvoiceView;

// const tableBody1 = [
//   [
//     {
//       rowSpan: 7,
//       stack: [
//         {
//           text: "CAUTION",
//           fontSize: 9,
//           bold: true,
//           alignment: "center",
//           decoration: "underline",
//           margin: [0, 1, 0, 0],
//         },
//         {
//           text: CAUTION[0].caution1 ? CAUTION[0].caution1 : "-",
//           alignment: "justify",
//           fontSize: 6.5,
//           lineHeight: 1,
//           font: "PTSerifRegular",
//           margin: [0, 4, 0, 0],
//         },
//       ],
//     },
//     {
//       stack: [
//         {
//           text: CAUTION[0].route ? CAUTION[0].route : "-",
//           fontSize: 9,
//           bold: true,
//           alignment: "center",
//           decoration: "underline",
//         },
//         {
//           text: `${role} COPY`,
//           fontSize: 9,
//           alignment: "center",
//           margin: [0, 3, 0, 0],
//         },
//       ],
//       rowSpan: 2,
//       colSpan: 2,
//       bold: true,
//       fontSize: 8,
//       alignment: "center",
//     },
//     {},
//     {
//       stack: [
//         {
//           text: CAUTION[0].route1 ? CAUTION[0].route1 : "-",
//           fontSize: 9,
//           bold: true,
//           alignment: "center",
//           decoration: "underline",
//         },
//         {
//           text: "THE CUSTOMER HAS STARED THAT HE",
//           fontSize: 8,
//           alignment: "center",
//           margin: [0, 3, 0, 0],
//         },
//         {
//           text: "HAS INSURED THE CONSIGNMENT",
//           fontSize: 8,
//           alignment: "center",
//         },
//       ],
//       rowSpan: 2,
//       alignment: "center",
//       fontSize: 8,
//     },
//   ],
//   ["", "", "", ""],
//   [
//     "",
//     {
//       text: "CONSIGNMENT NOTE:",
//       rowSpan: 2,
//       colSpan: 2,
//       style: "tableHeader",
//       alignment: "center",
//       decoration: "underline",
//       margin: [0, 10, 0, 0],
//       characterSpacing: 0.5,
//     },
//     {},
//     {
//       text: `COMPANY NAME:`,
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//   ],
//   [
//     "",
//     "",
//     "",
//     {
//       text: `POLICY NO :${profileData?.policyNo !=="NA"?profileData?.policyNo:""}`,
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//   ],
//   [
//     "",
//     {
//       text: [
//         `FIN. YEAR : `,
//         { text: `${data?.financialYear}`, bold: true },
//       ],
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//     {
//       text: [`LR NO : `, { text: `${data?.lrNo}`, bold: true }],
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//     {
//       text: "DATE :",
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//   ],
//   [
//     "",
//     {
//       text: [
//         `LR DATE : `,
//         {
//           text: `${moment(props?.data?.lrDate).format("DD-MM-YYYY")}`,
//           bold: true,
//         },
//       ],
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//     {
//       // text:'PLOT NO. 64, HARI OM INDUSTRIAL ESTATE GIDC,',
//       text: [`LORRY NO : `, { text: `${data?.vehicleNo}`, bold: true }],
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//     {
//       text: "AMOUNT :",
//       rowSpan: 2,
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//     },
//   ],
//   [
//     "",
//     {
//       text: [`DELIVERY AT : `, { text: `${data?.to}`, bold: true }],
//       colSpan: 2,
//       fontSize: 8,
//       style: "cellStyle",
//       alignment: "left",
//       margin: [15, 0, 0, 0],
//     },
//     {},
//     "",
//   ],
// ];
