import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../UI/ButtonMain";
const styles = {
  common: `text-xs px-2 py-1 font-bold inline-flex rounded-md justify-between space-x-2 cursor-pointer`,
  Pending: `bg-primary-kirtiroadways font-bold text-white flex items-center justify-center uppercase`,
  Paid: `bg-gray-300 font-bold text-gray-600`,
};
export default function PaymentsStatusPhill({
  defaultValue,
  id,
  type,
  onClickEvent,
  label,
}) {
  let [isOpen, setIsOpen] = useState(false);
  const [isEnabled, setEnabled] = useState(defaultValue);

  const openModal = (props) => {
      setIsOpen(true);
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };
  const handleConfirmBtn = () => {
    setIsOpen(false);
    onClickEvent(!defaultValue, id, type);
    setEnabled(true);
  };
  return (
    <section className="flex justify-center items-center py-1">
        {defaultValue === false && isEnabled === false ? (
          <button
            className={`${styles.common} ${styles["Pending"]} `}
            onClick={() => {
              openModal();
            }}
          >
            Pending
          </button>
        ) : (
          <div className={`${styles.common} ${styles["Paid"]} cursor-not-allowed`}>
            Paid
          </div>
        )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30  flex justify-center  items-center bg-gray-100 bg-opacity-75 rounded-lg shadow-md"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="min-h-screen ml-4 px-4 text-center">
            <Dialog.Overlay className="fixed inset-0" />
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            {/* <Notification
              show={notif}
              setShow={setNotif}
              {...notifOpts}
            ></Notification> */}
            <div className="border-2 border-primary-kirtiroadways inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-center text-gray-900 mb-4"
              >
                Are you sure you want to complete the payment for this {label}?
              </Dialog.Title>

              <div className=" space-x-2 flex justify-center mt-6">
                <Button onClick={handleConfirmBtn} theme={`primary`}>
                  Yes
                </Button>
                <Button onClick={handleButtonClick} theme="secondary">
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          {/* </>
        )} */}
        </Dialog>
      </Transition>
    </section>
  );
}
