import { Button } from "@aws-amplify/ui-react";
import { Dialog } from "@headlessui/react";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "../../Utils/API";
import {
    ADD_COMPANY
} from "../../Utils/ApiGlobal";

const Input = (props) => (
  <input
    className="peer uppercase w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function CompanyCreatePopup({
  open,
  setOpen,
  handleSubmit,
  defaultCompany,
  setDefaultCompany,
}) {
  const [loader, setLoader] = useState(false);

  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };
  //   Form Submit click event
  const FormSubmitHandler = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setLoader(true);
    var obj = formValueConvertor(getFormJSON(ev.target));
    let COMPANY_DATA = {
      Name: obj?.Name?.toUpperCase(),
      BillToAddress: obj?.BillingAddress?.toUpperCase(),
      ShipToAddress: obj?.ShippingAddress?.toUpperCase(),
      Location: obj?.location?.toUpperCase(),
      Gst: obj.companyGST === " " ? "NA" : obj.companyGST.toUpperCase(),
      ContactPersonName: "",
      ContactNumber: "",
      // IsBalancePaid: balancePaid,
      CreatedBy: localStorage.getItem("userId"),
    };
    try {
      const company = await axiosInstance.post(ADD_COMPANY, COMPANY_DATA);
      if (company.data.data) {
        handleSubmit({
          name: COMPANY_DATA.Name,
          billToAddress: COMPANY_DATA.BillToAddress,
          gst: COMPANY_DATA.Gst,
          address: COMPANY_DATA.ShipToAddress,
          shipToAddress: COMPANY_DATA.ShipToAddress,
          location: COMPANY_DATA.Location,
          companyId: company.data.data.id,
        });
        setLoader(false);
        setDefaultCompany("");
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      setOpen(false);
    }
  };
  return (
    <Dialog
      as="div"
      open={open}
      static={open}
      onClose={setOpen}
      className="relative  z-100"
    >
      <div className=" fixed inset-0 z-10 overflow-y-auto">
        <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="border-2 border-primary-kirtiroadways bg-white rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-2/3">
            <div className="p-6 flex flex-col gap-5 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md h-full ">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Add Company Details
              </Dialog.Title>
              <form onSubmit={FormSubmitHandler}>
                <div className="">
                  {/* left side Content */}
                  <div className=" text-left space-y-6">
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      {/* Name */}
                      <div className=" flex flex-col  text-left md:w-1/3">
                        <Label label="Company Name" isRequired="true" />
                        <Input
                          name="Name"
                          required
                          defaultValue={defaultCompany?.toUpperCase()}
                          // autoComplete="off"
                        />
                      </div>
                      <div className=" flex md:w-1/3  flex-col  text-left">
                        <Label label="Billing Address" isRequired="true" />
                        <Input
                          name="BillingAddress"
                          required
                          // autoComplete="off"
                        />
                      </div>
                      <div className=" flex md:w-1/3  flex-col  text-left">
                        <Label label="Shipping Address" isRequired="true" />
                        <Input
                          name="ShippingAddress"
                          required
                          // autoComplete="off"
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      {/* Total Vehicles */}
                      <div className=" flex flex-col  text-left md:w-1/3">
                        <Label label="GST Number" />
                        <Input name="companyGST" />
                      </div>

                      <div className=" flex flex-col  text-left md:w-1/3">
                        <Label label="Location" />
                        <Input name="location" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-ceter mt-4">
                  <Button
                    isLoading={loader}
                    color={"white"}
                    colorTheme="error"
                    variation="primary"
                    className="flex items-center justify-center border h-10 w-28 !capitalize rounded-md !bg-kirtiroadways-900 !text-sm !font-semibold text-white "
                    loadingText="Saving..."
                    type="submit"
                  >
                    Create
                  </Button>{" "}
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
