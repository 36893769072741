import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { GET_PARTY_TABLE } from "../../Utils/ApiGlobal";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../Utils/API";

const animatedComponents = makeAnimated();

export default function ThirdPartyName({ handleOnChange, name, defaultValue }) {
  const [optionsParty, setOptions] = useState({});
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    axiosInstance
      .get(`${GET_PARTY_TABLE}?search=&pageNo=&pageSize=`)
      .then((response) => {
        if (response?.data?.data?.thirdPartyList.length > 0) {
          const options = response?.data?.data?.thirdPartyList.map(
            ({ name }, index) => ({
              name,
            })
          );
          setOptions(options);
        }
      });
    setLoader(false);
  }, []);

  useEffect(() => {
    setOptions([]);
  }, []);
  return (
    <>
      {optionsParty.length > 0 ? (
        !loader ? (
          // <div style={{ width: "310px" }}>
          <Select
            options={optionsParty.map((el) => ({
              label: el.name,
              value: el.name,
            }))}
            // name={name}
            components={animatedComponents}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            onChange={handleOnChange}
            placeholder="Select Party Name"
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: "#f68e92",
                primary: "#ed1c24",
              },
            })}
          />
        ) : (
          <>
            <Select
              options={{
                label: "Third Party is not available",
                value: "Third Party is not available",
              }}
              // name={name}
              components={animatedComponents}
              defaultValue={{ label: defaultValue, value: defaultValue }}
              onChange={handleOnChange}
              placeholder="Select Party Name"
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "#f68e92",
                  primary: "#ed1c24",
                },
              })}
            />
          </>
        )
      ) : (
        <>
          <Select
            options={{
              label: "Third Party is not available",
              value: "Third Party is not available",
            }}
            // name={name}
            components={animatedComponents}
            defaultValue={{
              label: "",
              value: "",
            }}
            onChange={handleOnChange}
            placeholder="Select Party Name"
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: "#f68e92",
                primary: "#ed1c24",
              },
            })}
          />
        </>
        // </div>
        // <Loader />
      )}
    </>
  );
}
