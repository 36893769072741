import React from "react";
import { useHistory } from "react-router-dom";
import DriverTable from "../../Components/DataTables/DriverTable";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import { setting_svg } from "../../Utils/SVGListing";

const Driver = () => {
  let history = useHistory();

  // To redirect to add page
  const redirectToAdd = () => {
    history.push({
      pathname: "/driver/add-edit-driver",
      state: { label: "Add" },
    });
  };
  return (
    <DashBoardLayout pageTitle="Driver">
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Pending Booking" /> */}
        {/* Search and setting */}
        <div className="flex flex-row justify-between ">
          <div>
            <SearchField placeholder={SEARCH_PLACEHOLDER} />
          </div>
          <div className="flex flex-row space-x-2  items-start">
            <Button svg={setting_svg}></Button>
            <ButtonWithBG
              handleClick={redirectToAdd}
              theme="primary"
              label="Add Driver"
            />
          </div>
        </div>
      </div>
      <DriverTable />
    </DashBoardLayout>
  );
};

export default Driver;
