import React, { useState } from "react";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
// import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import BiltyTable from "../../Components/DataTables/BiltyTable";
// import { setting_svg } from "../../Utils/SVGListing";

const Billty = () => {
  // let history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  return (
    <DashBoardLayout pageTitle="Billty">
      <div className="min-w-full space-y-4">
        <div className="flex flex-row justify-between">
          <div>
            <SearchField
              placeholder="Searching By Date"
              handleChange={setSearchValue}
              value={searchValue}
            />
          </div>
        </div>
      </div>
      <BiltyTable searchValue={searchValue} />
    </DashBoardLayout>
  );
};

export default Billty;
