import React from "react";
import Loader_red_Gif from "../../Images/Loader_red_Gif.gif";
// import Logo from "../../images/logo_monotone.png"

const Loader = () => {
  return (
    <div className="absolute inset-0  flex justify-center items-center">
      {/* <div>
                        <img src={Logo} alt="kirtiroadways" width="162" height="59" />
                    </div> */}
      <img src={Loader_red_Gif} alt="Loader" width="500" height="400" />
    </div>
  );
};

export default Loader;
