import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
// import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import CommissionEntryTable from "../../Components/DataTables/CommissionEntryTable";
import { setting_svg } from "../../Utils/SVGListing";
import { VehicleNumberClickDescription } from "../../Utils/Constants";
import DatePicker from "react-datepicker";

const CommissionEntry = () => {
  const [dateFilter, setDateFilter] = useState();
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();

  // To redirect to add page
  const redirectToAddCommission = () => {
    history.push({
      pathname: "/commission-entry/add-edit-commission",
      state: { label: "Add" },
    });
  };

  return (
    <DashBoardLayout pageTitle="Commission Entry">
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Commission Entry"/> */}
        {/* Search and setting */}
        <div className="flex md:flex-row flex-col md:justify-between  gap-2">
          <div className="flex flex-col md:flex-row gap-2">
            <SearchField
              placeholder="Enter Value to search"
              handleChange={setSearchValue}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
            <div className="w-full ">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={dateFilter}
                className="peer	 w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                placeholderText={"SELECT DATE FOR FILTER"}
                onChange={(date) => setDateFilter(date)}
                isClearable
              />
            </div>
          </div>

          <div className="flex flex-row space-x-2 items-start  md:order-last	order-first">
            <Button
              svg={setting_svg}
              onClick={() => history.push("/profile")}
            ></Button>
            <ButtonWithBG
              handleClick={redirectToAddCommission}
              theme="primary"
              label="Add Commission"
            />
          </div>
        </div>
      </div>
      <p className="text-left font-semibold	 text-xs	mt-2">
        {VehicleNumberClickDescription}
      </p>
      <CommissionEntryTable searchValue={searchValue} dateFilter={dateFilter} />
    </DashBoardLayout>
  );
};

export default CommissionEntry;
