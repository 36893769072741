import React, { useEffect, useState, Fragment } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import axiosInstance from "../../../Utils/API";
import { useLocation } from "react-router-dom";
// import { GET_VEHICLE } from "../../../Utils/ApiGlobal";
import { ADD_VEHICLE } from "../../../Utils/ApiGlobal";
import { UPDATE_VEHICLE } from "../../../Utils/ApiGlobal";
import { DELETE_VEHICLE_DETAILS } from "../../../Utils/ApiGlobal";
// import { GET_LEDGER_BOOKINGID } from "../../../Utils/ApiGlobal";
import { ConfirormationModal } from "../ConfirormationModal";
import Tooltip from "react-simple-tooltip";
import Swal from "sweetalert2";

import {
  //   useFilters,
  usePagination,
  useTable,
  useRowSelect,
} from "react-table";
import {
  cancel_round_svg,
  delete_svg,
  edit_svg,
  save_svg,
} from "../../../Utils/SVGListing";
import APILoader from "../APILoader";
const themes = ({ theme, type, tight, isLink }) => {
  const classes = {
    common: {
      td: `flex-nowrap border-y py-1 border-slate-300  text-sm  text-center`,
      th: `py-3 no-wrap ${
        tight ? "px-2" : "px-6"
      } text-center text-xs  uppercase sticky `,
      thead: `z-70 sticky top-0`,
    },
    primary: {
      thead: "bg-green-clearbox top-0 relative ",
      th: `text-grey `,
    },
    secondary: {
      th: "font-bold",
      td: "w-max-content",
      thead: "bg-white  font-bold  bg-opacity-100 border-b-2  z-30 ",
    },
    light: {
      thead: "bg-green-500 bg-opacity-20 ",
      th: "text-gray-500",
    },
    dark: {
      thead: "bg-white  bg-opacity-100 border-b-2  z-30",
      th: "text-sm  font-bold text-black-700 uppercase z-30 ",
    },
    dark_aligntop: {
      thead: "bg-white  bg-opacity-100 border-b-2 ",
      th: "text-sm font-bold text-black-700 uppercase ",
      td: "align-top no-wrap",
    },
  };

  return classes["common"][type] + " " + classes[theme][type];
};
// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editableRowIndex, // index of the row we requested for editing
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    // rowIndex,'vehicleNo',value
    updateMyData(index, id, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return index === editableRowIndex ? (
    <input
      className=" peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm "
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  ) : (
    <p className="uppercase">{value}</p>
  );
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

export default function EditableTable({
  columns,
  data,
  theme = "secondary",
  setData,
  updateMyData,
  component,
  skipPageReset,
  getData, // get API implemet after remove opertion performed
  editableRowIndex,
  setEditableRowIndex,
}) {
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  const location = useLocation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    // state: { selectedRowIds, globalFilter },
  } = useTable(
    {
      autoResetPage: !skipPageReset,
      columns,
      data,
      defaultColumn,
      updateMyData,
      // pass state variables so that we can access them in edit hook later
      editableRowIndex,
      setEditableRowIndex,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        ...columns,
        // pass edit hook
        {
          accessor: "edit",
          id: "edit",
          Header: "",
          maxWidth: 10,
          minWidth: 10,
          width: 10,
          Cell: ({ row, setEditableRowIndex, editableRowIndex }) => (
            <>
              <Tooltip
                style={{ fontWeight: "600", letterSpacing: 0.5 }}
                border="#ed1c24"
                color="black"
                background="#fabbbd"
                content={editableRowIndex !== row.index ? "Edit" : "Save"}
                radius={5}
                placement="top"
                zIndex={100}
                padding={10}
                arrow={5}
              >
                <button
                  className="action-button"
                  onClick={async () => {
                    let isNullRow =data.filter(row => row.vehicleNo === null)
                    // console.log(isNullRow)
                    if(isNullRow)
                    {
                      getData(localStorage.getItem("VehicleOwnerId"));
                    }
                    const currentIndex = row.index;
                    if (editableRowIndex !== currentIndex) {
                      // row requested for edit access
                      setEditableRowIndex(currentIndex);
                      // console.log(row.original.vehicleId)
                    } else {
                      // request for saving the updated row
                      // console.log(row.values.vehicleNo, "row.values.VehicleNo");
                      if (!row.values.vehicleNo) {
                        ConfirormationModal(
                          "Please enter vehicle number",
                          "error",
                          2000
                        );
                        return;
                      }
                      // All Data of Row
                      // const updatedRow = row.values.Vehicle_No;
                      // console.log(updatedRow)
                      setLoader({
                        loading: true,
                        message: "",
                        icon: "",
                      });
                      if (row.original.vehicleId !== undefined) {
                        //  console.log("UPDATE")
                        const UPDATE_VEHICLE_DATA = {
                          VehicleId: row.original.vehicleId,
                          VehicleNo: row.values.vehicleNo.toUpperCase(),
                        };
                        await axiosInstance
                          .post(UPDATE_VEHICLE, UPDATE_VEHICLE_DATA)
                          .then((response) => {
                            setLoader({
                              loading: false,
                              message: "",
                              icon: "",
                            });
                            ConfirormationModal(
                              response.data.data.message,
                              "success",
                              2000
                            );
                            getData(localStorage.getItem("VehicleOwnerId"));
                            return;
                          })
                          .catch((error) => {
                            ConfirormationModal(error.message, "error", 2000);
                            return;
                          });
                      } else if (row.original.vehicleId === undefined) {
                        const POST_VEHICLE_DATA = {
                          OwnerId: location?.state?.data?.ownerId,
                          VehicleNo: row.values.vehicleNo.toUpperCase(),
                        };
                        // console.log(POST_VEHICLE_DATA)
                        await axiosInstance
                          .post(ADD_VEHICLE, POST_VEHICLE_DATA)
                          .then((response) => {
                            setLoader({
                              loading: false,
                              message: "",
                              icon: "",
                            });
                            // console.log(response)
                            ConfirormationModal(
                              response.data.data.message,
                              "success",
                              2000
                            );
                            getData(localStorage.getItem("VehicleOwnerId"));
                            return;
                          })
                          .catch((error) => {
                            ConfirormationModal(error.message, "error", 2000);
                            return;
                          });
                      }
                      setEditableRowIndex(null);
                    }
                  }}
                >
                  {/* single action button supporting 2 modes */}
                  {editableRowIndex !== row.index ? edit_svg : save_svg}
                </button>
              </Tooltip>
              {/* Cancel button */}
              {editableRowIndex === row.index && (
                <Tooltip
                  style={{ fontWeight: "600", letterSpacing: 0.5 }}
                  border="#ed1c24"
                  color="black"
                  background="#fabbbd"
                  content={"Cancel"}
                  radius={5}
                  placement="top"
                  zIndex={100}
                  padding={10}
                  arrow={5}
                >
                  <button
                    className="action-button ml-2 "
                    onClick={async () => {
                      getData(localStorage.getItem("VehicleOwnerId"));
                      // console.log(row?.original.vehicleNo)
                      if (data?.[row.index]?.vehicleNo === undefined || !row?.original.vehicleNo) {
                        updateMyData(
                          row.index,
                          "vehicleNo",
                          data?.[row.index]?.vehicleNo
                        );
                        setEditableRowIndex(null);
                      } else {
                        setEditableRowIndex(null);
                      }
                      // rowIndex,'vehicleNo',value
                    }}
                  >
                    {cancel_round_svg}
                    {/* single action button supporting 2 modes */}
                  </button>
                </Tooltip>
              )}
            </>
          ),
        },
        // Delete Button
        {
          accessor: "delete",
          id: "delete",
          Header: "",
          maxWidth: 10,
          minWidth: 10,
          width: 10,
          Cell: ({ row, setEditableRowIndex, editableRowIndex }) => (
            <>
              <Tooltip
                style={{ fontWeight: "600", letterSpacing: 0.5 }}
                border="#ed1c24"
                color="black"
                background="#fabbbd"
                content="Delete"
                radius={5}
                placement="top"
                zIndex={100}
                padding={10}
                arrow={5}
              >
                <button
                  className="action-button"
                  onClick={(event) => {
                    // console.log(row?.original?.vehicleNo)
                    if(!row?.original?.vehicleNo)
                    {
                      getData(localStorage.getItem("VehicleOwnerId"));
                    }
                    else{
                      Swal.fire({
                        icon: "info",
                        title: "Are you sure you want to Remove?",
                        showCancelButton: true,
                        cancelButtonColor: "rgb(148 163 184)",
                        confirmButtonColor: "#ed1c24",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then(async (result) => {
                        if (result.isConfirmed === true) {
                          let VehicleData = {
                            VehicleId: row.original.vehicleId,
                          };
                          setLoader({ ...loader, loading: true });
                          await axiosInstance
                            .post(DELETE_VEHICLE_DETAILS, VehicleData)
                            .then((response) => {
                              ConfirormationModal(
                                response.data.data.message,
                                "success",
                                2000
                              );
                              getData(localStorage.getItem("VehicleOwnerId"));
                              setLoader({
                                loading: false,
                                message: "",
                                icon: "",
                              });
                            })
                            .catch((error) => {
                              ConfirormationModal(error.message, "error", 2000);
                            });
                        } else {
                          return;
                        }
                      });
                    }
                    // getData(localStorage.getItem("VehicleOwnerId"));

                  }}
                >
                  {/* single action button supporting 2 modes */}
                  {delete_svg}
                </button>
              </Tooltip>
            </>
          ),
        },
      ]);
    }
  );

  // const deleteUserHandler = async(event) => {
  //   const dataCopy = [...data];
  //   dataCopy.splice(event, 1);
  //   setData(dataCopy);
  // };

  const { pageIndex, pageSize } = state;

  return (
    <>
      <APILoader loader={loader} />
      <div>
        <Scrollbars
          universal
          autoHeight
          autoHeightMax={500}
          className=" shadow border-b border-gray-200 rounded-lg w-full"
        >
          <table
            className="overflow-x-auto table-fixed w-full  border border-black-400 divide-gray-200 "
            {...getTableProps()}
          >
            <thead className={themes({ theme, type: "thead" })}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={themes({ theme, type: "th" })}
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                        },
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`even:bg-gray-50 hover:bg-primary-kirtiroadways-hover`}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={themes({ theme, type: "td" })}
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            },
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Scrollbars>
        <div className="w-full flex  mt-5 md:flex-row flex-col justify-between items-center  ">
          <div className="no-wrap md:w-1/2 flex md:flex-row flex-col  md:space-x-2  md:items-center">
            <div className="text-left">
              Page{" "}
              <span className="font-semibold">
                {pageIndex + 1} of {pageOptions.length}
              </span>{" "}
            </div>
            <div className="flex space-x-2 no-wrap items-center text-left">
              <span>| Show </span>
              <select
                className=" rounded-md border border-gray-300 bg-white py-1 pl-3 pr-3 text-left shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[5, 15, 50].map((pageSize) => (
                  <option
                    className=" hover:bg-primary-kirtiroadways-hover"
                    key={pageSize}
                    value={pageSize}
                  >
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="  space-x-0">
            <button
              className="  rounded-l-md border border-gray-300 bg-white md:px-2 p-1 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className=" relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Previous
            </button>
            <button
              className="relative inline-flex items-center border border-gray-300 bg-white p-1 md:px-4 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white p-1 md:px-2 md:py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
