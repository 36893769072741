import React, {  useState } from "react";
import PropTypes from "prop-types";

const styles = {
  common: `text-xs px-2 py-1 font-bold inline-flex rounded-md justify-between space-x-2 cursor-pointer`,
  Pending: `bg-primary-kirtiroadways font-bold text-white`,
  Paid: `bg-gray-300 font-bold text-gray-600`,
};

//  ✓ ✔

function CategoryPill({ Pending, onChangeValue, id, type }) {
  const [isEnabled, setEnabled] = useState(Pending);
  const handleClick = () => {
    // console.log(id);
    onChangeValue(!Pending, id, type);
    setEnabled(true);
  };
  return (
    <>
      {(Pending === false && isEnabled === false)? (
        <button
          className={`${styles.common} ${styles["Pending"]}`}
          onClick={handleClick}
        >
          <div>Pending</div>
        </button>
      ) : (
        <div
          className={`${styles.common} ${styles["Paid"]}`}
        >
          <div>Paid</div>
        </div>
      )}
    </>
  );
}

export default CategoryPill;

CategoryPill.defaultProps = {
  label: "pill",
  onChangeValue: () => {},
};

CategoryPill.propTypes = {
  Pending: PropTypes.bool,
  label: PropTypes.string,
  onChangeValue: PropTypes.func,
};
