import React, { useState, useEffect } from "react";
import ButtonWithBG from "../UI/ButtonMain";
import EditableTable from "../UI/Table/EditableTable";
import { useLocation } from "react-router-dom";
import { GET_VEHICLE_OWNERBYID } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";
import APILoader from "../UI/APILoader";

export default function AddVehicleDetailsTable({
  name,
  // address,
  // NoOfVehicle,
  // type,
  // Owner_Name,
}) {
  const location = useLocation();
  const [loader, setLoader] = useState({
    loading: true,
    message: "",
    icon: "",
  });
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [rowdata, setRowData] = useState([]);
  const [editableRowIndex, setEditableRowIndex] = useState(null);

  const onAddRowClick = () => {
    const nullRows = rowdata.filter(
      (row) => !Object.values(row).some((val) => val === null)
    );
    if (nullRows.length === 0) {
      setRowData(
        rowdata.concat({
          id: 0,
          // Name: "",
          // date: "",
          vehicleNo: "",
          // Driver_No: "",
          // amount: "",
          // credit: "",
        })
      );
      setEditableRowIndex(rowdata.length);
    }
  };
  // Columns
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        Cell: (cell) => cell.row.index + 1,
        maxWidth: 10,
        minWidth: 10,
        width: 10,
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
      },
    ],
    []
  );

  // get api start
  useEffect(() => {
    getData(location?.state?.data?.ownerId);
  }, [location?.state?.data?.ownerId]); // eslint-disable-line react-hooks/exhaustive-deps
  // get api start

  const getData = async (ownerID) => {
    localStorage.setItem("VehicleOwnerId", location?.state?.data?.ownerId);
    await axiosInstance
      .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${ownerID}`)
      .then((response) => {
        setTimeout(
          setLoader({
            loading: false,
            message: "",
            icon: "",
          }),
          2000
        );

        const data = response?.data?.data;
        // ConfirormationModal(response.data.message, "success", 2000);
        setRowData(data);
        // SetVehicleData(data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setRowData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  // console.log(props,"for table")
  return (
    <section>
      <div className="flex justify-end ">
        <ButtonWithBG handleClick={onAddRowClick} theme="primary" label="Add" />
      </div>
      <div className="mt-4">
        <APILoader loader={loader} />
        {!loader.loading && (
          <EditableTable
            data={rowdata}
            columns={columns}
            setData={setRowData}
            updateMyData={updateMyData}
            getData={getData}
            skipPageReset={skipPageReset}
            loader={loader}
            editableRowIndex={editableRowIndex}
            setEditableRowIndex={setEditableRowIndex}
          />
        )}
      </div>
    </section>
  );
}
