import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useState } from "react";
import APILoader from "../../../Components/UI/APILoader";
import ButtonWithBG from "../../../Components/UI/ButtonMain";
import { ConfirormationModal } from "../../../Components/UI/ConfirormationModal";
import axiosInstance from "../../../Utils/API";
import { ADD_BANK_INFORMATION, UPDATE_BANK_INFORMATION } from "../../../Utils/ApiGlobal";
import { edit_svg } from "../../../Utils/SVGListing";

const Input = (props) => (
  <input
    className="peer w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-700 text-sm font-medium text-slate-700 mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
export default function AddEditBankInfo(props) {
  let [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });

  const openModal = () => {
    setIsOpen(true);
  };

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });

  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };
  //   Form submit click event
  const BankInfoForm = (ev) => {
    ev.preventDefault();
    setLoader({ ...loader, loading: true });
    var obj = formValueConvertor(getFormJSON(ev.target));
    if(props.componentType !== "edit"){
        let ADD_BANK_DATA = {
            BankName: obj.bankName,
            Branch: obj.bankBranch,
            AccountNo: obj.accNo,
            IfscCode: obj.ifscCode,
            CreatedBy: localStorage.getItem("userId"),
          };
          axiosInstance
            .post(ADD_BANK_INFORMATION, ADD_BANK_DATA)
            .then((response) => {
              ConfirormationModal(response.data.data.message, "success", 2000);
              setLoader({
                loading: false,
                message: "",
                icon: "",
              });
              handleButtonClick();
              props.getAPI();
            })
            .catch((error) => {
              ConfirormationModal(error.message, "error", 2000);
              setLoader({
                loading: false,
                message: "",
                icon: "",
              });
            });
    }
    if(props.componentType === "edit"){
        let UPDATE_BANK_DATA = {
            AccountId:props?.data?.accountId,
            BankName: obj.bankName,
            Branch: obj.bankBranch,
            AccountNo: obj.accNo,
            IfscCode: obj.ifscCode,
            CreatedBy: localStorage.getItem("userId"),
          };
          axiosInstance
            .post(UPDATE_BANK_INFORMATION, UPDATE_BANK_DATA)
            .then((response) => {
              ConfirormationModal(response.data.data.message, "success", 2000);
              setLoader({
                loading: false,
                message: "",
                icon: "",
              });
              handleButtonClick();
              props.getAPI.getAPI();
            })
            .catch((error) => {
              ConfirormationModal(error.message, "error", 2000);
              setLoader({
                loading: false,
                message: "",
                icon: "",
              });
              props.getAPI.getAPI();
            });
    }
  };

  const handleButtonClick = (action) => {
    // handle all the action in the main view
    setIsOpen(false);
  };

  return (
    <div>
      <div className="flex justify-end">
        {props.componentType !== "edit" ? (
          <ButtonWithBG
            handleClick={openModal}
            theme="primary"
            label="Add Bank Details"
          />
        ) : (
          <button onClick={openModal}>{edit_svg}</button>
        )}
      </div>
      <APILoader loader={loader} />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed w-full inset-0 z-30  flex justify-center  items-center bg-white bg-opacity-75 rounded-lg shadow-md"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="w-full ml-4 px-4 text-center">
            <Dialog.Overlay className="	 fixed  inset-0" />
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="border-2 w-8/12	 border-gray-500 inline-block  pt-6 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <>
                  <Dialog.Title
                    as="h3"
                    className="text-lg  font-medium leading-6 text-center text-gray-900 "
                  >
                    <p>Add Bank Details</p>
                  </Dialog.Title>
                  <form
                    onSubmit={BankInfoForm}
                    className=" px-6 pt-6 w-full pb-6"
                  >
                    <div className="space-y-4 ">
                      <div className="flex flex-row space-x-2 w-full text-left">
                        <div className="flex flex-col w-1/2 text-left ">
                          <Label label="Bank Name" isRequired="true" />
                          <Input
                            name="bankName"
                            placeholder="Bank Name"
                            defaultValue={props?.data?.bankName}
                            required
                          />
                        </div>
                        <div className="flex flex-col w-1/2 text-left ">
                          <Label label="Bank Branch" isRequired="true" />
                          <Input
                            name="bankBranch"
                            placeholder="Bank Branch"
                            defaultValue={props?.data?.branch}
                            required
                          />
                        </div>
                      </div>
                      <div className="flex flex-row space-x-2 w-full text-left">
                        <div className="flex flex-col w-1/2 text-left ">
                          <Label label="Account No." isRequired="true" />
                          <Input
                            name="accNo"
                            placeholder="Account No."
                            defaultValue={props?.data?.accountNo}
                            required
                          />
                        </div>
                        <div className="flex flex-col w-1/2 text-left ">
                          <Label label="IFSC code" isRequired="true" />
                          <Input
                            name="ifscCode"
                            placeholder="IFSC code"
                            defaultValue={props?.data?.ifscCode}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* Button */}
                    <div className=" mt-4 space-x-2 flex justify-center">
                      <ButtonWithBG
                        type="submit"
                        theme="primary"
                        label="Save"
                      />
                      <ButtonWithBG
                        handleClick={handleButtonClick}
                        theme="secondary"
                        label="Cancel"
                      />
                    </div>
                  </form>
                </>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
