import React, { useEffect, useRef } from "react";
import { useState } from "react";
import logo from "../../Images/logo_transparent.png";
import black_logo from "../../Images/black_logo.png";
import sign from "../../Images/sign.png";
// import table_bg from "../../Images/table_bg.png";
// import Scrollbars from "react-custom-scrollbars-2";
import Loader from "../../Components/UI/Loader";
// import BillingInvoicePrint from "./BillingInvoicePrint";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import {
  GETINVOICENO,
  GET_BANK_LIST,
  GET_BOOKING_BILLING,
} from "../../Utils/ApiGlobal";
import {
  InvoicSubject,
  KIRTIROADLINES,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  PaymentbypayeeCchequeDrafs,
  // AmountChargeableInWords,
  OURBANKDETAILS,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
  WE_USING_PAYPHONE,
  SCANQRCONTENT,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import Checkbox from "../../Components/UI/Checkbox";
import { GETVIEWBOOKINGBYID } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import Button from "../../Components/UI/ButtonMain";
import { GET_COMPANY_INFORMATION_DATA } from "../../Utils/ApiGlobal";
import qrcode from "../../Images/qrcode.jpg";
import {
  Tablebg,
  TabletheadRedLastStyle,
  TabletheadRedStyle,
  TabletheadStyle,
  address,
  borderToggle,
  heading,
} from "../../Utils/TableStyling";
import BillingInvoiceView from "./BillingInvoiceView";
import BillingBothPaid from "./BillingBothPaid";
import { arrStringSorting } from "../../Components/Functionality/Helper";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

const BillingView = () => {
  // billingData.lrDetails
  // const [bankDetails, setBankDetails] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const [bookingData, setBookingData] = useState([]);
  const [builltyData, setBuilltyData] = useState([]);
  const [billingData, setBillingData] = useState([]);
  const [lrData, setLrData] = useState([]);
  const [invoiceTo, setInvoiceTo] = useState([]); // eslint-disable-next-line
  const [showBorder, setShowBorder] = useState(true);
  const [blackWhite] = useState(true);
  // const [otherName, setOtherName] = useState();
  // const [otherAdd, setOtherAdd] = useState();
  // const [otherGST, setOtherGST] = useState();
  const [open, setOpen] = useState(true);
  const [note, setNote] = useState("");
  // const [sentInvoice ,setSentInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]); // eslint-disable-next-line
  const [mobilePrint, setMobilePrint] = useState(true); // eslint-disable-next-line
  const [printData, setPrintData] = useState([]); // eslint-disable-next-line
  const [bankBranch, setBankBranch] = useState({
    bank: "",
    branch: "",
    accNo: "",
    ifsc: "",
  });
  const [Dropdown, setDropdown] = useState(false);
  const [optionsBank, setOptions] = useState({});
  const [Rate, SetRate] = useState();
  const [Weight, SetWeight] = useState();
  const [invoiceNo, setInvoiceNo] = useState("");
  const [isPaidBySame, setIsPaidBySame] = useState(false);
  const [checkedBox, setCheckedBox] = useState(false);
  const ref = useRef(null);
  const location = useLocation();
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? `white` : "white",
        isSelected: `white`,
        ":active": {
          ...styles[":active"],
          backgroundColor: `white`,
        },
      };
    },
    ///.....
  };
  // Access Bank Details
  useEffect(() => {
    getBankDetails();
    getInvoiceNo(); // eslint-disable-next-line
  }, []); // eslint-disable-next-line

  const getBankDetails = async () => {
    // setIsLoading(true);
    await axiosInstance.get(`${GET_BANK_LIST}`).then((response) => {
      // console.log(response?.data?.data);
      if (response?.data?.data.length > 0) {
        const options = response?.data?.data.map(
          ({ accountId, bankName, branch, accountNo, ifscCode }, index) => ({
            accountId,
            bankName,
            branch,
            accountNo,
            ifscCode,
          })
        );
        setBankBranch({
          bank: response?.data?.data[0].bankName,
          branch: response?.data?.data[0].branch,
          accNo: response?.data?.data[0].accountNo,
          ifsc: response?.data?.data[0].ifscCode,
        });
        setDropdown(true);
        setOptions(options);
        // console.log(options);
      }
    });
  };
  const getInvoiceNo = async () => {
    // setIsLoading(true);
    await axiosInstance.get(`${GETINVOICENO}`).then((response) => {
      // response?.data?.data
      // console.log(
      //   location?.data?.data,
      //   "location?.data?.data?.bookingInvoiceNo"
      // );
      if (location?.data?.data?.bookingInvoiceNo !== null) {
        setInvoiceNo(location?.data?.data?.bookingInvoiceNo);
      } else {
        setInvoiceNo(response?.data?.data);
      }
    });
  };

  // Builty check button click
  const headerClick = async (e) => {
    setShowHeader(e.target.checked);
  };

  useEffect(() => {
    _FetchProfileAPI();
    handleBoxSelected();
  }, []);

  // console.log(Weight)
  const _FetchProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(data);
        setProfileData(data);
        setsecondaryPhoneNo(response?.data?.data?.secondaryPhoneNo);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  useEffect(() => {
    const BookingId = location?.data?.data?.bookingId;
    GetBookingData(BookingId);
  }, [location]);

  // Invoice To functionality

  //Get Data by Booking ID
  const GetBookingData = async (bookingId) => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        // console.log(response.data?.data);
        setBookingData(response?.data?.data?.bookingDetails);
        setBuilltyData(response?.data?.data?.billtyDetails);
        setLrData(response?.data?.data?.bookingDetails?.lrDetails);
        const allPaidBySame =
          response?.data?.data?.bookingDetails?.lrDetails.every(
            (item) =>
              item.paidBy ===
              response?.data?.data?.bookingDetails?.lrDetails[0].paidBy
          );
        setIsPaidBySame(allPaidBySame);
        setIsLoading(false);
      });
  };

  const mergeLrDetailsAndBuilltyDetails = (billingData) => {
    const lrNoSet = new Set(billingData.map((lr) => lr.lrNo));
    const filteredBilltyDetails = builltyData.filter((billty) =>
      lrNoSet.has(billty.lrNo)
    );

    // Step 2: Merging Data
    const mergedData = [];
    for (const lr of billingData) {
      const matchingBillties = filteredBilltyDetails.filter(
        (billty) => billty.lrNo === lr.lrNo
      );

      const mergedLrData = {
        ...lr, // Spread all properties from lrDetails
        billtyDetails: matchingBillties[0],
      };

      mergedData.push(mergedLrData);
    }
    return mergedData;
  };

  const handleBoxSelected = () => {
    let checkedBoxes = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );

    let checkedRadio = document.querySelectorAll("input[type=radio]:checked");

    const anyCheckboxChecked = checkedBoxes.length > 1;
    const anyRadioChecked = checkedRadio.length > 0;

    setCheckedBox(!anyCheckboxChecked && !anyRadioChecked ? true : false);
  };

  const DialogSubmitButton = () => {
    setIsLoading(true);

    let checkedBoxes = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );

    let checkedRadio = document.querySelectorAll("input[type=radio]:checked");

    // console.log(checkedBoxes, "checkedBoxes");
    let InvoicesPara = Array.from(
      isPaidBySame ? checkedBoxes : checkedRadio
    ).map((x) => {
      return { Id: x.value, Type: x.name };
    });

    // console.log(InvoicesPara);
    let getValues = Array.from(InvoicesPara)
      .filter((x) => x.Type)
      .map((x) => {
        // Extract value and name attributes from each checkbox
        return x.Id;
      });
    // GET_BOOKING_BILLING
    // lrData.map((item) => item.lrId)
    let ApiPara = {
      BookingId: location?.data?.data?.bookingId,
      LrId: getValues.includes("AllLr")
        ? lrData.map((item) => item.lrId)
        : getValues.filter((value) => value !== "AllLr"),
    };

    const lrDetail = lrData.filter((lr) => lr.lrId === ApiPara.LrId[0]);

    if (
      lrDetail[0]?.paidBy !== "Both" &&
      lrDetail[0]?.paidBy !== "OtherParty" &&
      bookingData?.paidBy !== "Both" &&
      bookingData?.paidBy !== "OtherParty"
    ) {
      setInvoiceTo([
        {
          name:
            lrDetail[0]?.paidBy === "Consigner" ||
            bookingData?.paidBy === "Consigner"
              ? bookingData?.consignerName
              : bookingData?.consigneeName,
          address:
            lrDetail[0]?.paidBy === "Consigner" ||
            bookingData?.paidBy === "Consigner"
              ? bookingData?.consignerBillToAddress
              : bookingData?.consigneeBillToAddress,
          gst:
            lrDetail[0]?.paidBy === "Consigner" ||
            bookingData?.paidBy === "Consigner"
              ? bookingData?.consignerGst
              : bookingData?.consigneeGst,
          amount: bookingData?.bookingAmount,
          total: bookingData?.total,
          grandTotal: bookingData?.grandTotal,
          grandTotalInwords: bookingData?.totalInWords.toUpperCase(),
        },
      ]);
    } else if (
      lrDetail[0]?.paidBy === "Both" ||
      bookingData?.paidBy === "Both"
    ) {
      setInvoiceTo([
        {
          name: bookingData?.consigneeName,
          address: bookingData?.consignerBillToAddress,
          gst: bookingData?.consigneeGst,
          amount: bookingData?.consigneeAmount,
          total: bookingData?.consigneeAmount,
          grandTotal: bookingData?.consigneeGrandTotal,
          grandTotalInwords: bookingData?.consigneeTotalInWords.toUpperCase(),
        },
        {
          name: bookingData?.consignerName,
          address: bookingData?.consigneeBillToAddress,
          gst: bookingData?.consignerGst,
          amount: bookingData?.consignerAmount,
          total: bookingData?.consignerAmount,
          grandTotal: bookingData?.consignerGrandTotal,
          grandTotalInwords: bookingData?.consignerTotalInWords.toUpperCase(),
        },
      ]);
    } else if (
      lrDetail[0]?.paidBy === "OtherParty" ||
      bookingData?.paidBy === "OtherParty"
    ) {
      setInvoiceTo([
        {
          name: lrDetail[0]?.otherPartyName || bookingData?.otherPartyName,
          address:
            lrDetail[0]?.otherPartyAddress || bookingData?.otherPartyAddress,
          gst: lrDetail[0]?.otherPartyGst || bookingData?.otherPartyGst,
          amount: bookingData?.bookingAmount,
          total: bookingData?.total,
          grandTotal: bookingData?.grandTotal,
          grandTotalInwords: bookingData?.totalInWords.toUpperCase(),
        },
      ]);
    }
    axiosInstance.post(GET_BOOKING_BILLING, ApiPara).then((response) => {
      const mergerdData = mergeLrDetailsAndBuilltyDetails(
        response?.data?.data.lrDetails // merging Lrdata and bulity data as per Lr no
      );
      const finalBillingData = {
        lrDetails: mergerdData,
        total: response?.data?.data?.total,
        totalInWords: response?.data?.data?.totalInWords,
        consigneeTotal: response?.data?.data?.consigneeTotal,
        consigneeGrandTotal: response?.data?.data?.consigneeGrandTotal,
        consigneeTotalInWords: response?.data?.data?.consigneeTotalInWords,
        consignerTotal: response?.data?.data?.consignerTotal,
        consignerGrandTotal: response?.data?.data?.consignerGrandTotal,
        consignerTotalInWords: response?.data?.data?.consignerTotalInWords,
        grandTotal: response?.data?.data?.grandTotal,
      };

      // console.log(response?.data?.data);
      setBillingData(finalBillingData);
      setIsLoading(false);
      setOpen(false);
      return;
      // setExtraCharge(response?.data?.data?.totalExtraCharge);
      // setAdvance(response?.data?.data?.totalAdvance);
      // setFreight(response?.data?.data?.totalFreight);
    });
  };

  // console.log(location?.data?.data?.weightType,"location?.data?.data?.weightType")
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{ minWidth: "1240px" }}>
          {/* <button onClick={()=>{console.log(location?.data?.data)}}>Click</button> */}
          {open && (
            <Transition show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                initialFocus={ref}
                onClose={() => null}
                
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-300 bg-opacity-95 transition-opacity" />
                </Transition.Child>

                <div className=" fixed inset-0 z-10 overflow-y-auto">
                  <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="border-2 border-primary-kirtiroadways  rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-1/2">
                        <div className="bg-white px-8 pt-4 pb-4">
                          <div className="">
                            <div className=" text-center ">
                              <div className="mt-2">
                                <div className="flex flex-row justify-between space-x-4 mt-4">
                                  <div className=" flex md:w-1/2  flex-col  text-left">
                                    <Label label="Rate" />
                                    <Input
                                      onChange={(val) => {
                                        handleBoxSelected();
                                        SetRate(val.target.value.toUpperCase());
                                      }}
                                    />
                                  </div>
                                  {!location?.data?.data?.actualWeight && (
                                    <div className=" flex md:w-1/2  flex-col  text-left">
                                      <Label label="Weight" />
                                      <Input
                                        onChange={(val) =>
                                          SetWeight(
                                            val.target.value.toUpperCase()
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className=" flex md:w-full  flex-col  text-left ">
                                  <Label label="Note" />
                                  <textarea
                                    className="peer  w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                    onChange={(val) => {
                                      handleBoxSelected();
                                      setNote(val.target.value.toUpperCase());
                                    }}
                                    maxLength={80}
                                  />
                                </div>
                                {lrData && (
                                  <div className="flex flex-col justify-start items-start w-full   mt-4">
                                    {isPaidBySame && (
                                      <div className="flex flex-row mt-2 gap-2 items-center">
                                        <Checkbox
                                          name="AllLr"
                                          value="AllLr"
                                          className="accent-red-600	text-white"
                                          onChange={handleBoxSelected}
                                        />
                                        <p>All LR</p>
                                      </div>
                                    )}

                                    <div className="flex flex-col ">
                                      {isPaidBySame &&
                                        lrData.map((option) => (
                                          <div className="flex flex-row mt-1 gap-2 items-center justify-start">
                                            <Checkbox
                                              name={option.lrId}
                                              value={option.lrId}
                                              className="accent-red-600	text-white"
                                              onChange={handleBoxSelected}
                                            />
                                            <p>{option.lrNo}</p>
                                          </div>
                                        ))}
                                      {!isPaidBySame &&
                                        lrData.map((option) => (
                                          <div className="flex flex-row mt-1 gap-2 items-center justify-start">
                                            <input
                                              type="radio"
                                              name={"action"}
                                              value={option.lrId}
                                              className="accent-red-600	text-white"
                                              onChange={handleBoxSelected}
                                            />
                                            <p>{option.lrNo}</p>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className=" px-4 py-3 flex justify-center  sm:px-6">
                            <Button
                              onClick={() => {
                                // setOpen(false)
                                DialogSubmitButton();
                              }}
                              // ref={ref }
                              disabled={!Rate || checkedBox || !location?.data?.data?.actualWeight ? !Weight : false}
                              theme="primary"
                            >
                              OK
                            </Button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          )}
          <div className="flex flex-row gap-4 justify-center mt-5">
            <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">With Header</p>
              <Checkbox
                defaultChecked={showHeader}
                handleClick={(e) => headerClick(e)}
              />
            </div>
          </div>

          {invoiceTo.map((invoice, index) => {
            return (
              <div style={{ minWidth: "1024px" }} key={index}>
                <div className=" block min-h-screen align-top text-center bg-center bg-no-repeat  bg-[length:65%]">
                  <div
                    className={`${
                      showBorder &&
                      ` border-double  border-4 ${
                        blackWhite ? "border-black" : "border-red-200"
                      } `
                    }${
                      !showHeader && `mt-32`
                    } pageStyle  block  min-h-screen m-5   align-top text-center bg-center bg-no-repeat  bg-[length:65%]`}
                  >
                    {" "}
                    <div className="">
                      <div className="flex  flex-row justify-center font-bold sm:mx-8 mx-2 mb-3 sm:text-sm text-xs">
                        {/* <h5>{InvoicSubject}</h5> */}
                        <h5>Tax Invoice</h5>
                        {/* <h5> </h5> */}
                      </div>
                      {/* Logo and Title Start */}
                      {showHeader && (
                        <div className="m-2 items-center space-x-3 flex flex-row">
                          <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                            <div className="flex flex-row justify-start items-start ">
                              <div className="w-72 ml-2">
                                {blackWhite ? (
                                  <img src={black_logo} alt="Kirti Roadlines" />
                                ) : (
                                  <img src={logo} alt="Kirti Roadlines" />
                                )}
                              </div>
                              <div className=" flex flex-col w-full">
                                <span
                                  className={`kirtiroadline-headline ${
                                    !blackWhite && "text-primary-kirtiroadways"
                                  } font-extrabold font-serif`}
                                >
                                  {" "}
                                  {profileData.tagLine}
                                </span>
                                <span className="2xl:text-4xl text-3xl font-serif text-black">
                                  {profileData.subTagLine}
                                </span>
                              </div>
                            </div>
                            {/* Address Start */}
                            <div
                              className={`rounded-b-3xl rounded-t-lg font-bold   p-2 w-full  ${address(
                                blackWhite
                              )} flex flex-col space-y-1 text-center text-sm`}
                            >
                              {" "}
                              <p className="flex  text-md justify-center md:flex-row items-start">
                                <span className="text-md w-5">
                                  {address_svg}
                                </span>{" "}
                                <span className="text-md ml-1">
                                  {profileData.address}
                                </span>
                              </p>
                              <div className="  flex space-x-4 justify-center md:flex-row items-start ">
                                <div className="flex space-x-1 text-md justify-center md:flex-row items-center ">
                                  <span>{call_svg} </span>{" "}
                                  <span className="flex flex-row">
                                    {secondaryPhoneNo.map((number, i) => (
                                      <span
                                        key={number}
                                        className="text-sm font-semibold tracking-wider flex flex-row"
                                      >
                                        {(i ? ", " : "") + number}
                                      </span>
                                    ))}
                                  </span>
                                </div>
                                <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                                  <span>{email_svg} </span>
                                  <span>{profileData.email}</span>
                                </div>
                              </div>
                            </div>
                            {/* Address End */}
                          </div>
                        </div>
                      )}
                      {/* Logo and Title End */}
                    </div>
                    {/* Invoice  No and details*/}
                    <BillingBothPaid
                      invoice={invoice}
                      profileData={profileData}
                      heading={heading}
                      blackWhite={blackWhite}
                      location={location}
                      Today_Date={Today_Date}
                      invoiceNo={invoiceNo}
                      borderToggle={borderToggle}
                    />
                    {/* Table start*/}
                    <div
                      // style={{ minWidth: "768px" }}
                      className="p-2 w-full md:mb-0 mb-2 border-t-0 border-neutral-900"
                    >
                      <table className="border-spacing-y-2 border-separate w-full border-neutral-900 text-sm font-sans">
                        <thead style={{ maxHeight: "5px" }} className="mb-4">
                          <tr className=" border-neutral-900 text-white ">
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadStyle(blackWhite)}
                            >
                              Loading
                              <br />
                              Date
                            </th>
                            {bookingData?.lrDetails?.length === 0 ? null : (
                              <th
                                style={{ maxHeight: "5px" }}
                                className={TabletheadStyle(blackWhite)}
                              >
                                LR No.
                              </th>
                            )}
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadStyle(blackWhite)}
                            >
                              invoice No.
                            </th>
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadStyle(blackWhite)}
                            >
                              Vehicle No.
                            </th>
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadStyle(blackWhite)}
                            >
                              From
                            </th>
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadStyle(blackWhite)}
                            >
                              To
                            </th>
                            {/* <th
                    className=" bg-no-repeat"
                    style={{
                      backgroundImage: "url(" + table_bg + ")",
                    }}
                  ></th> */}
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadRedStyle(blackWhite)}
                              // style={{
                              // backgroundImage: "url(" + table_whitered + ")",
                              // }}
                            >
                              Weight
                            </th>
                            {/* <th className="bg-primary-kirtiroadways border-neutral-900">
                    Charged Weight
                  </th> */}
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadRedStyle(blackWhite)}
                            >
                              Rate
                            </th>
                            <th
                              style={{ maxHeight: "5px" }}
                              className={TabletheadRedLastStyle(blackWhite)}
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>

                        <tbody
                          className="align-top font-bold text-center bg-bottom bg-no-repeat bg-[length:80%_90%]"
                          // style={{
                          //   backgroundImage: "url(" + logo_green_oposity + ")",
                          // }}
                        >
                          {bookingData?.lrDetails?.length > 0 ? ( //   :
                            billingData?.lrDetails?.map((data, i) => (
                              <tr height="20px" className="mt-6">
                                <td
                                  height="20px"
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-32`}
                                >
                                  <h1 className="mt-2">
                                    {" "}
                                    {bookingData?.loadingDate
                                      ? moment(bookingData?.loadingDate).format(
                                          "DD-MM-YYYY"
                                        )
                                      : " "}
                                  </h1>
                                </td>
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-28`}
                                >
                                  <h1 className="mt-2">
                                    {data?.lrNo
                                      ? arrStringSorting(data?.lrNo).join(", ")
                                      : "--"}
                                  </h1>
                                </td>
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-28`}
                                >
                                  <h1 className="mt-2">
                                    {data?.companyInvoiceNo ||
                                      bookingData?.companyInvoiceNo}
                                  </h1>
                                </td>
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-32`}
                                >
                                  <h1 className="mt-2">
                                    {bookingData?.vehicleNo}
                                  </h1>
                                </td>
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-48`}
                                >
                                  <h1 className="mt-2">
                                    {data?.from || bookingData?.from}
                                  </h1>
                                </td>
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-48`}
                                >
                                  <h1 className="ml-2 mt-2 text-center">
                                    {data?.to || bookingData?.to}
                                  </h1>
                                </td>
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-32`}
                                >
                                  <h1 className="mt-2 text-center ">
                                    <span className="mr-1">
                                      {data?.billtyDetails?.actualWeight
                                        ? data?.billtyDetails?.actualWeight ===
                                          " "
                                          ? Weight
                                          : data?.billtyDetails?.actualWeight
                                        : Weight
                                        ? Weight
                                        : ""}
                                    </span>
                                    {data?.billtyDetails?.actualWeight !== "fix"
                                      ? data?.billtyDetails?.actualWeight &&
                                        data?.billtyDetails?.weightType !== "NA"
                                        ? data?.billtyDetails?.weightType
                                        : ""
                                      : ""}
                                  </h1>
                                </td>
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-24`}
                                >
                                  <h1 className="mt-2 text-center">{Rate}</h1>
                                </td>
                                {billingData?.lrDetails?.length > 0 &&
                                  (billingData?.lrDetails[i]?.paidBy !==
                                  "Both" ? (
                                    i === 0 && (
                                      <td
                                        style={{
                                          verticalAlign: "middle",
                                          textAlign: "center",
                                        }}
                                        rowSpan={`${
                                          billingData?.lrDetails[i]?.paidBy ===
                                          "Both"
                                            ? 1
                                            : billingData?.lrDetails?.length
                                        }`}
                                      >
                                        <h1 className="text-center">
                                          {billingData?.lrDetails[i]?.paidBy ===
                                          "Both"
                                            ? index === 0
                                              ? billingData?.lrDetails[i]
                                                  .consignerAmount
                                              : billingData?.lrDetails[i]
                                                  .consigneeAmount
                                            : billingData?.total}
                                        </h1>
                                      </td>
                                    )
                                  ) : (
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                        textAlign: "center",
                                      }}
                                      rowSpan={`${
                                        billingData?.lrDetails[i]?.paidBy ===
                                        "Both"
                                          ? 1
                                          : billingData?.lrDetails?.length
                                      }`}
                                    >
                                      <h1 className="text-center">
                                        {billingData?.lrDetails[i]?.paidBy ===
                                        "Both"
                                          ? index === 0
                                            ? billingData?.lrDetails[i]
                                                .consignerAmount
                                            : billingData?.lrDetails[i]
                                                .consigneeAmount
                                          : billingData?.total}
                                      </h1>
                                    </td>
                                  ))}
                              </tr>
                            ))
                          ) : (
                            <tr height="20px" className="mt-6">
                              <td
                                height="20px"
                                className={` ${
                                  blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-32`}
                              >
                                <h1 className="mt-2">
                                  {" "}
                                  {location?.data?.data?.loadingDate
                                    ? moment(
                                        location?.data?.data?.loadingDate
                                      ).format("DD-MM-YYYY")
                                    : " "}
                                </h1>
                              </td>
                              {/* {bookingData?.lrDetails?.length === 0 ? null : (
                              <th
                                style={{ maxHeight: "5px" }}
                                className={TabletheadStyle(blackWhite)}
                              >
                                LR No.
                              </th>
                            )} */}

                              {/* {bookingData?.lrDetails?.length === 0 ? null : (
                                <td
                                  className={` ${
                                    blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-28`}
                                >
                                  <h1 className="mt-2">
                                    {location?.data?.data?.lrNo
                                      ? arrStringSorting(
                                          location?.data?.data?.lrNo
                                        ).join(", ")
                                      : "--"}
                                  </h1>
                                </td>
                              )} */}

                              <td
                                className={` ${
                                  blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-28`}
                              >
                                <h1 className="mt-2">
                                  {bookingData?.companyInvoiceNo || "--"}
                                </h1>
                              </td>
                              <td
                                className={` ${
                                  blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-32`}
                              >
                                <h1 className="mt-2">
                                  {bookingData?.vehicleNo}
                                </h1>
                              </td>
                              <td
                                className={` ${
                                  blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-48`}
                              >
                                <h1 className="mt-2">{bookingData?.from}</h1>
                              </td>
                              <td
                                className={` ${
                                  blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-48`}
                              >
                                <h1 className="ml-2 mt-2 text-center">
                                  {bookingData?.to}
                                </h1>
                              </td>
                              <td
                                className={` ${
                                  blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-32`}
                              >
                                <h1 className="mt-2 text-center">
                                  {location?.data?.data?.actualWeight
                                    ? location?.data?.data?.actualWeight
                                    : Weight
                                    ? Weight
                                    : "--"}
                                  {location?.data?.data?.actualWeight !== "fix"
                                    ? location?.data?.data?.actualWeight &&
                                      location?.data?.data?.weightType
                                    : ""}
                                </h1>
                              </td>
                              <td
                                className={` ${
                                  blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-24`}
                              >
                                <h1 className="mt-2 text-center">{Rate}</h1>
                              </td>
                              <td className="">
                                <h1 className="mt-2 text-center">
                                  {/* {bookingData?.lrDetails?.paidBy==="Both" ? "Cash" : billingData?.total} */}
                                  {billingData?.total}
                                </h1>
                              </td>
                            </tr>
                          )}

                          <tr style={{ height: "250px" }}></tr>
                          <tr
                            className={`border-b-2  ${borderToggle(
                              blackWhite
                            )} min-w-full`}
                          >
                            <td
                              className={`border-b-2  ${borderToggle(
                                blackWhite
                              )}`}
                              colSpan="10"
                            >
                              {note ? (
                                <div
                                  className={`px-1 leading-snug h-12 py-1 w-[550px] mb-2 text-md tracking-wide rounded-lg uppercase ${Tablebg(
                                    blackWhite
                                  )} flex items-start`}
                                >
                                  {" "}
                                  <p className="font-bold w-14">NOTE:</p>
                                  <p
                                    style={{ fontSize: "12px" }}
                                    className="font-semibold break-all"
                                  >
                                    {note}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {/* Bannk Detail Start */}
                      <div className="flex flex-row justify-between  font-semibold text-left text-sm font-sans">
                        <div className="">
                          <div className="flex flex-col space-x-1 ">
                            <div
                              className={`px-1 leading-snug	text-center py-2 w-96 mb-2 text-md tracking-wide  rounded-lg uppercase ${Tablebg(
                                blackWhite
                              )}`}
                            >
                              {" "}
                              <p>{OURBANKDETAILS}</p>
                              <p style={{ fontSize: "10px" }}>
                                ( {SCANQRCONTENT} )
                              </p>
                            </div>
                            {optionsBank.length > 0 && (
                              <div
                                style={{
                                  minWidth: "350px",
                                  maxWidth: "350px",
                                  marginLeft: "inherit",
                                }}
                                className="flex flex-col  text-left w-1/2 z-80"
                              >
                                <Select
                                  options={optionsBank.map((el) => ({
                                    label: el.bankName,
                                    value: el.accountNo,
                                    branch: el.branch,
                                    billAdd: el.billToAddress,
                                    ifscCode: el.ifscCode,
                                  }))}
                                  defaultValue={optionsBank[0]}
                                  onChange={(e) => {
                                    setBankBranch({
                                      ...bankBranch,
                                      bank: e.label,
                                      branch: e.branch,
                                      accNo: e.value,
                                      ifsc: e.ifscCode,
                                    });
                                    // console.log(e);
                                    setDropdown(true);
                                  }}
                                  placeholder="Bank Branch"
                                  className="z-80 "
                                  menuPortalTarget={document.body}
                                  menuPosition={"fixed"}
                                  styles={customStyles}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                      ...theme.colors,
                                      primary25: "#f68e92",
                                      primary: "#ed1c24",
                                    },
                                  })}
                                />
                              </div>
                            )}
                          </div>
                          <div>
                            <div className="flex space-x-1">
                              {/* QR code */}
                              <div className="flex flex-col ">
                                <img
                                  width="98"
                                  src={qrcode}
                                  alt="Kirti Roadlines"
                                />
                              </div>
                              <div className="space-y-1  mt-2">
                                <h5>Bank Name</h5>
                                <h5>Branch</h5>
                                <h5>A/c. No</h5>
                                <h5>IFSC Code</h5>
                              </div>
                              {Dropdown === false ? (
                                <>
                                  {optionsBank.length > 0 && (
                                    <div className="space-y-1 mt-2">
                                      {" "}
                                      <h5>:{optionsBank[0]?.bankName}</h5>
                                      <h5>: {optionsBank[0].branch}</h5>
                                      <h5>: {optionsBank[0].accountNo}</h5>
                                      <h5>: {optionsBank[0].ifscCode}</h5>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className="space-y-1  mt-2">
                                  <h5>: {bankBranch.bank}</h5>
                                  <h5>: {bankBranch.branch}</h5>
                                  <h5>: {bankBranch.accNo}</h5>
                                  <h5>: {bankBranch.ifsc}</h5>
                                </div>
                              )}
                            </div>
                            <p className="text-xs">{WE_USING_PAYPHONE}</p>
                          </div>
                        </div>
                        <div className="flex flex-row  justify-end w-64 ">
                          <div className="space-y-1   text-right w-32">
                            <h5 className="pr-2 font-bold">TOTAL :</h5>
                            <div className="h-16 font-bold">
                              {bookingData?.hamali > 0 && (
                                <h5 className=" pr-2 font-bold">Hamali :</h5>
                              )}
                              {bookingData?.detention > 0 && (
                                <h5 className="pr- font-bold2">Detention :</h5>
                              )}
                              {bookingData?.extraCharge > 0 && (
                                <h5 className=" pr-2 font-bold">
                                  Extra Charge :
                                </h5>
                              )}
                            </div>
                            <h5
                              className={`pr-2 text-sm	 px-2 py-2 tracking-wide text-md whitespace-nowrap rounded-l-lg  w-full uppercase font-bold ${Tablebg(
                                blackWhite
                              )}`}
                            >
                              {" "}
                              Grand Total
                            </h5>
                          </div>
                          <div className="space-y-1 text-right w-28 font-bold">
                            <h5>
                              &#8377;
                              {/* {billingData?.lrDetails[index]?.paidBy } -{billingData?.lrDetails.length} */}
                              {billingData?.lrDetails.length !== 1
                                ? billingData?.lrDetails[index]?.paidBy ===
                                  "Both"
                                  ? index === 0
                                    ? billingData?.consignerTotal
                                    : billingData?.consigneeTotal
                                  : billingData?.total
                                : billingData?.lrDetails[0]?.paidBy === "Both"
                                ? index === 0
                                  ? billingData?.consignerTotal
                                  : billingData?.consigneeTotal
                                : billingData?.total}
                              {/* {billingData?.lrDetails[i]?.paidBy ===
                                        "Both"
                                          ? index === 0
                                            ? billingData?.lrDetails[i]
                                                .consignerAmount
                                            : billingData?.lrDetails[i]
                                                .consigneeAmount
                                          : billingData?.total} */}
                            </h5>
                            <div className="h-16 font-bold">
                              {bookingData?.hamali > 0 && (
                                <h5>&#8377; {bookingData?.hamali}</h5>
                              )}
                              {bookingData?.detention > 0 && (
                                <h5>&#8377; {bookingData?.detention}</h5>
                              )}
                              {bookingData?.extraCharge > 0 && (
                                <h5> &#8377; {bookingData?.extraCharge}</h5>
                              )}
                            </div>
                            <h5
                              className={`border-l-2 text-md whitespace-nowrap rounded-r-lg pr-2 px-5 py-2 tracking-wide b-r-2 w-full uppercase font-bold ${Tablebg(
                                blackWhite
                              )}`}
                            >
                              {" "}
                              &#8377;
                              {billingData?.lrDetails.length !== 1
                                ? billingData?.lrDetails[index]?.paidBy ===
                                  "Both"
                                  ? index === 0
                                    ? billingData?.consignerGrandTotal
                                    : billingData?.consigneeGrandTotal
                                  : billingData?.grandTotal
                                : billingData?.lrDetails[0]?.paidBy === "Both"
                                ? index === 0
                                  ? billingData?.consignerGrandTotal
                                  : billingData?.consigneeGrandTotal
                                : billingData?.grandTotal}
                            </h5>
                          </div>
                        </div>
                      </div>
                      {/* Bannk Detail End */}

                      {/* Extra Information */}
                      <div
                        className={`flex flex-col justify-start items-start font-sans  border-t-2 ${borderToggle(
                          blackWhite
                        )} `}
                      >
                        <div className="flex justify-between w-full">
                          <p>Amount Chargeable (in words)</p>
                          <p className="text-xs font-bold">
                            As per MSME law you kindly release payment in 45
                            days
                          </p>
                        </div>
                        <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
                          {/* Four Thousand Four Hundred Fifty */}
                          {billingData?.lrDetails.length !== 1
                            ? billingData?.lrDetails[index]?.paidBy === "Both"
                              ? index === 0
                                ? billingData?.consignerTotalInWords
                                : billingData?.consigneeTotalInWords
                              : billingData?.totalInWords
                            : billingData?.lrDetails[0]?.paidBy === "Both"
                            ? index === 0
                              ? billingData?.consignerTotalInWords
                              : billingData?.consigneeTotalInWords
                            : billingData?.totalInWords}{" "}
                          ONLY
                        </p>
                      </div>
                      {/* terms and condition */}
                      <div className="flex flex-row justify-start items-start mt-2 font-sans">
                        <div className="w-1/2 text-left flex flex-col">
                          <p className="border-black border-b-4 w-44 pb-1">
                            Terms & Conditions
                          </p>
                        </div>
                        <div className="w-1/2 text-left text-sm">E. & O.E.</div>
                        <div className="w-1/2 text-left text-sm ">FOR,</div>
                        <div className="w-1/3 text-lg font-bold text-right ">
                          {KIRTIROADLINES}
                        </div>
                      </div>
                      {/* second line */}
                      <div className="flex  flex-row justify-between items-start font-sans">
                        <div className="w-2/3 text-left flex flex-col font-sans">
                          <p>{InvoicSubject} </p>
                        </div>
                        <div className=" text-right  font-bold flex justify-end">
                          <img src={sign} alt="signature" width={100} />
                        </div>
                      </div>
                      {/* last line */}
                      <div className="flex  border-black border-b-2 flex-row justify-start items-start mb-2 font-sans">
                        <div className="w-2/3 text-left flex flex-col font-sans">
                          <p>
                            {PaymentbypayeeCchequeDrafs} {KIRTIROADLINES}
                          </p>
                        </div>
                        <div className="w-1/2 text-right  font-bold ">
                          <h5>{AuthorizedSignatory}</h5>
                        </div>
                      </div>

                      {/* printing */}
                      <h2
                        className={`text-sm mt-0 mb-0 text-center font-bold ${Tablebg(
                          blackWhite
                        )} rounded-md p-2 font-sans`}
                      >
                        {" "}
                        {computerGeneratedInvoice}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <BillingInvoiceView
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            // sentInvoice={sentInvoice}
            builltyData={builltyData}
            bankBranch={bankBranch}
            Dropdown={Dropdown}
            ShowHeader={showHeader}
            profileData={profileData}
            secondaryPhoneNo={secondaryPhoneNo}
            Rate={Rate}
            weight={Weight}
            BookingData={bookingData}
            InvoiceTo={invoiceTo}
            showBorder={showBorder}
            blackWhite={blackWhite}
            mobilePrint={mobilePrint}
            invoiceNo={invoiceNo}
            note={note}
            billingData={billingData}
          />
        </div>
      )}
    </>
  );
};

export default BillingView;

// {location?.data?.data?.paidBy === "Both" ? (
//   <BillingBothPaid
//     invoice={invoice}
//     profileData={profileData}
//     heading={heading}
//     blackWhite={blackWhite}
//     location={location}
//     Today_Date={Today_Date}
//     invoiceNo={invoiceNo}
//     borderToggle={borderToggle}
//   />
// ) : (
//   <BillingOnePartyPaid
//     invoice={invoice}
//     profileData={profileData}
//     heading={heading}
//     blackWhite={blackWhite}
//     location={location}
//     Today_Date={Today_Date}
//     invoiceNo={invoiceNo}
//     borderToggle={borderToggle}
//   />
// )}
