import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import {
  Billing_Svg,
  BILLTY_SVG,
  edit_svg,
  LCV_SVG,
  ledger_svg,
  // view_svg,
} from "../../../Utils/SVGListing";
import ButtonWithBG from "../ButtonMain";
import Modal from "../Modal";
import { useState } from "react";

export default function TableAction({
  data,
  getAPI,
  redirectPath,
  redirectViewPath,
  isBuillty,
  redirectBuillty,
  isBilling,
  redirectBilling,
  isLedger,
  redirectLedger,
  isLCV,
  redirectLCV,
  component,
  noEdit,
  noDelete,
}) {
  const [mouseCoords, setMouseCoords] = useState([]);
  const [x, y] = mouseCoords;

  const mouseEnter = (ev) => {
    const box = ev.target.getBoundingClientRect();
    setMouseCoords([box.right, box.top]);
  };

  let history = useHistory();

  // set localstorage id and component id
  localStorage.setItem("component", component);
  if (component === "Booking") {
    localStorage.setItem("id", data?.bookingId);
  } else if (component === "Company") {
    localStorage.setItem("id", data?.companyId);
  } else if (component === "Transporter") {
    localStorage.setItem("id", data?.transporterId);
  } else if (component === "Commission") {
    localStorage.setItem("id", data?.commissionId);
  } else if (component === "Vehicle Owner") {
    localStorage.setItem("id", data?.ownerId);
  } else if (component === "Third Party") {
    localStorage.setItem("id", data?.thirdPartyId);
  }else if (component === "Other Party") {
    localStorage.setItem("id", data?.otherPartyId);
  }
  // To redirect to add page
  const redirectToEdit = () => {
    if (redirectPath) {
      history.push({
        pathname: `/${redirectPath}`,
        state: { label: "Edit", data: { data } },
      });
      // console.log(data)
    }
  };

  // const redirectToView = () => {
  //   //  console.log(VehicleOwnerID )
  //   if (redirectViewPath) {
  //     history.push({
  //       pathname: `/${redirectViewPath}`,
  //       state: { data: { data } },
  //     });
  //   }
  // };
  // Redirect to ledger
  const redirectToLedger = () => {
    if (redirectLedger) {
      history.push({
        pathname: `/${redirectLedger}`,
        data: { data },
        component: { component },
      });
    }
  };
  // Redirect to Billing
  const redirectToBilling = () => {
    if (redirectBilling) {
      history.push({
        pathname: `/${redirectBilling}`,
        data: { data },
      });
    }
    // console.log(data)
  };
  // Redirect to Buillty
  const redirectToBuillty = () => {
    if (redirectBuillty) {
      history.push({
        pathname: `/${redirectBuillty}`,
        data: { data },
      });
      // console.log(data,"data")
      if (data) {
        localStorage.setItem("consigneeName", data.consigneeName);
      }
    }
  };
  // Redirect to LCV
  const redirectToLCV = () => {
    if (redirectLCV) {
      history.push({
        pathname: `/${redirectLCV}`,
        data: { data },
      });
      // console.log(data)
      if (data) {
        localStorage.setItem("transporterName", data.transporterName);
      }
    }
  };

  return (
    <div className=" text-center ">
      <Menu as="div" className=" inline-block md:text-left text-right">
        <div onMouseEnter={mouseEnter}>
          <Menu.Button className=" justify-center">
            {/* Options */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
              />
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            style={{ left: x, top: y }}
            className="z-100 md:fixed flex flex-row mt-2 w-content  divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            {!noEdit && (
              <div className="px-1 py-1 ">
                <Menu.Item>
                  <span>
                    <ButtonWithBG
                      tooltipContent="Edit"
                      onClick={redirectToEdit}
                      theme="secondary"
                      svg={edit_svg}
                    ></ButtonWithBG>
                  </span>
                </Menu.Item>
              </div>
            )}
            {/* <div className="px-1 py-1">
              <Menu.Item>
                <span>
                  {" "}
                  <ButtonWithBG
                    tooltipContent="View"
                    theme="secondary"
                    svg={view_svg}
                    onClick={redirectToView}
                  ></ButtonWithBG>
                </span>
              </Menu.Item>
            </div> */}
            {isLedger && (
              <div className="px-1 py-1">
                <Menu.Item>
                  <span>
                    {" "}
                    <ButtonWithBG
                      tooltipContent="Ledger"
                      theme="secondary"
                      svg={ledger_svg}
                      onClick={redirectToLedger}
                    ></ButtonWithBG>
                  </span>
                </Menu.Item>
              </div>
            )}
            {data.totalBillty > 0 && (
              <div className="px-1 py-1">
                <Menu.Item>
                  <span>
                    {" "}
                    <ButtonWithBG
                      tooltipContent="Buillty"
                      theme="secondary"
                      svg={BILLTY_SVG}
                      onClick={redirectToBuillty}
                    ></ButtonWithBG>
                  </span>
                </Menu.Item>
              </div>
            )}
            {isBilling && (
              <div className="px-1 py-1">
                <Menu.Item>
                  <span>
                    {" "}
                    <ButtonWithBG
                      tooltipContent="Billing"
                      theme="secondary"
                      svg={Billing_Svg}
                      onClick={redirectToBilling}
                    ></ButtonWithBG>
                  </span>
                </Menu.Item>
              </div>
            )}
            {isLCV && (
              <div className="px-1 py-1">
                <Menu.Item>
                  <span>
                    {" "}
                    <ButtonWithBG
                      tooltipContent="LCV"
                      theme="secondary"
                      svg={LCV_SVG}
                      onClick={redirectToLCV}
                    ></ButtonWithBG>
                  </span>
                </Menu.Item>
              </div>
            )}
            {!noDelete && (
              <div className="px-1 py-1">
                <Modal
                  component={component}
                  data={getAPI}
                  // TableDeleteHandler={DeleteListHandler}
                />
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
