import React from "react";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../Utils/API";
import {
  GET_COMMISSION_TABLE,
  UPDATE_COMMISSION_DATA,
  UPDATE_TRANSPORTER_VEHICLE_PAYMENT,
} from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";
import moment from "moment";
import Checkbox from "../UI/Checkbox";
import { ConfirormationModal } from "../UI/ConfirormationModal";
import { BUFFERING_SVG } from "../../Utils/SVGListing";
import PaymentsStatusPhill from "../Functionality/PaymentsStatusPhill";
import Badge from "../UI/Badge/index";
import Tooltip from "react-simple-tooltip";
import VehicleInformation from "../Functionality/VehicleInformation";

export default function CommissionEntryTable(props) {
  // console.log(props,"for table")
  var Today_Date = new Date();
  const [CommissionData, SetCommissionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    getAPI(); // eslint-disable-next-line
  }, [props.searchValue, props.dateFilter]);

  const getAPI = () => {
    axiosInstance
      .get(
        `${GET_COMMISSION_TABLE}?search=${
          props.searchValue ? props.searchValue : ""
        }${
          props.dateFilter !== undefined && props.dateFilter !== null
            ? moment(props?.dateFilter).format("YYYY-MM-DD")
            : ""
        }&pageNo=&pageSize=`
      )
      .then((response) => {
        const data = response?.data?.data?.commission;
        SetCommissionData(data);
        // console.log(data)
        setIsLoading(false);
        setActionLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // Builtty checkbox APIS
  const postBuilltyRecive = (commissionData, builltyValue) => {
    commissionData.isBilltyReceive = builltyValue;
    commissionData.billtyReceiveDate = Today_Date;
    // console.log(commissionData,"commissionNewData")
    axiosInstance
      .post(UPDATE_COMMISSION_DATA, commissionData)
      .then((response) => {
        getAPI();
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };

  // Change Payment status
  const UpdatePaymentMethod = async (e, id, type) => {
    // console.log(e)
    // console.log(id)
    // console.log(type)
    // setIsLoading(true);
    // props?.ownerId
    setActionLoading(true);
    // Vehicle & transporter payment update
    let paymentData = {
      Id: id,
      IsPaid: e,
    };
    await axiosInstance
      .post(UPDATE_TRANSPORTER_VEHICLE_PAYMENT, paymentData)
      .then((response) => {
        getAPI();
        // console.log(response);
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };
  const columns = [
    {
      Header: "Is Buillty Recived",
      accessor: "isBiltyReceive",
      id: "isBiltyReceive",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div className="hover:cursor-pointer">
          {actionLoading ? (
            <span>{BUFFERING_SVG} </span>
          ) : (
            <Checkbox
              defaultChecked={row.row.original.isBilltyReceive}
              className="accent-red-600	text-white hover:cursor-pointer"
              onClick={(e) => {
                postBuilltyRecive(row.row.original, e.target.checked);
                // console.log(row.row.original,"row.row.original")

                // e.preventDefault();
                // return false;
              }}
            />
          )}
        </div>
      ),
    },
    {
      Header: "Action",
      id: "Action",
      maxWidth: 50,
      minWidth: 50,
      width: 50,
      Cell: (row) => (
        <div>
          <TableAction
            // isBuillty
            isLCV
            isBilling
            data={row.row.original}
            redirectPath="commission-entry/update-commission"
            redirectViewPath="commission-entry/commission-invoice"
            redirectLCV="LCV/Transporter-Loading-Slip"
            component="Commission"
            redirectBilling="commission-entry/commission-invoice"
            getAPI={getAPI}
          />
        </div>
      ),
    },
    {
      Header: "Payment Status",
      id: "Payment Status",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) =>
      actionLoading ? (
        <span>{BUFFERING_SVG} </span>
      ) : (
        <PaymentsStatusPhill
        onClickEvent={UpdatePaymentMethod}
        defaultValue={row?.row?.original?.isTransporterPaid && row?.row?.original?.isVehicleOwnerPaid}
        id={row?.row?.original?.commissionId}
        type={row?.row?.original?.type}
        label="Commission"
      />
      ),
    },
    // {
    //   Header: "DATE",
    //   accessor: (d) => {
    //     return moment(d.createdAt).format("DD-MM-YYYY");
    //   },
    // },
    {
      Header: "L. DATE	",
      accessor: (d) => {
        return moment(d.loadingDate).format("DD-MM-YYYY");
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Vehicle NO.",
      accessor: "vehicleNo",
      Cell: (row) => (
        <VehicleInformation
          id={row?.row?.original?.vehicleNo}
          label={row?.row?.original?.vehicleNo}
          freight={row?.row?.original?.vehicleFreight}
          balance={row?.row?.original?.vehicleBalance}
          advance={row?.row?.original?.vehicleAdvance}
        />
      ),
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "TRANSPORTER",
      accessor: "transporterName",
      Cell: ({ value, row }) => (
        <Tooltip
          className="w-48"
          border="#ed1c24"
          color="black"
          background="#fabbbd"
          content={value}
          radius={5}
          placement={row.index === 0 ? "bottom" : "top"}
          zIndex={100}
          padding={10}
          arrow={5}
        >
          <p className="line-clamp-1  whitespace-nowrap truncate cursor-pointer text-left	ml-4 w-32">
            {value}
          </p>
        </Tooltip>
      ),
      maxWidth: 170,
      minWidth: 170,
      width: 170,
    },
    {
      Header: "VEHICLE OWNER",
      accessor: "vehicleOwnerName",
      Cell: ({ value, row }) => (
        <Tooltip
          className="w-48"
          border="#ed1c24"
          color="black"
          background="#fabbbd"
          content={value}
          radius={5}
          placement={row.index === 0 ? "bottom" : "top"}
          zIndex={100}
          padding={10}
          arrow={5}
        >
          <p className="line-clamp-1 whitespace-nowrap truncate cursor-pointer text-left	ml-4 w-32">
            {value}
          </p>
        </Tooltip>
      ),
      maxWidth: 170,
      minWidth: 170,
      width: 170,
    },
    {
      Header: "FROM",
      accessor: "from",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "To",
      accessor: "to",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    // {
    //   Header: "DRIVER PAID BALANCE",
    //   accessor: "balancePaidBillNo",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BILLTY RECEIVED OR NOT",
    //   // accessor: "isBilltyReceive",
    //   accessor: (d) => {
    //     if (d.isBilltyReceive === true) {
    //       return <span>true</span>;
    //     }
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BOOKING Amount",
    //   accessor: "bookingAmount",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "LR NO.",
    //   accessor: "lrNo",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    {
      Header: "Freight",
      accessor: "transporterFreigth",
      maxWidth: 60,
      minWidth: 60,
      width: 60,
    },
    {
      Header: "Advance",
      accessor: "transporterAdvance",
      maxWidth: 60,
      minWidth: 60,
      width: 60,
    },
    {
      Header: "Balance",
      accessor: "transporterBalance",
      Cell: (row) =>
        actionLoading ? (
          <span>{BUFFERING_SVG} </span>
        ) : (
          <Badge
            isPaid={
              row.row.original.isTransporterPaid &&
              row.row.original.isVehicleOwnerPaid
            }
            value={row.value}
          />
        ),
      maxWidth: 60,
      minWidth: 60,
      width: 60,
    },
    {
      Header: "Hamali",
      accessor: "transporterHamali",
      maxWidth: 60,
      minWidth: 60,
      width: 60,
    },
    {
      Header: "Detention",
      accessor: "transporterDetention",
      maxWidth: 90,
      minWidth: 90,
      width: 90,
    },
    {
      Header: "Extra Charge",
      accessor: "transporterExtraCharge",
      maxWidth: 60,
      minWidth: 60,
      width: 60,
    },
    {
      Header: "Total",
      accessor: "transporterTotal",
      Cell: (row) =>
        actionLoading ? (
          <span>{BUFFERING_SVG} </span>
        ) : (
          <Badge isPaid={row.row.original.isPaid} value={row.value} />
        ),
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    // {
    //   Header: "FALCO",
    //   accessor: "falko",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },

    // {
    //   Header: "TRANSPORTER BALANCE",
    //   accessor: "transporterBalance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BALANCE BILL NO.",
    //   accessor: "balanceBillNo",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },

    // {
    //   Header: "Note",
    //   accessor: "note",
    // },
  ];

  return (
    <section>
      <div className="mt-4 ">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={CommissionData} columns={columns} />
        )}
      </div>
    </section>
  );
}
