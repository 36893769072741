import React, { useRef } from "react";
import sign from "../../Images/sign.png";
// import table_bg from "../../Images/table_bg.png";
// import Scrollbars from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import moment from "moment";
import {
  InvoicSubject,
  KIRTIROADLINES,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  PaymentbypayeeCchequeDrafs,
  // AmountChargeableInWords,
  OURBANKDETAILS,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
  WE_USING_PAYPHONE,
  SCANQRCONTENT,
} from "../../Utils/Constants";
import qrcode from "../../Images/qrcode.jpg";
import PrintingHeader from "../../Components/Functionality/KirtiRoadline/PrintingHeader";
import {
  Tablebg,
  TabletheadRedLastStyle,
  TabletheadRedStyle,
  TabletheadStyle,
  borderToggle,
} from "../../Utils/TableStyling";
import PDFHeader from "../../Components/Functionality/KirtiRoadline/PDFHeader";
import { UPDATEINVOICENO } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";
import { background } from "../../Layouts/pdfLayout/BackgroundLayout";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "../../Utils/PTSerif-Regular";
import { robotoBlack } from "../../Utils/Roboto-Black";
import { PTSerifBold } from "../../Utils/PTSerif-Bold";
import { BillingHeader } from "../../Layouts/pdfLayout/BillingHeaderLayout";
import { NotoSansDevanagariBold } from "../../Utils/NotoSansDevanagari-Bold";
import { NotoSansDevanagariRegular } from "../../Utils/NotoSansDevanagari-Regular";
import { BookingBillingContent } from "../../Layouts/pdfLayout/BookingBillingContent";
import { WithoutHeaderBackground } from "../../Layouts/pdfLayout/WithoutHeaderBackground";
import { SimpleBookingBilling } from "../../Layouts/pdfLayout/SimpleBookingBilling";
const pdfMakeX = require("pdfmake/build/pdfmake.js");
const pdfFontsX = require("pdfmake-unicode/dist/pdfmake-unicode.js");
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
//importing the fonts whichever present inside vfs_fonts file
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["PTSerif-Regular.ttf"] = font;
window.pdfMake.vfs["PTSerif-Bold.ttf"] = PTSerifBold;
window.pdfMake.vfs["Roboto-Black.ttf"] = robotoBlack;
window.pdfMake.vfs["NotoSansDevanagari-Bold.ttf"] = NotoSansDevanagariBold;
window.pdfMake.vfs["NotoSansDevanagari-Regular.ttf"] =
  NotoSansDevanagariRegular;
// window.pdfMake.vfs["Fontello.ttf"] = Fontello;

const BillingInvoiceView = ({
  sentInvoice,
  ShowHeader,
  BankDetails,
  profileData,
  secondaryPhoneNo,
  bankBranch,
  Dropdown,
  Rate,
  weight,
  BookingData,
  InvoiceTo,
  showBorder,
  blackWhite,
  mobilePrint,
  invoiceNo,
  builltyData,
  note,
  setIsLoading,
  billingData,
}) => {
  // console.log(invoiceNo,"invoiceNo")
  // eslint-disable-next-line
  const pageStyle = `@page {
    size: A4;
    margin: 20%;
  ]}
  @page :second {
    size: A4;
    margin: 10pt !important;
  }
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
  }
  }
  `;
  let componentRef = useRef();
  const location = useLocation();

  // console.log(builltyData,'builltyData')

  const sanitizeFilename = (filename) => {
    // Replace special characters with underscores
    // Download pdf
    let checkNull = filename.includes("null");
    if (checkNull) {
      return filename.replace(/[^\w\s]/gi, "_")?.replace("null", "");
    } else if (!checkNull) {
      return filename.replace(/[^\w\s]/gi, "_");
    }
  };

  async function generateContent(InvoiceTo) {
    // Ensure 'data' is an array before calling 'map'
    const contentPromises = InvoiceTo.map(async (invoice, i) => {
      const pagesContent = [];
      const BillingHeaderContent = await BillingHeader(
        profileData,
        secondaryPhoneNo,
        "billing",
        ShowHeader
      );
      const BillingContent =
        BookingData?.lrDetails?.length === 0
          ? await SimpleBookingBilling(
              location,
              WE_USING_PAYPHONE,
              invoice,
              bankBranch,
              Rate,
              profileData,
              ShowHeader,
              invoiceNo,
              weight,
              BookingData,
              note,
              builltyData,
              billingData
            )
          : await BookingBillingContent(
              location,
              WE_USING_PAYPHONE,
              invoice,
              bankBranch,
              Rate,
              profileData,
              ShowHeader,
              invoiceNo,
              weight,
              BookingData,
              note,
              builltyData,
              billingData,
              i
            );

      pagesContent.push([BillingHeaderContent, BillingContent]);

      if (i < InvoiceTo.length - 1) {
        // Add page break if not the last iteration
        pagesContent.push({ text: "", pageBreak: "after" });
      }

      return pagesContent;
    });
    return (await Promise.all(contentPromises)).flat();
  }

  async function handleDownloadPdf(action) {
    updateInvoiceNo();
    setIsLoading(true);
    //Specifying the fonts which we are going to use in our PDF
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Black.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      PTSerifRegular: {
        normal: "PTSerif-Regular.ttf",
        bold: "PTSerif-Bold.ttf",
        italics: "PTSerif-Regular.ttf",
        bolditalics: "PTSerif-Regular.ttf",
      },
      NotoSansDevanagari: {
        normal: "NotoSansDevanagari-Regular.ttf",
        bold: "NotoSansDevanagari-Bold.ttf",
        italics: "NotoSansDevanagari-Regular.ttf",
        bolditalics: "NotoSansDevanagari-Regular.ttf",
      },
    };

    const sanitizedFilename = sanitizeFilename(
      location?.data?.data?.bookingInvoiceNo
        ? location?.data?.data?.bookingInvoiceNo
        : invoiceNo
    );
    // eslint-disable-next-line
    const fileName = `${sanitizedFilename}.pdf`;

    const pdfDocDefinition = {
      pageMargins: [35, ShowHeader ? 35 : 130, 35, 20],
      content: await generateContent(InvoiceTo),
      info: {
        title: fileName,
      },
      styles: {
        table: {
          margin: [0, 0, 0, 0], // Adjust the top margin of the entire table
        },
        tableHeader: {
          fontSize: 9,
          alignment: "start", // 'start' aligns text to the start of the cell
          bold: true,
          fillColor: "#fff",
          color: "#000",
          margin: [1, 0, 0, 0], // Adjust the margin for each header cell
          borderBottom: "1px solid #28328c",
        },
        cellStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
        },
      },
      background: !ShowHeader ? WithoutHeaderBackground : background,
    };

    const callbackFunction = function () {
      // Here implement function for hide waiting loader
      setIsLoading(false);
    };

    const newLocal = pdfMake.createPdf(pdfDocDefinition);
    const handlePrint = () => {
      pdfDocGenerator.print();
      setIsLoading(false);
    };
    // eslint-disable-next-line
    const pdfDocGenerator = newLocal;
    action === "download"
      ? pdfDocGenerator.download(fileName, callbackFunction)
      : handlePrint();
  }

  const updateInvoiceNo = () => {
    let data = {
      BookingId: BookingData?.bookingId,
      BookingInvoiceNo: invoiceNo,
    };
    if (invoiceNo !== location?.data?.data?.bookingInvoiceNo) {
      axiosInstance
        .post(UPDATEINVOICENO, data)
        .then((response) => {
          // console.log(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <div className="hidden">
        <div ref={componentRef}>
          {InvoiceTo.map((invoice, index) => {
            return (
              <div
                key={invoice.gst}
                className={`${
                  showBorder &&
                  ` border-double  border-4 ${
                    blackWhite ? "border-black" : "border-red-200"
                  } `
                } ${
                  !ShowHeader && `mt-36`
                } pageStyle  block  m-5   align-top text-center bg-center bg-no-repeat `}
                // bg-no-repeat  bg-[length:65%]`}
                // style={{
                //   backgroundImage: "url(" + logo_oposity + ")",
                // }}
              >
                {mobilePrint ? (
                  <PDFHeader
                    data={location?.data?.data}
                    profileData={profileData}
                    secondaryPhoneNo={secondaryPhoneNo}
                    companyName={invoice.name}
                    companyAdd={invoice.address}
                    companyGst={invoice.gst}
                    noHeader={!ShowHeader}
                    border={showBorder}
                    blackWhite={blackWhite}
                    mobilePrint={mobilePrint}
                    invoiceNo={invoiceNo}
                    comapnyInvoiceNo
                  />
                ) : (
                  <PrintingHeader
                    data={location?.data?.data}
                    profileData={profileData}
                    secondaryPhoneNo={secondaryPhoneNo}
                    companyName={invoice.name}
                    companyAdd={invoice.address}
                    companyGst={invoice.gst}
                    noHeader={!ShowHeader}
                    border={showBorder}
                    blackWhite={blackWhite}
                    comapnyInvoiceNo
                    invoiceNo={invoiceNo}
                  />
                )}

                {/* Table start*/}
                <div className="p-2 pb-0 w-full mb-2 border-t-0 border-neutral-900">
                  <table className="border-spacing-y-2 border-separate w-full border-neutral-900 text-xs font-sans">
                    <thead className="mb-2">
                      <tr className=" border-neutral-900 text-white ">
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `}
                        >
                          Loading
                          <br />
                          Date
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `}
                        >
                          LR No.
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `}
                        >
                          Vehicle No.
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `}
                        >
                          From
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `}
                        >
                          To
                        </th>
                        {/* <th
                    className=" bg-no-repeat"
                    style={{
                      backgroundImage: "url(" + table_bg + ")",
                    }}
                  ></th> */}
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadRedStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `} // style={{
                          // backgroundImage: "url(" + table_whitered + ")",
                          // }}
                        >
                          Weight
                        </th>
                        {/* <th className="bg-primary-kirtiroadways border-neutral-900">
                      Charged Weight
                    </th> */}
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadRedStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `}
                        >
                          Rate
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={`${TabletheadRedLastStyle(blackWhite)}  ${
                            mobilePrint && "pb-5"
                          } `}
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      className="align-top font-bold text-center bg-bottom bg-no-repeat bg-[length:80%_90%]"
                      // style={{
                      //   backgroundImage: "url(" + logo_oposity + ")",
                      // }}
                    >
                      <tr className="mt-6  border-neutral-900">
                        <td
                          className={` ${
                            blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-24`}
                        >
                          <h1 className="mb-4">
                            {location?.data?.data?.loadingDate
                              ? moment(
                                  location?.data?.data?.loadingDate
                                ).format("DD-MM-YYYY")
                              : " "}
                          </h1>
                        </td>
                        <td
                          className={` ${
                            blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-20`}
                        >
                          <h1 className="mb-4">
                            {location?.data?.data?.lrNo
                              ? location?.data?.data?.lrNo
                              : "--"}
                          </h1>
                        </td>
                        <td
                          className={` ${
                            blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-24`}
                        >
                          <h1 className="mb-4">
                            {location?.data?.data?.vehicleNo}
                          </h1>
                        </td>
                        <td
                          className={` ${
                            blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28`}
                        >
                          <h1 className="mb-4">{location?.data?.data?.from}</h1>
                        </td>
                        <td
                          className={` ${
                            blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28`}
                        >
                          <h1 className="ml-2 mb-4 text-center">
                            {location?.data?.data?.to}
                          </h1>
                        </td>
                        <td
                          className={` ${
                            blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-24`}
                        >
                          <h1 className="mb-4 text-center">
                            {location?.data?.data?.actualWeight
                              ? location?.data?.data?.weightType !== "NA" &&
                                location?.data?.data?.weightType
                              : weight
                              ? weight
                              : "--"}
                          </h1>
                        </td>
                        {/* <td className="border-l border-neutral-900 w-24">
                      <h1 className="mt-2 text-center">
                        {location?.data?.data?.chargedWeight}
                      </h1>
                    </td> */}
                        <td
                          className={` ${
                            blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-16`}
                        >
                          <h1 className="mb-4 text-center">{Rate}</h1>
                        </td>
                        <td className="">
                          <h1 className="mb-4 text-center">
                            {invoice?.amount}
                          </h1>
                        </td>
                      </tr>
                      <tr style={{ height: "120px", maxHeight: "120px" }}></tr>
                      <tr
                        className={`border-t-2  ${borderToggle(
                          blackWhite
                        )} min-w-full`}
                      >
                        {" "}
                        <td
                          className={`border-t-2  ${borderToggle(blackWhite)}`}
                          colSpan="10"
                        ></td>
                      </tr>
                    </tbody>
                  </table>

                  {/* Bannk Detail Start */}
                  <div className="flex flex-row justify-between font-sans font-semibold text-left text-xs">
                    <div className=" ">
                      <div className="flex flex-row space-x-2 ">
                        <div
                          className={`px-1 leading-snug	text-center py-2  mb-2 text-md tracking-wide  rounded-lg uppercase ${Tablebg(
                            blackWhite
                          )} ${mobilePrint ? "pt-1 pb-4" : "py-2"}`}
                        >
                          {" "}
                          <p className="tracking-wide text-sm">
                            {OURBANKDETAILS}
                          </p>
                          <p style={{ fontSize: "11px" }}>
                            ( {SCANQRCONTENT} )
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-row space-x-1 ">
                          {/* QR code */}
                          <div className="mt-1">
                            <img
                              width={80}
                              src={qrcode}
                              alt="Kirti Roadlines"
                            />
                          </div>
                          <div className=" mt-2">
                            <h5 style={{ fontSize: "10px" }}>Bank Name</h5>
                            <h5 style={{ fontSize: "10px" }}>Branch</h5>
                            <h5 style={{ fontSize: "10px" }}>A/c. No</h5>
                            <h5 style={{ fontSize: "10px" }}>IFSC Code</h5>
                          </div>
                          {Dropdown === false ? (
                            <div className="mt-2">
                              <h5 style={{ fontSize: "10px" }}>
                                : State Bank of India
                              </h5>
                              <h5 style={{ fontSize: "10px" }}>
                                : Prahlad Nagar Ahmedabad
                              </h5>
                              <h5 style={{ fontSize: "10px" }}>
                                : 005167983000351
                              </h5>
                              <h5 style={{ fontSize: "10px" }}>
                                : SBIT0CKMNSB (FIFTH CHARACTER IS ZERO)
                              </h5>
                            </div>
                          ) : (
                            <div className="mt-2">
                              <h5 style={{ fontSize: "10px" }}>
                                : {bankBranch.bank}
                              </h5>
                              <h5 style={{ fontSize: "10px" }}>
                                : {bankBranch.branch}
                              </h5>
                              <h5 style={{ fontSize: "10px" }}>
                                : {bankBranch.accNo}
                              </h5>
                              <h5 style={{ fontSize: "10px" }}>
                                : {bankBranch.ifsc}
                              </h5>
                            </div>
                          )}
                        </div>
                        {/* Pay by phone */}
                        <p style={{ fontSize: "10px" }}>{WE_USING_PAYPHONE}</p>
                      </div>
                    </div>
                    <div className="flex flex-row  justify-end w-1/3 font-bold">
                      <div className="space-y-1   text-right w-32  font-bold">
                        <h5 className="pr-2 font-bold">TOTAL :</h5>
                        <div className="h-16 font-bold">
                          {location?.data?.data?.hamali > 0 && (
                            <h5 className=" pr-2 font-bold">Hamali :</h5>
                          )}
                          {location?.data?.data?.detention > 0 && (
                            <h5 className="pr-2 font-bold">Detention :</h5>
                          )}
                          {location?.data?.data?.extraCharge > 0 && (
                            <h5 className=" pr-2 font-bold">Extra Charge :</h5>
                          )}
                        </div>
                        <h5
                          className={`pr-2  px-2  tracking-wide text-md whitespace-nowrap rounded-l-lg  w-full uppercase font-bold ${Tablebg(
                            blackWhite
                          )} ${mobilePrint ? "pt-1 pb-4" : "py-2"}`}
                        >
                          {" "}
                          Grand Total
                        </h5>
                      </div>
                      <div className="space-y-1 text-right w-28 font-bold">
                        <h5>&#8377; {invoice?.total}</h5>
                        <div className="h-16">
                          {BookingData?.hamali > 0 && (
                            <h5>&#8377; {BookingData?.hamali}</h5>
                          )}
                          {BookingData?.detention > 0 && (
                            <h5>&#8377; {BookingData?.detention}</h5>
                          )}
                          {BookingData?.extraCharge > 0 && (
                            <h5> &#8377; {BookingData?.extraCharge}</h5>
                          )}
                        </div>
                        <h5
                          className={`border-l-2 text-md whitespace-nowrap rounded-r-lg pr-2 px-5  tracking-wide b-r-2 w-full uppercase font-bold ${Tablebg(
                            blackWhite
                          )} ${mobilePrint ? "pt-1 pb-4" : "py-2"}`}
                        >
                          {" "}
                          &#8377; {invoice?.grandTotal}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {/* Bannk Detail End */}

                  {/* Extra Information */}
                  <div
                    className={`flex flex-col justify-start items-start font-sans  border-t-2 mt-2 ${borderToggle(
                      blackWhite
                    )} `}
                  >
                    {" "}
                    <p>Amount Chargeable (in words)</p>
                    <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left pb-2">
                      {/* Four Thousand Four Hundred Fifty */}
                      {invoice?.grandTotalInwords} ONLY
                    </p>
                  </div>
                  {/* terms and condition */}
                  <div className="flex flex-row justify-between font-sans text-xs">
                    <div className="text-left flex flex-col">
                      <p className="border-black border-b-2 w-40 pb-2">
                        Terms & Conditions
                      </p>
                    </div>
                    <div className="text-left text-xs">E. & O.E.</div>
                    <div className="text-left text-xs ">FOR,</div>
                    <div className="text-lg font-bold text-right ">
                      {KIRTIROADLINES}
                    </div>
                  </div>
                  {/* second line */}
                  <div className="flex  flex-row justify-between items-start font-sans">
                    <div className=" text-left flex flex-col font-sans text-xs">
                      <p>{InvoicSubject} </p>
                    </div>
                    <div className=" text-right  font-bold flex justify-end mt-2">
                      <img src={sign} alt="signature" width={100} />
                    </div>
                  </div>
                  {/* last line */}
                  <div className="flex mt-2 border-black pb-2 border-b-2 flex-row justify-between items-start  font-sans text-xs">
                    <div className="text-left flex flex-col ">
                      <p>
                        {PaymentbypayeeCchequeDrafs} {KIRTIROADLINES}
                      </p>
                    </div>
                    <div className="text-right  font-bold ">
                      <h5>{AuthorizedSignatory}</h5>
                    </div>
                  </div>

                  {/* printing */}
                  <h2
                    className={`${
                      mobilePrint
                        ? " text-center text-xs p-2 -mt-2 "
                        : `text-xs mt-0 mb-0 text-center font-bold rounded-md p-2 font-sans ${Tablebg(
                            blackWhite
                          )} `
                    }`}
                  >
                    {" "}
                    {computerGeneratedInvoice}
                  </h2>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* printing */}
      <div className="mb-5 flex flex-row justify-center space-x-5 mt-10">
        <ButtonWithBG
          onClick={() => {
            updateInvoiceNo();
            handleDownloadPdf("download");
          }}
          label="Download Pdf"
        />
        <ButtonWithBG
          onClick={() => {
            updateInvoiceNo();
            handleDownloadPdf("print");
          }}
          label="Print"
        />
      </div>
    </>
  );
};

export default BillingInvoiceView;

// pdfDocGenerator.getBlob((dataBlob) => {
//   const url = URL.createObjectURL(dataBlob);

//   const a = document.createElement("a");
//   a.href = url;
//   a.download = fileName;
//   document.body.appendChild(a);
//   a.click();

//   document.body.removeChild(a);
//   URL.revokeObjectURL(url);

//   // history.push("/bilty/billty-invoice-view");
//   setIsLoading(false);
// });

// if(pdfDocDefinition){
//   const pdfDocGenerator = pdfMake.createPdf(pdfDocDefinition);
//   pdfDocGenerator.getBlob((dataBlob) => {
//     const url = URL.createObjectURL(dataBlob);

//     // Open the PDF in a new tab
//     window.open(url, "_blank");

//     URL.revokeObjectURL(url);
//   });
// }

//   const generatePDFAndDisplay = async () => {
//     try {
//       // Get the entire content from the useReactToPrint reference
//       const content = componentRef.current;
//       // console.log(content, "click");

//       // Generate PDF us  ing html2pdf
//       const pdfOptions = {
//         margin: 0,
//         filename:
//           "Invoice of " +
//           location?.data?.data?.consignerName.replace(/\./g, " ") +
//           location?.data?.data?.bookingInvoiceNo,
//         image: { type: "jpeg", quality: 0.98 },
//         html2canvas: { scale: 2 },
//         jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
//       };
//       html2pdf().set(pdfOptions).from(content).save();

//       // const pdfBlob = await html2pdf()
//       //   .set(pdfOptions)
//       //   .from(content)
//       //   .outputPdf("blob");

//       // // Convert PDF to data URI (Blob URL)
//       // const pdfBlobUrl = URL.createObjectURL(pdfBlob);

//       // // Display the generated PDF in a new window
//       // window.open(pdfBlobUrl, "_blank");
//     } catch (error) {
//       // Handle errors appropriately
//     }
//   };
/* <ReactToPrint
          pageStyle={pageStyle}
          documentTitle={
            "Invoice of " +
            location?.data?.data?.consignerName +
            location?.data?.data?.bookingInvoiceNo
          }
          trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
          content={() => componentRef.current}
        /> */
