import React from "react";
import PayTo from "../../Components/Functionality/PayTo";
import OtherPartyName from "../../Components/Functionality/OtherPartyName";
import ThirdPartyName from "../../Components/Functionality/ThirdPartyName";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

const WithoutLrNo = ({ addFormValues, setAddFormValues, values_handler }) => {
  return (
    <div className="flex md:flex-row flex-wrap flex-col gap-2">
      {/* Pay To */}
      <div className="flex md:w-[32%]  flex-col  text-left">
        <Label label="Invoice Pay To." isRequired="true" />
        <PayTo
          defaultValue={addFormValues?.paidBy}
          name={"paidBy"}
          handleOnChange={(e) =>
            setAddFormValues({
              ...addFormValues,
              paidBy: e.value,
            })
          }
        />
      </div>
      {/* Consignor Amount */}
      {addFormValues?.paidBy === "Both" && (
        <div className="flex md:w-[32%]    flex-col  text-left">
          <Label label="Consignor amount" isRequired="true" />
          <Input
          defaultValue={addFormValues?.consignerAmount}
            name={"consignerAmount"}
            type="text"
            onChange={values_handler}
            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
          />
        </div>
      )}
      {/* Consignee Amount */}
      {addFormValues?.paidBy === "Both" && (
        <div className="flex md:w-[32%]    flex-col  text-left">
          <Label label="Consignee amount" isRequired="true" />
          <Input
            defaultValue={addFormValues?.consigneeAmount}
            name={"consigneeAmount"}
            type="text"
            onChange={values_handler}
            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
          />
        </div>
      )}
      {/* Other Party Name */}
      {addFormValues?.paidBy === "OtherParty" && (
        <div className="flex md:w-[32%]  flex-col  text-left">
          <Label label="Other party name" isRequired="true" />
          <OtherPartyName
            defaultValue={addFormValues?.otherPartyName}
            name={"otherPartyName"}
            type="text"
            handleOnChange={(e) =>
              setAddFormValues({
                ...addFormValues,
                otherPartyName: e.label,
                otherPartyGst: e.value,
                otherPartyAddress: e.add,
              })
            }
          />
        </div>
      )}
      {/* Other Party GST */}
      {addFormValues?.paidBy === "OtherParty" && (
        <div className="flex md:w-[32%]   flex-col  text-left">
          <Label label="Other party gst" isRequired="true" />
          <Input
            value={addFormValues?.otherPartyGst}
            name={"otherPartyGst"}
            type="text"
            onChange={values_handler}
            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
          />
        </div>
      )}
      {/* Other Party Address */}
      {addFormValues?.paidBy === "OtherParty" && (
        <div className="flex md:w-[32%]   flex-col  text-left">
          <Label label="Other party address" isRequired="true" />
          <Input
            value={addFormValues?.otherPartyAddress}
            name={"otherPartyAddress"}
            type="text"
            onChange={values_handler}
            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
          />
        </div>
      )}
      {/* Is Third Party Available Checkbox */}
      {(addFormValues?.paidBy === "Consigner" ||
        addFormValues?.paidBy === "Consignee") && (
        <div className="flex md:w-[32%]  flex-col  text-left">
          <Label label="Is third party available" isRequired="true" />
          <Input
            checked={addFormValues?.isThirdPartyPay}
            type="checkbox"
            name={"isThirdPartyPay"}
            onChange={(e) =>
              setAddFormValues({
                ...addFormValues,
                isThirdPartyPay: e.target.checked,
              })
            }
            className="accent-red-600 text-white"
          />
        </div>
      )}
      {/* Party Name */}
      {addFormValues?.isThirdPartyPay && (
        <div className="flex md:w-[32%]   flex-col  text-left">
          <Label label="Party name" isRequired="true" />
          <ThirdPartyName
          defaultValue={addFormValues?.thirdPartyName}
            name={"thirdPartyName"}
            handleOnChange={(e) =>
              setAddFormValues({
                ...addFormValues,
                thirdPartyName: e.label,
              })
            }
          />
        </div>
      )}
      {/* Third Party Commission */}
      {addFormValues?.isThirdPartyPay && (
        <div className="flex md:w-[32%]  flex-col  text-left">
          <Label label="Third party commission" isRequired="true" />
          <Input
          defaultValue={addFormValues?.thirdPartyCommission}
            name={"thirdPartyCommission"}
            type="text"
            onChange={values_handler}
            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
          />
        </div>
      )}
      {/* Third Party Comment */}
      {addFormValues.isThirdPartyPay && (
        <div className="flex md:w-[32%]  flex-col  text-left">
          <Label label="Third party comment" isRequired="true" />
          <Input
                    defaultValue={addFormValues?.thirdPartyComment}

            name={"thirdPartyComment"}
            type="text"
            onChange={values_handler}
            className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
          />
        </div>
      )}
      {/* From */}
      <div className="flex md:w-[32%] flex-col  text-left">
        <Label label="From" isRequired="true" />
        <Input
          defaultValue={addFormValues?.from}
          name={"from"}
          type="text"
          onChange={values_handler}
          className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
        />
      </div>
      {/* To */}
      <div className="flex md:w-[32%]   flex-col  text-left">
        <Label label="To" isRequired="true" />
        <Input
          defaultValue={addFormValues?.to}
          name={"to"}
          type="text"
          onChange={values_handler}
          className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
        />
      </div>
      {/* Booking Amount */}
      <div className=" flex md:w-[32%]   flex-col  text-left">
        {" "}
        <Label label="Booking amount" isRequired="true" />
        <Input
          defaultValue={addFormValues?.bookingAmount}
          name={"bookingAmount"}
          type="text"
          onChange={values_handler}
          className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
          step="any"
        />
      </div>
      {/* Company Invoice Number */}
      <div className=" flex md:w-[32%]   flex-col  text-left">
        {" "}
        <Label label="company Invoice No." isRequired="true" />
        <Input
          defaultValue={addFormValues?.companyInvoiceNo}
          name={"companyInvoiceNo"}
          type="text"
          onChange={values_handler}
          className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm"
        />
      </div>
    </div>
  );
};

export default WithoutLrNo;
