import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";
import ButtonWithBG from "../../../Components/UI/ButtonMain";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "../../../Utils/PTSerif-Regular";
import { robotoBlack } from "../../../Utils/Roboto-Black";
import { PTSerifBold } from "../../../Utils/PTSerif-Bold";
import { background } from "../../../Layouts/pdfLayout/BackgroundLayout";
import { WE_USING_PAYPHONE } from "../../../Utils/Constants";
import { NotoSansDevanagariBold } from "../../../Utils/NotoSansDevanagari-Bold";
import { NotoSansDevanagariRegular } from "../../../Utils/NotoSansDevanagari-Regular";
import { BillingHeader } from "../../../Layouts/pdfLayout/BillingHeaderLayout";
import { BillingFooter } from "../../../Layouts/pdfLayout/BillingFooterLayout";
import { LedgerTotal } from "../../../Layouts/pdfLayout/LedgerContentLayout";
import { LedgerContentLayout } from "../../../Layouts/pdfLayout/LedgerContentLayout";
const pdfMakeX = require("pdfmake/build/pdfmake.js");
const pdfFontsX = require("pdfmake-unicode/dist/pdfmake-unicode.js");
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
//importing the fonts whichever present inside vfs_fonts file
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["PTSerif-Regular.ttf"] = font;
window.pdfMake.vfs["PTSerif-Bold.ttf"] = PTSerifBold;
window.pdfMake.vfs["Roboto-Black.ttf"] = robotoBlack;
window.pdfMake.vfs["NotoSansDevanagari-Bold.ttf"] = NotoSansDevanagariBold;
window.pdfMake.vfs["NotoSansDevanagari-Regular.ttf"] =
  NotoSansDevanagariRegular;

const LedgerDownload = (props) => {
  // const location = useLocation();

  useEffect(() => {
    // console.log(props, "print");
  }, [props]);

  // Download pdf
  const sanitizeFilename = (filename) => {
    // Replace special characters with underscores
    let checkNull = filename.includes("null");
    if (checkNull) {
      return filename.replace(/[^\w\s]/gi, "_")?.replace("null", "");
    } else if (!checkNull) {
      return filename.replace(/[^\w\s]/gi, "_");
    }
  };
  async function handleDownloadPdf(action) {
    props.setIsLoading(true);

    //Specifying the fonts which we are going to use in our PDF
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Black.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      PTSerifRegular: {
        normal: "PTSerif-Regular.ttf",
        bold: "PTSerif-Bold.ttf",
        italics: "PTSerif-Regular.ttf",
        bolditalics: "PTSerif-Regular.ttf",
      },
      NotoSansDevanagari: {
        normal: "NotoSansDevanagari-Regular.ttf",
        bold: "NotoSansDevanagari-Bold.ttf",
        italics: "NotoSansDevanagari-Regular.ttf",
        bolditalics: "NotoSansDevanagari-Regular.ttf",
      },
    };
    const invoicedata = props.commissionData;
    const profileData = props.profileData;
    const secondaryPhoneNo = props.secondaryPhoneNo;

    const contentRender = async () => {
      try {
        const BillingHeaderContent = await BillingHeader(
          profileData,
          secondaryPhoneNo,
          "billing",
          !props.noHeader
        );

        const BillingContent = await LedgerContentLayout(
          invoicedata,
          WE_USING_PAYPHONE,
          profileData
        );

        const BillingTotalContent = await LedgerTotal(invoicedata);

        const BillingFooterContent = await BillingFooter(
          "billing",
          false,
          false
        );

        return [
          BillingHeaderContent,
          BillingContent,
          {
            stack: [BillingTotalContent, BillingFooterContent],
            unbreakable: true,
          },
          props.setIsLoading(false),
        ];
      } catch (error) {
        // Handle errors if necessary
        console.error("Error in contentRender:", error);
        props.setIsLoading(false);

        throw error; // Re-throw the error to propagate it
      }
    };

    const sanitizedFilename = sanitizeFilename(
      (props?.commissionData?.invoiceNo ||
      props?.commissionData?.bookingInvoiceNo
        ? props?.commissionData?.invoiceNo
          ? props?.commissionData?.invoiceNo
          : props?.commissionData?.bookingInvoiceNo
          ? props?.commissionData?.bookingInvoiceNo
          : "--"
        : props.invoiceNo
        ? props.invoiceNo
        : "--")
  );
  const fileName = `${sanitizedFilename}.pdf`;

    const pdfDocDefinition = {
      pageMargins: [35, 35, 35, 10],
      content: await contentRender().then((result) => result),
      info:{
        title:fileName
      },
      styles: {
        table: {
          margin: [0, 0, 0, 0], // Adjust the top margin of the entire table
        },
        tableHeader: {
          fontSize: 9,
          alignment: "start", // 'start' aligns text to the start of the cell
          bold: true,
          fillColor: "#fff",
          color: "#000",
          margin: [1, 0, 0, 0], // Adjust the margin for each header cell
          borderBottom: "1px solid #28328c",
        },
        cellStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
        },
      },
      background: props.showBorder ? background : () => {},
    };


    const callbackFunction = function () {
      // Here implement function for hide waiting loader
      props.setIsLoading(false);
    };
    const handlePrint = () => {
      pdfDocGenerator.print();
      props.setIsLoading(false);
    };
    const newLocal = pdfMake.createPdf(pdfDocDefinition);
    const pdfDocGenerator = newLocal;

    // pdfDocGenerator.open()
    action === "download"
    ? pdfDocGenerator.download(fileName, callbackFunction)
    : handlePrint(); 
  }
  return (
    <div className="mb-3 flex gap-3 justify-center">
      <ButtonWithBG onClick={()=>handleDownloadPdf('download')} label="Download" />
      <ButtonWithBG onClick={()=>handleDownloadPdf('print')} label="Print" />
    </div>
  );
};

export default LedgerDownload;

// pdfDocGenerator.getBlob((dataBlob) => {
//   const url = URL.createObjectURL(dataBlob);

//   const a = document.createElement("a");
//   a.href = url;
//   a.download = fileName;
//   document.body.appendChild(a);
//   a.click();

//   document.body.removeChild(a);
//   URL.revokeObjectURL(url);

//   // history.push("/bilty/billty-invoice-view");
//   //
// });

// const pdfDocGenerator = pdfMake.createPdf(pdfDocDefinition);

// pdfDocGenerator.getBlob((dataBlob) => {
//   const url = URL.createObjectURL(dataBlob);

//   // Open the PDF in a new tab
//   window.open(url, "_blank");

//   URL.revokeObjectURL(url);
// });
