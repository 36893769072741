import React, { useEffect } from "react";
import Swal from "sweetalert2";

const APILoader = ({ loader }) => {
  const Loader = Swal.mixin({
    position: "center",
    imageUrl: require("../../Images/Loader_red_Gif.gif"),
    showCancelButton: false,
    showConfirmButton: false,
    imageWidth: 500,
    imageHeight: 350,
    customClass: {
      loader: "custom-loader",
      popup:"bg-swal"
    },
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
  });

  useEffect(() => {
    if (loader.loading) {
      Loader.fire({
        text: loader.message && loader.message.length > 0 ? loader.message : "",
        // didOpen: (toast) => Loader.showLoading(),
      });
    } else {
      if (!loader.loading && Loader.isVisible() && Loader.isLoading()) {
        Loader.close();
      }
      if (!loader.loading) {
        Loader.close();
      }

      if (!loader.loading) {
        let message = loader.message;

        if (
          loader.icon === "error" &&
          (message === undefined || message.length === 0)
        ) {
          message = "Error while setting up request...";
        }

        // only fires if message greater than 0, and loading false
        if (message.length > 0)
          // Swal.fire({ icon: loader.icon.length > 0 ? loader.icon : 'info', text: message, timer: 2000 })
          Swal.fire({
            icon: loader.icon.length > 0 ? loader.icon : "info",
            text: message,
            allowOutsideClick: false,
          });
      }
    }
    // eslint-disable-next-line
  }, [loader.loading]);

  return <></>;
};

export default APILoader;
