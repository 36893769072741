export async function getBase64ImageFromURL(url) {
    console.log(url);
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        console.error("Error loading image:", url, error);
        reject(error);
      };
      img.src = url;
    });
  }

  export const arrStringSorting  =  (data) => {
    const tempData = data?.split(','); // Create a temporary copy of the array
    tempData.sort((a, b) => a - b); // Sort numerically
    return tempData
  }

  export const findLrNo =  (subData, required = 'companyInvoiceNo', bookingData) => {
    const lrDetails = bookingData?.lrDetails
    const lr = lrDetails.filter((data)=> data.lrNo === subData.lrNo)
    return lr[0][required]
  }

  export const sortingArray = (data, key) => {
    // Handle potential errors
    if (!Array.isArray(data)) {
      throw new Error('sortingArray: data must be an array');
    }
    if (typeof key !== 'string') {
      throw new Error('sortingArray: key must be a string');
    }
  
    // Use JavaScript's built-in sort function for correct sorting
    const sortedData = data.sort((a, b) => {
      // Ensure both values exist before comparison
      if (a[key] === undefined || b[key] === undefined) {
        return 0;  // Treat missing values as equal
      }
  
      // Convert to numbers for comparison
      const numA = +a[key];
      const numB = +b[key];
  
      // Handle potential NaN values
      if (isNaN(numA) || isNaN(numB)) {
        return 0;  // Treat invalid values as equal
      }
  
      // Perform numeric sorting
      return numA - numB; 
    });
  
    return sortedData;
  }

  export const  hasNumber = (text) => {
    const hasNumbers = /\d/.test(text);
    const hasLetters = /[a-zA-Z]/.test(text);
    return hasNumbers && hasLetters ? true : false;
  }