import React from "react";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function VehicleOwnerImage() {
    const location = useLocation();
    const history = useHistory();

    return (
        <>
            <div className="h-96 flex flex-col justify-center items-center pt-24">
                {/* <button onClick={()=>{console.log(location)}}>Click</button> */}
               <img className="mb-5" src={location.state.image} alt="Vehicle-Owner_Image"/>
               <ButtonWithBG handleClick={()=>{
                    history.push ('/vehicle');
               }}>Go To Vehicle table</ButtonWithBG>

            </div>
        </>
    )

}