import React, { useEffect, useState } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import { useLocation } from "react-router-dom";
import KirtiLedgerTable from "../../Components/DataTables/KirtiLedgerTable";
import AddEditLedger from "./AddEditLedger";
import Select from "react-select";
import { LedgerTypeOptions } from "../../Utils/Constants";
import axiosInstance from "../../Utils/API";
import { GET_BANK_LIST, GET_KIRTIRL_LEDGER } from "../../Utils/ApiGlobal";
import Loader from "../../Components/UI/Loader";
import DatePicker from "react-datepicker";
import moment from "moment";

const Label = (props) => (
  <p className="flex flex-row text-gray-700 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
export default function KirtiRoadLineLedger() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [ledgerType, setLedgerType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerData, SetLedgerData] = useState([]);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalCredit, setCredit] = useState(0);
  const [totalDebit, setDebit] = useState(0);
  const [error] = useState(false);
  const [optionsBank, setOptions] = useState({});
  const [bankBranch, setBankBranch] = useState("");
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
  };
  const dependency = {
    ledgerType,
    bankBranch
   }

  const location = useLocation();
  useEffect(() => {
    getBankDetails(); // eslint-disable-next-line
    getVehicleOwnerLedger(ledgerType, bankBranch); // eslint-disable-next-line
  }, [ledgerType, startDate, endDate, bankBranch]);

  const getVehicleOwnerLedger = async (type, bankBranch) => {
    setIsLoading(true);
    let StartDate =
      startDate === undefined ? "" : moment(startDate).format("YYYY-MM-DD");
    let EndDate =
      endDate === undefined ? "" : moment(endDate).format("YYYY-MM-DD");
    if (bankBranch === "Both") {
      bankBranch = "";
    }
    await axiosInstance
      .get(
        `${GET_KIRTIRL_LEDGER}?Type=${type}&bankBranch=${bankBranch}&startDate=${
          StartDate ? StartDate : "0001-01-01"
        }&endDate=${EndDate ? EndDate : "0001-01-01"}`
      )
      .then((response) => {
        // console.log(response);
        SetLedgerData(response?.data?.data?.ledgerList);
        setTotalPayment(response?.data?.data?.finalTotal);
        setCredit(response?.data?.data?.totalCredit);
        setDebit(response?.data?.data?.totalDebit);
      })
      .catch((error) => {
        console.log(error);
      });
    setIsLoading(false);
  };

  const getBankDetails = async () => {
    setIsLoading(true);
    await axiosInstance.get(`${GET_BANK_LIST}`).then((response) => {
      // console.log(response?.data?.data);
      if (response?.data?.data.length > 0) {
        const options = response?.data?.data.map(
          ({ accountId, bankName, branch, accountNo, ifscCode }, index) => ({
            accountId,
            bankName,
            branch,
            accountNo,
            ifscCode,
          })
        );
        let new_options = [
          ...options,
          {
            accountId: "",
            bankName: "Both",
            branch: "",
            accountNo: "",
            ifscCode: "",
          },
        ];
        setOptions(new_options);
      }
    });
  };
  const [pageTitle] = useState(`Kirti Roadline Ledger`);
  return (
    <DashBoardLayout pageTitle={pageTitle}>
      <div className="flex flex-col md:flex-row justify-between ">
        <div className="flex md:flex-row flex-col  md:space-x-2">
          <div className=" flex flex-col w-1/2  text-left ">
            <Label label="Start Date" />
            <div className="flex flex-row justify-center w-full">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                className="peer	 w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                placeholderText={"Please select start date"}
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>
          <div className=" flex flex-col w-1/2 text-left ">
            <Label label="End Date" />
            <div className="flex flex-row justify-center ">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
          <div className="flex flex-col  text-left w-1/2">
            <Label label="Ledger Type" />
            <Select
              options={LedgerTypeOptions}
              className="z-80"
              name="LedgerType"
              onChange={(e) => {
                if (e.value === "Both") {
                  setLedgerType("");
                } else {
                  setLedgerType(e.value);
                }
              }}
              menuPortalTarget={document.body}
              menuPosition={"fixed"}
              placeholder="Ledger Type"
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                colors: {
                  ...theme.colors,
                  primary25: "#f68e92",
                  primary: "#ed1c24",
                },
              })}
            />
          </div>
          {optionsBank.length > 0 && (
            <div
              style={{ minWidth: "300px", maxWidth: "300px" }}
              className="flex flex-col  text-left w-1/2 z-80"
            >
              <Label label="Bank Account" />
              <Select
                options={optionsBank.map((el) => ({
                  label: el.bankName,
                  value: el.accountNo,
                  branch: el.branch,
                  billAdd: el.billToAddress,
                  ifscCode: el.ifscCode,
                }))}
                onChange={(e) => {
                  setBankBranch(e.label);
                }}
                placeholder="Bank Branch"
                className="z-80 "
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={customStyles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "#f68e92",
                    primary: "#ed1c24",
                  },
                })}
              />
            </div>
          )}
        </div>
        <div className="md:order-last	order-first flex flex-row space-x-2 md:mt-5  items-start">
          {isLoading ? (
            <Loader />
          ) : (
            <AddEditLedger
              getAPI={getVehicleOwnerLedger}
              companyName={location?.data?.data?.name}
              companyNumber={location?.data?.data?.contactNumber}
              component={location?.component}
              dependency= {dependency}
            />
          )}
        </div>
      </div>
      {isLoading || !optionsBank ? (
        <Loader />
      ) : (
        <>
          <KirtiLedgerTable
            totalPayment={totalPayment}
            ledgerData={ledgerData}
            totalCredit={totalCredit}
            totalDebit={totalDebit}
            error={error}
          />
        </>
      )}
    </DashBoardLayout>
  );
}
