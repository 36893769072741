import React, { useEffect } from "react";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import axiosInstance from "../../Utils/API";
import { GET_LCV_DATA } from "../../Utils/ApiGlobal";
import { useState } from "react";
import Loader from "../UI/Loader";
// import moment from "moment";

const columns = [
  {
    Header: "Action",
    id: "Action",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    Cell: (row) => (
      <div>
        <TableAction
          data={row.row.original}
          redirectViewPath="LCV/Transporter-Loading-Slip"
        />
      </div>
    ),
  },
  // {
  //   Header: "Id",
  //   accessor: (d,index) => {
  //     return index +1
  //   },
  //   maxWidth: 100,
  //   minWidth: 100,
  //   width: 100,
  // },
  // {
  //   Header: "Date",
  //   accessor: d => {
  //     return moment(d.createdAt)
  //       .format("DD-MM-YYYY")
  //   },
  //   maxWidth: 100,
  //   minWidth: 100,
  //   width: 100,
  // },
  {
    Header: "Consigner Name",
    accessor: "consignerName",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Vehicle No",
    accessor: "vehicleNo",
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "FROM",
    accessor: "from",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    Header: "To",
    accessor: "to",
    Cell:row => <div className="text-left">{row.value}</div>,
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Goods",
    accessor: "goods",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Guarantee",
    accessor: "guarantee",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Load Settled With",
    accessor: "loadSettledWith",
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    Header: "Rate",
    accessor: "rates",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Freight",
    accessor: "freigth",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Advance",
    accessor: "advance",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    Header: "Balance",
    accessor: "balance",
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
];
export default function LCVTable(props) {
  const [LCVData, setLCVData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(props,"for table")

  // for Search by Date.
  // const filterByDate = ({Date}) => {
  //     return Date.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  //   };
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`${GET_LCV_DATA}?pageNo=1&pageSize=100`)
      .then((response) => {
        const data =response?.data?.data?.lcv
        // console.log(data);
        setLCVData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.searchValue]);

  return (
    <section>
      <div className="mt-4 pt-4">
        {isLoading ? (
          <Loader />
        ) : (
          <TablePagination data={LCVData} columns={columns} />
        )}
      </div>
    </section>
  );
}
