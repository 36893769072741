import moment from "moment";
import { getBase64ImageFromURL } from "../../Components/Functionality/Helper";
import axisBarcode from "../../Images/qrcode.jpg";
import { BillingFooter } from "./BillingFooterLayout";

const billingDetails = async (
  invoice,
  profileData,
  companyName,
  companyAdd
) => {
  const billingDetails = [
    {
      table: {
        widths: ["50%", "50%"],
        headerRows: 1,
        fontSize: 9,
        bold: true,
        heights: [25],
        body: [
          [
            {
              border: [false, false, false, false],
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 100,
                      h: 25,
                      r: 10,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  relativePosition: { x: 21, y: -18 },
                  text: "INVOICE TO",
                  color: "#000",
                  fontSize: 11,
                  bold: true,
                },
              ],
            },
            {
              alignment: "right",
              border: [false, false, false, false],
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 100,
                      h: 25,
                      r: 10,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  alignment: "center",
                  relativePosition: { x: 75, y: -18 },
                  text: "OUR DETAILS",
                  color: "#000",
                  fontSize: 11,
                  bold: true,
                },
              ],
            },
          ],
        ],
      },
    },
    {
      columns: [
        {
          width: 300,
          stack: [
            {
              text: `${companyName.toUpperCase()}`,
              color: "#000",
              fontSize: 11,
              bold: true,
              margin: [0, 5, 0, 0],
            },
            {
              text: `ADDRESS :${companyAdd}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
            },
          ],
        },
        {
          text: "",
          width: "*",
        },
        {
          alignment: "right",
          width: 180,
          stack: [
            {
              text: `SERVICE : ${profileData?.service}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `PAN NO. : ${profileData?.panNo}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `STATE CODE : ${profileData?.stateCode}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              canvas: [
                {
                  type: "line",
                  x1: 200,
                  y1: 5,
                  x2: 20,
                  y2: 5,
                  lineWidth: 2,
                },
              ],
              margin: [0, 0, 0, 2],
            },
            {
              text: `INVOICE NO : ${
                invoice.invoiceNo || invoice?.bookingInvoiceNo
                  ? invoice?.invoiceNo
                    ? invoice?.invoiceNo
                    : invoice?.bookingInvoiceNo
                    ? invoice?.bookingInvoiceNo
                    : "--"
                  : invoice?.invoiceNo
                  ? invoice?.invoiceNo
                  : "--"
              }`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `DATE : ${moment().format("DD-MM-YYYY")}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
          ],
        },
      ],
    },
  ];
  return billingDetails;
};

const billingTable = async (invoice) => {
  function getColumns(invoices) {
    const hasHamali = invoices.some(
      (invoice) =>
        invoice.transporterHamali !== 0 && invoice.transporterHamali !== null
    );

    const hasDetention = invoices.some(
      (invoice) =>
        invoice.transporterDetention !== 0 &&
        invoice.transporterDetention !== null
    );

    const hasExtraCharge = invoices.some(
      (invoice) =>
        invoice.transporterExtraCharge !== 0 &&
        invoice.transporterExtraCharge !== null
    );

    const isExtraField = hasDetention && hasHamali && hasExtraCharge;

    const manipulateWidths = isExtraField ? "12%" : "*";

    let widths = [
      isExtraField ? "auto" : "*",
      isExtraField ? "auto" : "*",
      manipulateWidths,
      manipulateWidths,
      manipulateWidths,
      manipulateWidths,
    ];

    const columns = [
      {
        text: "LOADING DATE",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [false, true, true, true],
      },
      {
        text: "FROM",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "TO",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "FREIGHT",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "ADVANCE",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "BALANCE",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
    ];

    let lastColumnIndex = columns.length - 1; // Initially,  the last column is the final static one

    // Check for hamali column
    if (
      invoices.some(
        (invoice) =>
          invoice.transporterHamali !== 0 && invoice.transporterHamali !== null
      )
    ) {
      columns.push({
        text: "HAMALI",
        bold: true,
        fontSize: 9,
        alignment: "center",
      });
      widths.push("auto");
      lastColumnIndex++; // Update the last column index
    }

    if (
      invoices.some(
        (invoice) =>
          invoice.transporterDetention !== 0 &&
          invoice.transporterDetention !== null
      )
    ) {
      columns.push({
        text: "DETENTION",
        bold: true,
        fontSize: 9,
        alignment: "center",
      });
      widths.push("auto");
      lastColumnIndex++; // Update the last column index
    }
    if (
      invoices.some(
        (invoice) =>
          invoice.transporterExtraCharge !== 0 &&
          invoice.transporterExtraCharge !== null
      )
    ) {
      columns.push({
        text: "EXTRA CHARGE",
        bold: true,
        fontSize: 9,
        alignment: "center",
      });
      widths.push("auto");
      lastColumnIndex++; // Update the last column index
    }
    columns[lastColumnIndex].border = [true, true, false, true];

    return { columns, widths };
  }
  function getTableBody(invoices, columns) {
    const body = [];
    invoices.forEach((data) => {
      const row = [
        {
          text: `${moment(data?.loadingDate).format("DD-MM-YYYY")}`,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [false, false, true, false],
        },
        {
          text: data?.from?.toUpperCase(),
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.to?.toUpperCase(),
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.transporterFreigth ? data?.transporterFreigth : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.transporterAdvance ? data?.transporterAdvance : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.transporterBalance ? data?.transporterBalance : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
      ];

      if (columns.some((col) => col.text === "HAMALI")) {
        row.push({
          text: data?.transporterHamali ?? 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        });
      }
      if (columns.some((col) => col.text === "DETENTION")) {
        row.push({
          text: data?.transporterDetention ?? 0,
          bold: true,
          fontSize: 9,
          alignment: "center",
          border: [true, false, true, false],
        });
      }
      if (columns.some((col) => col.text === "EXTRA CHARGE")) {
        row.push({
          text: data?.transporterExtraCharge ?? 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        });
      }

      const lastColumnIndex = row.length - 1; // Get index of last column
      row[lastColumnIndex].border = [true, false, false, false];

      body.push(row);
    });
    const numEmptyRowsNeeded = Math.max(0, 6 - body.length);
    for (let i = 0; i < numEmptyRowsNeeded; i++) {
      const emptyRow = [];
      columns.forEach((column, index) => {
        emptyRow.push({
          border: [index !== 0 ? false : false, false, false, false],
          text: "",
          fontSize: 9,
          alignment: "center",
        }); // Adjust styling as needed
      });
      body.push(emptyRow);
    }
    return body;
  }

  const { columns, widths } = getColumns(invoice?.commission);
  const body = getTableBody(invoice?.commission, columns);
  // console.log(columns);
  // console.log(body);

  const table = {
    heights: 23,
    headerRows: 1,
    widths: widths,
    bold: true,
    margin: [0, 10, 10, 10],
    body: [columns, ...body],
  };

  const content = [
    // Other content
    {
      table,
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 ? 1 : 0.5; // Set to 1 for the bottom border of each cell in the first row, 0.5 for other rows
        },
        hLineColor: function (i) {
          return i === 0 ? "#000" : "#000"; // Set color for the bottom border of each cell in the first row
        },
        paddingTop: function () {
          return 4; // Adjust padding as needed
        },
        paddingBottom: function () {
          return 4; // Adjust padding as needed
        },
        vLineWidth: function (i) {
          return 1; // Set to 0 for no vertical borders
        },
        vLineColor: function () {
          return "#000"; // Set to white or your background color
        },
      },
    },
    // Other content
  ];

  return content;
};

const billingPayment = async (WE_USING_PAYPHONE, bankBranch, locate) => {
  const Images = {
    axisbarcode: await getBase64ImageFromURL(axisBarcode),
  };
  const billingDetails = [
    {
      stack: [
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 0,
              w: 520, // width of the rectangle
              h: 0.8, // height of the rectangle
              color: "black",
            },
          ],
        },
        {
          columns: [
            {
              width: 300,
              stack: [
                {
                  margin: [0, 7, 0, 0],
                  border: [false, true, false, false],
                  stack: [
                    {
                      canvas: [
                        {
                          type: "rect",
                          x: 0,
                          y: 0,
                          w: 250,
                          h: 30,
                          r: 10,
                          lineColor: "black",
                        },
                      ],
                    },
                    {
                      absolutePosition: { x: 76, y: 10 },
                      text: "OUR BANK DETAILS",
                      color: "#000",
                      fontSize: 11,
                      bold: true,
                    },
                    {
                      absolutePosition: { x: 14, y: 25 },
                      text: "(YOU MAY ALSO PAY USING ANY UPI APP BY SCANNING A BARCODE)",
                      color: "#000",
                      fontSize: 7,
                    },
                  ],
                },
                {
                  columns: [
                    {
                      image: Images.axisbarcode,
                      width: 60,
                      height: 60,
                      margin: [0, 5, 2, 0],
                    },
                    {
                      margin: [2, 2, 0, 0],
                      stack: [
                        {
                          margin: [2, 5, 0, 0],
                          text: `BANK NAME: ${bankBranch?.bank}`,
                          fontSize: 8,
                        },
                        {
                          margin: [2, 5, 0, 0],
                          text: `BRANCH: ${bankBranch?.branch}`,
                          fontSize: 8,
                        },
                        {
                          margin: [2, 5, 0, 0],
                          text: `A/C. NO: ${bankBranch?.accNo}`,
                          fontSize: 8,
                        },
                        {
                          margin: [2, 5, 0, 0],
                          text: `IFSC CODE: ${bankBranch?.ifsc}`,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              table: {
                margin: [0, 7, 0, 0],
                widths: ["100%"],
                headerRows: 1,
                fontSize: 9,
                bold: true,
                heights: [30],
                body: [
                  [
                    {
                      border: [false, false, false, false],
                      margin: [0, 0, 0, 0],
                      columns: [
                        {
                          stack: [
                            {
                              text: `BALANCE :`,
                              color: "#000",
                              bold: true,
                              fontSize: 9,
                              margin: [0, 5, 0, 0],
                              alignment: "right",
                            },
                            locate?.totalHamali !== 0
                              ? {
                                  text: `HAMALI :`,
                                  color: "#000",
                                  bold: true,
                                  fontSize: 9,
                                  margin: [0, 2, 0, 0],
                                  alignment: "right",
                                }
                              : {},
                            locate?.totalDetention !== 0
                              ? {
                                  text: `DETENTION :`,
                                  color: "#000",
                                  bold: true,
                                  fontSize: 9,
                                  margin: [0, 2, 0, 0],
                                  alignment: "right",
                                }
                              : {},
                            locate?.totalExtraCharge !== 0
                              ? {
                                  text: `EXTRA CHARGE :`,
                                  color: "#000",
                                  bold: true,
                                  fontSize: 9,
                                  margin: [0, 2, 0, 0],
                                  alignment: "right",
                                }
                              : {},
                          ],
                        },
                        {
                          stack: [
                            {
                              text: `₹ ${locate?.grandTotal}`,
                              color: "#000",
                              bold: true,
                              fontSize: 9,
                              font: "NotoSansDevanagari",
                              margin: [0, 5, 0, 0],
                              alignment: "right",
                            },
                            locate?.totalHamali !== 0
                              ? {
                                  text: `₹ ${locate?.totalHamali}`,
                                  color: "#000",
                                  bold: true,
                                  fontSize: 9,
                                  margin: [0, 2, 0, 0],
                                  alignment: "right",
                                  font: "NotoSansDevanagari",
                                }
                              : {},
                            locate?.totalDetention !== 0
                              ? {
                                  text: `₹ ${locate?.totalDetention}`,
                                  color: "#000",
                                  bold: true,
                                  fontSize: 9,
                                  margin: [0, 2, 0, 0],
                                  alignment: "right",
                                  font: "NotoSansDevanagari",
                                }
                              : {},
                            locate?.totalExtraCharge !== 0
                              ? {
                                  text: `₹ ${locate?.totalExtraCharge}`,
                                  color: "#000",
                                  bold: true,
                                  fontSize: 9,
                                  margin: [0, 2, 0, 0],
                                  alignment: "right",
                                  font: "NotoSansDevanagari",
                                }
                              : {},
                          ],
                        },
                      ],
                    },
                  ],
                  [
                    {
                      margin: [0, 10, 0, 0],
                      border: [false, false, false, false],
                      columns: [
                        {
                          canvas: [
                            {
                              type: "rect",
                              x: 50,
                              y: 0,
                              w: 165,
                              h: 30,
                              r: 10,
                              lineColor: "black",
                            },
                          ],
                        },
                        {
                          relativePosition: { x: -145, y: 0 },
                          columns: [
                            {
                              margin: [0, 9, 0, 8],
                              alignment: "center",
                              width: 75,
                              text: "GRAND TOTAL",
                              color: "#000",
                              fontSize: 11,
                              bold: true,
                            },
                            {
                              alignment: "center",
                              width: 10,
                              canvas: [
                                {
                                  type: "rect",
                                  x: 0,
                                  y: 0,
                                  w: 2,
                                  h: 30,
                                  color: "black",
                                },
                              ],
                            },
                            {
                              margin: [10, 9, 0, 0],
                              width: "*",
                              text: `₹ ${locate?.grandTotal}`,
                              color: "#000",
                              fontSize: 11,
                              font: "NotoSansDevanagari",
                              bold: true,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          margin: [0, 10, 0, 0],
          stack: [
            {
              text: WE_USING_PAYPHONE.toUpperCase(),
              fontSize: 9,
            },
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 520, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
            {
              characterSpacing: 0.5,
              margin: [0, 7, 0, 0],
              text: "AMOUNT CHARGEABLE (IN WORDS)",
              fontSize: 11,
            },
            {
              characterSpacing: 0.8,
              margin: [0, 6, 0, 0],
              text: `${locate?.grandTotalInWords} ONLY`,
              fontSize: 13,
            },
            {
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 2,
                      w: 520, // width of the rectangle
                      h: 1, // height of the rectangle
                      color: "black",
                    },
                  ],
                },
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 1,
                      w: 520, // width of the rectangle
                      h: 1, // height of the rectangle
                      color: "black",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      unbreakable: true,
    },
  ];
  return billingDetails;
};

export const TransporterBillingContentLayout = async (
  invoice,
  WE_USING_PAYPHONE,
  bankBranch,
  profileData,
  companyName,
  companyAdd,
  locate
) => {
  // console.log(invoice, "invoice");
  // console.log(profileData, "profileData");
  // console.log(bankBranch, "bankBranch");
  // console.log(locate, "locate");
  const BillingInfo = await billingDetails(
    invoice,
    profileData,
    companyName,
    companyAdd
  );

  const BillingTabDATA = await billingTable(
    invoice,
    WE_USING_PAYPHONE,
    bankBranch,
    profileData
  );

  const BillingPayData = await billingPayment(
    WE_USING_PAYPHONE,
    bankBranch,
    locate
  );

  const BillingFooterContent = await BillingFooter("billing");

  return [
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
    BillingInfo,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
    BillingTabDATA,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
    {
      stack: [BillingPayData, BillingFooterContent],
      unbreakable: true,
    },

    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
  ];
};

// const addEmptyRows = (count) => {
//   let rows = [];
//   for (let index = 0; index < count; index++) {
//     let row = [
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//       {
//         text: "",
//         bold: true,
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, false, false],
//       },
//     ];
//     rows.push(row);
//   }

//   return rows;
// };
// const mappingOfData = () => {
//   const rows = invoice.commission.map((data, index) => {
//     return [
//       {
//         text: `${moment(data?.loadingDate).format("DD-MM-YYYY")}`,
//         fontSize: 9,
//         alignment: "center",
//         border: [false, false, true, false],
//       },
//       {
//         text: data?.from,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, true, false],
//       },
//       {
//         text: data?.to,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, true, false],
//       },
//       {
//         text: data?.transporterFreigth,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, true, false],
//       },
//       {
//         text: data?.transporterAdvance,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, true, false],
//       },
//       {
//         text: data?.transporterBalance,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, true, false],
//       },
//       {
//         text: data?.transporterHamali,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, true, false],
//       },
//       {
//         text: data?.transporterDetention,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, true, false],
//       },
//       {
//         text: data?.transporterExtraCharge,
//         bold: true,
//         fontSize: 9,
//         alignment: "center",
//         border: [true, false, false, false],
//       },
//     ];
//   });
//   return rows;
// };
