import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import axiosInstance from "../../Utils/API";
import {
  ADD_VEHICLEOWNER_PAYMENT,
  APPPEND_DISCOUNT,
  VEHICLE_COMMISSION_INVOICE,
  VEHICLE_COMMISSION_INVOICE_PRINT,
} from "../../Utils/ApiGlobal";
// import { Lendger } from "../../Utils/Constants";
import TablePagination from "../UI/Table/TablewithPagination";
import moment from "moment";
import Loader from "../UI/Loader";
import {
  CheckboxDes,
  LESSCOMMISSION,
  TOTALPAIDDRIVER,
  TOTALPAYMENTPWNER,
} from "../../Utils/Constants";
import Checkbox from "../UI/Checkbox";
import ButtonWithBG from "../UI/ButtonMain";
import { useHistory } from "react-router-dom";
import CategoryPill from "../UI/CategoryPill";
import { ConfirormationModal } from "../UI/ConfirormationModal";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm text-center"
    {...props}
  />
);

export default function VehicleInvoiceTable(props) {
  // for Search
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [commissionData, setCommissionData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [hamali, setHamali] = useState(0);
  const [detention, setDetention] = useState(0);
  const [balance, setBalance] = useState(0);
  const [commission, setCommission] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPaidToOwner, setTotalPaidToOwner] = useState(0);
  const [error, setError] = useState(false);
  const [note, setNote] = useState();
  const [extraCharge, setExtraCharge] = useState(0);

  // const [ownerId,setOwnerId] = useState();
  // const filterLedger = ({ Date }) => {
  //   return Date.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1;
  // };

  // Discount append
  const discountAppend = (e, d) => {
    let discountDetails = {
      Id: [d.id],
      type: "VehicleOwner",
      Discount: e.target.value,
    };
    // Append discount
    axiosInstance
      .post(APPPEND_DISCOUNT, discountDetails)
      .then((response) => {
        // console.log(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      Header: "",
      id: "invoiceId",
      maxWidth: 10,
      minWidth: 10,
      width: 10,
      Cell: (row) => (
        <div>
          <Checkbox
            onClick={(e) => {
              // var invoicePrint_array = [...invoicePrint];
              // if (e.target.checked=== true) {
              //   invoicePrint_array = [...invoicePrint, e.target.value];
              //   console.log(invoicePrint_array, " console.log(invoicePrint);");
              //   // let Id= row.row.original.id;
              //   // let Type = row.row.original.type;
              //   //   setInvoicePrint({
              //   //     ...invoicePrint,
              //   //     id: Id,
              //   //     type:Type
              //   //   })
              // } else {
              //   invoicePrint_array.splice(
              //     invoicePrint.indexOf(e.target.value),
              //     1
              //   );
              // }
              // setInvoicePrint(invoicePrint_array);
            }}
            name={row.row.original.type}
            value={row.row.original.id}
            className="accent-red-600	text-white"
          />
        </div>
      ),
    },
    {
      Header: "Payment Status",
      id: "Payment Status",
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      Cell: (row) => {
        return (
          <CategoryPill
            onChangeValue={(e, id, type) => {
              UpdatePaymentMethod(e, id, row?.row?.original?.type);
              // console.log(e, id, type)
              // console.log(row?.row?.original)
            }}
            Pending={row?.row?.original?.isPaid}
            id={row?.row?.original?.id}
            type={row?.row?.original?.type}
          />
        )
      },
    },
    // {
    //   Header: "#",
    //   accessor: (d, index) => {
    //     return index + 1;
    //   },
    //   maxWidth: 10,
    //   minWidth: 10,
    //   width: 10,
    // },
    {
      Header: "Date",
      accessor: (d) => {
        return moment(d.loadingDate).format("DD-MM-YYYY");
      },
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      //   Footer: <span className="font-semibold text-left ">Total Amount:</span>,
    },
    {
      Header: "Type",
      accessor: "type",
      maxWidth: 80,
      minWidth: 80,
      width: 80,
      //   Footer: <span className="font-semibold text-left ">Total Amount:</span>,
    },
    {
      Header: "Buillty Received",
      // accessor: 'isBilltyReceived',
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      accessor: (d) => {
        return d.isBilltyReceived ? "Yes" : "No";
      },
    },
    {
      Header: "Discount",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      accessor: (d) => {
        return (
          <Input
            defaultValue={d.discount}
            onChange={(e) => {
              discountAppend(e, d);
            }}

            // autoComplete="off"
          />
        );
      },
    },
    {
      Header: "Truck No",
      accessor: "truckNo",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      //   Footer: <span className="font-semibold text-left ">Total Amount:</span>,
    },
    {
      Header: "FREIGHT",
      accessor: "freight",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Advance",
      accessor: "advance",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left ">Total</span>,
    },
    {
      Header: "Balance",
      accessor: "balance",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left ">{balance}</span>,
    },
    {
      Header: "Hamali",
      accessor: (d) => {
        if (d.hamali !== null) {
          return d.hamali;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left ">{hamali}</span>,
    },
    {
      Header: "Detention",
      accessor: (d) => {
        if (d.detention !== null) {
          return d.detention;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left ">{detention}</span>,
    },
    {
      Header: "Extra Charge",
      accessor: (d) => {
        if (d.extraCharge !== null) {
          return d.extraCharge;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left ">{extraCharge}</span>,
    },
    {
      Header: "Commission",
      accessor: "commission",
      maxWidth: 110,
      minWidth: 110,
      width: 110,
      Footer: <span className="font-semibold text-left">{totalPaidToOwner} Dr</span>,
    },
  ];
  useEffect(() => {
    // setOwnerId(localStorage.getItem('id'))
    getVehicleOwnerInvoice(localStorage.getItem("id"));
  }, [props?.startDate, props?.endDate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get API
  const getVehicleOwnerInvoice = async (FetchownerId) => {
    setIsLoading(true);
    let StartDate =
      props?.startDate === undefined
        ? ""
        : moment(props?.startDate).format("YYYY-MM-DD");
    let EndDate =
      props?.endDate === undefined
        ? ""
        : moment(props?.endDate).format("YYYY-MM-DD");
    await axiosInstance
      .get(
        `${VEHICLE_COMMISSION_INVOICE}?vehicleOwnerId=${FetchownerId}&startDate=${StartDate}&endDate=${EndDate}`
      )
      .then((response) => {
        // console.log(response);
        setInvoiceData(response?.data?.data);
        setCommissionData(response?.data?.data?.details);
        setHamali(response?.data?.data?.totalHamali);
        setDetention(response?.data?.data?.totalDetention);
        setBalance(response?.data?.data?.totalBalance);
        setCommission(response?.data?.data?.totalCommission);
        setExtraCharge(response?.data?.data?.totalExtraCharge);
        setTotal(response?.data?.data?.totalOwnerPayment);
        setTotalPaidToOwner(response?.data?.data?.totalPaidToOwner);
        setIsLoading(false);
        return;
        // setExtraCharge(response?.data?.data?.totalExtraCharge);
        // setAdvance(response?.data?.data?.totalAdvance);
        // setFreight(response?.data?.data?.totalFreight);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
        return;
      });
  };
  // Get Print Invoice API
  const PrintBtnClick = async () => {
    setIsLoading(true);
    let checkedBoxes = document.querySelectorAll(
      "input[type=checkbox]:checked"
    );
    let InvoicesPara = Array.from(checkedBoxes).map((x) => {
      return { Id: x.value, Type: x.name };
    });
    let InvoiceDetails = {
      OwnerId: props?.ownerId,
      Invoices: InvoicesPara,
    };
    // props?.ownerId
    await axiosInstance
      .post(VEHICLE_COMMISSION_INVOICE_PRINT, InvoiceDetails)
      .then((response) => {
        // console.log(response);
        if (response?.data?.data?.details) {
          history.push({
            pathname: "/vehicle/vehicle-invoice-view",
            commissionData: { invoiceData: response?.data?.data },
            note: { note },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setError(true);
      });
  };

  // Change Payment status
  const UpdatePaymentMethod = async (e, id, type) => {
    // console.log(e)
    // console.log(id)
    // console.log(type)
    // setIsLoading(true);
    // props?.ownerId
    let paymentData = {
      Id: id,
      Type: type,
      IsPaid: e,
    };
    await axiosInstance
      .post(ADD_VEHICLEOWNER_PAYMENT, paymentData)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };

  return (
    <section>
      <p className="text-left font-semibold	 text-xs	mt-2">
        {CheckboxDes} Invoices.
      </p>
      {isLoading && invoiceData ? (
        <Loader />
      ) : (
        <div className="mt-2">
          {error ? (
            <p className="text-warn-error">No data found</p>
          ) : (
            <>
              {/* Table call */}
              <TablePagination
                noPagination
                data={commissionData}
                columns={columns}
                isFooter
              />
              {/* Calculation of  Total Amount paid to Truck Driver*/}
              <div className="flex flex-row justify-between mt-4">
                <div className="flex  text-left flex-row w-1/4 justify-start	">
                  <div className="flex justify-start flex-col text-sm  w-80">
                    <p className="">{TOTALPAYMENTPWNER}</p>
                    <p className="">{LESSCOMMISSION}</p>
                    <p className="">{TOTALPAIDDRIVER}</p>
                  </div>
                  <div className="flex text-right flex-col justify-start text-sm ">
                    <span className="font-bold text-right">
                      {total ? total : 0}
                    </span>
                    <span className="font-bold text-right">
                      {commission ? commission : 0}
                    </span>
                    <span className="font-bold text-right">
                      {totalPaidToOwner ? totalPaidToOwner : 0}
                    </span>
                  </div>
                </div>
                {/* Note input to show information in invoice print */}
                <div className="flex  row space-x-2 w-1/2">
                  <p className="text-sm">Note:</p>
                  <textarea
                    className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                    name="Note"
                    id=""
                    cols="30"
                    rows="1"
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    type="text"
                  ></textarea>
                </div>
              </div>
              <div className="flex mt-10 flex-row space-x-2 justify-center items-center">
                {/* {invoiceData?.details && ( */}
                <ButtonWithBG
                  label="Genrate Invoice"
                  handleClick={PrintBtnClick}
                ></ButtonWithBG>

                {/* <ButtonWithBG
                  theme="secondary"
                  label="Invoice Paid"
                  onClick={(e) => {
                    PrintBtnClick();
                  }}
                ></ButtonWithBG> */}
              </div>
            </>
          )}
        </div>
      )}
    </section>
  );
}
