import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashBoardLayout from "../../../Layouts/private/DashboardLayout";
import ButtonWithBG from "../../../Components/UI/ButtonMain";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import axiosInstance from "../../../Utils/API";
import Notification from "../../../Components/UI/Notification";
import Loader from "../../../Components/UI/Loader";
import APILoader from "../../../Components/UI/APILoader";
import { ConfirormationModal } from "../../../Components/UI/ConfirormationModal";
import { ADD_OTHERPARTY,UPDATE_OTHERPARTY} from "../../../Utils/ApiGlobal";
// import Checkbox from "../../Components/UI/Checkbox";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);
const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function AddEditOtherParty({ props }) {
  const location = useLocation();
  const history = useHistory();
  const [heading, setHeading] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notif, setNotif] = useState(false);
  const [notifOpts, setNotifOpts] = useState({});
  // const [balancePaid, setBalancePaid] = useState();
  // const [Checked, setChecked] = useState();
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  });
  // To fetch Data from previous route
  useEffect(() => {
    // console.log(location?.state)
    if (location?.state?.label === "Add") {
      setHeading("Add Other Party");
    } else {
      setHeading("Edit Other Party");
    }
  }, [location]);

    // For Loading
  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      setIsLoading(false);
    };
    loadData();
  }, [])

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });
  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      // the only way to determine the type is to use the GraphQL schema. Some String, some [String]
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  //   Form Submit click event
  const FormSubmitHandler = async (ev) => {
    ev.preventDefault();
    // console.log(balancePaid)
    setLoader({ ...loader, loading: true });
    var obj = formValueConvertor(getFormJSON(ev.target));
    // console.log(obj);
    if (location?.state?.label === "Add"){
      let PARTY_DATA = {
        OtherPartyName: obj.Name,
        Address: obj.Address,
        OtherPartyGst: obj.gst,
        // IsBalancePaid: balancePaid,
        CreatedBy: localStorage.getItem("userId"),
      };
      await axiosInstance
        .post(ADD_OTHERPARTY, PARTY_DATA)
        .then((response) => {
          // console.log(response)
          setNotif(true);
          if(response.data.data){
          setNotifOpts({
            mode: "success",
            timeout: 10000,
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/otherParties",
          });
        }else{
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error",
            content: response.data.error.message,
          });
          ConfirormationModal(response.data.error.message, "error", 2000);
        }})
        .catch((error) => {
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error",
            content: "Something Went Wrong",
          });
          // return;
          ConfirormationModal(error.message, "error", 2000);
        });
    }
    else{
      let PARTY_UPDATE_DATA = {
        OtherPartyId: location?.state?.data?.data?.otherPartyId,
        OtherPartyName: obj.Name,
        Address: obj.Address,
        OtherPartyGst: obj.gst,
        // IsBalancePaid: balancePaid,
        CreatedBy: localStorage.getItem("userId"),
      };
      await axiosInstance
        .post(UPDATE_OTHERPARTY, PARTY_UPDATE_DATA)
        .then((response) => {
          setNotif(true);
          setNotifOpts({
            mode: "success",
            timeout: 10000,
            top: 10,
            title: "Success",
            content: response.data.data.message,
          });
          ConfirormationModal(response.data.data.message, "success", 2000);
          history.push({
            pathname: "/otherParties",
          });
        })
        .catch((error) => {
          setNotifOpts({
            mode: "failure",
            timeout: 10000,
            top: 10,
            title: "Error",
            content: "Something Went Wrong",
          });
          // return;
          ConfirormationModal(error.message, "error", 2000);
          // console.log(COMPANY_UPDATE_DATA)
        });
    }
  };
  return (
    <>
      <DashBoardLayout pageTitle={heading}>
      <APILoader loader={loader} />
        <Notification
          show={notif}
          setShow={setNotif}
          {...notifOpts}
          supportContent={true}
        ></Notification>
        {isLoading ? (
          <Loader/>
        ) : (
          <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
            <form onSubmit={FormSubmitHandler}>
              <div className="">
                {/* left side Content */}
                <div className=" text-left space-y-6">
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Name */}
                    <div className=" flex flex-col  text-left md:w-1/3">
                      <Label label="Party Name" isRequired="true" />
                      <Input
                        defaultValue={location?.state?.data?.data?.otherPartyName}
                        name="Name"
                        required
                        // autoComplete="off"
                      />
                    </div>

                    <div className=" flex md:w-1/3  flex-col  text-left">
                      <Label label="Address" isRequired="true" />
                      <Input
                        defaultValue={location?.state?.data?.data?.address}
                        name="Address"
                        required
                        // autoComplete="off"
                      />
                    </div>
                    <div className=" flex md:w-1/3  flex-col  text-left">
                      <Label label="GST" isRequired="true" />
                      <Input
                        defaultValue={location?.state?.data?.data?.otherPartyGst}
                        name="gst"
                        required
                        // autoComplete="off"
                      />
                    </div>
                  </div>

                </div>
              </div>
              <div className="items-ceter mt-4">
                <ButtonWithBG theme="primary" label="SAVE" type="submit" />
              </div>
            </form>
          </div>
        )}
      </DashBoardLayout>
    </>
  );
}
