import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axiosInstance from "../../Utils/API";
import { GET_BANK_LIST } from "../../Utils/ApiGlobal";
import Loader from "../UI/Loader";

const animatedComponents = makeAnimated();

export default function BankDetails({
  handleOnChange,
  name,
  defaultValue,
}) {
  const [optionsBank, setOptions] = useState({});
  useEffect(() => {
    axiosInstance
      .get(`${GET_BANK_LIST}`)
      .then((response) => {
        // console.log(response?.data?.data?.company);
        if (response?.data?.data.length > 0) {
          const options = response?.data?.data.map(
            ({ accountId, bankName,branch, accountNo, ifscCode }, index) => ({
                accountId,
                bankName,
                branch,
                accountNo,
                ifscCode
            })
          );
          setOptions(options);
        }
      });
  }, []);

  return (
    <>
      {optionsBank.length > 0 ? (
        <div >
          <Select
            components={animatedComponents}
            defaultValue={{ label: defaultValue, value: defaultValue }}
            options={optionsBank.map((el) => ({
              label: el.bankName,
              value: el.accountNo,
              branch: el.branch,
              billAdd: el.billToAddress,
              ifscCode:el.ifscCode
            }))}
            name={name}
            onChange={handleOnChange}
            placeholder="Select Bank Branch"
            className="z-80"
            theme={(theme) => ({
              ...theme,
              borderRadius: 5,
              colors: {
                ...theme.colors,
                primary25: "#f68e92",
                primary: "#ed1c24",
              },
            })}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
