import React from "react";
// import { useLocation } from "react-router-dom";
import {
  InvoicSubject,
  KIRTIROADLINES,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  OURBANKDETAILS,
  PaymentbypayeeCchequeDrafs,
  SCANQRCONTENT,
  WE_USING_PAYPHONE,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../../Utils/Constants";
import moment from "moment";
import ButtonWithBG from "../../../Components/UI/ButtonMain";
import { useRef } from "react";
import qrcode from "../../../Images/qrcode.jpg";
import {
  Tablebg,
  TabletheadRedLastStyle,
  TabletheadRedStyle,
  TabletheadStyle,
  borderToggle,
} from "../../../Utils/TableStyling";
import logo from "../../../Images/logo_transparent.png";
import black_logo from "../../../Images/black_logo.png";
import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import sign from "../../../Images/sign.png";
import { CommisionBillingContent } from "../../../Layouts/pdfLayout/CommisionBillingContentLayout";
import * as pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { font } from "../../../Utils/PTSerif-Regular";
import { robotoBlack } from "../../../Utils/Roboto-Black";
import { PTSerifBold } from "../../../Utils/PTSerif-Bold";
import { BillingFooter } from "../../../Layouts/pdfLayout/BillingFooterLayout";
import { BillingHeader } from "../../../Layouts/pdfLayout/BillingHeaderLayout";
import { billingPaymentA5 } from "../../../Layouts/pdfLayout/BillingPaymentLayout";
import { NotoSansDevanagariBold } from "../../../Utils/NotoSansDevanagari-Bold";
import { NotoSansDevanagariRegular } from "../../../Utils/NotoSansDevanagari-Regular";
const pdfMakeX = require("pdfmake/build/pdfmake.js");
const pdfFontsX = require("pdfmake-unicode/dist/pdfmake-unicode.js");
pdfMakeX.vfs = pdfFontsX.pdfMake.vfs;
//importing the fonts whichever present inside vfs_fonts file
//Making use of all the fonts defined
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["PTSerif-Regular.ttf"] = font;
window.pdfMake.vfs["PTSerif-Bold.ttf"] = PTSerifBold;
window.pdfMake.vfs["Roboto-Black.ttf"] = robotoBlack;
window.pdfMake.vfs["NotoSansDevanagari-Bold.ttf"] = NotoSansDevanagariBold;
window.pdfMake.vfs["NotoSansDevanagari-Regular.ttf"] =
  NotoSansDevanagariRegular;
const CommissionInvoiceViewDownload = (props) => {
  const pageStyle = `@page {
    size: A5;
    margin: 10pt;
  }
  @media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
  }
`;
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");
  //   const generatePDFAndDisplay = async () => {
  //     try {
  //       // Get the entire content from the useReactToPrint reference
  //       const content = componentRef.current;
  //       // console.log(content, "click");

  //       // Generate PDF us  ing html2pdf
  //       const pdfOptions = {
  //         margin: 0,
  //         filename:
  //           `Invoice of ` +
  //           props?.data?.transporterName +
  //           "-" +
  //           (props?.data?.invoiceNo || props?.data?.bookingInvoiceNo
  //             ? props?.data?.invoiceNo
  //               ? props?.data?.invoiceNo
  //               : props?.data?.bookingInvoiceNo
  //               ? props?.data?.bookingInvoiceNo
  //               : "--"
  //             : "--"),
  //         image: { type: "jpeg", quality: 0.98 },
  //         html2canvas: { scale: 2 },
  //         jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
  //       };
  //       html2pdf().set(pdfOptions).from(content).save();

  //       // const pdfBlob = await html2pdf()
  //       //   .set(pdfOptions)
  //       //   .from(content)
  //       //   .outputPdf("blob");

  //       // // Convert PDF to data URI (Blob URL)
  //       // const pdfBlobUrl = URL.createObjectURL(pdfBlob);

  //       // // Display the generated PDF in a new window
  //       // window.open(pdfBlobUrl, "_blank");
  //     } catch (error) {
  //       // Handle errors appropriately
  //     }
  //   };
  // const location = useLocation();
  // Download pdf
  const sanitizeFilename = (filename) => {
    // Replace special characters with underscores
    // Download pdf
    let checkNull = filename.includes("null");
    if (checkNull) {
      return filename.replace(/[^\w\s]/gi, "_")?.replace("null", "");
    } else if (!checkNull) {
      return filename.replace(/[^\w\s]/gi, "_");
    }
  };

  async function handleDownloadPdf(action) {
    props.setIsLoading(true);

    //Specifying the fonts which we are going to use in our PDF
    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Black.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-Italic.ttf",
      },
      PTSerifRegular: {
        normal: "PTSerif-Regular.ttf",
        bold: "PTSerif-Bold.ttf",
        italics: "PTSerif-Regular.ttf",
        bolditalics: "PTSerif-Regular.ttf",
      },
      NotoSansDevanagari: {
        normal: "NotoSansDevanagari-Regular.ttf",
        bold: "NotoSansDevanagari-Bold.ttf",
        italics: "NotoSansDevanagari-Regular.ttf",
        bolditalics: "NotoSansDevanagari-Regular.ttf",
      },
    };

    const invoicedata = props.data;
    const bankBranch = props.bankBranch;
    const profileData = props.profileData;
    const secondaryPhoneNo = props.secondaryPhoneNo;
    const note = props.note
    const contentRender = async () => {
      try {
        const BillingHeaderContent = await BillingHeader(
          profileData,
          secondaryPhoneNo,
          "billing",
          !props.noHeader,
          true
        );

        const BillingContent = await CommisionBillingContent(
          invoicedata,
          WE_USING_PAYPHONE,
          bankBranch,
          profileData
        );

        const BillingPayment = await billingPaymentA5(
          invoicedata,
          WE_USING_PAYPHONE,
          bankBranch,
          note
        );

        const BillingFooterContent = await BillingFooter("billing", true);

        return [
          BillingHeaderContent,
          BillingContent,
          { stack: [BillingPayment, BillingFooterContent], unbreakable: true },
        ];
      } catch (error) {
        // Handle errors if necessary
        console.error("Error in contentRender:", error);
        throw error; // Re-throw the error to propagate it
      }
    };

   function background (currentPage, pageSize) {
      const borderWidth = 1; // Adjust the border width
      const spaceBetweenBorders = 1; // Adjust the space between the borders
  
      const outerBorder = {
          type: "rect",
          x: 18,
          y: 24,
          w: pageSize.width - 35,
          h: pageSize.height - 45,
          lineWidth: borderWidth,
      };
  
      const innerBorder = {
          type: "rect",
          x: outerBorder.x + borderWidth + spaceBetweenBorders,
          y: outerBorder.y + borderWidth + spaceBetweenBorders,
          w: outerBorder.w - (2 * (borderWidth + spaceBetweenBorders)),
          h: outerBorder.h - (2 * (borderWidth + spaceBetweenBorders)),
          lineWidth: borderWidth,
      };
  
      const contentTable = {
          table: {
              widths: [pageSize.width - (20 + spaceBetweenBorders * 2)],
              heights: [pageSize.height - (20 + spaceBetweenBorders * 2)],
              body: [[""]],
          },
          layout: "noBorders",
          margin: 5,
      };
  
      return {
          canvas: [outerBorder, innerBorder, contentTable],
      };
  }

  function withoutHeaderbackground (currentPage, pageSize) {
    const borderWidth = 1; // Adjust the border width
    const spaceBetweenBorders = 1; // Adjust the space between the borders

    const outerBorder = {
        type: "rect",
        x: 18,
        y:  95,
        w: pageSize.width - 35,
        h: pageSize.height - 120,
        lineWidth: borderWidth,
    };

    const innerBorder = {
        type: "rect",
        x: outerBorder.x + borderWidth + spaceBetweenBorders,
        y: outerBorder.y + borderWidth + spaceBetweenBorders,
        w: outerBorder.w - (2 * (borderWidth + spaceBetweenBorders)),
        h: outerBorder.h - (2 * (borderWidth + spaceBetweenBorders)),
        lineWidth: borderWidth,
    };

    const contentTable = {
        table: {
            widths: [pageSize.width - (20 + spaceBetweenBorders * 2)],
            heights: [pageSize.height - (20 + spaceBetweenBorders * 2)],
            body: [[""]],
        },
        layout: "noBorders",
        margin: 5,
    };

    return {
        canvas: [outerBorder, innerBorder, contentTable],
    };
}
  

  const sanitizedFilename = sanitizeFilename(
    props?.data?.invoiceNo 
  );
  const fileName = `${sanitizedFilename}.pdf`;

    const pdfDocDefinition = {
      pageSize: "A5",
      pageMargins: [25, !props.noHeader ? 35 : 110, 25, 10],
      content: await contentRender().then((result) => result),
      info:{
        title: fileName
      },
      styles: {
        table: {
          margin: [0, 0, 0, 0], // Adjust the top margin of the entire table
        },
        tableHeader: {
          fontSize: 9,
          alignment: "start", // 'start' aligns text to the start of the cell
          bold: true,
          fillColor: "#fff",
          color: "#000",
          margin: [1, 0, 0, 0], // Adjust the margin for each header cell
          borderBottom: "1px solid #28328c",
        },
        cellStyle: {
          fontSize: 8,
          alignment: "center", // 'start' aligns text to the start of the cell
          fillColor: "#fff",
          color: "#000",
          margin: [0, 0, 0, 0], // Adjust the margin for each header cell
        },
      },
      background: props.showBorder ? !props.noHeader ? background : withoutHeaderbackground : () => {},
    };


    const callbackFunction = function () {
      // Here implement function for hide waiting loader
      props.setIsLoading(false);
    };
    const handlePrint = () => {
      pdfDocGenerator.print();
      props.setIsLoading(false);
    };

   

    const newLocal = pdfMake.createPdf(pdfDocDefinition);
    const pdfDocGenerator = newLocal;

    // pdfDocGenerator.open()
    action === "download"
    ? pdfDocGenerator.download(fileName, callbackFunction)
    : handlePrint();  }

  let componentRef = useRef();
  // console.log(props)
  return (
    <>
      {props?.data?.transporterName && (
        <>
          <div className="hidden">
            <div ref={componentRef}>
              <div
                className={`${
                  props.showBorder
                    ? ` border-double  border-4 ${
                        props.blackWhite ? "border-black" : "border-red-200"
                      } `
                    : "mx-2"
                } 
                ${props.noHeader && "mt-36"}
           pageStyle  block m-4 align-top text-center bg-center bg-no-repeat  bg-[length:65%]`}
              >
                <div className="mt-2">
                  {!props.NoTaxTag && (
                    <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-xs">
                      <h5>Tax Invoice</h5>
                    </div>
                  )}
                  {/* Logo and Title Start */}
                  {!props.noHeader && (
                    <div className="mx-2 items-center space-x-3 flex flex-row">
                      <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                        <div className="flex flex-row justify-between">
                          <div className="w-32 ml-2">
                            {props.blackWhite ? (
                              <img src={black_logo} alt="Kirti Roadlines" />
                            ) : (
                              <img src={logo} alt="Kirti Roadlines" />
                            )}
                          </div>
                          <div className=" flex flex-col  -mt-2">
                            <span
                              style={{ fontSize: "35px", lineHeight: "1" }}
                              className={`${
                                !props.blackWhite &&
                                "text-primary-kirtiroadways"
                              } font-extrabold `}
                            >
                              {props.profileData.tagLine}
                            </span>
                            <span className="text-xs font-serif text-black mt-2">
                              {props.profileData.subTagLine}
                            </span>
                          </div>
                        </div>
                        {/* Address Start */}
                        <div
                          style={{
                            width: "100%",
                            height: "65px",
                            // Set the container height to full viewport height
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start", // Center the content vertically
                            alignItems: "center",
                            borderRadius: "1.5rem",
                            padding: "3px",
                            fontSize: "10px",
                            background: !props.blackWhite
                              ? "linear-gradient(to right, #FFC7C7, #FFFFFF)"
                              : "white",
                            border: props.blackWhite ? "1px solid black" : "",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "start", // Center the content vertically
                              gap: "4px", // Adjust spacing between SVG and text
                              marginLeft: "2px",
                            }}
                          >
                            <span className="relative top-2 left-3">
                              {address_svg}
                            </span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center", // Center the content vertically
                                letterSpacing: "0px",
                                textRendering: "optimizeLegibility", // Improve text rendering for better readability
                                WebkitFontSmoothing: "antialiased", // For WebKit browsers (e.g., Chrome, Safari)
                                MozOsxFontSmoothing: "grayscale", // For Firefox on macOS
                                fontFamily: "sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              {props.profileData.address}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "2px",
                              letterSpacing: "0px",
                              textRendering: "optimizeLegibility", // Improve text rendering for better readability
                              WebkitFontSmoothing: "antialiased", // For WebKit browsers (e.g., Chrome, Safari)
                              MozOsxFontSmoothing: "grayscale", // For Firefox on macOS
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center", // Center the content vertically
                              }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "end", // Center the content vertically
                                  placeItems: "end",
                                }}
                              >
                                <span className="relative top-2 mr-1">
                                  {call_svg}
                                </span>
                                {props.secondaryPhoneNo.map((number, i) => (
                                  <span key={number}>
                                    {(i ? ", " : "") + number}
                                  </span>
                                ))}
                              </span>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                spaceX: "1",
                                justifyContent: "center",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <span className="relative top-2 mr-1 ml-2">
                                {email_svg}
                              </span>

                              <span className="mt-1">
                                {props.profileData.email}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* Address End */}
                      </div>
                    </div>
                  )}
                  {/* Logo and Title End */}
                </div>

                {/* Invoice  No and details*/}
                {!props.NoDetails && (
                  <div
                    // className={`${
                    //   props.noHeader && "mt-44"
                    // } p-2 flex font-sans flex-row justify-between`}
                    className={`px-2 flex font-sans flex-row justify-between`}
                  >
                    <div className="flex flex-col text-left w-1/2">
                      <div
                        style={{
                          position: "relative",
                          height: "20px",
                          top: "0px",
                          width: "150px",
                          textTransform: "uppercase",
                          marginBottom: "0",
                          fontSize: "15px",
                          textDecoration: "underline",
                          fontWeight: "500",
                        }}
                      >
                        Invoice to
                      </div>
                      <div className="w-96">
                        <p
                          className="text-left font-bold uppercase font-sans text-md	subpixel-antialiased tracking-wide mt-1	"
                          style={{ fontSize: "10px" }}
                        >
                          {props?.data.transporterName
                            ? props?.data.transporterName?.toUpperCase()
                            : null}
                        </p>
                      </div>
                      {props?.data.address && (
                        <div
                          className="text-sm text-left"
                          style={{ fontSize: "9px" }}
                        >
                          <div className="flex  flex-row space-x-1 items-start justify-start pt-1">
                            <p
                              className=" w-28 flex-nowrap font-semibold"
                              style={{ fontSize: "9px" }}
                            >
                              Address :
                            </p>
                            <p
                              className="w-96 font-semibold "
                              style={{ fontSize: "9px" }}
                            >
                              {props?.data.address}
                            </p>
                          </div>
                        </div>
                      )}
                      {props.companyGst && (
                        <div className="text-sm " style={{ fontSize: "9px" }}>
                          <div className="flex flex-row space-x-1 text-left">
                            <p className="">PAN :</p>
                            <p className="w-1/2">
                              {props?.companyGst.toUpperCase()}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Right side */}
                    <div className="flex  flex-col text-right justify-end items-end ">
                      <span
                        style={{
                          position: "relative",
                          height: "20px",
                          top: "0px",
                          width: "150px",
                          textTransform: "uppercase",
                          marginBottom: "0",
                          fontSize: "15px",
                          textDecoration: "underline",
                          fontWeight: "500",
                        }}
                      >
                        OUR Details
                      </span>
                      <div
                        style={{ fontSize: "9px" }}
                        className="flex space-x-1 flex-row justify-end uppercase font-semibold	pt-2"
                      >
                        <p className="flex flex-col">
                          <span style={{ fontSize: "9px" }}>
                            Service : {props.profileData.service}
                          </span>
                          <span style={{ fontSize: "9px" }}>
                            Pan No. : {props.profileData.panNo}
                          </span>
                          <span style={{ fontSize: "9px" }}>
                            State Code : {props.profileData.stateCode}
                          </span>
                        </p>
                        {/* <p className="flex flex-col">
                      <span>Transportation</span>
                      <span>AJGPD9182R</span>
                      <span>24</span>
                    </p> */}
                      </div>

                      <div
                        className={`uppercase font-semibold	 mt-1 pl-16 border-t-2 ${borderToggle(
                          props.blackWhite
                        )} `}
                      >
                        <span
                          className="flex flex-col "
                          style={{ fontSize: "9px" }}
                        >
                          <span className="text-right min-w-1/2">
                            Invoice No :{" "}
                            {props?.data?.invoiceNo ||
                            props?.data?.bookingInvoiceNo
                              ? props?.data?.invoiceNo
                                ? props?.data?.invoiceNo
                                : props?.data?.bookingInvoiceNo
                                ? props?.data?.bookingInvoiceNo
                                : "--"
                              : "--"}
                          </span>
                          {/* <p className="text-right min-w-1/2">
                          Bill OF Loading :
                        </p> */}
                          <span
                            className="text-right min-w-1/2"
                            style={{ fontSize: "9px" }}
                          >
                            Date : {Today_Date}
                          </span>
                          {/* <p className="text-right w-1/2">Transportation</p> */}
                        </span>
                        {/* <span className="flex flex-col "> */}
                        {/* <p className="text-left min-w-1/2">--</p> */}
                        {/* <p className="text-left min-w-1/2">--</p> */}
                        {/* <p className="text-left min-w-1/2">{Today_Date}</p> */}
                        {/* <p className="text-right w-1/2">Transportation</p> */}
                        {/* </span> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Table start*/}
                <div className="px-1 w-full ">
                  <table className=" text-xs border-spacing-y-2 border-separate w-full border-neutral-900  font-sans">
                    <thead
                      style={{
                        maxHeight: "5px",
                        minHeight: "5px",
                        verticalAlign: "center",
                      }}
                      className="mb-2 text-center"
                    >
                      <tr className=" border-neutral-900 text-white ">
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${TabletheadStyle(props.blackWhite)}  ${
                            props.mobilePrint && "pb-4"
                          } `}
                        >
                          Loading Date
                        </th>
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${TabletheadStyle(props.blackWhite)}  ${
                            props.mobilePrint && "pb-4"
                          } `}
                        >
                          Truck No.
                        </th>
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${TabletheadStyle(props.blackWhite)}  ${
                            props.mobilePrint && "pb-4"
                          } `}
                        >
                          From
                        </th>
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${TabletheadStyle(props.blackWhite)}  ${
                            props.mobilePrint && "pb-4"
                          } `}
                        >
                          To
                        </th>
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${TabletheadRedStyle(
                            props.blackWhite
                          )}  ${props.mobilePrint && "pb-4"} `}
                        >
                          Freight
                        </th>
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${TabletheadRedStyle(
                            props.blackWhite
                          )}  ${props.mobilePrint && "pb-4"} `}
                        >
                          Advance
                        </th>
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${TabletheadRedStyle(
                            props.blackWhite
                          )}  ${props.mobilePrint && "pb-4"} `}
                        >
                          To Pay
                        </th>
                        <th
                          style={{
                            maxHeight: "8px",
                            minHeight: "8px",
                            verticalAlign: "middle",
                            fontSize: "10px",
                          }}
                          className={`${
                            props?.data?.transporterExtraCharge > 0 &&
                            props?.data?.transporterExtraCharge !== null
                              ? TabletheadRedStyle(props.blackWhite)
                              : TabletheadRedLastStyle(props.blackWhite)
                          }  ${props.mobilePrint && "pb-4"} `}
                        >
                          Balance
                        </th>
                        {props?.data?.transporterHamali > 0 &&
                          props?.data?.transporterHamali !== null && (
                            <th
                              style={{
                                maxHeight: "8px",
                                minHeight: "8px",
                                verticalAlign: "middle",
                                fontSize: "10px",
                              }}
                              className={`${TabletheadRedStyle(
                                props.blackWhite
                              )}  ${props.mobilePrint && "pb-4"} `}
                            >
                              Hamali
                            </th>
                          )}
                        {props?.data?.transporterDetention > 0 &&
                          props?.data?.transporterDetention !== null && (
                            <th
                              style={{
                                maxHeight: "8px",
                                minHeight: "8px",
                                verticalAlign: "middle",
                                fontSize: "10px",
                              }}
                              className={`${TabletheadRedStyle(
                                props.blackWhite
                              )}  ${props.mobilePrint && "pb-4"} `}
                            >
                              Detention
                            </th>
                          )}
                        {props?.data?.transporterExtraCharge > 0 &&
                          props?.data?.transporterExtraCharge !== null && (
                            <th
                              style={{
                                maxHeight: "8px",
                                minHeight: "8px",
                                verticalAlign: "middle",
                                fontSize: "10px",
                              }}
                              className={`${TabletheadRedLastStyle(
                                props.blackWhite
                              )}  ${props.mobilePrint && "pb-4"} `}
                            >
                              Extra Charge
                            </th>
                          )}
                      </tr>
                    </thead>
                    {/* Tr data */}
                    <tbody className="font-bold text-base	 align-top text-center">
                      <tr height="20px">
                        <td
                          style={{ fontSize: "10px" }}
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-28 pb-3 `}
                        >
                          <span className="mt-2 ">
                            {moment(props?.data?.loadingDate).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </td>
                        <td
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-40  pb-3`}
                          style={{ fontSize: "10px" }}
                        >
                          <span className="mt-2">
                            {props?.data?.vehicleNo.toUpperCase()}
                          </span>
                        </td>
                        <td
                          style={{ fontSize: "10px" }}
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-40 `}
                        >
                          <span className="mt-2">
                            {props?.data?.from.toUpperCase()}
                          </span>
                        </td>
                        <td
                          style={{ fontSize: "10px" }}
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-40 `}
                        >
                          <span className="mt-2">
                            {props?.data?.to.toUpperCase()}
                          </span>
                        </td>
                        <td
                          style={{ fontSize: "10px" }}
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-24 `}
                        >
                          <span className="ml-2 mt-2 text-center">
                            {props?.data?.transporterFreigth}
                          </span>
                        </td>
                        <td
                          style={{ fontSize: "10px" }}
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-24 `}
                        >
                          <span className="mt-2 text-center">
                            {props?.data?.transporterAdvance}
                          </span>
                        </td>
                        <td
                          style={{ fontSize: "10px" }}
                          height="20px"
                          className={` ${
                            props.blackWhite
                              ? "border-black border-r"
                              : "border-r-2 border-black-300"
                          }   items-center  w-24 `}
                        >
                          <span className="mt-2 text-center">
                            {props?.data?.toPay}
                          </span>
                        </td>
                        <td
                          style={{ fontSize: "10px" }}
                          height="20px"
                          className={`${
                            props?.data?.transporterHamali > 0 &&
                            props?.data?.transporterHamali !== null
                              ? " w-24"
                              : `${
                                  props.blackWhite
                                    ? "border-black border-r"
                                    : "border-r   border-gray-300"
                                }  `
                          }`}
                        >
                          <span className="mt-2 text-center">
                            {props?.data?.transporterBalance}
                          </span>
                        </td>
                        {props?.data?.transporterHamali > 0 &&
                          props?.data?.transporterHamali !== null && (
                            <td
                              style={{ fontSize: "10px" }}
                              height="20px"
                              className={` ${
                                props.blackWhite
                                  ? "border-black border-r"
                                  : "border-r-2 border-black-300"
                              }   items-center  w-24 `}
                            >
                              <span className="mt-2 text-center">
                                {props?.data?.transporterHamali}
                              </span>
                            </td>
                          )}
                        {props?.data?.transporterDetention > 0 &&
                          props?.data?.transporterDetention !== null && (
                            <td
                              style={{ fontSize: "10px" }}
                              height="20px"
                              className={` ${
                                props.blackWhite
                                  ? "border-black border-r"
                                  : "border-r-2 border-black-300"
                              }   items-center  w-24 `}
                            >
                              <span className="mt-2 text-center">
                                {props?.data?.transporterDetention}
                              </span>
                            </td>
                          )}
                        {props?.data?.transporterExtraCharge > 0 &&
                          props?.data?.transporterExtraCharge !== null && (
                            <td height="20px" className="  w-24">
                              <span
                                className={` ${
                                  props.blackWhite
                                    ? "border-black border-r"
                                    : "border-r-2 border-black-300"
                                }   items-center  w-24 mt-2`}
                              >
                                {props?.data?.transporterExtraCharge}
                              </span>
                            </td>
                          )}
                      </tr>
                      <tr style={{ height: "0px" }}></tr>
                    </tbody>

                    {/* Total data */}
                    <thead className="mb-2">
                      <tr className=" border-neutral-900 font-bold  ">
                        <th
                          style={{ minHeight: "8px", fontSize: "10px" }}
                          colSpan="6"
                          className="w-20"
                        ></th>
                        <th
                          style={{ minHeight: "10px", fontSize: "10px" }}
                          className={`w-16 text-center   pb-3 tracking-wide self-center font-bold uppercase ${
                            !props.blackWhite
                              ? "text-white bg-primary-kirtiroadways border-r-2"
                              : "border-2 border-black border-r"
                          } font-bold `}
                        >
                          Total
                        </th>
                        <th
                          style={{ maxHeight: "10px", fontSize: "10px" }}
                          className={`${
                            !props.blackWhite
                              ? "text-white bg-primary-kirtiroadways border-r-2"
                              : "border-2 border-black border-r-2"
                          }  w-20 font-bold pb-3 `}
                        >
                          {props?.data?.transporterBalance}
                        </th>
                        {props?.data?.transporterHamali > 0 &&
                          props?.data?.transporterHamali !== null && (
                            <th
                              style={{ maxHeight: "10px" }}
                              className={`${
                                !props.blackWhite
                                  ? "text-white bg-primary-kirtiroadways "
                                  : "border-2"
                              } border-r-2 w-20 font-bold pb-3`}
                            >
                              {props?.data?.transporterHamali}
                            </th>
                          )}
                        {props?.data?.transporterDetention > 0 &&
                          props?.data?.transporterDetention !== null && (
                            <th
                              style={{ maxHeight: "10px" }}
                              className={`${
                                !props.blackWhite
                                  ? "text-white bg-primary-kirtiroadways "
                                  : "border-2"
                              } border-r-2 w-20 font-bold pb-3`}
                            >
                              {props?.data?.transporterDetention}
                            </th>
                          )}
                        {props?.data?.transporterExtraCharge > 0 &&
                          props?.data?.transporterExtraCharge !== null && (
                            <th
                              style={{ maxHeight: "10px" }}
                              className={`${
                                !props.blackWhite
                                  ? "text-white bg-primary-kirtiroadways "
                                  : "border-2"
                              } border-r-2 w-20 font-bold pb-3`}
                            >
                              {props?.data?.transporterExtraCharge}
                            </th>
                          )}
                      </tr>
                    </thead>
                    {/* Horizotal Line */}
                    <tbody>
                      <tr
                        style={{ border: "1px solid black" }}
                        className={`${
                          !props.blackWhite
                            ? "border-primary-kirtiroadways border"
                            : "border-black  border"
                        }  min-w-full font-bold `}
                      ></tr>
                    </tbody>
                  </table>
                </div>

                <div
                  className={`flex flex-row  item-start justify-between pb-2	px-2 font-sans border-b ${
                    !props.blackWhite
                      ? "border-primary-kirtiroadways"
                      : "border-black"
                  }   font-semibold text-left text-xs`}
                >
                  <div className="item-start  w-full">
                    <div className="w-full">
                      <div className="flex flex-row justify-between w-full">
                        <div
                          className={`px-5 pt-1 leading-snug	text-center  rounded-lg uppercase ${
                            !props.blackWhite
                              ? "text-white bg-primary-kirtiroadways "
                              : "border-2 border-black"
                          } `}
                        >
                          <p
                            className="tracking-wide text-xs "
                            style={{ fontSize: "9px" }}
                          >
                            {OURBANKDETAILS}
                          </p>
                          <p style={{ fontSize: "6px", paddingBottom: "10px" }}>
                            ( {SCANQRCONTENT} )
                          </p>
                        </div>
                        {/* grand Total */}
                        <div className="flex flex-row  justify-end   h-fit ">
                          <span
                            className={`text-center  text-xs whitespace-nowrap	 px-4 pt-2 pb-4 tracking-wide   rounded-lg uppercase ${
                              !props.blackWhite
                                ? "text-white bg-primary-kirtiroadways "
                                : "border-2 border-black"
                            }  font-bold`}
                          >
                            {" "}
                            Grand Total : &#8377;{" "}
                            {props?.data?.transporterTotal === null
                              ? "0"
                              : props?.data?.transporterTotal}
                          </span>
                        </div>
                      </div>
                      {/* Bannk Detail Start */}
                      <div>
                        <div className="flex flex-row space-x-1 ">
                          {/* QR code */}
                          <div className="mt-1">
                            <img
                              width={70}
                              src={qrcode}
                              alt="Kirti Roadlines"
                            />
                          </div>
                          <div
                            className="mt-1 flex flex-col font-bold"
                            style={{ fontSize: "8px" }}
                          >
                            <span className="font-bold">Bank Name</span>
                            <span className="font-bold">Branch</span>
                            <span className="font-bold">A/c. No</span>
                            <span className="font-bold">IFSC Code</span>
                          </div>
                          {props.Dropdown === false ? (
                            <div
                              className="mt-1 flex flex-col"
                              style={{ fontSize: "8px" }}
                            >
                              <span className="font-bold">
                                : State Bank of India
                              </span>
                              <span className="font-bold">
                                : Prahlad Nagar Ahmedabad
                              </span>
                              <span className="font-bold">
                                : 005167983000351
                              </span>
                              <span className="font-bold">
                                : SBIT0CKMNSB (FIFTH CHARACTER IS ZERO)
                              </span>
                            </div>
                          ) : (
                            <div
                              className=" mt-1 flex flex-col"
                              style={{ fontSize: "8px" }}
                            >
                              <span className="font-bold">
                                : {props.bankBranch.bank}
                              </span>
                              <span className="font-bold">
                                : {props.bankBranch.branch}
                              </span>
                              <span className="font-bold">
                                : {props.bankBranch.accNo}
                              </span>
                              <span className="font-bold">
                                : {props.bankBranch.ifsc}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Pay by phone */}
                    <p className="text-xs bold" style={{ fontSize: "8px" }}>
                      {WE_USING_PAYPHONE}
                    </p>
                  </div>
                </div>
                {/* Bannk Detail End */}

                {/* In word */}
                <div className="flex flex-col justify-start items-start  font-san ">
                  <p style={{ fontSize: "8px" }} className="pl-2 text-bold">
                    Amount Chargeable (in words)
                  </p>
                  <p
                    className="text-bold font-mono mt-1 pb-2   tracking-widest w-full text-left  pl-2"
                    style={{ fontSize: "10px" }}
                  >
                    {/* Four Thousand Four Hundred Fifty */}
                    {props?.data?.totalInWords} ONLY
                  </p>
                </div>
                {/* terms and condition */}
                <div className="flex  flex-row text-xs  items-start	 justify-between font-sans border-double border-spacing-2 border-black border-t-4">
                  <div className="text-left flex flex-col pl-2">
                    <p
                      className="border-black border-b-2 w-28 pb-1 font-semibold"
                      style={{ fontSize: "7px" }}
                    >
                      Terms & Conditions
                    </p>
                    <p
                      className="text-xs font-semibold"
                      style={{ fontSize: "7px" }}
                    >
                      {InvoicSubject}
                    </p>
                  </div>
                  <div
                    className="text-left text-xs font-semibold"
                    style={{ fontSize: "7px" }}
                  >
                    E. & O.E.
                  </div>
                  <div
                    className="text-left text-xs font-semibold "
                    style={{ fontSize: "7px" }}
                  >
                    FOR,
                  </div>
                  {/* Extra informations */}
                  <div
                    className="text-lg font-bold text-right pr-2"
                    style={{ fontSize: "7px" }}
                  >
                    {KIRTIROADLINES}
                    <div className="text-left ml-2 text-xs 	flex items-end justify-end">
                      <img src={sign} alt="signature" width={90} />
                    </div>
                  </div>
                </div>
                <div className="flex border-black border-b-2 pb-1 flex-row justify-between items-start mx-2 font-sans text-xs	">
                  <div
                    className="text-left font-semibold "
                    style={{ fontSize: "7px" }}
                  >
                    <p>
                      {PaymentbypayeeCchequeDrafs} {KIRTIROADLINES}
                    </p>
                  </div>
                  <div>
                    <p className="font-bold" style={{ fontSize: "7px" }}>
                      {AuthorizedSignatory}
                    </p>
                  </div>
                </div>

                <span
                  className={`${
                    props.mobilePrint
                      ? " text-center text-xs pb-1  font-semibold "
                      : `text-xs mt-0 mb-0 text-center font-bold rounded-md font-sans ${Tablebg(
                          props.blackWhite
                        )} `
                  }`}
                  style={{ fontSize: "5px" }}
                >
                  {computerGeneratedInvoice}
                </span>
              </div>
            </div>
          </div>
          <div className=" flex flex-row justify-center space-x-5 mt-10">
            <style style={{ fontSize: "10px" }}>{pageStyle}</style>
            {/* <ReactToPrint
              documentTitle={
                `Invoice of ` +
                props?.data?.transporterName +
                "-" +
                (props?.data?.invoiceNo || props?.data?.bookingInvoiceNo
                  ? props?.data?.invoiceNo
                    ? props?.data?.invoiceNo
                    : props?.data?.bookingInvoiceNo
                    ? props?.data?.bookingInvoiceNo
                    : "--"
                  : "--")
              }
              trigger={() => <ButtonWithBG label="Print"></ButtonWithBG>}
              content={() => componentRef.current}
            /> */}
            <ButtonWithBG onClick={()=>handleDownloadPdf('download')} label="Download" />
            <ButtonWithBG onClick={()=>handleDownloadPdf('print')} label="Print" />

          </div>
        </>
      )}
    </>
  );
};

export default CommissionInvoiceViewDownload;

// pdfDocGenerator.getBlob((dataBlob) => {
//   const url = URL.createObjectURL(dataBlob);

//   const a = document.createElement("a");
//   a.href = url;
//   a.download = fileName;
//   document.body.appendChild(a);
//   a.click();

//   document.body.removeChild(a);
//   URL.revokeObjectURL(url);

//   // history.push("/bilty/billty-invoice-view");
//   // setIsLoading(false);
// });

// const pdfDocGenerator = pdfMake.createPdf(pdfDocDefinition);

// pdfDocGenerator.getBlob((dataBlob) => {
//   const url = URL.createObjectURL(dataBlob);

//   // Open the PDF in a new tab
//   window.open(url, "_blank");

//   URL.revokeObjectURL(url);
// });
