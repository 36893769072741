import moment from "moment";

const billingDetails = async (invoice, profileData) => {
  const billingDetails = [
    {
      columns: [
            {
              alignment: "left",
              margin: [0, 0, 0, 0], // Set margin to 0
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 100,
                      h: 25,
                      r: 5,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  margin:[0,7,0,0],
                  relativePosition: { x: 20, y: -26 },
                  text: "INVOICE TO",
                  color: "#000",
                  fontSize: 11,
                  bold: true,
                },
              ],
            },
            {
              margin: [0, 0, 0, 0], // Set margin to 0
              alignment: "right",
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 100,
                      h: 25,
                      r: 5,
                      lineColor: "black",
                    },
                  ],
                },
                {
                  margin:[0,7,0,0],
                  alignment: "right",
                  relativePosition: { x: -15, y: -26 },
                  text: "OUR DETAILS",
                  color: "#000",
                  fontSize: 11,
                  bold: true,
                },
              ],
      },
    ]
    },
    {
      columns: [
        {
          width: 300,
          stack: [
            {
              text: `${invoice?.companyDetails?.name?.toUpperCase()}`,
              color: "#000",
              fontSize: 11,
              bold: true,
              margin: [0, 5, 0, 0],
            },
            {
              text: `ADDRESS :${invoice?.companyDetails.billToAddress}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
            },
            {
              text: `GST : ${invoice?.companyDetails.gst ? invoice?.companyDetails?.gst?.toUpperCase() : "-"}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
            },
          ],
        },
        {
          text: "",
          width: "*",
        },
        {
          alignment: "right",
          width: 180,
          stack: [
            {
              text: `SERVICE : ${profileData?.service}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `PAN NO. : ${profileData?.panNo}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `STATE CODE : ${profileData?.stateCode}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              canvas: [
                {
                  type: "line",
                  x1: 200,
                  y1: 5,
                  x2: 20,
                  y2: 5,
                  lineWidth: 2,
                },
              ],
              margin: [0, 0, 0, 2],
            },
            {
              text: `INVOICE NO : ${
                invoice.invoiceNo || invoice?.bookingInvoiceNo
                  ? invoice?.invoiceNo
                    ? invoice?.invoiceNo
                    : invoice?.bookingInvoiceNo
                    ? invoice?.bookingInvoiceNo
                    : "--"
                  : invoice?.invoiceNo
                  ? invoice?.invoiceNo
                  : "--"
              }`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `DATE : ${moment().format("DD-MM-YYYY")}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
          ],
        },
      ],
    },
  ];
  return billingDetails;
};

const billingTable = async (invoice) => {
  const addEmptyRows = (count) => {
    let rows = [];
    for (let index = 0; index < count; index++) {
      let row = [
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
        {
          text: "",
          bold: true,
          fontSize: 9,
          alignment: "center",
          border: [false, false, false, false],
        },
      ];
      rows.push(row);
    }

    return rows;
  };
  const mappingOfData = () => {
    const rows = invoice.booking.map((data, index) => {
      return [
        {
          text: `${moment(data?.loadingDate).format("DD-MM-YYYY")}`,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [false, false, true, false],
        },
        {
          text: data?.from?.toUpperCase(),
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.to?.toUpperCase(),
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.freigth ? data?.freigth : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.hamali ? data?.hamali : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.detention ? data?.detention : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.extraCharge ? data?.extraCharge : 0,
          bold: true,
          fontSize: 9,
          alignment: "center",
          border: [true, false, false, false],
        },
      ];
    });
    return rows;
  };
  const rowCount = 6 - +mappingOfData().length;
  const tableBody =
    mappingOfData().length > 6
      ? mappingOfData()
      : [...mappingOfData(), ...addEmptyRows(rowCount)];
  const tableBody1 = [
    [
      {
        text: "LOADING DATE",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [false, true, true, true],
      },
      {
        text: "FROM",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "TO",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "FREIGHT",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "HAMALI",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "DETENTION",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "EXTRA CHARGE",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [true, true, false, true],
      },
    ],
    ...tableBody,
  ];

  const table = {
    heights: 25,
    widths: ["*", "*", "*", "*", "*", "*", "*"],
    headerRows: 1,
    fontSize: 9,
    bold: true,
    margin: [0, 10, 0, 10],
    body: tableBody1,
  };

  const content = [
    // Other content
    {
      table,
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 ? 1 : 0.5; // Set to 1 for the bottom border of each cell in the first row, 0.5 for other rows
        },
        hLineColor: function (i) {
          return i === 0 ? "#000" : "#000"; // Set color for the bottom border of each cell in the first row
        },
        paddingTop: function () {
          return 4; // Adjust padding as needed
        },
        paddingBottom: function () {
          return 4; // Adjust padding as needed
        },
        vLineWidth: function (i) {
          return 1; // Set to 0 for no vertical borders
        },
        vLineColor: function () {
          return "#000"; // Set to white or your background color
        },
      },
    },
    // Other content
  ];

  return content;
};

export const CompanyBillingLayout = async (
  invoice,
  WE_USING_PAYPHONE,
  bankBranch,
  profileData
) => {
  // console.log(invoice, "invoice");
  // console.log(profileData, "profileData");
  // console.log(bankBranch, "bankBranch");
  const BillingInfo = await billingDetails(invoice, profileData);

  const BillingTabDATA = await billingTable(
    invoice,
    WE_USING_PAYPHONE,
    bankBranch,
    profileData
  );

  return [
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
    BillingInfo,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
    BillingTabDATA,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
  ];
};
