import React from "react";
import {
  AuthorizedSignatory,
  GPAYCONTENT,
  InvoicSubject,
  KIRTIROADLINES,
  OURBANKDETAILS,
  PaymentbypayeeCchequeDrafs,
  SCANQRCONTENT,
  WE_USING_PAYPHONE,
  computerGeneratedInvoice,
} from "../../../Utils/Constants";
import qrcode from "../../../Images/qrcode.jpg";
import { Tablebg, borderToggle } from "../../../Utils/TableStyling";
import sign from "../../../Images/sign.png";

const PrintingFooter = (props) => {
  return (
    <>
      {/* Bannk Detail Start */}
      <div className="flex flex-row justify-between font-sans font-semibold text-left text-xs mx-2">
        {!props.noBankDetails && (
          <div className="mt-2 ">
            <div className="flex flex-row space-x-2 ">
              <div
                className={`px-5 py-2 leading-snug	text-center  rounded-lg uppercase ${Tablebg(
                  props.blackWhite
                )}`}
              >
                <p className="tracking-wide text-sm">{OURBANKDETAILS}</p>
                <p style={{ fontSize: "11px" }}>( {SCANQRCONTENT} )</p>
              </div>
            </div>
            <div>
              <div className="flex flex-row space-x-1 ">
                {/* QR code */}
                <div className="mt-1">
                  <img width={95} src={qrcode} alt="Kirti Roadlines" />
                </div>
                <div className="space-y-1 mt-2">
                  <h5>Bank Name</h5>
                  <h5>Branch</h5>
                  <h5>A/c. No</h5>
                  <h5>IFSC Code</h5>
                </div>
                {props?.Dropdown === false ? (
                  <div className="space-y-1 mt-2">
                    <h5>: State Bank of India</h5>
                    <h5>: Prahlad Nagar Ahmedabad</h5>
                    <h5>: 005167983000351</h5>
                    <h5>: SBIT0CKMNSB (FIFTH CHARACTER IS ZERO)</h5>
                  </div>
                ) : (
                  <div className="space-y-1 mt-2">
                    <h5>: {props?.bankBranch?.bank}</h5>
                    <h5>: {props?.bankBranch?.branch}</h5>
                    <h5>: {props?.bankBranch?.accNo}</h5>
                    <h5>: {props?.bankBranch?.ifsc}</h5>
                  </div>
                )}
              </div>
              <p className="text-xs">{WE_USING_PAYPHONE}</p>
            </div>
          </div>
        )}

        {!props.noTotal && (
          <div
            className={`flex flex-row  justify-end mt-4 border-t-2 w-1/3 ${borderToggle(
              props.blackWhite
            )}`}
          >
            <div className="space-y-1  mt-2  text-right w-32">
              {!props.noBalance && <h5 className="pr-2 font-bold">BALANCE :</h5>}
              {props.fright && <h5 className="pr-2 font-bold">FREIGHT :</h5>}
              <div className="h-14 font-bold">
                {props?.data?.totalHamali > 0 && (
                  <h5 className=" pr-2 font-bold">Hamali :</h5>
                )}
                {props?.data?.totalDetention > 0 && (
                  <h5 className="pr-2 font-bold">Detention :</h5>
                )}
                {props?.data?.totalExtraCharge > 0 && (
                  <h5 className=" pr-2 font-bold">Extra Charge :</h5>
                )}
              </div>
              <h5
                className={`pr-2 text-sm px-2 py-2 tracking-wide text-md whitespace-nowrap rounded-l-lg  w-full uppercase font-bold ${Tablebg(
                  props.blackWhite
                )}`}
              >
                Grand Total
              </h5>
            </div>
            <div className="space-y-1 mt-2 text-right w-28 font-bold">
              {!props.noBalance && (
                <h5>
                  &#8377;{" "}
                  {props?.data?.totalFreight ? props?.data?.totalFreight : 0}
                </h5>
              )}
              {props.fright && (
                <h5>
                  &#8377;{" "}
                  {props?.data?.totalFreight ? props?.data?.totalFreight : 0}
                </h5>
              )}
              <div className="h-14">
                {props?.data?.totalHamali > 0 && (
                  <h5>
                    &#8377;{" "}
                    {props?.data?.totalHamali ? props?.data?.totalHamali : 0}
                  </h5>
                )}
                {props?.data?.totalDetention > 0 && (
                  <h5>
                    &#8377;{" "}
                    {props?.data?.totalDetention
                      ? props?.data?.totalDetention
                      : 0}
                  </h5>
                )}
                {props?.data?.totalExtraCharge > 0 && (
                  <h5>
                    {" "}
                    &#8377;{" "}
                    {props?.data?.totalExtraCharge
                      ? props?.data?.totalExtraCharge
                      : 0}
                  </h5>
                )}
              </div>
              <h5
                className={`border-l-2 text-sm whitespace-nowrap rounded-r-lg pr-2 px-5 py-2 tracking-wide b-r-2 w-full uppercase ${Tablebg(
                  props.blackWhite
                )}`}
              >
                &#8377; {props?.data?.grandTotal ? props?.data?.grandTotal : 0}
              </h5>
            </div>
          </div>
        )}
      </div>

      {/* Extra Information */}
      <div
        className={`flex flex-col justify-start items-start mx-2 font-sans  border-t-2 ${borderToggle(
          props.blackWhite
        )} `}
      >
        <p>Amount Chargeable (in words)</p>
        <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
          {/* Four Thousand Four Hundred Fifty */}
          {props.totalInwords}
          {""}ONLY
        </p>
      </div>
      {/* terms and condition */}
      <div className="flex  flex-row text-sm	 justify-between mt-2 mx-2 font-sans">
        <div className="text-left flex flex-col space-y-1">
          <p className="border-black border-b-4 w-40 pb-1">
            Terms & Conditions
          </p>
          <p className="text-xs">{InvoicSubject}</p>
        </div>
        <div className="text-left text-sm">E. & O.E.</div>
        <div className="text-left text-sm ">FOR,</div>
        {/* Extra informations */}
        <div className="text-lg font-bold text-right ">
          {KIRTIROADLINES}
          <div className="text-left ml-2 text-sm	flex justify-end">
            <img src={sign} alt="signature" width={100} />
          </div>
        </div>
      </div>
      {/* gpay*/}
      {props.gpayContent && (
        <div className="text-left ml-2 text-xs	">
          {GPAYCONTENT} ({KIRTIROADLINES})
        </div>
      )}
      <div className="flex border-black border-b-2 flex-row justify-between items-start mx-2 mb-2 font-sans text-xs	">
        <div className="text-left ">
          <p>
            {PaymentbypayeeCchequeDrafs} {KIRTIROADLINES}
          </p>
        </div>
        <div>
          <p className="font-bold">{AuthorizedSignatory}</p>
        </div>
      </div>

      <h2
        className={`text-xs mt-0 mb-0 text-center font-bold ${Tablebg(
          props.blackWhite
        )} rounded-md p-2 font-sans`}
      >
        {computerGeneratedInvoice}
      </h2>
    </>
  );
};

export default PrintingFooter;