import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { APIURL } from "../../Utils/Constants";
// import { BOOKING_PENDING_DATA } from "../../Utils/Constants";
import Tooltip from "react-simple-tooltip";
import axiosInstance from "../../Utils/API";
import {
  BOOKING_PAYMENT_ALL,
  UPDATE_BUILTY_AVAILABLE
} from "../../Utils/ApiGlobal";
import { BUFFERING_SVG } from "../../Utils/SVGListing";
import { arrStringSorting } from "../Functionality/Helper";
import PaymentsStatusPhill from "../Functionality/PaymentsStatusPhill";
import Badge from "../UI/Badge/index";
import Checkbox from "../UI/Checkbox";
import { ConfirormationModal } from "../UI/ConfirormationModal";
import Loader from "../UI/Loader";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
// import { UPDATE_BUILTY_AVAILABLE } from "../../Utils/ApiGlobal";
// import axiosInstance from "../../Utils/API";
// import { ConfirormationModal } from "../UI/ConfirormationModal";

export default function PendingBooking(props) {
  const [ApiData, SetApiData] = useState([]);
  const [ApiDataCount, SetApiDataCout] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  var Today_Date = new Date();

  useEffect(() => {
    getAPI();
    // eslint-disable-next-line
  }, [props.searchValue, ApiDataCount,props.dateFilter]);

  // GET API
  const getAPI = () => {
    // setIsLoading(true);
    props?.setLoading(true);
    axios
      .get(
        `${APIURL}api/Booking/ViewBooking?search=${props.searchValue}${(props.dateFilter !== undefined && props.dateFilter !== null )? moment(props?.dateFilter).format("YYYY-MM-DD"):""}&pageNo=&pageSize=`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        SetApiDataCout(response.data.data.count);
        SetApiData(response.data.data.booking);
        // console.log(response.data.data.booking)
        setIsLoading(false);
        setActionLoading(false);
      });
  };

  // Builtty checkbox APIS
  const postBuilltyRecive = (bookingId, builltyValue) => {
    setActionLoading(true);
    let builltyParams = {
      BookingId: bookingId,
      IsBilltyAvailable: builltyValue,
      billtyReceiveDate: Today_Date,
    };
    // console.log(builltyParams,"builltyParams")
    // setIsLoading(true);
    axiosInstance
      .post(UPDATE_BUILTY_AVAILABLE, builltyParams)
      .then((response) => {
        getAPI();
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };

  // Change Payment status
  const UpdatePaymentMethod = async (e, id, LrNo) => {
    // console.log(e)
    // console.log(id)
    // console.log(type)
    // setIsLoading(true);
    // props?.ownerId
    // debugger

    setActionLoading(true);
    let paymentData = {
      BookingId: id,
    };

    await axiosInstance
    .post(BOOKING_PAYMENT_ALL, paymentData)
    .then((response) => {
      getAPI();
      // console.log(response);
    })
    .catch((error) => {
      ConfirormationModal(error.message, "error", 2000);
    });
  };
  // Columns
  const columns = [
    {
      Header: "IS Buillty Recived",
      accessor: "isBiltyReceive",
      id: "isBiltyReceive",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div className="mt-2 hover:cursor-pointer">
          {actionLoading ? (
            <span>{BUFFERING_SVG} </span>
          ) : (
            <Checkbox
              defaultChecked={row.row.original.isBiltyReceive}
              onClick={(e) => {
                postBuilltyRecive(row.row.original.bookingId, e.target.checked);
                // console.log(e.target.checked,"row.row.original")
              }}
              className="accent-red-600	text-white hover:cursor-pointer"
            />
          )}
        </div>
      ),
    },
    {
      Header: "Action",
      id: "Action",
      maxWidth: 50,
      minWidth: 50,
      width: 50,
      Cell: (row) => (
        <div>
          <TableAction
            isBilling
            isBuillty
            data={row.row.original}
            redirectPath="pending-booking/update-booking"
            redirectViewPath="pending-booking/booking-data-view"
            redirectBuillty="bilty/billty-invoice-view"
            redirectBilling="billing/billing-view"
            component="Booking"
            getAPI={getAPI}
          />
        </div>
      ),
    },
    {
      Header: "Payment Status",
      id: "Payment Status",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => {
        const selectedRow = row?.row?.original;
        const unPaidCompany = selectedRow?.lrDetails?.filter(
          (x) => x.isCompanyPaid === false ? x : null
        )
        const isCompanyPaid =
          selectedRow?.lrDetails?.length === 0
            ? selectedRow?.isCompanyPaid
            : unPaidCompany?.length === 0 ? true : false;
        return (
          <PaymentsStatusPhill
            onClickEvent={(e)=>UpdatePaymentMethod(e, row?.row?.original?.bookingId, row?.row?.original?.lrNo?.split(",")[0])}
            defaultValue={row?.row?.original?.isPaid && isCompanyPaid}
            id={row?.row?.original?.bookingId}
            type={row?.row?.original?.type}
            LrNo={row?.row?.original?.lrNo?.split(",")[0]}
            label="Booking"
          />
        )
      },
    },
    {
      Header: "L. Date",
      accessor: (d) => {
        return moment(d.loadingDate).format("DD-MM-YYYY");
      },
      maxWidth: 80,
      minWidth: 80,
      width: 80,
    },
    {
      Header: "Vehicle No.",
      accessor: "vehicleNo",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Vehicle Owner",
      accessor: "vehicleOwner",
      Cell: ({row,value,i}) => (
        <Tooltip
          className="w-44"
          border="#ed1c24"
          color="black"
          background="#fabbbd"
          content={value}
          radius={5}
          placement={row.index === 0 ?"bottom":"top"}
          zIndex={100}
          padding={10}
          arrow={5}
        >
          <p className="line-clamp-1  whitespace-nowrap truncate cursor-pointer text-left	w-48 ml-4 ">
            {value}
          </p>
        </Tooltip>
      ),
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    // {
    //   Header: "Company Name",
    //   accessor: "Company_Name",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Address",
    //   accessor: "Address",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "GST No.",
    //   accessor: "Gst_No",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    {
      Header: () => <span className="text-left">Consignor's Name</span>,
      accessor: "consignerName",
      Cell: ({row,value,i}) => (
        <Tooltip
          className="w-44"
          border="#ed1c24"
          color="black"
          background="#fabbbd"
          content={value}
          radius={5}
          placement={row.index === 0 ?"bottom":"top"}
          zIndex={100}
          padding={10}
          arrow={5}
        >
          <p className="line-clamp-1  whitespace-nowrap truncate cursor-pointer text-left	w-44 ml-4 ">
            {value}
          </p>
        </Tooltip>
      ),
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      //   <Tooltip style={{maxWidth:"350px"} }  placement="top-start" offset={15} className={TooltipStlyeForData} content={value}>
      //   <p
      //     className="line-clamp-1 cursor-pointer text-left	ml-4"
      //     onClick={(e) => handleClick(e, row)}
      //   >
      //     {value}
      //   </p>
      // </Tooltip>
    },
    // {
    //   Header: "Consignor's Address",
    //   accessor: "consignerAddress",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Consignor's GST No.",
    //   accessor: "consignerGst",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    {
      Header: "Consignee's Name",
      accessor: "consigneeName",
      Cell: ({ value, row }) => (
        <Tooltip
          border="#ed1c24"
          color="black"
          background="#fabbbd"
          content={value}
          radius={5}
          placement={row.index === 0 ?"bottom":"top"}
          zIndex={100}
          padding={10}
          arrow={5}
        >
          <p className="line-clamp-1 whitespace-nowrap truncate cursor-pointer text-left	ml-4 w-48">
            {value}
          </p>
        </Tooltip>
      ),
      maxWidth: 250,
      minWidth: 250,
      width: 250,
    },
    // {
    //   Header: "Consignee's Address",
    //   accessor: "consigneeAddress",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Consignee's GST No.",
    //   accessor: "consigneeGst",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "Packages",
    //   accessor: "packages",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Actual Weight",
    //   accessor: "actualWeight",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Charged Weight",
    //   accessor: "chargedWeight",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Rate",
    //   accessor: "Rate",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    {
      Header: "From",
      accessor: "from",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 110,
      minWidth: 110,
      width: 110,
    },
    {
      Header: "To",
      accessor: "to",
      Cell: (row) => <div className="text-left">{row.value}</div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    // {
    //   Header: "Delivery At",
    //   accessor: (d) => {
    //     return d.to;
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    {
      Header: "BOOKING AMOUNT",
      accessor: "bookingAmount",
      Cell: (row) => {
        // debugger
        const selectedRow = row?.row?.original;
        const unPaidCompany = selectedRow?.lrDetails?.filter(
          (x) => x.isCompanyPaid === false ? x : null
        )
        const isCompanyPaid =
          selectedRow?.lrDetails?.length === 0
            ? selectedRow?.isCompanyPaid
            : unPaidCompany?.length === 0 ? true : false;
        return (
          actionLoading ? (
            <span>{BUFFERING_SVG} </span>
          ) : (<Badge isPaid={row.row.original.isPaid && isCompanyPaid} value={row.value === 0 ? selectedRow?.lrDetails?.map((x) => x.bookingAmount).join(", ") : row.value} />)
        )
      },
      maxWidth: 80,
      minWidth: 80,
      width: 80,
    },
    {
      Header: "LR No.",
      accessor: "lrNo",
      Cell: (row) => (
        <p>{arrStringSorting(row.row.original.lrNo).join(", ")}</p>
      ),
      maxWidth: 80,
      minWidth: 80,
      width: 80,
    },
    {
      Header: "Invoice No.",
      accessor: "bookingInvoiceNo",
      maxWidth: 80,
      minWidth: 80,
      width: 80,
    },
    // {
    //   Header: "Bill No.",
    //   accessor: "billNo",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Fright",
    //   accessor: "freigth",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Advance",
    //   accessor: "advance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Balance",
    //   accessor: "balance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Hamali",
    //   accessor: "hamali",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Detention",
    //   accessor: "detention",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Extra Charge",
    //   accessor: "extraCharge",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Total",
    //   accessor: "total",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Vehicle Owner",
    //   accessor: "Vehicle_Owner",
    //   maxWidth: 200,
    //   minWidth: 200,
    //   width: 200,
    // },
    // {
    //   Header: "DRIVER PAID BALANCE",
    //   accessor: "isDriverPaidBalance",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BALANCE PAID BILL NO.",
    //   accessor: "balanceInvoiceId",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "BILLTY RECEVIED OR NOT",
    //   // isBiltyReceive
    //   accessor: (d) => {
    //     if (d.isBiltyReceive === true) {
    //       return "Yes";
    //     } else {
    //       return "No";
    //     }
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Note",
    //   accessor: "note",
    //   style: {
    //     fontWeight: "bolder",
    //   },
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
    // {
    //   Header: "Description",
    //   accessor: "Description",
    //   maxWidth: 100,
    //   minWidth: 100,
    //   width: 100,
    // },
  ];

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          <div className="mt-4 ">
            <TablePagination data={ApiData} columns={columns} />
          </div>
        </section>
      )}
    </>
  );
}
