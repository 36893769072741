import React from 'react'
import PropTypes from 'prop-types'

export const Tab = ({ label, isActive, ...rest }) => <button
    className={` rounded-lg mr-5 w-20 px-3 py-4 text-sm font-medium leading-5 text-black-700 ring-primary-kirtiroadways-hover ring-opacity-60 ring-offset-2 ring-offset-kirtiroadways-hover focus:outline-none focus:ring-2  ${isActive  ? "bg-white shadow text-primary-kirtiroadways font-bold"
    : "text-primary-kirtiroadways hover:bg-primary-kirtiroadways/[0.12] "} `}
    {...rest}
>{label}</button>

function TabInterface({ tabs, handleSwitchTab, activeTabIndex }) {
    return (
        <div
            className="bg-gray-100 shrink"
        >
            {tabs.map(({ id, label }, i) => (
                <Tab
                    key={id}
                    isActive={activeTabIndex === i}
                    onClick={() => handleSwitchTab(i)} label={label}
                />
            ))}
        </div>
    )
}

export default TabInterface

TabInterface.propTypes = {
    tabs: PropTypes.array, // array of objects
    handleSwitchTab: PropTypes.func.isRequired, // returns tab index
}