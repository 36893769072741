import React, { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { Lendger } from "../../Utils/Constants";
import TablePagination from "../UI/Table/TablewithPagination";
import moment from "moment";

export default function KirtiLedgerTable(props) {
  // for Search
  // const location = useLocation();

  const columns = [
    {
      Header: "#",
      accessor: (d, index) => {
        return index + 1;
      },
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },
    {
      Header: "Type",
      accessor: (d) => {
        // if (d.creditOrDebit === "Credit") {
        //   return "Payment-In";
        // } else {
        //   return "Payment-Out";
        // }
      },
      Cell: (row) =>
            <div className="truncate flex flex-row justify-start items-center space-x-4">
              {row.row.values.Credit > 0 ? 
                  <p className="h-2 w-2 rounded-xl bg-primary-kirtiroadways"></p>
                  : 
                  <p className="h-2 w-2 rounded-xl bg-orange-500"></p>
                  }
              <div>{row.row.values.Credit > 0 ? "Payment-In" : "Payment-Out"}</div>
            </div>,
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Date",
      accessor: (d) => {
        return moment(d.createdAt).format("DD-MM-YYYY");
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="font-semibold text-left ">Total Amount:</span>,
    },
    {
      Header: "Bank Account No",
      accessor: "bankAccountNo",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "Bank Branch",
      accessor: "bankBranchName",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Ledger Type",
      accessor: "ledgerType",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Vehicle No.",
      accessor: "vehicleNo",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Note",
      accessor: "note",
      Cell: (row) => <div className="truncate">{row.value}</div>,
      maxWidth: 50,
      minWidth: 50,
      width: 50,
    },

    {
      Header: "Debit",
      accessor: (d) => {
        if (d.creditOrDebit === "Debit") {
          return d.amount;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{props.totalDebit}</span>,
    },
    {
      Header: "Credit",
      accessor: (d) => {
        if (d.creditOrDebit === "Credit") {
          return d.amount;
        } else {
          return 0;
        }
      },
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{props.totalCredit}</span>,
    },
    {
      Header: "Balance",
      accessor: "amount",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Footer: <span className="">{props.totalPayment} Dr</span>,
    },
  ];

  useEffect(() => {
    // console.log(props)
    // console.log(location?.state?.data?.data);
  }); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(props,"for table")
  return (
    <section>
      <div className="mt-4">
        {props.error ? (
          <p className="text-warn-error">No data found</p>
        ) : (
          <TablePagination
            maxHeight={400}
            data={props.ledgerData}
            columns={columns}
            noPagination
            isFooter
          />
        )}
      </div>
    </section>
  );
}
