import React from "react";
import PrintBlankInvoice from "../../Components/UI/PrintBlankInvoice";
import logo from "../../Images/logo_transparent.png";
import {
  CAUTION,
  CAUTIONContnet,
  CompanyName,
  CompanyResponsible,
  // ConsigneeNameAdd,
  CONSIGNMENTNOTE,
  CustomerDeclaration,
  // email,
  emailID,
  InvoiceDeclartion,
  InvoicSubject,
  KIRTIROADLINES,
  InvoiceBilltyNote,
  KIRTIROADLINESTAGLINE,
  // Mo,
  Mo1,
  Mo2,
  Mo3,
  OfficeAdd,
  OfficeNo,
  OwnerRiskInsurance,
  PANNo,
  receiptDeclaration,
  TermsandCondition,
  TermsandConditionNote,
  THANKYOUFORBUSSINESSWITHUS,
  ServiceTaxLiabilityConsignor,
  // ConsignorName,
  // Name,
  Address,
} from "../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
// import InvoicePrint from "../../Components/UI/InvoicePrint";

const BlankBuillty = () => {
  const invoiceFor = ["CONSIGNOR", "CONSIGNEE", "DRIVER"];
  return (
    <> 
      <div style={{minWidth: "1240px"}}>
      {invoiceFor.map((copyFor,index) => (
        <div key={index} className="block m-2  border-double border-4 border-red-200">
          <div className="flex  flex-row justify-between font-bold sm:mx-8 mx-2 mb-3 sm:text-sm text-xs">
            <h5>{InvoicSubject}</h5>
            <h5>{InvoiceDeclartion}</h5>
          </div>
         {/* Logo and Title Start */}
         <div className="m-2 items-center space-x-3 flex flex-row">
            <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
              <div className="flex flex-row justify-between">
                <div className="w-80 ml-2">
                  <img src={logo} alt="Kirti Roadlines" />
                </div>
                <div className=" flex flex-col ">
                  <span className="text-8xl text-primary-kirtiroadways font-extrabold ">
                    {KIRTIROADLINES}
                  </span>
                  <span className="text-4xl font-serif text-black">
                    {KIRTIROADLINESTAGLINE}
                  </span>
                </div>
              </div>
              {/* Address Start */}
              <div className="rounded-b-3xl rounded-t-lg font-bold   p-2 w-full  bg-gradient-to-r from-red-200 to-white-900 flex flex-col space-y-1 text-center text-sm">
                <p className="flex  text-md justify-center flex-row items-start">
                  <span className="text-md w-5">{address_svg}</span>{" "}
                  <span className="text-md ml-1">
                    {OfficeNo}
                    {OfficeAdd}
                  </span>
                </p>
                <div className="  flex space-x-4 justify-center flex-row items-start ">
                  <div className="flex space-x-1 text-md justify-center flex-row items-center ">
                    <span>{call_svg} </span>{" "}
                    <span>
                      {Mo1}, {Mo2}, {Mo3}
                    </span>
                  </div>
                  <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                    <span>{email_svg} </span>
                    <span>{emailID}</span>
                  </div>
                </div>
              </div>
              {/* Address End */}
            </div>
          </div>
          {/* Logo and Title End */}

          <div className="font-md flex flex-row p-4">
            <div
              className="
                shadow-sm 
                text-sm 
                border 
                flex
                flex-row rounded-x-md w-full divide-x border-neutral-400 divide-slate-500"
            >
              <div className="w-1/3 p-2">
                <h1 className="font-bold underline text-lg">{CAUTION}</h1>
                <ul className="text-justify font-serif text-xs ml-5 mr-5 list-outside">
                  <li className="mt-2">{CAUTIONContnet}</li>
                  <li>{receiptDeclaration}</li>
                </ul>
              </div>

              <div className="flex flex-col divide-y border-neutral-400 divide-slate-500 w-2/3">
                <div className="h-1/5 flex flex-row divide-x border-neutral-400 divide-slate-500">
                  <div className="self-center w-1/2 font-bold text-lg">
                    {copyFor} COPY
                  </div>
                  <div className="w-1/2 ">
                    <h1 className="font-bold text-lg underline">
                      {OwnerRiskInsurance}
                    </h1>
                    <h4 className="text-xs">{CustomerDeclaration}</h4>
                  </div>
                </div>

                <div className="h-4/5 flex flex-row divide-x  border-neutral-400 divide-neutral-500">
                  <div className="w-1/2">
                    <div className="pt-3 w-full ">
                      <h1 className="underline font-bold text-lg text-primary-kirtiroadways">
                        {CONSIGNMENTNOTE}
                      </h1>
                      <table className="mt-3  border-collapse text-base border-t border-neutral-400  w-full">
                        <thead>
                          <tr>
                            <th className=" border-neutral-400 p-2" colSpan="2">
                              <span className="float-left">Delivery At. :</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="w-1/2 border-t border-r border-neutral-400 p-2">
                              <span className="float-left">LR No. :</span>
                            </td>
                            <td className="border-t  border-neutral-400 p-2">
                              <span className="float-left">LR Date : </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-1/2 border-t border-r border-neutral-400  p-2">
                              <span className="float-left">Fin. Year :</span>
                            </td>
                            <td className="border-t  border-neutral-400 p-2 border-b md:border-b-0 ">
                              <span className="float-left">Lorry No. :</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="w-1/2">
                    <div className="pt-3 w-full ">
                      <h1 className="underline font-bold text-lg">{PANNo}</h1>
                      <table className="mt-3 border-collapse text-base border-t border-neutral-400  w-full">
                        <thead>
                          <tr>
                            <th
                              className="border-y border-neutral-400 p-2"
                              colSpan="2"
                            >
                              <span className="float-left">
                                {CompanyName} :
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colSpan="2"
                              className="w-1/2 border-neutral-400 p-2"
                            >
                              <span className="float-left">Policy No. :</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="w-1/2 border-t border-r border-neutral-400 p-2">
                              <span className="float-left">Date :</span>
                            </td>
                            <td className="border-t  border-neutral-400 p-2">
                              <span className="float-left">Amount :</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Consignore and consignee Start*/}
          <div
            className="mx-4 mb-4 border-gray-600 shadow-sm border rounded-l-md  text-sm rounded-r-md
                                flex flex-col "
          >
            <table className="border-collapse text-base   ">
              <tbody>
                <tr>
                  <td className="md:w-1/2  border-b border-r border-neutral-400 p-1">
                    <span className="float-left font-bold">
                      <span className="text-primary-kirtiroadways font-bold ">
                        Consignor's Name :
                      </span>{" "}
                    </span>
                  </td>
                  <td className="w-1/2 font-bold border-b  border-neutral-400 p-1">
                    <span className="float-left">
                      <span className="text-primary-kirtiroadways">
                        Consignee's Name :
                      </span>{" "}
                    </span>
                  </td>
                </tr>
                <tr className="align-top">
                  <td className="w-1/2 border-r border-neutral-400 p-1">
                    <div className="float-left flex flex-col space-y-2 text-left mx-0"
                      style={{width: "-webkit-fill-available"}}>
                      <div>
                        <span className="min-w-fit self-start">{Address}</span>{" "}
                      </div>
                      <div className="text-justify border-t border-neutral-400 py-1">
                        <span className="min-w-fit">GST No :</span>{" "}
                      </div>
                    </div>
                  </td>
                  <td className="w-1/2  border-neutral-400 p-1">
                    <span className="float-left flex flex-row space-x-1">
                      <span className="min-w-fit">{Address}</span>
                    </span>
                  </td>
                </tr>
                {/* <tr>
                  <td className="w-1/2 border-t border-r border-neutral-400 p-3">
                    <span className="float-left"></span>
                  </td>
                  <td className="border-t  border-neutral-400 p-2">
                    <span className="float-left"></span>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          {/* Consignore and consignee End*/}

          {/* from and to Start*/}
          <div
            className="mx-4 mb-4 border-gray-600 shadow-sm border rounded-l-md  text-sm rounded-r-md
                                flex flex-col "
          >
            <table className="border-collapse text-base  border-neutral-400  ">
              <tbody>
                <tr>
                  <td className="w-1/2 border-r border-neutral-400 p-2">
                    <span className="float-left font-bold">
                      <span className="text-primary-kirtiroadways font-bold ">
                        From:-
                      </span>{" "}
                    </span>
                  </td>
                  <td className="w-1/2 font-bold  border-neutral-400 p-1">
                    <span className="float-left">
                      <span className="text-primary-kirtiroadways">To:-</span>{" "}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* from and to End*/}

          {/* Table */}
          <div className=" mx-4 mt-4">
            {/* Table Start */}

            <table className="w-full">
              <thead>
                <tr className="border ">
                  <th className="border-2 border-neutral-300">Packages</th>
                  <th className="border-2  border-neutral-300">
                    Description (said to contains)
                  </th>
                  <th className="border-2 border-neutral-300">
                    <h1 className="border-b-2 border-neutral-300">Weight</h1>
                    <div className="w-full flex flex-row justify-evenly text-center">
                      <h1>Actual</h1>
                      <h1>Charged</h1>
                    </div>
                  </th>
                  <th className="border-2 border-neutral-300">Rate</th>
                  <th className="border-2 border-neutral-300">
                    <h1 className="border-b-2 border-neutral-300">
                      Amount to Pay/Paid
                    </h1>
                    <h1>Rs.</h1>
                  </th>
                </tr>
              </thead>
              <tbody className="align-top font-light text-center">
                <tr className="border font-light border-neutral-300">
                  <td
                    rowSpan="2"
                    className="border-2 font-semibold border-neutral-300 items-center"
                  ></td>
                  <td className="font-semibold align-bottom">
                    <h1 className="font-semibold">AS PER INVOICE</h1>
                  </td>
                  <td className="border-2 border-neutral-300">
                    <div className="flex flex-col space-y-12 ">
                      <div className="flex flex-row justify-evenly">
                      </div>
                      <div className="font-semibold">
                        <h1 className="font-semibold border-y-2 border-neutral-300">
                        Company Invoice No.
                        </h1>
                      </div>
                    </div>
                  </td>
                  <td className="flex flex-col space-y-4">
                    <h1 className="font-semibold">Freight</h1>
                    <h1 className="font-semibold">Sur. Ch.</h1>
                    <h1 className="font-semibold">St. Ch.</h1>
                    <h1 className="font-semibold">Risk Ch.</h1>
                  </td>
                  <td className="border-2 border-neutral-300 align-bottom">
                    <h1 className="uppercase font-semibold">TO PAY</h1>
                  </td>
                </tr>
                <tr>
                  <td className="font-semibold border-t-2  border-b-2 border-neutral-300 text-left">
                    <h1 className="ml-1">Private Marks:</h1>
                  </td>
                  <td className="border-x-2 border-b-2 border-neutral-300">
                  </td>
                  <td className="text-md border-r-2 border-b-2 border-t-2 bg-primary-kirtiroadways font-bold border-neutral-300 font-mono  text-center justify-center">
                    <h1>TOTAL</h1>
                  </td>
                  <td className="border-r-2 border-b-2 border-neutral-300 bg-primary-kirtiroadways font-bold text-center justify-center">
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="w-full flex flex-row justify-center mt-2">
              <div className="font-bold text-xs border-2 w-full border-black ">
                <span className="mr-20">To Pay :</span>
              </div>
            </div>
            <div className="font-bold text-xs border border-neutral-400">
              <h1>{InvoiceBilltyNote}</h1>
              <h1>{CompanyResponsible}</h1>
            </div>
          </div>
          {/* Table End */}

          <div className="mx-6 mt-5">
            {/* bottom left and right start */}
            <div className="flex flex-row space-y-0 justify-between text-left">
              <div className="max-w-xl">
                <h1 className="font-bold underline border-neutral-400  text-sm">
                  {TermsandCondition}
                </h1>
                <h6 className="	text-xs text-justify">
                  {TermsandConditionNote}
                </h6>
              </div>
              <div className="font-semibold text-sm text-right">
                <h3>Value: AS PER INVOICE</h3>
                <h3>{ServiceTaxLiabilityConsignor}</h3>
                <h3 className="mt-8 pt-4">For {KIRTIROADLINES}</h3>
              </div>
            </div>
            {/* bottom left and right end */}

            {/* <div className="relative">
                <div className="absolute left-0 h-1 text-center w-full bg-black"></div>
          </div> */}
          </div>

          <div className="border-t-0 border-neutral-400 font-serif	bg-primary-kirtiroadways py-1 text-white  w-full mt-2 font-bold rounded-md">
            <h6>{THANKYOUFORBUSSINESSWITHUS}</h6>
          </div>
        </div>
      ))}
      <PrintBlankInvoice />
      </div>
    </>
  );
};

export default BlankBuillty;
