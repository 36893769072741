import { Disclosure } from "@headlessui/react";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Formik, Form, Field } from "formik";
import PayTo from "../../Components/Functionality/PayTo";
import OtherPartyName from "../../Components/Functionality/OtherPartyName";
import ThirdPartyName from "../../Components/Functionality/ThirdPartyName";

const UpdateLrNo = ({ items, setLrData, childButtonRef }) => {
  const [openDisclosure, setOpenDisclosure] = useState(null);
  const handleDisclosureClick = (itemId) => {
    setOpenDisclosure(itemId === openDisclosure ? null : itemId);
  };

  const desiredObjectStructure = {
    payTo: "",
    consignorAmount: "",
    consigneeAmount: "",
    otherPartyName: "",
    otherPartyGST: "",
    otherPartyAddress: "",
    isThirdPartyAvailable: false,
    partyName: "",
    thirdPartyCommission: "",
    thirdPartyComment: "",
    from: "",
    to: "",
    bookingAmount: "",
    lrNo: "",
    companyInvoiceNumber: "",
  };

  const getInitialValues = (sourceObject) => {
    const newObject = { ...desiredObjectStructure }; // Create a copy of the base structure

    // Map properties from sourceObject to newObject:
    newObject.payTo = sourceObject.paidBy;
    newObject.consignorAmount = sourceObject.consignerAmount;
    newObject.consigneeAmount = sourceObject.consigneeAmount;
    newObject.otherPartyName = sourceObject.otherPartyName;
    newObject.otherPartyGST = sourceObject.otherPartyGst;
    newObject.otherPartyAddress = sourceObject.otherPartyAddress;
    newObject.isThirdPartyAvailable = sourceObject.isThirdPartyPay;
    newObject.partyName = sourceObject.thirdPartyName;
    newObject.thirdPartyCommission = sourceObject.thirdPartyCommission;
    newObject.thirdPartyComment = sourceObject.thirdPartyComment;
    newObject.from = sourceObject.from;
    newObject.to = sourceObject.to;
    newObject.bookingAmount = sourceObject.bookingAmount;
    newObject.lrNo = sourceObject.lrNo;
    newObject.companyInvoiceNumber = sourceObject.companyInvoiceNo;

    return newObject;
  };

  // Create the resulting array:
  const transformedArray = items?.map((item) => getInitialValues(item));

  // console.log(transformedArray);

  const handleSubmit = (newvalues) => {
    const values = newvalues.values;
    let maxIndex = 0;
    Object.keys(values).forEach((key) => {
      const index = key.match(/\d+/);
      if (index !== null && parseInt(index) > maxIndex) {
        maxIndex = parseInt(index);
      }
    });

    // Create arrays based on the maximum LR number index
    const lrDetails = [];
    for (let i = 0; i <= maxIndex; i++) {
      lrDetails.push({
        PaidBy: values[i][`payTo`] ? values[i][`payTo`] : items[i]?.paidBy,
        // pay both
        ConsigneeAmount:
          values[i][`payTo`] === "Both"
            ? values[i][`consigneeAmount`] || 0
            : items[i]?.consigneeAmount,
        ConsignerAmount:
          values[i][`payTo`] === "Both"
            ? values[i][`consignorAmount`] || 0
            : items[i]?.consignerAmount,
        IsThirdPartyPay: values[i][`isThirdPartyAvailable`]
          ? values[i][`isThirdPartyAvailable`]
            ? true
            : false
          : items[i]?.isThirdPartyAvailable,
        // pay third  party
        ThirdPartyName: values[i][`isThirdPartyAvailable`]
          ? values[i][`partyName`]
          : items[i]?.thirdPartyName,
        ThirdPartyComment: values[i][`isThirdPartyAvailable`]
          ? values[i][`thirdPartyComment`]
          : items[i]?.thirdPartyComment,
        ThirdPartyCommission: values[i][`isThirdPartyAvailable`]
          ? values[i][`thirdPartyCommission`]
          : items[i]?.thirdPartyCommission,
        // Pay other
        otherPartyAddress:
          values[i][`payTo`] === "OtherParty"
            ? values[i][`otherPartyAddress`]
            : items[i]?.otherPartyAddress,
        otherPartyGst:
          values[i][`payTo`] === "OtherParty"
            ? values[i][`otherPartyGST`]
            : items[i]?.otherPartyGst,
        otherPartyName:
          values[i][`payTo`] === "OtherParty"
            ? values[i][`otherPartyName`]
            : items[i]?.otherPartyName,
        // need values
        BookingAmount: values[i][`bookingAmount`]
          ? values[i][`bookingAmount`]
          : items[i]?.bookingAmount,
        CompanyInvoiceNo: values[i][`companyInvoiceNumber`]
          ? values[i][`companyInvoiceNumber`]
          : items[i]?.companyInvoiceNo,
        To: values[i][`to`] ? values[i][`to`] : items[i]?.to,
        From: values[i][`from`] ? values[i][`from`] : items[i]?.from,
        LrNo: items[i].lrValue ? values[i][`LrNo`] : items[i]?.lrNo,
        LrId: items[i]?.lrId,
      });
    }
    localStorage.setItem("lrDetails", JSON.stringify(lrDetails));
  };

  return (
    <div className="space-y-2 mb-4">
      <Formik
        initialValues={{ values: transformedArray }}
        onSubmit={handleSubmit}
        className="space-y-2 flex flex-col gap-2"
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="space-y-2">
              {items?.length > 0 &&
                items.map((item, index) => (
                  <Disclosure key={item.lrNo} as="div" className="space-y-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-semibold text-primary-kirtiroadways hover:bg-kirtiroadways-300 hover:text-black focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75"
                          onClick={() => handleDisclosureClick(item.id)}
                        >
                          Lr No - {item.lrNo}
                          <ChevronDownIcon
                            className={`w-5 h-5 transition-transform transform ${
                              open ? "rotate-180" : ""
                            }`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel
                          as="div"
                          className={`px-4 py-2 md:grid flex md:grid-cols-3 md:gap-4 flex-col space-y-4  md:space-y-0 min-w-full ${
                            open ? "rounded-b-lg" : "hidden"
                          }`}
                        >
                          {/* Pay To */}
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Invoice Pay to{" "}
                              {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                                          *
                                        </span> */}
                            </p>
                            <PayTo
                              name={`values[${index}].payTo`}
                              defaultValue={item.paidBy}
                              handleOnChange={(e) => {
                                setFieldValue(
                                  `values[${index}].payTo`,
                                  e.value
                                );
                                if (e.value !== "Consigner") {
                                  setFieldValue(
                                    `values[${index}].isThirdPartyAvailable`,
                                    false
                                  );
                                }
                              }}
                              // handleOnChange={(e) => {
                              //   const newValue = e.value;
                              //   const currentValue = values[i][`payTo${index}`];

                              //   // Check if the new value is different from the current value
                              //   if (newValue !== currentValue) {
                              //     // If different, update the form field value
                              //     setFieldValue(`payTo${index}`, newValue);
                              //   }
                              // }}
                            />
                          </div>
                          {/* Consignor Amount */}
                          {(values.values[index]?.payTo === "Both"
                            ? values.values[index]?.payTo === "Both"
                            : item.paidBy === "Both") && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Consignor Amount
                              </p>
                              <Field
                                name={`values[${index}].consignorAmount`}
                                defaultValue={item.consignerAmount}
                                type="text"
                                onChange={(e) => {
                                    setFieldValue(
                                      `values[${index}].consignorAmount`,
                                      parseInt(e.target.value || 0)
                                    );
                                    setFieldValue(
                                      `values[${index}].bookingAmount`,
                                      parseInt(e.target.value || 0) +
                                        parseInt(
                                          values.values[index]?.consigneeAmount || 0
                                        )
                                    );
                                }}
                                className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                              />
                            </div>
                          )}
                          {/* Consignee Amount */}
                          {(values.values[index]?.payTo === "Both"
                            ? values.values[index]?.payTo === "Both"
                            : item.paidBy === "Both") && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Consignee Amount
                              </p>
                              <Field
                                name={`values[${index}].consigneeAmount`}
                                defaultValue={item.consigneeAmount}
                                onChange={(e) => {
                                  setFieldValue(
                                    `values[${index}].consigneeAmount`,
                                    parseInt(e.target.value || 0)
                                  );
                                  setFieldValue(
                                    `values[${index}].bookingAmount`,
                                    parseInt(e.target.value || 0) +
                                      parseInt(
                                        values.values[index]?.consignorAmount || 0
                                      )
                                  );
                              }}
                                type="text"
                                className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                              />
                            </div>
                          )}
                          {/* Other Party Name */}
                          {(values.values[index]?.payTo === "OtherParty" ||
                            item.paidBy === "OtherParty") && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Other Party Name
                              </p>
                              <OtherPartyName
                                name={`values[${index}].otherPartyName`}
                                defaultValue={item?.otherPartyName}
                                handleOnChange={(e) => {
                                  setFieldValue(
                                    `values[${index}].otherPartyName`,
                                    e.label
                                  );
                                  setFieldValue(
                                    `values[${index}].otherPartyGST`,
                                    e.value
                                  );
                                  setFieldValue(
                                    `values[${index}].otherPartyAddress`,
                                    e.add
                                  );
                                }}
                              />
                            </div>
                          )}
                          {/* Other Party GST */}
                          {(values.values[index]?.payTo
                            ? values.values[index]?.payTo === "OtherParty"
                            : item.paidBy === "OtherParty") && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Other Party GST
                              </p>
                              <Field
                                name={`values[${index}].otherPartyGST`}
                                defaultValue={item.otherPartyGst}
                                type="text"
                                className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                              />
                            </div>
                          )}
                          {/* Other Party Address */}
                          {(values.values[index]?.payTo
                            ? values.values[index]?.payTo === "OtherParty"
                            : item.paidBy === "OtherParty") && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Other Party Address
                              </p>
                              <Field
                                name={`values[${index}].otherPartyAddress`}
                                defaultValue={item.otherPartyAddress}
                                type="text"
                                className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                              />
                            </div>
                          )}
                          {/* Is Third Party Available Checkbox */}
                          {(values.values[index]?.payTo
                            ? values.values[index]?.payTo === "Consigner" ||
                              values.values[index]?.payTo === "Consignee"
                            : item.paidBy === "Consigner" ||
                              item.paidBy === "Consignee") && (
                            <div className="flex flex-col pt-2 text-left items-start">
                              <label className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Is Third Party Available
                              </label>
                              <Field
                                name={`values[${index}].isThirdPartyAvailable`}
                                type="checkbox"
                                checked={
                                  values.values[index]?.isThirdPartyAvailable
                                }
                                className="accent-red-600 text-white"
                                onChange={(e) => {
                                  setFieldValue(
                                    `values[${index}].isThirdPartyAvailable`,
                                    e.target.checked
                                  );
                                }}
                              />
                            </div>
                          )}
                          {/* Party Name */}
                          {(values.values[index]?.isThirdPartyAvailable ||
                            item.isThirdPartyAvailable) && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Party Name{" "}
                                <span className="ml-1 text-red-500 block text-sm font-medium">
                                  *
                                </span>
                              </p>
                              <ThirdPartyName
                                name={`values[${index}].partyName`}
                                defaultValue={item.thirdPartyName}
                                handleOnChange={(e) =>
                                  setFieldValue(`partyName${index}`, e.label)
                                }
                              />
                            </div>
                          )}
                          {/* Third Party Commission */}
                          {(values.values[index]?.isThirdPartyAvailable
                            ? values.values[index]?.isThirdPartyAvailable
                            : item.isThirdPartyAvailable) && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Third Party Commission
                              </p>
                              <Field
                                name={`values[${index}].thirdPartyCommission`}
                                defaultValue={item.thirdPartyCommission}
                                type="text"
                                className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                              />
                            </div>
                          )}
                          {/* Third Party Comment */}
                          {(values.values[index]?.isThirdPartyAvailable
                            ? values.values[index]?.isThirdPartyAvailable
                            : item.isThirdPartyAvailable) && (
                            <div className="flex flex-col text-left">
                              <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                Comment
                              </p>
                              <Field
                                name={`values[${index}].thirdPartyComment`}
                                defaultValue={item.thirdPartyComment}
                                type="text"
                                className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                              />
                            </div>
                          )}
                          {/* From */}
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              From{" "}
                              {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                                          *
                                        </span> */}
                            </p>
                            <Field
                              defaultValue={item.from}
                              name={`values[${index}].from`}
                              type="text"
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                          {/* To */}
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              To{" "}
                              {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                                          *
                                        </span> */}
                            </p>
                            <Field
                              name={`values[${index}].to`}
                              defaultValue={item.to}
                              type="text"
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                          {/* Booking Amount */}
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Booking Amount{" "}
                              {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                                          *
                                        </span> */}
                            </p>
                            <Field
                              name={`values[${index}].bookingAmount`}
                              type="text"
                              defaultValue={item?.bookingAmount}
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                              step="any"
                            />
                          </div>
                          {/* LR No. */}
                          {/* <div className="flex flex-col text-left">
                                        <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                                          LR No.
                                        </p>
                                        <Field
                                          name={`lrNo${index}`}
                                          type="text"
                                          className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                                          value={item.lrNo}
                                          disabled
                                        />
                                      </div> */}
                          {/* Company Invoice Number */}
                          <div className="flex flex-col text-left">
                            <p className="flex flex-row text-gray-500 text-sm font-medium mb-1">
                              Company Invoice Number{" "}
                              {/* <span className="ml-1 text-red-500 block text-sm font-medium">
                                          *
                                        </span> */}
                            </p>
                            <Field
                              name={`values[${index}].companyInvoiceNumber`}
                              defaultValue={item.companyInvoiceNo}
                              type="text"
                              className="peer w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500 rounded-md focus:outline-none focus:z-10 sm:text-sm"
                            />
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}

              <button
                ref={childButtonRef}
                className="hidden"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default form submission behavior
                  handleSubmit(values);
                }}
              >
                Child Button
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateLrNo;
