import React, { useEffect } from "react";
import { SupportContent } from "../../Utils/Constants";

export default function Notification({
  show,
  title,
  content,
  setShow,
  timeout,
  supportContent,
  //top = 0,
  mode = "success",
}) {
  useEffect(() => {
    let timer = setTimeout(() => {
      if (show) setShow(false);
    }, [timeout]);

    return () => clearTimeout(timer);
  }, [show, setShow, timeout]);

  const ModeToIcon = (mode) => {
    switch (mode) {
      case "failure":
        return (
          <svg
            className="h-6 w-6 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      case "success":
      default:
        return (
          <svg
            className="h-6 w-6 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
    }
  };

  if (show)
    return (
      <div
        className={
          "z-100 fixed inset-0 flex items-end items-start justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end top-0"
        }
      >
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">{ModeToIcon(mode)}</div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-gray-900 text-left">
                  {title}
                </p>
                <p className="mt-1 text-sm text-gray-500 text-left">
                  {content}
                </p>
                {SupportContent === true && (
                  <>
                    <hr />{" "}
                    <p className="mt-1 text-sm text-gray-500 text-left">
                      <a
                        href="https://kirtiroadways.freshdesk.com/support/home"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {SupportContent}
                      </a>
                      <br />
                      <a href="mailto:help@kirtiroadways.com">
                        help@kirtiroadways.com
                      </a>{" "}
                    </p>
                  </>
                )}
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setShow(false)}
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  return <></>;
}
