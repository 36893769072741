import moment from "moment";
import {
  LESSCOMMISSION,
  TOTALPAIDDRIVER,
  TOTALPAYMENTPWNER,
} from "../../Utils/Constants";

const billingDetails = async (invoice, profileData) => {
  const billingDetails = [
    {
      columns: [
        {
          alignment: "left",
          margin: [0, 0, 0, 0], // Set margin to 0
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 100,
                  h: 25,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              relativePosition: { x: 20, y: -19 },
              text: "INVOICE TO",
              color: "#000",
              fontSize: 11,
              bold: true,
            },
          ],
        },
        {
          margin: [0, 0, 0, 0], // Set margin to 0
          alignment: "right",
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 0,
                  w: 100,
                  h: 25,
                  r: 5,
                  lineColor: "black",
                },
              ],
            },
            {
              alignment: "right",
              relativePosition: { x: -16, y: -19 },
              text: "OUR DETAILS",
              color: "#000",
              fontSize: 11,
              bold: true,
            },
          ],
        },
      ],
    },
    {
      columns: [
        {
          width: 300,
          stack: [
            {
              text: `${invoice?.ownerName.toUpperCase()}`,
              color: "#000",
              fontSize: 11,
              bold: true,
              margin: [0, 5, 0, 0],
            },
            // {
            //   text: `ADDRESS :${invoice?.companyDetails.billToAddress}`,
            //   color: "#000",
            //   fontSize: 9,
            //   margin: [0, 5, 0, 0],
            // },
            // {
            //   text: `GST : ${invoice?.companyDetails.gst ? invoice?.companyDetails.gst.toUpperCase() : "-"}`,
            //   color: "#000",
            //   fontSize: 9,
            //   margin: [0, 5, 0, 0],
            // },
          ],
        },
        {
          text: "",
          width: "*",
        },
        {
          alignment: "right",
          width: 180,
          stack: [
            {
              text: `SERVICE : ${profileData?.service}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `PAN NO. : ${profileData?.panNo}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `STATE CODE : ${profileData?.stateCode}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              canvas: [
                {
                  type: "line",
                  x1: 200,
                  y1: 5,
                  x2: 20,
                  y2: 5,
                  lineWidth: 2,
                },
              ],
              margin: [0, 0, 0, 2],
            },
            {
              text: `INVOICE NO : ${
                invoice.invoiceNo || invoice?.bookingInvoiceNo
                  ? invoice?.invoiceNo
                    ? invoice?.invoiceNo
                    : invoice?.bookingInvoiceNo
                    ? invoice?.bookingInvoiceNo
                    : "--"
                  : invoice?.invoiceNo
                  ? invoice?.invoiceNo
                  : "--"
              }`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
            {
              text: `DATE : ${moment().format("DD-MM-YYYY")}`,
              color: "#000",
              fontSize: 9,
              margin: [0, 5, 0, 0],
              alignment: "right",
            },
          ],
        },
      ],
    },
  ];
  return billingDetails;
};

const billingTable = async (invoice) => {
  function getColumns(invoices) {
    let widths = ["*", '*', '*', '*', '*'];

    const columns = [
      {
        text: "LOADING DATE",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [false, true, true, true],
      },
      {
        text: "TRUCK NO.",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "FREIGHT",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "ADVANCE",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
      {
        text: "BALANCE",
        bold: true,
        fontSize: 9,
        alignment: "center",
      },
    ];

    let lastColumnIndex = columns.length - 1; // Initially,  the last column is the final static one

    // Check for hamali column
    if (
      invoices.some(
        (invoice) => invoice.hamali !== 0 && invoice.hamali !== null
      )
    ) {
      columns.push({
        text: "HAMALI",
        bold: true,
        fontSize: 9,
        alignment: "center",
      });
      widths.push('auto');
      lastColumnIndex++; // Update the last column index
    }

    if (
      invoices.some(
        (invoice) => invoice.detention !== 0 && invoice.detention !== null
      )
    ) {
      columns.push({
        text: "DETENTION",
        bold: true,
        fontSize: 9,
        alignment: "center",
      });
      widths.push('auto');
      lastColumnIndex++; // Update the last column index
    }
    if (
      invoices.some(
        (invoice) => invoice.extraCharge !== 0 && invoice.extraCharge !== null
      )
    ) {
      columns.push({
        text: "EXTRA CHARGE",
        bold: true,
        fontSize: 9,
        alignment: "center",
      });
      widths.push("auto");
      lastColumnIndex++; // Update the last column index
    }
    if (
      invoices.some(
        (invoice) => invoice.commission !== 0 && invoice.commission !== null
      )
    ) {
      columns.push({
        text: "COMMISSION",
        bold: true,
        fontSize: 9,
        alignment: "center",
        border: [true, true, false, true],
      });
      widths.push('auto');
      lastColumnIndex++; // Update the last column index
    }

    columns[lastColumnIndex].border = [true, true, false, true];

    return { columns, widths };
  }

  // eslint-disable-next-line
  function getTableBody(invoices, columns) {
    const body = [];
    invoices.forEach((data) => {
      const row = [
        {
          text: `${moment(data?.loadingDate).format("DD-MM-YYYY")}`,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [false, false, true, false],
        },
        {
          text: data?.truckNo?.toUpperCase(),
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.freight ? data?.freight : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.advance ? data?.advance : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
        {
          text: data?.balance ? data?.balance : 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        },
      ];

      if (columns.some((col) => col.text === "HAMALI")) {
        row.push({
          text: data?.hamali ?? 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        });
      }
      if (columns.some((col) => col.text === "DETENTION")) {
        row.push({
          text: data?.detention ?? 0,
          bold: true,
          fontSize: 9,
          alignment: "center",
          border: [true, false, true, false],
        });
      }
      if (columns.some((col) => col.text === "EXTRA CHARGE")) {
        row.push({
          text: data?.extraCharge ?? 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, true, false],
        });
      }
      if (columns.some((col) => col.text === "COMMISSION")) {
        row.push({
          text: data?.commission ?? 0,
          fontSize: 9,
          bold: true,
          alignment: "center",
          border: [true, false, false, false],
        });
      }

      const lastColumnIndex = row.length - 1; // Get index of last column
      row[lastColumnIndex].border = [true, false, false, false];

      body.push(row);
    });
    const numEmptyRowsNeeded = Math.max(0, 8 - body.length);
    for (let i = 0; i < numEmptyRowsNeeded; i++) {
      const emptyRow = [];
      columns.forEach((column, index) => {
        // console.log(index);
        emptyRow.push({
          border: [index !== 0 ? false : false, false, false, false],
          text: "",
          fontSize: 9,
          alignment: "center",
        }); // Adjust styling as needed
      });
      body.push(emptyRow);
    }
    return body;
  }

  const {columns, widths} = getColumns(invoice?.details);
  const body = getTableBody(invoice?.details, columns);
  // console.log(columns);
  // console.log(body);

  const table = {
    heights: 25,
    widths: widths,
    headerRows: 1,
    fontSize: 9,
    bold: true,
    margin: [0, 10, 0, 10],
    body: [columns, ...body],
  };

  const content = [
    // Other content
    {
      table,
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 ? 1 : 0.5; // Set to 1 for the bottom border of each cell in the first row, 0.5 for other rows
        },
        hLineColor: function (i) {
          return i === 0 ? "#000" : "#000"; // Set color for the bottom border of each cell in the first row
        },
        paddingTop: function () {
          return 4; // Adjust padding as needed
        },
        paddingBottom: function () {
          return 4; // Adjust padding as needed
        },
        vLineWidth: function (i) {
          return 1; // Set to 0 for no vertical borders
        },
        vLineColor: function () {
          return "#000"; // Set to white or your background color
        },
      },
    },
    // Other content
  ];

  return content;
};

export const VehicleBillingLayout = async (
  invoice,
  WE_USING_PAYPHONE,
  profileData
) => {
  // console.log(invoice, "invoice");
  // console.log(profileData, "profileData");
  const BillingInfo = await billingDetails(invoice, profileData);

  const BillingTabDATA = await billingTable(
    invoice,
    WE_USING_PAYPHONE,
    profileData
  );

  return [
    {
      margin: [0, 5, 0, 0],
      style: "color",
      text: "",
    },
    BillingInfo,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
    BillingTabDATA,
    {
      margin: [0, 7, 0, 0],
      style: "color",
      text: "",
    },
  ];
};

export const BillingTotal = async (invoice) => {

  function createTableBody(invoiceData) {
    const tableRow = [{
      margin: [0, 5, 0, 5],
      text: "TOTAL", 
      fontSize: 10,
      border: [true, true, true, true],
      bold: true,
      alignment: "center",
    }, {
      margin: [0, 5, 0, 5],
      text: invoiceData.totalBalance, 
      fontSize: 10,
      border: [true, true, true, true],
      bold: true,
      alignment: "center",
    }];
  
    // Add 'widths' array 
    const tableWidths = ['*', '*'];  
  
    // Dynamically add entries and adjust widths (exclude 'totalBalance')
    const validKeys = ['totalHamali', 'totalDetention', 'totalExtraCharge', 'totalCommission'];
  
    for (const key of validKeys) {
      if (invoiceData[key] !== 0 && invoiceData[key] !== null && invoiceData[key] !== undefined) {
        tableRow.push({
          margin: [0, 5, 0, 5],
          text: invoiceData[key],
          fontSize: 10,
          border: [true, true, true, true],
          bold: true,
          alignment: "center",
        });
        tableWidths.push('*'); // Add a width for the new column
      }
    }

  
    return [tableRow];
  }

  const tableBody = createTableBody(invoice)

  const BillingData = [
    {
      stack: [
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: -4,
              w: 522, // width of the rectangle
              h: 1, // height of the rectangle
              color: "black",
            },
          ],
        },
        {
          columns: [
            {
              margin: [0, 2, 0, 2],
              stack: [
                {
                  margin: [0, 2, 0, 2],
                  text: `${TOTALPAYMENTPWNER} : ${
                    invoice?.totalOwnerPayment ? invoice?.totalOwnerPayment : 0
                  }`,
                  fontSize: 10,
                },
                {
                  margin: [0, 2, 0, 2],
                  text: `${LESSCOMMISSION} : ${
                    invoice?.totalCommission ? invoice?.totalCommission : 0
                  }`,
                  fontSize: 10,
                },
                {
                  margin: [0, 2, 0, 2],
                  text: `${TOTALPAIDDRIVER} : ${
                    invoice?.totalPaidToOwner ? invoice?.totalPaidToOwner : 0
                  }`,
                  fontSize: 10,
                },
              ],
            },
            {
              margin:[0,9,0,0],
              alignment: "right",
              table: {
                widths:'*',
                heights: 20,
                body: tableBody
              },
            },
          ],
        },
        {
          canvas: [
            {
              type: "rect",
              x: 0,
              y: 0,
              w: 522, // width of the rectangle
              h: 1, // height of the rectangle
              color: "black",
            },
          ],
        },
        {
          characterSpacing: 0.5,
          margin: [0, 7, 0, 0],
          text: "AMOUNT CHARGEABLE (IN WORDS)",
          fontSize: 11,
        },
        {
          characterSpacing: 0.8,
          margin: [0, 6, 0, 0],
          text: `${invoice?.totalInWords} ONLY`,
          fontSize: 13,
        },
        {
          stack: [
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 2,
                  w: 522, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
            {
              canvas: [
                {
                  type: "rect",
                  x: 0,
                  y: 1,
                  w: 522, // width of the rectangle
                  h: 1, // height of the rectangle
                  color: "black",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  return BillingData;
};
