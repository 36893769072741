import React from "react";
import { useLocation } from "react-router-dom";
import Logo from "../../Images/logo_transparent.png";
import black_logo from "../../Images/black_logo.png";
import { address_svg, call_svg, email_svg } from "../../Utils/SVGListing";
import moment from "moment";
import sign from "../../Images/sign.png"

const LCVPrint = ({
  profileData,
  secondaryPhoneNo,
  guarantee,
  goods,
  showBorder,
  blackWhite,
}) => {
  let location = useLocation();
  return (
    <>
      <div
        className={`m-6 border-2 ${
          showBorder &&
          ` border-2 ${
            blackWhite ? "border-black" : "border-primary-kirtiroadways "
          } `
        } 
         `}
        // style={{
        //   backgroundImage: "url(" + logo_green_oposity + ")",
        // }}
      >
        <div className="">
          {/* logo and Kiti Roadlines Start */}
          <div className="flex flex-row items-start space-x-0 my-2 mx-2 ">
            <div>
              {blackWhite ? (
                <img
                  src={black_logo}
                  alt="Kirti Roadlines"
                  className="w-52 h-auto"
                />
              ) : (
                <img src={Logo} alt="Kirti Roadlines" className="w-52 h-auto" />
              )}{" "}
            </div>
            <div className="flex flex-col  w-full">
              <div className="flex flex-row justify-end items-start text-lg font-bold ">
                {/* <h1 className='ml-32'>|| श्री हरी ||</h1> */}
                <h1>Vehicle Loading Slip</h1>
              </div>
              <div
                className={`text-3xl  text-right font-bold w-full ${
                  !blackWhite && "text-primary-kirtiroadways"
                }`}
              >
                {profileData.tagLine}
              </div>
            </div>
          </div>
          {/* logo and Kiti Roadlines End */}

          {/* LCV Start */}
          <div
            className={`border-y-2 ${
              blackWhite ? "border-black" : "border-primary-kirtiroadways "
            } text-xl font-bold text-center align-top  h-12`}
          >
            <span>L. C. V</span>
          </div>
          {/* LCV End */}

          {/* Address Start */}
          <div className="mx-2 items-center space-x-3 flex flex-row">
                      <div className=" space-y-2   flex flex-col  justify-center text-center w-full">

                        {/* Address Start */}
                        <div
                          style={{
                            width: "100%",
                            height: "65px",
                            // Set the container height to full viewport height
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start", // Center the content vertically
                            alignItems: "center",
                            borderRadius: "1.5rem",
                            padding: "3px",
                            fontSize: "10px",
                            background:  "linear-gradient(to right, #FFC7C7, #FFFFFF)",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "start", // Center the content vertically
                              gap: "4px", // Adjust spacing between SVG and text
                              marginLeft: "2px",
                            }}
                          >
                            <span className="relative top-2 left-3">
                              {address_svg}
                            </span>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center", // Center the content vertically
                                letterSpacing: "0px",
                                textRendering: "optimizeLegibility", // Improve text rendering for better readability
                                WebkitFontSmoothing: "antialiased", // For WebKit browsers (e.g., Chrome, Safari)
                                MozOsxFontSmoothing: "grayscale", // For Firefox on macOS
                                fontFamily: "sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              {profileData.address}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "2px",
                              letterSpacing: "0px",
                              textRendering: "optimizeLegibility", // Improve text rendering for better readability
                              WebkitFontSmoothing: "antialiased", // For WebKit browsers (e.g., Chrome, Safari)
                              MozOsxFontSmoothing: "grayscale", // For Firefox on macOS
                              fontFamily: "sans-serif",
                              fontWeight: "bold",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center", // Center the content vertically
                              }}
                            >
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "end", // Center the content vertically
                                  placeItems: "end",
                                }}
                              >
                                <span className="relative top-2 mr-1">
                                  {call_svg}
                                </span>
                                {secondaryPhoneNo.map((number, i) => (
                                  <span key={number}>
                                    {(i ? ", " : "") + number}
                                  </span>
                                ))}
                              </span>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                spaceX: "1",
                                justifyContent: "center",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <span className="relative top-2 mr-1 ml-2">
                                {email_svg}
                              </span>

                              <span className="mt-1">
                                {profileData.email}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* Address End */}
                      </div>
                    </div>
          {/* Address End */}
          {/* Main Form Start */}
          <div className="m-2 space-y-2">
            {/* No and date */}
            <div className="flex flex-row justify-between font-bold">
              <div className="flex flex-row ">
                <h1>No. :</h1>
                <h1 className="ml-2">{location?.data?.data?.lcvNo}</h1>
              </div>
              <div className="flex flex-row justify-center items-center space-x-2">
                <h1 className="text-lg">Date :</h1>
                {/* <h1>20-09-2022</h1> */}
                <h1 className="mt-1">
                  {moment(location?.data?.data?.loadingDate).format(
                    "DD-MM-YYYY"
                  )}
                </h1>
              </div>
            </div>
            {/* Name */}
            <div className="flex flex-row">
              <h1>M/S.</h1>
              <div
                className={`w-full ml-1 border-b-2 pb-2  text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.transporterName}
              </div>
            </div>
            {/* Vehicle No */}
            <div className="flex flex-row space-x-0">
              <div className="min-w-fit	text-left">
                <h1>We Are Sending Truck No.</h1>
              </div>
              <div
                className={`w-full ml-1 border-b-2 pb-2  text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.vehicleNo}
              </div>
            </div>
            {/* From to  */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">Please Load From</h1>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.from}
              </div>
              <h1 className="mx-2 text-left">To,</h1>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.to}
              </div>
            </div>
            {/* goods and garantee */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">of Goods</h1>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {goods}
              </div>
              <h1 className="min-w-fit text-center">Guarantee</h1>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {guarantee}
              </div>
            </div>
            {/* full loads */}
            <div className="flex flex-row space-x-0">
              <div className="min-w-fit text-left  pb-2 ">
                Full Load has been Settled with
              </div>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.loadSettledWith}
              </div>
            </div>
            {/* rate rs and total fright */}
            <div className="flex flex-row space-x-0">
              <div className="min-w-fit text-left">Rate Rs.</div>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.rates}
              </div>
              <div className="min-w-fit text-center">Total Freight Rs.</div>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.transporterFreigth}
              </div>
            </div>
            {/* Adance and balance */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">Advance Rs.</h1>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.transporterAdvance}
              </div>
              <div className="text-left min-w-fit">Balance:</div>
              <div
                className={`w-full ml-1 border-b-2 pb-2 text-center ${
                  blackWhite
                    ? "border-b-black"
                    : "border-b-primary-kirtiroadways "
                } font-bold`}
              >
                {location?.data?.data?.transporterBalance}
              </div>
            </div>
            {/* extra note */}
            <div className="flex flex-row space-x-0">
              <h1 className="min-w-fit text-left">Thanks</h1>
            </div>
          </div>

          {/* Note Start */}
          <div
            className={`text-xs p-1 border-y-2  ${
              blackWhite ? "border-black" : "border-primary-kirtiroadways "
            }  align-center text-center pb-4`}
          >
            <span className="text-xs font-bold">Note :</span> गाड़ी भरने के वक्त
            गाड़ी की R.C. औरा PAN CARD की ज़ेरॉक्स की कॉपी लेना ट्रांसपोर्ट की
            जिमेदारी रहेगी
          </div>
          {/* Note End */}

          {/* Signature Box Start */}
          <div className="flex items-center  mx-2 flex-row justify-between">
            <div
              className={`text-2xl font-bold  ${
                !blackWhite && "text-primary-kirtiroadways"
              }`}
            >
              No Varai, No Hamali
            </div>
            <div className=" flex flex-col  items-center justify-start pb-2">
                    <p className="font-bold">Your Faithfully</p>
                    <img src={sign} alt="signature" width={100} className="pt-2"/>
                    <span className="font-bold mb-2">For, Kirti Roadlines</span>
                  </div>
          </div>
          {/* Signature Box End */}

          {/* Last content Start */}
          <div
            className={`border-t-2 ${
              blackWhite ? "border-black" : "border-primary-kirtiroadways "
            }`}
          >
            <div className="mx-2">
              <p className="text-base	text-left font-bold" style={{fontSize:"11px"}}>NOTE :</p>
              <div className="text-base	text-left no-wr">
                  <p className="text-sm break-all font-bold	" style={{fontSize:"9px"}}>
                    1. <span className="break-all	"> Please Check up Engine & Chassis No. R.T.O. Book C.S.T. No.
                    Etc for your Satisfaction</span>
                  </p>
                  <p className="text-sm  pb-2 font-bold " style={{fontSize:"9px"}}>
                    2. We are not Responsible of Accident, Leakage & Breakage
                  </p>
              </div>
            </div>
          </div>
          {/* Last content End */}
        </div>
      </div>
    </>
  );
};

export default LCVPrint;
