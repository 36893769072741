import React,{ useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { Billing_DATA } from "../../Utils/Constants";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import axiosInstance from "../../Utils/API";
import { GET_BUILTY_TABLE } from "../../Utils/ApiGlobal";
import moment from "moment";
import Loader from "../UI/Loader";

export default function BillingTable(props) {
  //  const location = useLocation();
   const [billingData, SetBillingData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

  // for Search by Bill No.
  // const filterByBillNo = ({ Bill_No }) => {
  //   return (
  //     Bill_No.toLowerCase().indexOf(props.searchValue.toLowerCase()) !== -1
  //   );
  // };



  const columns = [
    {
      Header: "Action",
      id: "Action",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
      Cell: (row) => (
        <div>
          <TableAction
            data={row.row.original}
            // redirectPath="billing/add-edit-Billing"
            redirectViewPath="billing/billing-view"
          />
        </div>
      ),
    },
    {
      Header: "Loading Date",
      accessor: d => {
        return moment(d.lrDate)
          .format("DD-MM-YYYY")
      },
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "Bill No",
      accessor: "billtyNo",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "LR No",
      accessor: "lrNo",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Vehicle No",
      accessor: "vehicleNo",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "Hamali",
      accessor: "hamali",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "Detention",
      accessor: "detention",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "Extra Charge",
      accessor: "extraCharge",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "From",
      accessor: "from",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "To",
      accessor: "to",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "Actual Weight (Kg)",
      accessor: "ActualWeight",
      maxWidth: 150,
      minWidth: 150,
      width: 150,
    },
    {
      Header: "Charged Weight (Kg)",
      accessor: "ChargedWeight",
      maxWidth: 160,
      minWidth: 160,
      width: 160,
    },
    {
      Header: "Rate",
      accessor: "Rate",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "Balance Paid Bill No",
      accessor: "Balance_Paid_Bill_No",
      maxWidth: 150,
      minWidth: 150,
      width: 100,
    },
    {
      Header: "Billty Received",
      accessor: "Billty_Received_or_Not",
      maxWidth: 100,
      minWidth: 100,
      width: 100,
    },
    {
      Header: "Amount",
      accessor: "total",
      maxWidth: 120,
      minWidth: 120,
      width: 120,
    },
    {
      Header: "Note",
      accessor: "Note",
    },
  ];
  // console.log(props,"for table")
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`${GET_BUILTY_TABLE}?search=${props.searchValue}&pageNo=&pageSize=`)
      .then((response) => {
        const data = response?.data?.data?.billty
        SetBillingData(data);
        setIsLoading(false);
        // console.log(data)
      })
      .catch((error) => { 
        console.log(error);
      });
  },[props.searchValue]);

  return (
    <section>
      <div className="mt-4 ">
       { isLoading ? <Loader/> : 
          <TablePagination
          data={billingData}
          columns={columns}
        />}
      </div>
    </section>
  );
}
