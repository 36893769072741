import React, { useState, useEffect } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import Card from "./Card";
// import SearchField from '../../Components/UI/SearchField';
import LineChart from "./LineChart";
import {
  SVG_PENDING,
  BILLTY_SVG,
  Billing_Svg,
  commison_svg,
} from "../../Utils/SVGListing";
import axiosInstance from "../../Utils/API";
import { GET_DASHBOARD_LIST } from "../../Utils/ApiGlobal";
import Loader from "../../Components/UI/Loader";
// import { Disclosure } from "@headlessui/react";
// import { ChevronUpIcon } from "@heroicons/react/solid";

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);// eslint-disable-next-line
  const [bookingData, setBookingData] = useState();// eslint-disable-next-line
  const [transporterData, setTransporterData] = useState();// eslint-disable-next-line
  const [vehicleOwnerData, setVehicleOwnerData] = useState();
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    setRefetch(false)
    _GET_DASHBOARD_DATA();
    // eslint-disable-next-line
  }, [refetch]);
  // GET API
  const _GET_DASHBOARD_DATA = () => {
    setIsLoading(true);
    axiosInstance
      .get(`${GET_DASHBOARD_LIST}`)
      .then((response) => {
        // console.log(response?.data?.data);
        const data = response?.data?.data;
        setDashboardData(data);
        let bookingsDB, TransporterDB,VehicleownerDB;
        bookingsDB=data.pendingBooking.filter(function (e){
          return e.isPaid === false
      })
      TransporterDB=data.pendingTransporters.filter(function (e){
          return e.isTransporterPaid === false
      })
      VehicleownerDB=data.pendingVehicleOwner.filter(function (e){
          return e.isPaid === false
      })
      // console.log(response?.data?.data)
        setBookingData(bookingsDB)
        setTransporterData(TransporterDB);
        setVehicleOwnerData(VehicleownerDB);
        // console.log(data)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <DashBoardLayout pageTitle="Dashboard">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {/* <div className='md:w-80 w-full'>
              <SearchField
                placeholder="Search"
              handleChange={setSearchValue}
              value={searchValue}
              />
            </div> */}
            <section className="text-gray-700 body-font">
              <div className="container px-5 py-10 mx-auto">
                <div className="flex flex-wrap -m-4 text-center">
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card
                      title="Booking"
                      svg={SVG_PENDING}
                      countData={dashboardData.booking}
                      path="/pending-booking"
                    />
                  </div>
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card
                      title="Vehicle Owner"
                      svg={BILLTY_SVG}
                      countData={dashboardData.vehicleowner}
                      path="/vehicle"
                    />
                  </div>
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card
                      title="Commission"
                      svg={Billing_Svg}
                      countData={dashboardData.comission}
                      path="/commission-entry"
                    />
                  </div>
                  <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                    <Card
                      title="Company"
                      svg={commison_svg}
                      countData={dashboardData.company}
                      path="/company"
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* <p className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-medium text-primary-kirtiroadways hover:bg-primary-kirtiroadways-200 focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75">
              <span>Pending Company Invoice</span>
            </p>
            <DashboardInvoice companyData={bookingData }  component="booking"/> */}

            {/* <p className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-medium text-primary-kirtiroadways hover:bg-primary-kirtiroadways-200 focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75">
              <span>Pending Transporter Invoice</span>
            </p>
            <DashboardInvoice companyData={transporterData} component="transporter" setRefetch={setRefetch} />

            <p className="flex w-full justify-between rounded-lg bg-kirtiroadways-100 px-4 py-2 text-left text-sm font-medium text-primary-kirtiroadways hover:bg-primary-kirtiroadways-200 focus:outline-none focus-visible:ring focus-visible:ring-kirtiroadways-500 focus-visible:ring-opacity-75">
              <span>Pending Vehicle Owner Invoice</span>
            </p>
            <DashboardInvoice companyData={transporterData} vehicleInvoiceData={vehicleOwnerData} component="vehicleOwner" setRefetch={setRefetch} /> */}

            <div className="flex justify-center my-10">
              <div className="w-full">
                <LineChart chartData={dashboardData} />
              </div>
            </div>
          </div>
        )}
      </DashBoardLayout>
    </>
  );
}
