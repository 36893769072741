import React from "react";
import TableAction from "../UI/Table/TableAction";
import TablePagination from "../UI/Table/TablewithPagination";
import { DRIVER_DATA } from "../../Utils/Constants";

const columns = [
  {
    Header: "Action",
    id: "Action",
    maxWidth: 10,
    minWidth: 10,
    width: 10,
    Cell: (row) => (
      <div>
        <TableAction
          data={row.row.original}
          redirectPath="driver/add-edit-driver"
        />
      </div>
    ),
  },
  {
    Header: "Driver Name",
    accessor: "Driver_Name",
    Cell:row => <div className="text-left">{row.value}</div>,
  },
  {
    Header: "Driver Mobile",
    accessor: "Driver_Mobile",
  },
  {
    Header: "Driver Paid Balance",
    accessor: "Driver_Paid_Balance",
  },
];
function DriverTable(props) {
  // console.log(props,"for table")
  return (
    <section>
      <div className="mt-4 ">
        <TablePagination data={DRIVER_DATA} columns={columns} />
      </div>
    </section>
  );
}

export default DriverTable;
