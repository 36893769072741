import React, { useState, useEffect } from "react";
import logo from "../../../Images/logo_transparent.png";
import black_logo from "../../../Images/black_logo.png";
import { GET_COMPANY_INFORMATION_DATA } from "../../../Utils/ApiGlobal";
import {
  InvoicSubject,
  AuthorizedSignatory,
  computerGeneratedInvoice,
  TOTALPAYMENTPWNER,
  LESSCOMMISSION,
  TOTALPAIDDRIVER,
  // SetAnotherBankDetails,
  // InvoiceDeclartion,
} from "../../../Utils/Constants";
import { address_svg, call_svg, email_svg } from "../../../Utils/SVGListing";
import moment from "moment";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../Utils/API";
import Loader from "../../../Components/UI/Loader";
import Checkbox from "../../../Components/UI/Checkbox";
import {
  TabletheadRedLastStyle,
  TabletheadRedStyle,
  TabletheadStyle,
  address,
  borderToggle,
  heading,
} from "../../../Utils/TableStyling";
import sign from "../../../Images/sign.png";
import VehicleInvoiceDownloadView from "./VehicleInvoiceDownloadView";

const VehicleInvoiceView = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [secondaryPhoneNo, setsecondaryPhoneNo] = useState([]);
  const [showBorder, setShowBorder] = useState(true);
  const [blackWhite] = useState(true); // eslint-disable-next-line
  const [mobilePrint, setMobilePrint] = useState(true);

  const location = useLocation();
  var date = new Date();
  var Today_Date = moment(date).format("DD-MM-YYYY");
  // console.log(props);
  useEffect(() => {
    // console.log(location, "print");
  }, [props]);

  useEffect(() => {
    _FetchProfileAPI();
  }, []);
  const _FetchProfileAPI = () => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${GET_COMPANY_INFORMATION_DATA}?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setIsLoading(false);
        const data = response?.data?.data;
        // console.log(data);
        setProfileData(data);
        setsecondaryPhoneNo(response?.data?.data?.secondaryPhoneNo);
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  const hasHamali = location?.commissionData?.invoiceData.details?.some(
    (item) => item?.hamali !== 0 && item?.hamali !== null
  );
  const hasDetention = location?.commissionData?.invoiceData.details?.some(
    (item) => item.detention !== 0 && item.detention !== null
  );
  const hasExtraCharge = location?.commissionData?.invoiceData.details?.some(
    (item) => item.extraCharge !== 0 && item.extraCharge !== null
  );
  const hasCommission = location?.commissionData?.invoiceData.details?.some(
    (item) => item.commission !== 0 && item.commission !== null
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex flex-row gap-4 justify-center mt-5">
            <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">With Border</p>
              <Checkbox
                defaultChecked={showBorder}
                handleClick={(e) => setShowBorder(e.target.checked)}
              />
            </div>
            {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
              <p className="text-xs">Black and white</p>
              <Checkbox
                defaultChecked={blackWhite}
                handleClick={(e) => setBlackWhite(e.target.checked)}
              />
            </div> */}
            {/* <div className="flex flex-row mt-2 space-x-2 justify-center text-center items-center">
                  <p className="text-xs">Mobile Print</p>
                  <Checkbox
                    defaultChecked={mobilePrint}
                    handleClick={(e) => setMobilePrint(e.target.checked)}
                  />
                </div> */}
          </div>
          {/* <button onClick={()=>{console.log(location.commissionData.invoiceData)}}>Click</button> */}
          {location?.commissionData?.invoiceData && (
            <div style={{ minWidth: "1240px" }}>
              <div
                className={`${
                  showBorder &&
                  ` border-double  border-4 ${
                    blackWhite ? "border-black" : "border-red-200"
                  } `
                } pageStyle  block  min-h-screen m-5   align-top text-center bg-center bg-no-repeat  bg-[length:65%]`}
                // style={{
                //   backgroundImage: "url(" + logo_oposity + ")",
                // }}
              >
                <div className="mt-2">
                  <div className="flex  flex-row justify-center font-bold mx-2 mb-3 text-xs">
                    <h5>Tax Invoice</h5>
                  </div>
                  {/* Logo and Title Start */}
                  <div className="m-2 items-center space-x-3 flex flex-row">
                    <div className=" space-y-2   flex flex-col  justify-center text-center w-full">
                      <div className="flex flex-row justify-between">
                        <div className="w-80 ml-2">
                          {blackWhite ? (
                            <img src={black_logo} alt="Kirti Roadlines" />
                          ) : (
                            <img src={logo} alt="Kirti Roadlines" />
                          )}{" "}
                        </div>
                        <div className=" flex flex-col w-full">
                          <span
                            className={`kirtiroadline-headline ${
                              !blackWhite && "text-primary-kirtiroadways"
                            } font-serif font-extrabold `}
                          >
                            {" "}
                            {profileData.tagLine}
                          </span>
                          <span className="2xl:text-4xl text-3xl font-serif text-black">
                            {profileData.subTagLine}
                          </span>
                        </div>
                      </div>
                      {/* Address Start */}
                      <div
                        className={`rounded-b-3xl rounded-t-lg font-bold   p-2 w-full  ${address(
                          blackWhite
                        )} flex flex-col space-y-1 text-center text-sm`}
                      >
                        {" "}
                        <p className="flex  text-md justify-center flex-row items-start">
                          <span className="text-md w-5">{address_svg}</span>{" "}
                          <span className="text-md ml-1">
                            {profileData.address}
                          </span>
                        </p>
                        <div className="  flex space-x-4 justify-center flex-row items-start ">
                          <div className="flex space-x-1 text-md justify-center flex-row items-center ">
                            <span>{call_svg} </span>{" "}
                            <span className="flex flex-row">
                              {secondaryPhoneNo.map((number, i) => (
                                <span
                                  key={i}
                                  className="text-sm font-semibold tracking-wider flex flex-row"
                                >
                                  {(i ? ", " : "") + number}
                                </span>
                              ))}
                            </span>
                          </div>
                          <div className="flex text-md space-x-1 justify-center flex-row items-center ">
                            <span>{email_svg} </span>
                            <span>{profileData.email}</span>
                          </div>
                        </div>
                      </div>
                      {/* Address End */}
                    </div>
                  </div>
                  {/* Logo and Title End */}
                </div>
                {/* Invoice  No and details*/}
                <div className="p-2 flex font-sans flex-row justify-between">
                  <div className="flex flex-col text-left w-1/2">
                    <div
                      className={`font-bold uppercase text-md	py-1 mb-0 px-5 w-36  ${heading(
                        blackWhite
                      )}`}
                    >
                      {" "}
                      Invoice to
                    </div>
                    <div>
                      <h1 className="text-left font-bold uppercase font-sans text-xl	subpixel-antialiased tracking-wide mt-0	">
                        {location?.commissionData?.invoiceData?.ownerName?.toUpperCase()}
                      </h1>
                    </div>
                    {/* <div className="text-xs text-left">
                    <div className="flex  flex-row space-x-1 mt-1">
                      <p className="w-14">Address :</p>
                      <p className="w-1/2 ">{location?.commissionData?.invoiceData.address}</p>
                    </div>
                  </div> */}
                    <div className="text-sm mt-1">
                      <div className="flex flex-row space-x-1 text-left">
                        <p className="">PAN :</p>
                        <p className="w-1/2">
                          {location?.commissionData?.invoiceData?.panNo?.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Right side */}
                  <div className="flex  flex-col text-right justify-end items-end">
                    <span
                      className={`font-bold uppercase text-md	py-1 mb-0 px-5  ${
                        blackWhite
                          ? "text-black border border-black rounded-lg"
                          : "text-white rounded-lg bg-primary-kirtiroadways"
                      } `}
                    >
                      {" "}
                      OUR Details
                    </span>
                    <div className="flex text-base space-x-1 flex-row justify-end uppercase font-semibold	pt-2">
                      <p className="flex flex-col">
                        <span>Service : {profileData.service}</span>
                        <span>Pan No. : {profileData.panNo}</span>
                        <span>State Code : {profileData.stateCode}</span>
                      </p>
                      {/* <p className="flex flex-col">
                    <span>Transportation</span>
                    <span>AJGPD9182R</span>
                    <span>24</span>
                  </p> */}
                    </div>

                    <div
                      className={`uppercase font-semibold	pt-1 mt-2 pl-16 border-t-2 ${borderToggle(
                        blackWhite
                      )} `}
                    >
                      {" "}
                      <div className="flex flex-row text-sm text-right space-x-12">
                        <span className="flex flex-col ">
                          <p className="text-right min-w-1/2">
                            Invoice No :{" "}
                            {location?.commissionData?.invoiceData.invoiceNo
                              ? location?.commissionData?.invoiceData.invoiceNo
                              : "--"}
                          </p>
                          {/* <p className="text-right min-w-1/2">
                          Bill OF Loading :
                        </p> */}
                          <p className="text-right min-w-1/2">
                            Date : {Today_Date}
                          </p>
                          {/* <p className="text-right w-1/2">Transportation</p> */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Table start*/}
                <div
                  style={{ minHeight: "450px" }}
                  className="p-2 w-full border-t-0 border-neutral-900"
                >
                  <table className=" border-spacing-y-2 border-separate w-full border-neutral-900 text-sm font-sans">
                    <thead style={{ maxHeight: "5px" }} className="mb-2">
                      <tr className=" border-neutral-900 text-white ">
                        <th
                          style={{ maxHeight: "5px" }}
                          className={TabletheadStyle(blackWhite)}
                        >
                          Loading
                          <br />
                          Date
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={TabletheadStyle(blackWhite)}
                        >
                          Truck
                          <br />
                          No.
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={TabletheadStyle(blackWhite)}
                        >
                          Freight
                        </th>
                        <th className={TabletheadRedStyle(blackWhite)}>
                          Advance
                        </th>
                        <th
                          style={{ maxHeight: "5px" }}
                          className={TabletheadRedStyle(blackWhite)}
                        >
                          Balance
                        </th>
                        {hasHamali && (
                          <th
                            style={{ maxHeight: "5px" }}
                            className={TabletheadRedStyle(blackWhite)}
                          >
                            Hamali
                          </th>
                        )}
                        {hasDetention && (
                          <th
                            style={{ maxHeight: "5px" }}
                            className={TabletheadRedStyle(blackWhite)}
                          >
                            Detention
                          </th>
                        )}
                        {hasExtraCharge && (
                          <th
                            style={{ maxHeight: "5px" }}
                            className={TabletheadRedStyle(blackWhite)}
                          >
                            Extra
                            <br />
                            Charge
                          </th>
                        )}
                        {hasCommission && (
                          <th
                            style={{ maxHeight: "5px" }}
                            className={TabletheadRedLastStyle(blackWhite)}
                          >
                            Commission
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody
                      className=" align-top font-bold text-center bg-bottom bg-no-repeat bg-[length:80%_90%]"
                      // style={{
                      //   backgroundImage: "url(" + logo_oposity + ")",
                      // }}
                    >
                      {location?.commissionData?.invoiceData.details.map(
                        (data, i) => (
                          <>
                            <tr
                              height="20px"
                              className="border-neutral-900 "
                              key={i}
                            >
                              <td
                                height="20px"
                                className="border-r-2 border-gray-300 items-center  w-28"
                              >
                                <h1 className="mt-2">
                                  {moment(data?.loadingDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </h1>
                              </td>
                              <td
                                height="20px"
                                className=" border-r-2 border-gray-300 w-32"
                              >
                                <h1 className="mt-2">
                                  {data?.truckNo?.toUpperCase()}
                                </h1>
                              </td>
                              <td
                                height="20px"
                                className="border-r-2 border-gray-300 w-28"
                              >
                                <h1 className="ml-2 mt-2 text-center">
                                  {data?.freight ? data?.freight : 0}
                                </h1>
                              </td>
                              <td
                                height="20px"
                                className="border-r-2 border-gray-300 w-28"
                              >
                                <h1 className="mt-2 text-center">
                                  {data?.advance ? data?.advance : 0}
                                </h1>
                              </td>
                              <td
                                height="20px"
                                className="border-r-2 border-gray-300 w-28"
                              >
                                <h1 className="mt-2 text-center">
                                  {data?.balance ? data?.balance : 0}
                                </h1>
                              </td>
                              {hasHamali && (
                                <td
                                  height="20px"
                                  className={` ${
                                    props.blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-28 `}
                                >
                                  <h1 className=" text-center mb-4">
                                    {data?.hamali ? data?.hamali : 0}
                                  </h1>
                                </td>
                              )}
                              {hasDetention && (
                                <td
                                  height="20px"
                                  className={` ${
                                    props.blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-28 mb-4 `}
                                >
                                  <h1 className=" text-center">
                                    {data?.detention ? data?.detention : 0}
                                  </h1>
                                </td>
                              )}

                              {hasExtraCharge && (
                                <td
                                  height="20px"
                                  className={` ${
                                    props.blackWhite
                                      ? "border-black border-r"
                                      : "border-r-2 border-black-300"
                                  }   items-center  w-28 mb-4 `}
                                >
                                  <h1 className=" text-center">
                                    {data?.extraCharge ? data?.extraCharge : 0}
                                  </h1>
                                </td>
                              )}

                              {hasCommission && (
                                <td height="20px" className=" w-28 mr-2">
                                  <h1 className=" text-center mb-4">
                                    {data?.commission ? data?.commission : 0}
                                  </h1>
                                </td>
                              )}
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </table>
                </div>

                <table
                  className={`border-y ${
                    blackWhite ? "border-black" : "border-primary-kirtiroadways"
                  } w-full font-bold font-sans`}
                >
                  <thead style={{ maxHeight: "5px" }}>
                    <tr height="20px" className="border-neutral-900 ">
                      <td className="font-semibold text-left text-sm pl-2">
                        <div>
                          {TOTALPAYMENTPWNER} :{" "}
                          {location?.commissionData?.invoiceData
                            .totalOwnerPayment
                            ? location?.commissionData?.invoiceData
                                .totalOwnerPayment
                            : 0}
                        </div>
                        <div>
                          {LESSCOMMISSION} :{" "}
                          {location?.commissionData?.invoiceData.totalCommission
                            ? location?.commissionData?.invoiceData
                                .totalCommission
                            : 0}
                        </div>
                        <div>
                          {TOTALPAIDDRIVER} :{" "}
                          {location?.commissionData?.invoiceData
                            .totalPaidToOwner
                            ? location?.commissionData?.invoiceData
                                .totalPaidToOwner
                            : 0}
                        </div>
                      </td>
                      <td height="5px" className=" w-28 justify-start align-middle p-0">
                        <h1
                          className={`font-bold  text-center  ${
                            !blackWhite
                              ? "bg-primary-kirtiroadways text-white"
                              : "border-2 border-black"
                          } py-2 `}
                        >
                          Total
                        </h1>
                      </td>
                      <td height="20px" className={`w-28  align-middle p-0 ${(hasDetention || hasExtraCharge || hasCommission || hasHamali) ? '' : 'pr-2'}`}>
                        <h1
                          className={`font-bold  text-center  ${
                            !blackWhite
                              ? "bg-primary-kirtiroadways text-white"
                              : "border-2 border-black"
                          } py-2 `}
                        >
                          {location?.commissionData?.invoiceData.totalBalance}
                        </h1>
                      </td>
                      {hasHamali && (
                        <td height="20px" className={`w-28  align-middle p-0 ${(hasDetention || hasExtraCharge || hasCommission) ? '' : 'pr-2'}`}>
                          <h1
                            className={`font-bold  text-center  ${
                              !blackWhite
                                ? "bg-primary-kirtiroadways text-white"
                                : "border-2 border-black"
                            } py-2 `}
                          >
                            {location?.commissionData?.invoiceData.totalHamali}
                          </h1>
                        </td>
                      )}
                      {hasDetention && (
                        <td height="20px" className={`w-28  align-middle p-0 ${(hasExtraCharge ||  hasCommission) ? '' : 'pr-2'}`}>
                          <h1
                            className={`font-bold  text-center  ${
                              !blackWhite
                                ? "bg-primary-kirtiroadways text-white"
                                : "border-2 border-black"
                            } py-2 `}
                          >
                            {
                              location?.commissionData?.invoiceData
                                .totalDetention
                            }
                          </h1>
                        </td>
                      )}
                      {hasExtraCharge && (
                        <td height="20px" className={`w-28  align-middle p-0 ${hasCommission ? '' : 'pr-2'}`}>
                          <h1
                            className={`font-bold  text-center  ${
                              !blackWhite
                                ? "bg-primary-kirtiroadways text-white"
                                : "border-2 border-black"
                            } py-2 `}
                          >
                            {
                              location?.commissionData?.invoiceData
                                .totalExtraCharge
                            }
                          </h1>
                        </td>
                      )}
                      {hasCommission && (
                        <td
                          height="20px"
                          className={`w-28 align-middle p-0 pr-2`}
                        >
                          <h1
                            className={`font-bold  text-center  ${
                              !blackWhite
                                ? "bg-primary-kirtiroadways text-white"
                                : "border-2 border-black"
                            } py-2 `}
                          >
                            {
                              location?.commissionData?.invoiceData
                                .totalCommission
                            }
                          </h1>
                        </td>
                      )}
                    </tr>
                  </thead>
                </table>

                {/* Extra Information */}
                <div className="flex flex-col justify-start items-start ml-2 font-sans">
                  <p>Amount Chargeable (in words)</p>
                  <p className="font-mono text-xl border-double border-black tracking-widest border-b-4 w-full text-left">
                    {/* Four Thousand Four Hundred Fifty */}
                    {location?.commissionData?.invoiceData.totalInWords} ONLY
                  </p>
                </div>

                {/* terms and condition */}
                <div className="flex  flex-row text-sm	 justify-between mt-2 mx-2 font-sans">
                  <div className="text-left flex flex-col space-y-1">
                    <p className="border-black border-b-4 w-44 pb-1">
                      Terms & Conditions
                    </p>
                    <p>{InvoicSubject}</p>
                  </div>
                  <div className="text-left text-sm">E. & O.E.</div>
                  <div className="text-left text-sm ">FOR,</div>
                  {/* Extra informations */}
                  <div className="text-lg font-bold text-right ">
                    <img src={sign} alt="signature" width={100} />
                    {/* {KIRTIROADLINES} */}
                  </div>
                </div>
                {/* gpay*/}
                {/* <div className="text-left ml-2 text-sm	">
                  {GPAYCONTENT} ({KIRTIROADLINES})
                </div> */}
                <div className="flex border-black border-b-2 flex-row justify-between items-start mx-2 mb-2 font-sans text-sm	">
                  <div className="text-left ">
                    <p>{/* {PaymentbypayeeCchequeDrafs} {KIRTIROADLINES} */}</p>
                  </div>
                  <div>
                    <p className="font-bold">{AuthorizedSignatory}</p>
                  </div>
                </div>

                {/* printing */}
                <h2
                  className={`text-sm mt-0 mb-0 text-center font-bold ${
                    !blackWhite
                      ? "bg-primary-kirtiroadways text-white"
                      : "border border-black"
                  } rounded-md p-2  font-sans`}
                >
                  {computerGeneratedInvoice}
                </h2>
              </div>
            </div>
          )}
          {/* printing */}
          <div className=" ">
            <VehicleInvoiceDownloadView
              commissionData={location?.commissionData?.invoiceData}
              note={location?.note?.note}
              profileData={profileData}
              secondaryPhoneNo={secondaryPhoneNo}
              showBorder={showBorder}
              blackWhite={blackWhite}
              invoiceNo={location?.commissionData?.invoiceData.invoiceNo}
              mobilePrint={mobilePrint}
              setIsLoading={setIsLoading}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VehicleInvoiceView;
