import React, { useRef } from "react";
import { useState } from "react";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
import DatePicker from "react-datepicker";
import Checkbox from "../../Components/UI/Checkbox";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import axiosInstance from "../../Utils/API";
import { GETVIEWBOOKINGBYID, UPDATE_BOOKING } from "../../Utils/ApiGlobal";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../Components/UI/Loader";
import SearchVehicleNo from "../../Components/Functionality/SearchVehicleNo";
// import SearchVelicleOwner from "../../Components/Functionality/SearchVelicleOwner";
import CompanyInformation from "../../Components/Functionality/CompanyInformation";
import { ConfirormationModal } from "../../Components/UI/ConfirormationModal";
import APILoader from "../../Components/UI/APILoader";
import { GET_VEHICLE_OWNERBYID } from "../../Utils/ApiGlobal";
import moment from "moment";
import UpdateLrNo from "./UpdateLrNo";
import { sortingArray } from "../../Components/Functionality/Helper";
import WithoutLrNo from "./WithoutLrNo";

const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2 uppercase border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);

export default function UpdateBooking() {
  const [LRDate, setLRDate] = useState(new Date());
  const [isBuilltyRecivedDate, setIsBuilltyRecivedDate] = useState(new Date());
  const [balanceCal, setBalanceCal] = useState();
  const [totalCal, setTotalCal] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [builltyData, setBuilltyData] = useState([]);
  const [builltyLength, setBuilltyLength] = useState();
  const [checked, setChecked] = useState(builltyLength > 0 ? true : false);
  const [isBuilltyAvailable, setIsBuilltyAvailable] = useState();
  const [vehicleOwnerId, setVehicleOwnerId] = useState();
  const [vehicleOwnerName, setVehicleOwnerName] = useState();
  // const [clearDefaultvalue, setClearDefaultvalue] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState();
  const [consignorName, setConsignorName] = useState();
  const [consignorAdd, setConsignorAdd] = useState();
  const [consignorBillAdd, setConsignorBillAdd] = useState("");
  const [consignorGST, setConsignorGST] = useState();
  const [consigneeName, setConsigneeName] = useState();
  const [consigneeAdd, setConsigneeAdd] = useState();
  const [consigneeBillAdd, setConsigneeBillAdd] = useState("");
  const [consigneeGST, setConsigneeGST] = useState(); // eslint-disable-next-line
  const [otherPartyName, setOtherPartyName] = useState(""); // eslint-disable-next-line
  const [otherPartyAdd, setOtherPartyAdd] = useState(""); // eslint-disable-next-line
  const [otherPartyGST, setOtherPartyGST] = useState(""); // eslint-disable-next-line
  const [payTo, setPayTo] = useState("");
  const [isThirdParty, setIsThirdParty] = useState(false);
  const [partyName, setPartyName] = useState("");
  const childButtonRef = useRef(null);

  // const [newvehicleOwnerName, setNewVehicleOwnerName] = useState();
  const [showHamali, setShowHamali] = useState({
    Freight: 0,
    hamali: 0,
    detention: 0,
    extraCharge: 0,
    Advance: 0,
  });
  const [loader, setLoader] = useState({
    loading: false,
    message: "",
    icon: "",
  }); // eslint-disable-next-line
  const [formErrors, setFormErrors] = useState({});

  const history = useHistory();
  const location = useLocation();
  //Onchange input fields store data array
  const [addFormValues, setAddFormValues] = useState({});

  //Get Data by Booking ID
  const GetBookingData = async (bookingId) => {
    setIsLoading(true);
    await axiosInstance
      .get(`${GETVIEWBOOKINGBYID}?bookingId=${bookingId}`)
      .then((response) => {
        const data = response?.data?.data?.bookingDetails;
        // loadingDate
        setLRDate(
          moment(response?.data?.data?.bookingDetails?.loadingDate).toDate()
        );
        setIsBuilltyRecivedDate(
          moment(
            response?.data?.data?.bookingDetails?.billtyReceiveDate
          ).toDate()
        );
        setBuilltyData(
          sortingArray(response?.data?.data?.billtyDetails, "lrNo")
        );
        setBuilltyLength(response?.data?.data?.billtyDetails.length);
        const newValues = {
          ...addFormValues,
          Freight: parseInt(data?.freigth === null ? 0 : data?.freigth),
          Advance: parseInt(data?.advance === null ? 0 : data?.advance),
          hamali: parseInt(data?.hamali === null ? 0 : data?.hamali), //Data accepts INT format
          detention: parseInt(data?.detention === null ? 0 : data?.detention), //Data accepts INT format
          extraCharge: parseInt(
            data?.extraCharge === null ? 0 : data?.extraCharge
          ),
          LR_No: response?.data?.data?.bookingDetails?.lrNo
            ? response?.data?.data?.bookingDetails?.lrNo
            : localStorage.getItem("lrValue"),
        };
        // console.log(data)
        setBookingData(data);
        setPayTo(response?.data?.data?.bookingDetails?.paidBy);
        setIsThirdParty(response?.data?.data?.bookingDetails?.isThirdPartyPay);
        setPartyName(response?.data?.data?.bookingDetails?.thirdPartyName);
        // setLRDate(moment(response?.data?.data?.bookingDetails?.loadingDate)
        setConsignorAdd(response?.data?.data?.bookingDetails?.consignerAddress);
        setConsignorBillAdd(
          response?.data?.data?.bookingDetails?.consignerBillToAddress
        );
        setConsignorGST(response?.data?.data?.bookingDetails?.consignerGst);
        // setOtherPartyName(response?.data?.data?.bookingDetails?.otherPartyName);
        setOtherPartyGST(response?.data?.data?.bookingDetails?.otherPartyGst);
        setOtherPartyAdd(
          response?.data?.data?.bookingDetails?.otherPartyAddress
        );
        setConsigneeAdd(response?.data?.data?.bookingDetails?.consigneeAddress);
        setConsigneeBillAdd(
          response?.data?.data?.bookingDetails?.consigneeBillToAddress
        );
        setConsigneeGST(response?.data?.data?.bookingDetails?.consigneeGst);
        setChecked(
          response?.data?.data?.billtyDetails.length > 0 ? true : false
        );
        setIsBuilltyAvailable(
          response?.data?.data?.bookingDetails?.isBiltyReceive
        );
        let diff = parseInt(data?.freigth) - parseInt(data?.advance);
        setBalanceCal(diff);
        setAddFormValues(newValues);
        if (
          data?.hamali === null &&
          data?.detention === null &&
          data?.extraCharge === null
        ) {
          let diffrence = parseInt(data?.freigth) - parseInt(data?.advance);
          setTotalCal(diffrence);
          setShowHamali(false);
        } else {
          const total =
            parseInt(data?.freigth === null ? 0 : data?.freigth) -
            parseInt(data?.advance === null ? 0 : data?.advance) +
            parseInt(data?.hamali === null ? 0 : data?.hamali) +
            parseInt(data?.detention === null ? 0 : data?.detention) +
            parseInt(data?.extraCharge === null ? 0 : data?.extraCharge);
          setTotalCal(total);

          setAddFormValues(newValues);
          setShowHamali(true);
        }
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        return;
      });
  };
  // To fetch Data from previous route
  useEffect(() => {
    GetBookingData(location?.state?.data?.data?.bookingId);
    localStorage.removeItem("lrDetails");
  }, [location?.state?.data?.data?.bookingId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Select handle Vehicle Number
  const handleVehicleOnChange = (e) => {
    // console.log(e.label);
    // console.log(e.value);
    // console.log("Owner Id", e.ownerId);
    setVehicleNumber(e.label);
    setVehicleOwnerId(e.ownerId);
    // setNewVehicleOwnerName(e.vehicleOwner);
  };

  // Get Vehicle Owner Name By ID
  useEffect(() => {
    axiosInstance
      .get(`${GET_VEHICLE_OWNERBYID}?ownerId=${vehicleOwnerId}`)
      .then((response) => {
        // console.log(response)
        // console.log(response?.data?.data[0].ownerName)
        setVehicleOwnerName(response?.data?.data[0]?.ownerName);
      });
  }, [vehicleOwnerId]);

  // Select handle company
  const handleOnChangeCompany = (e) => {
    if (e.type.name === "cosignor") {
      setConsignorName(e.label);
      setConsignorAdd(e.add);
      setConsignorGST(e.value);
      setConsignorBillAdd(e.billAdd);
    } else if (e.type.name === "consignee") {
      setConsigneeName(e.label);
      setConsigneeAdd(e.add);
      setConsigneeGST(e.value);
      setConsigneeBillAdd(e.billAdd);
    } else if (e.type.name === "OtherParty") {
      setOtherPartyName(e.label);
      setOtherPartyAdd(e.add);
      setOtherPartyGST(e.value);
    }
  };
  // Pay to handle on change
  // eslint-disable-next-line
  const handleOnChangePayto = (e) => {
    if (e.value === "Both") {
      setIsThirdParty(false);
    }
    setPayTo(e.value);
  };

  // Pay to handle on change
  // eslint-disable-next-line
  const handleOnChangePartyName = (e) => {
    setPartyName(e.value);
  };

  //Onchange values handleClick event
  const values_handler = (e) => {
    let name = e.target.name;
    let newValue;
    let value = e.target.value;
    if (typeof value === "string" && value.trim().length === 0) {
      newValue = 0;
    } else {
      newValue = value;
    }
    const newValues = {
      ...addFormValues,
      [name]: newValue,
    };
    setAddFormValues(newValues);
    calBalance(newValues);
    calTotal(newValues);
  };

  // Total Calculation (Freight - Advance)
  const calBalance = (addFormValues) => {
    // console.log(addFormValues, "addFormValues");
    const { Freight, Advance } = addFormValues;
    const balance = parseInt(Freight) - parseInt(Advance);
    // console.log(parseInt(balance), "balance");
    setBalanceCal(parseInt(balance));
    // setTotalCal(balance);
  };

  // Total calTotal (Freight - Advance + hamali + detention+ extraCharge)
  const calTotal = (addFormValues) => {
    const { Freight, Advance, hamali, detention, extraCharge } = addFormValues;
    const total =
      parseInt(Freight) -
      parseInt(Advance) +
      parseInt(hamali) +
      parseInt(detention) +
      parseInt(extraCharge);
    // console.log(total, "total");
    setTotalCal(parseInt(total));
  };

  // Form values into spread objects
  const formValueConvertor = (formObj) => ({
    ...formObj,
  });

  const getFormJSON = (form) => {
    const data = new FormData(form);
    return Array.from(data.keys()).reduce((result, key) => {
      var value = data.get(key);
      if (value) result[key] = value;
      result[key] = value || " ";
      return result;
    }, {});
  };

  //Add Booking API
  const PostUpdateBooking = async (ev) => {
    ev.preventDefault();

    if (childButtonRef.current) {
      // If the ref exists, trigger a click event on the child button
      childButtonRef.current.click();
    } else {
      localStorage.removeItem("lrDetails");
    }

    var obj = formValueConvertor(getFormJSON(ev.target));
    const lrData = JSON.parse(localStorage.getItem("lrDetails")) || [];

    let UPDATE_BOOKING_DATA = {
      BookingId: location?.state?.data?.data?.bookingId,
      LoadingDate: LRDate,
      VehicleNo: vehicleNumber ? vehicleNumber : bookingData?.vehicleNo,
      VehicleOwner: vehicleOwnerName
        ? vehicleOwnerName.toUpperCase()
        : bookingData?.vehicleOwner,
      ConsignerName: consignorName
        ? consignorName.toUpperCase()
        : bookingData?.consignerName,
      ConsignerAddress: consignorAdd.toUpperCase(),
      ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
      ConsignerGst: consignorGST.toUpperCase(),
      ConsigneeName: consigneeName
        ? consigneeName.toUpperCase()
        : bookingData?.consigneeName,
      ConsigneeAddress: consigneeAdd.toUpperCase(),
      ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
      ConsigneeGst: consigneeGST.toUpperCase(),
      LrDetails: lrData,
      Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
      Advance: parseInt(addFormValues.Advance), //Data accepts INT format
      Balance: parseInt(balanceCal), //Data accepts INT format
      Hamali: obj.hamali === 0 ? null : addFormValues.hamali,
      Detention: obj.detention === 0 ? null : addFormValues.detention,
      ExtraCharge: obj.extraCharge === 0 ? null : addFormValues.extraCharge,
      Total: totalCal,
      IsDriverPaidBalance: parseInt(obj.Driver_paid_bal), //Data accepts INT format
      balanceInvoiceId: obj.Balance_Paid_Bill_No,
      From:
        lrData?.length === 1
          ? lrData[0].From
          : lrData?.length === 0
          ? obj.from
          : null,
      To:
        lrData?.length === 1
          ? lrData[0].To
          : lrData?.length === 0
          ? obj.to
          : null,
      // CompanyInvoiceNo: lrData?.length === 0 ? obj.Bill_No : null,
      CompanyInvoiceNo: obj.companyInvoiceNo,
      OtherPartyName: obj.otherPartyName || bookingData?.otherPartyName,
      OtherPartyAddress: obj.otherPartyAddress,
      OtherPartyGst: obj.otherPartyGst,
      ThirdPartyName: obj.thirdPartyName || bookingData?.thirdPartyName ,
      ThirdPartyCommission: obj.thirdPartyCommission,
      ThirdPartyGst: obj.thirdPartyComment,
      ConsignerAmount:obj.consignerAmount,
      ConsigneeAmount: obj.consigneeAmount,
      BookingAmount: obj.bookingAmount,
      PaidBy: obj.paidBy,
      IsThirdPartyPay: obj.isThirdPartyPay === "on" ? true : false,

      // FInancial Year
      FinancialYear: obj.FinancialYear,
      Note: obj.note.toUpperCase(),
      IsBiltyReceive: isBuilltyAvailable,
      billtyReceiveDate: isBuilltyRecivedDate,
    };

    setLoader({ ...loader, loading: true });
    await axiosInstance
      .post(UPDATE_BOOKING, UPDATE_BOOKING_DATA)
      .then((response) => {
        // console.log(response.data)
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/pending-booking",
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
        return;
      });
  };

  const GotoUpdateBuillty = async (ev) => {
    ev.preventDefault();
    // console.log(addFormValues.LR_No)

    if (childButtonRef.current) {
      // If the ref exists, trigger a click event on the child button
      childButtonRef.current.click();
    }
    // console.log( bookingData.lrNo)
    const lrData = JSON.parse(localStorage.getItem("lrDetails"));

    setLoader({ ...loader, loading: true });
    let UPDATE_BOOKING_DATA = {
      BookingId: location?.state?.data?.data?.bookingId,
      LoadingDate: LRDate,
      VehicleNo: vehicleNumber
        ? vehicleNumber.toUpperCase()
        : bookingData?.vehicleNo,
      VehicleOwner: vehicleNumber
        ? vehicleNumber.toUpperCase()
        : bookingData?.vehicleOwner,
      ConsignerName: consignorName
        ? consignorName.toUpperCase()
        : bookingData?.consignerName,
      ConsignerAddress: consignorAdd.toUpperCase(),
      ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
      ConsignerGst: consignorGST.toUpperCase(),
      ConsigneeName: consigneeName
        ? consigneeName.toUpperCase()
        : bookingData?.consigneeName,
      ConsigneeAddress: consigneeAdd.toUpperCase(),
      ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
      ConsigneeGst: consigneeGST.toUpperCase(),
      // Other new
      // otherPartyName: bookingData?.otherPartyName,
      // otherPartyGst: bookingData?.otherPartyGst,
      // otherPartyAddress: bookingData?.otherPartyAddress,

      // Other new feature
      From: lrData?.length === 1 ? lrData[0].From : null,
      To: lrData?.length === 1 ? lrData[0].To : null,
      LrDetails: JSON.parse(localStorage.getItem("lrDetails"))
        ? JSON.parse(localStorage.getItem("lrDetails"))
        : bookingData?.lrDetails,
      Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
      Advance: parseInt(addFormValues.Advance), //Data accepts INT format
      Balance: parseInt(balanceCal), //Data accepts INT format
      Hamali: addFormValues.hamali === 0 ? null : addFormValues.hamali,
      Detention: addFormValues.detention === 0 ? null : addFormValues.detention,
      ExtraCharge:
        addFormValues.extraCharge === 0 ? null : addFormValues.extraCharge,
      Total: totalCal,
      IsDriverPaidBalance: parseInt(
        addFormValues.Driver_paid_bal
          ? addFormValues.Driver_paid_bal
          : bookingData.isDriverPaidBalance
      ), //Data accepts INT format
      balanceInvoiceId: addFormValues.Balance_Paid_Bill_No
        ? addFormValues.Balance_Paid_Bill_No
        : bookingData.balanceInvoiceId,
      Note: addFormValues.note
        ? addFormValues?.note.toUpperCase()
        : bookingData?.note,
      // FInancial Year
      FinancialYear: addFormValues.FinancialYear
        ? addFormValues.FinancialYear
        : bookingData?.financialYear,
      // New values
      IsThirdPartyPay: isThirdParty,
      ThirdPartyName: partyName ? partyName : bookingData?.thirdPartyName,
      ThirdPartyComment: addFormValues?.ThirdPartyComment
        ? addFormValues?.ThirdPartyComment
        : bookingData?.thirdPartyComment,
      ThirdPartyCommission: addFormValues?.ThirdPartyCommission
        ? addFormValues?.ThirdPartyCommission
        : bookingData?.thirdPartyCommission,
      IsBiltyReceive: isBuilltyAvailable,
      billtyReceiveDate: isBuilltyRecivedDate,
    };

    await axiosInstance
      .post(UPDATE_BOOKING, UPDATE_BOOKING_DATA)
      .then((response) => {
        // console.log(response.data)
        ConfirormationModal(response.data.data.message, "success", 2000);
        history.push({
          pathname: "/bilty/edit-Buillty",
          state: {
            data: {
              data: {
                bookingId: location?.state?.data?.data?.bookingId,
                builltyId: builltyData?.billtyId,
              },
            },
          },
        });
      })
      .catch((error) => {
        ConfirormationModal(error.message, "error", 2000);
      });
  };
  // Builty checkbox click
  const builltyClick = async (e, path) => {
    e.preventDefault();
    const lrData = JSON.parse(localStorage.getItem("lrDetails"));

    if (addFormValues?.LR_No === null) {
      setFormErrors({ LR_No: true });
      // console.log("fetch data")
      // setNotif(true);
      // setNotifOpts(error_notification({ title: `Please select LR No` }));
      return;
    } else {
      if (e.target.checked === true) {
        let UPDATE_BOOKING_DATA = {
          BookingId: location?.state?.data?.data?.bookingId,
          LoadingDate: LRDate,
          VehicleNo: vehicleNumber
            ? vehicleNumber.toUpperCase()
            : bookingData?.vehicleNo,
          VehicleOwner: vehicleNumber
            ? vehicleNumber.toUpperCase()
            : bookingData?.vehicleOwner,
          ConsignerName: consignorName
            ? consignorName.toUpperCase()
            : bookingData?.consignerName,
          PaidBy: payTo === "Third Party" ? "ThirdParty" : payTo,
          // New values
          IsThirdPartyPay: isThirdParty,
          ThirdPartyName: partyName ? partyName : bookingData?.thirdPartyName,
          ThirdPartyComment: addFormValues?.ThirdPartyComment
            ? addFormValues?.ThirdPartyComment
            : bookingData?.thirdPartyComment,
          ThirdPartyCommission: addFormValues?.ThirdPartyCommission
            ? addFormValues?.ThirdPartyCommission
            : bookingData?.thirdPartyCommission,
          ConsignerAddress: consignorAdd.toUpperCase(),
          ConsignerBillToAddress: consignorBillAdd.toUpperCase(),
          ConsignerGst: consignorGST.toUpperCase(),
          ConsigneeName: consigneeName
            ? consigneeName.toUpperCase()
            : bookingData?.consigneeName,
          ConsigneeAddress: consigneeAdd.toUpperCase(),
          ConsigneeBillToAddress: consigneeBillAdd.toUpperCase(),
          ConsigneeGst: consigneeGST.toUpperCase(),

          FinancialYear: addFormValues.FinancialYear
            ? addFormValues.FinancialYear
            : bookingData?.financialYear,
          LrDetails: JSON.parse(localStorage.getItem("lrDetails")),
          From: lrData?.length === 1 ? lrData[0].From : null,
          To: lrData?.length === 1 ? lrData[0].To : null,
          Freigth: parseInt(addFormValues.Freight), //Data accepts INT format
          Advance: parseInt(addFormValues.Advance), //Data accepts INT format
          Balance: parseInt(balanceCal), //Data accepts INT format
          Hamali: addFormValues.hamali === 0 ? null : addFormValues.hamali,
          Detention:
            addFormValues.detention === 0 ? null : addFormValues.detention,
          ExtraCharge:
            addFormValues.extraCharge === 0 ? null : addFormValues.extraCharge,
          Total: totalCal,
          IsDriverPaidBalance: parseInt(
            addFormValues.Driver_paid_bal
              ? addFormValues.Driver_paid_bal
              : bookingData.isDriverPaidBalance
          ), //Data accepts INT format
          balanceInvoiceId: addFormValues.Balance_Paid_Bill_No
            ? addFormValues.Balance_Paid_Bill_No.toUpperCase()
            : bookingData.balanceInvoiceId,
          Note: addFormValues.note
            ? addFormValues?.note.toUpperCase()
            : bookingData?.note,
          IsBiltyReceive: isBuilltyAvailable,
          billtyReceiveDate: isBuilltyRecivedDate,
        };
        setLoader({ ...loader, loading: true });
        await axiosInstance
          .post(UPDATE_BOOKING, UPDATE_BOOKING_DATA)
          .then((response) => {
            ConfirormationModal(response.data.data.message, "success", 2000);
            history.push({
              pathname: path,
              state: {
                bookingId: response.data.data.bookingId,
              },
            });
          })
          .catch((error) => {
            ConfirormationModal(error.message, "error", 2000);
          });
      } else {
        setChecked(false);
      }
    }
  };

  // Create blank buillty
  // const createBlanckBuillty = () => {
  //   // /bilty/billty-invoice-view
  //   let path = "/bilty/blank-buillty";
  //   history.push({
  //     pathname: path,
  //   });
  // };

  return (
    <>
      <DashBoardLayout pageTitle="Edit Booking">
        <APILoader loader={loader} />
        {/* <Notification
          show={notif}
          setShow={setNotif}
          {...notifOpts}
          supportContent={true}
        ></Notification> */}
        {isLoading ? (
          <Loader />
        ) : (
          <div className=" p-6 shadow-lg ring-1 ring-black ring-opacity-5 border-2 rounded-md ">
            <form onSubmit={PostUpdateBooking}>
              <div className="">
                <div className=" text-left space-y-6">
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="L. Date" isRequired="true" />
                      <div className="flex flex-row justify-center ">
                        {/* loadingDate */}
                        <DatePicker
                          dateFormat="dd-MM-yyyy"
                          className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                          selected={LRDate}
                          onChange={(date) => setLRDate(date)}
                          name="L_date"
                        />
                      </div>
                    </div>
                    {/* Vehicle No. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle No." isRequired="true" />
                      <SearchVehicleNo
                        // defaultValue={
                        //   clearDefaultvalue === false
                        //     ? bookingData?.vehicleNo
                        //     : ""
                        // }
                        defaultValue={
                          vehicleNumber ? vehicleNumber : bookingData?.vehicleNo
                        }
                        // vehicleOwnerId={vehicleOwnerId}
                        handleOnChange={handleVehicleOnChange}
                      />
                    </div>
                    {/* Vehicle Owner Name. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Owner" />
                      {/* <SearchVelicleOwner
                        newvehicleOwnerName={newvehicleOwnerName}
                        defaultValue={
                          clearDefaultvalue === false
                            ? bookingData?.vehicleOwner
                            : ""
                        }
                        handleOnChange={handleOnChange}
                      /> */}
                      <div className="peer  w-full px-3 py-2  border border-gray-300 placeholder-gray-500       rounded-md focus:outline-none  focus:z-10 sm:text-sm ">
                        {vehicleOwnerName
                          ? vehicleOwnerName
                          : bookingData?.vehicleOwner}
                      </div>
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Consignor's Name */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Consignor's Name" isRequired="true" />
                      <CompanyInformation
                        defaultValue={bookingData?.consignerName}
                        name="cosignor"
                        handleOnChange={handleOnChangeCompany}
                      />
                    </div>
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      {/* Consignor's Address */}
                      <Label label="Consignor's Address" isRequired="true" />
                      <Input
                        name="Consignor_Address"
                        type="text"
                        value={consignorAdd}
                        onChange={values_handler}
                        required
                      />
                    </div>
                    {/*Consignor's GST No.  */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Consignor's GST No." />
                      <Input
                        name="Consignor_GST_No"
                        type="text"
                        value={consignorGST}
                        onChange={values_handler}
                        required
                      />
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/*  Consignee's Name */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Consignee's Name" isRequired="true" />
                      <CompanyInformation
                        name="consignee"
                        defaultValue={bookingData?.consigneeName}
                        handleOnChange={handleOnChangeCompany}
                      />
                    </div>
                    {/* Consignee's Address */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Consignee's Address" isRequired="true" />
                      <Input
                        name="Consignee_Address"
                        value={consigneeAdd}
                        onChange={values_handler}
                        required
                      />
                    </div>
                    {/* Consignee's GST No. */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Consignee's GST No." />
                      <Input
                        name="Consignee_GST_No"
                        type="text"
                        value={consigneeGST}
                        onChange={values_handler}
                        required
                      />
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Finacial Year */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Financial Year" isRequired="true" />
                      <Input
                        name="FinancialYear"
                        type="text"
                        defaultValue={bookingData?.financialYear}
                        onChange={values_handler}
                        required
                      />
                    </div>
                    {/* {bookingData?.lrDetails?.length === 0 && (
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="From" isRequired="true" />
                        <Input
                          name="From"
                          type="text"
                          defaultValue={bookingData?.from}
                          onChange={values_handler}
                        />
                        {formErrors.From === true && (
                          <p className="text-red-500 font-bold text-left text-xs">
                            Please enter From
                          </p>
                        )}
                      </div>
                    )} */}
                    {/* {bookingData?.lrDetails?.length === 0 && (
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="To" isRequired="true" />
                        <Input
                          name="To"
                          type="text"
                          defaultValue={bookingData?.to}
                          onChange={values_handler}
                        />
                        {formErrors.To === true && (
                          <p className="text-red-500 font-bold text-left text-xs">
                            Please enter To
                          </p>
                        )}
                      </div>
                    )} */}
                  </div>
                  {/* {bookingData?.lrDetails?.length === 0 && (
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Company Invoice No." isRequired="true" />
                        <Input
                          name="Bill_No"
                          type="text"
                          onChange={values_handler}
                          defaultValue={bookingData?.companyInvoiceNo}

                        />
                        {formErrors.CompanyInvoiceNo === true && (
                          <p className="text-red-500 font-bold text-left text-xs">
                            Please enter Company Invoice Number
                          </p>
                        )}
                      </div>
                    </div>
                  )} */}
                  {(bookingData?.lrDetails?.length === 0) && (
                    <WithoutLrNo
                      addFormValues={bookingData}
                      setAddFormValues={setBookingData}
                      values_handler={values_handler}
                    />
                  )}

                  <UpdateLrNo
                    items={bookingData?.lrDetails}
                    childButtonRef={childButtonRef}
                    // ref={childRef}
                  />

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/*Freight  */}
                    <div className=" flex flex-col  text-left md:w-1/2">
                      <Label label="Vehicle Freight" isRequired="true" />
                      <Input
                        type="text"
                        step="any"
                        name="Freight"
                        defaultValue={bookingData?.freigth}
                        onChange={values_handler}
                        required
                      />
                    </div>
                    {/* Advance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Advance" isRequired="true" />
                      <Input
                        name="Advance"
                        type="text"
                        step="any"
                        defaultValue={bookingData?.advance}
                        onChange={values_handler}
                        required
                      />
                    </div>
                    {/* Balance */}
                    <div className=" flex md:w-1/2  flex-col  text-left">
                      <Label label="Vehicle Balance" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {" "}
                        {balanceCal}
                      </p>
                    </div>
                  </div>

                  {/* Hanmali, Detention & Extra Charge  */}
                  <div>
                    <div className=" flex flex-row space-x-5 text-left ">
                      <button
                        className="font-bold pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover p-2 rounded-md text-white "
                        type="button"
                        onClick={() => {
                          setShowHamali(true);
                        }}
                      >
                        +
                      </button>
                      <button
                        className="font-bold  pt-0 text-2xl text-center bg-primary-kirtiroadways hover:bg-primary-kirtiroadways-hover py-2 px-3 rounded-md text-white "
                        type="button"
                        onClick={() => {
                          setShowHamali(false);
                        }}
                      >
                        -
                      </button>
                    </div>
                  </div>
                  {showHamali && (
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Hamali" isRequired="true" />
                        <Input
                          defaultValue={bookingData?.hamali}
                          onChange={values_handler}
                          name="hamali"
                          type="text"
                          step="any"
                        />
                      </div>
                      <div className=" flex md:w-1/2  flex-col  text-left">
                        <Label label="Detention" isRequired="true" />
                        <Input
                          defaultValue={bookingData?.detention}
                          onChange={values_handler}
                          name="detention"
                          type="text"
                          step="any"
                        />
                      </div>
                      <div className=" flex flex-col  text-left md:w-1/2">
                        <Label label="Extra Charge" isRequired="true" />
                        <Input
                          defaultValue={bookingData?.extraCharge}
                          onChange={values_handler}
                          name="extraCharge"
                          type="text"
                          step="any"
                        />
                      </div>
                    </div>
                  )}
                  {/* Total */}
                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    <div className=" flex md:w-1/3 w-full  flex-col  text-left">
                      <Label label="Total" isRequired="true" />
                      <p
                        className="
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-gray-100 bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        {" "}
                        {totalCal}
                      </p>
                      {/* <p
                        defaultValue={totalCal}
                        type="text"
                        step="any"
                        name="total"
                        required
                      /> */}
                    </div>
                    {/*Driver Paid Balance  */}
                    {/* <div className=" flex flex-col md:w-1/3 text-left w-full">
                      <Label label="Driver Paid Balance" />
                      <Input
                        onChange={values_handler}
                        defaultValue={bookingData?.isDriverPaidBalance}
                        name="Driver_paid_bal"
                      />
                    </div> */}
                    {/*Balance Paid Bill No. */}
                    {/* <div className=" flex md:w-1/3 w-full  flex-col  text-left">
                      <Label label="Balance Paid Bill No." isRequired="true" />
                      <Input
                        onChange={values_handler}
                        defaultValue={bookingData?.balanceInvoiceId}
                        name="Balance_Paid_Bill_No"
                        required
                      />
                    </div> */}
                  </div>

                  <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                    {/* Note */}
                    <div className=" w-full flex flex-col">
                      <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-4 space-y-4">
                        <div className=" flex flex-col  text-left md:w-1/2">
                          <Label label="Note" />
                          <textarea
                            defaultValue={bookingData?.note}
                            onChange={values_handler}
                            className="peer w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                            name="note"
                            id=""
                            cols="30"
                            rows="3"
                            type="text"
                          ></textarea>
                        </div>
                        {/* Is buillty available */}
                        {bookingData?.lrDetails?.length !== 0 && (
                          <>
                            <div className="w-32 flex  flex-col mt-4  text-center">
                              <Label label="Is Buillty Available" />
                              <Checkbox
                                defaultChecked={isBuilltyAvailable}
                                onClick={(ev) => {
                                  //  console.log(ev.target.checked)
                                  setIsBuilltyAvailable(ev.target.checked);
                                }}
                                className="accent-red-600	text-white"
                              />
                            </div>
                            <div className="w-1/2 flex flex-col  text-left ">
                              <Label label="Buillty Date" isRequired="true" />
                              <div className="min-w-full flex flex-row justify-center ">
                                {/* Buillty Avilable Date */}
                                <DatePicker
                                  dateFormat="dd-MM-yyyy"
                                  className="peer  min-w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                                  selected={isBuilltyRecivedDate}
                                  onChange={(date) =>
                                    setIsBuilltyRecivedDate(date)
                                  }
                                  name="builltyDate"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {bookingData?.lrDetails?.length !== 0 && (
                    <div className="flex md:flex-row flex-col md:space-x-4 md:space-y-0 space-y-4">
                      {/* Builty checkbox */}
                      <div className=" flex   flex-col  text-left">
                        {checked !== true ? (
                          <Label label="Buillty" />
                        ) : (
                          <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
                            Buillty Count: {builltyLength}
                          </p>
                        )}
                        <Checkbox
                          defaultChecked={checked}
                          className="accent-red-600	text-white"
                          handleClick={(e) =>
                            builltyClick(e, "/bilty/add-Multiple-builty")
                          }
                        />
                      </div>
                      {/* Builty checkbox */}
                      {/* {checked !== true && ( */}
                      {/* <div className=" flex  flex-col mt-4  text-left">
                      <Label label="Multiple Buillty" />
                      <Checkbox
                        onClick={(ev) => {
                          builltyClick(ev, "/bilty/add-Multiple-builty");
                        }}
                        className="accent-red-600	text-white"
                      />
                    </div> */}
                      {/* )} */}
                      {/* Builty checkbox */}
                      {/* <div className=" flex  flex-col mt-4  text-left">
                      <Label label="Blank Buillty" />
                      <Checkbox
                        onClick={(ev) => {
                          createBlanckBuillty(ev);
                        }}
                        className="accent-red-600	text-white"
                      />
                    </div> */}
                    </div>
                  )}
                </div>
              </div>
              <div className="items-center mt-4 space-x-2">
                <ButtonWithBG theme="primary" label="SAVE" type="submit" />
                {checked === true && (
                  <ButtonWithBG
                    onClick={(e) => {
                      GotoUpdateBuillty(e);
                    }}
                    type="submit"
                    theme="secondary"
                    label="Go to update buillty"
                  />
                )}
              </div>
            </form>
          </div>
        )}
      </DashBoardLayout>
    </>
  );
}
