import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PendingBooking from "../../Components/DataTables/PendingBooking";
import Button from "../../Components/UI/Button/Button";
import ButtonWithBG from "../../Components/UI/ButtonMain";
import SearchField from "../../Components/UI/SearchField";
import DashBoardLayout from "../../Layouts/private/DashboardLayout";
// import { SEARCH_PLACEHOLDER } from "../../Utils/Constants";
import { setting_svg } from "../../Utils/SVGListing";
import { GET_LR } from "../../Utils/ApiGlobal";
import axiosInstance from "../../Utils/API";
import DatePicker from "react-datepicker";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
const Input = (props) => (
  <input
    className="peer  w-full px-3 py-2 uppercase  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
    {...props}
  />
);

const Label = (props) => (
  <p className="flex flex-row text-gray-500 text-sm font-medium  mb-1">
    {props.label}
    {props.isRequired === "true" && (
      <>
        {" "}
        <span className="ml-1 text-red-500 block text-sm font-medium ">*</span>
      </>
    )}
  </p>
);
const BookingPending = () => {
  const ref = useRef(null);
  let history = useHistory();
  const [dateFilter, setDateFilter] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [lrValue, setLrValue] = useState("");
  const [loader, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [countOfLr, setcountOfLr] = useState(1);
  useEffect(() => {
    axiosInstance
      .get(GET_LR)
      .then((response) => {
        // console.log(response)
        setLrValue(response?.data?.data);
        setLoading(false);
        localStorage.setItem("lrValue", response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loader]);
  // To redirect to add page
  const redirectToAdd = () => {
    history.push({
      pathname: "/pending-booking/add-booking",
      state: { label: "Add", countOfLr: countOfLr },
    });
  };
  return (
    <DashBoardLayout pageTitle="Booking">
      {open && (
        <Transition show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-100"
            initialFocus={ref}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-300 bg-opacity-95 transition-opacity" />
            </Transition.Child>

            <div className=" fixed inset-0 z-10 overflow-y-auto">
              <div className="flex  min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="border-2 border-primary-kirtiroadways  rounded-2xl relative transform overflow-hidden shadow-md text-left transition-all  w-1/2">
                    <div className="bg-white px-8 pt-4 pb-4">
                      <div className="">
                        <div className=" text-center ">
                          <div className="mt-2">
                            <div className="flex flex-row justify-between space-x-4 mt-4">
                              <div className=" flex md:w-1/2  flex-col  text-left">
                                <Label label=" Lr no count?" />
                                <Input
                                  onChange={(val) =>
                                    setcountOfLr(val.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" px-4 py-3 flex justify-center  sm:px-6">
                          <button
                            onClick={redirectToAdd}
                            className={`inline-flex
                              justify-center 
                            ml-2
                            px-4 
                            py-2 
                            border 
                          border-gray-300 
                          shadow-sm 
                          text-sm 
                          font-medium 
                          rounded-md 
                          hover:bg-gray-50 
                          focus:outline-none 
                          text-primary-kirtiroadways`}
                            // ref={ref }
                            // disabled={!Rate && !Weight}
                            theme="primary"
                          >
                            OK
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      <div className="min-w-full space-y-4">
        {/* <Divider subtitle="Pending Booking" /> */}
        {/* Search and setting */}
        <div className="flex md:flex-row flex-col justify-between  gap-2 ">
          <div className=" flex md:flex-row space-x-2 flex-col gap-2">
            <div className="min-w-1/4">
              <SearchField
                placeholder="Enter value for searching "
                handleChange={setSearchValue}
                setSearchValue={setSearchValue}
                value={searchValue}
              />
            </div>
            <div className="min-w-1/4 max-w--1/4 ">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={dateFilter}
                className="peer	 w-full px-3 py-2  border border-gray-300 placeholder-gray-500  rounded-md focus:outline-none  focus:z-10 sm:text-sm "
                placeholderText={"SELECT DATE FOR FILTER"}
                onChange={(date) => setDateFilter(date)}
                isClearable
              />
            </div>
            <p className="md:mt-2 font-bold md:order-last	order-first">
              Upcoming Buillty No:{" "}
              {!lrValue || loader ? <span>Loading...</span> : lrValue}
            </p>
          </div>
          <div className="flex flex-row gap-2 items-start  md:order-last	order-first">
            {" "}
            <Button
              svg={setting_svg}
              onClick={() => history.push("/profile")}
            ></Button>
            <ButtonWithBG
              // handleClick={redirectToAdd}
              handleClick={() => setOpen(true)}
              theme="primary"
              label="Add Booking"
            />
          </div>
        </div>
      </div>

      <PendingBooking
        searchValue={searchValue}
        setLoading={setLoading}
        dateFilter={dateFilter}
      />
    </DashBoardLayout>
  );
};

export default BookingPending;
